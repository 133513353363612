<div *ngIf="loading" class="content-content has-text-centered">
  <button class="button is-loading"></button>
</div>
<div #formTag>
  <div *ngIf="!loading">
    <form [formGroup]="form">
      <div class="tabs">
        <ul>
          <li [ngClass]="formHandler.activeTab=='details' ? 'is-active' : ''" (click)="formHandler.activeTab='details'">
            <a>Details
            </a></li>
        </ul>
      </div>
      <div class="content-content is-tabs">
        <div *ngIf="formHandler.activeTab=='details'" class="tab-container">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Postleitzahl</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="postcode" placeholder="Name">
                </div>
              </div>
              <shared-form-autocomplete [autocompleteModel]="autocompleteDeliveryTour" (editItem)="editDeliveryTours($event)" (onChangeItems)="setDeliveryTours($event)"></shared-form-autocomplete>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Stadt</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="town" placeholder="Name">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-foot has-text-centered">
        <shared-form-submit-button (submit)="submit()" [formStatus]="formHandler.status"></shared-form-submit-button>
      </div>
    </form>
  </div>
</div>

