<div *ngIf="loading" class="content-content has-text-centered">
  <button class="button is-loading"></button>
</div>
<div #formTag>
  <div *ngIf="!loading">
    <form [formGroup]="form">
      <div class="tabs">
        <ul>
          <li [ngClass]="formHandler.activeTab=='details' ? 'is-active' : ''" (click)="formHandler.activeTab='details'">
            <a>Details
            </a></li>
          <li [ngClass]="formHandler.activeTab=='conditions' ? 'is-active' : ''" (click)="formHandler.activeTab='conditions'">
            <a>Bedingungen
            </a></li>
          <li [ngClass]="formHandler.activeTab=='description' ? 'is-active' : ''" (click)="formHandler.activeTab='description'">
            <a>Beschreibung
            </a></li>
          <li [ngClass]="formHandler.activeTab=='images' ? 'is-active' : ''" (click)="formHandler.activeTab='images'">
            <a>Bilder
            </a></li>
        </ul>
      </div>
      <div class="content-content is-tabs">
        <div class="tab-container" [ngClass]="formHandler.activeTab!='details' ? 'is-hidden' : ''">
          <div class="columns">
            <div class="column is-half-tablet">
              <div class="field">
                <label class="label">Position des dynamischen Artikels</label>
                <div class="control">
                  <input class="input is-small" type="number"
                         formControlName="position" placeholder="Position">
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-half-tablet">
              <div class="field">
                <label class="label">Kategorie <span class="is-pointer"><fa-icon [icon]="faInfoCircle" (click)="opInfoCategory.toggle($event, infoCategory)"></fa-icon></span></label>
                <div class="control">
                  <div class="select is-small">
                    <select formControlName="category" #infoCategory>
                      <option [value]="null">Bitte wählen</option>
                      <option value="selection">Auswahlmöglichkeit durch Kunden</option>
                      <option value="selectionWithOptionalMappingToCustomer">Auswahlmöglichkeit durch Kunden + optionale Hinzufügung zu Kundenkonto</option>
                      <option value="delivery">Versandkosten</option>
                      <option value="deliverySelectable">Versandkosten [Auswahlmöglichkeit]</option>
                      <option value="additionalArticle">Zugabeartikel</option>
                      <option value="others">Sonstige</option>
                    </select>
                  </div>
                </div>
                <p-overlayPanel  #opInfoCategory>
                  <ng-template pTemplate="content">
                    <p class="m-0">
                      <b>Auswahlmöglichkeit durch den Kunden</b><br>Der Kunde kann bei der Bestellung mehrere Optionen optional auswählen.<br><br>
                      <b>Auswahlmöglichkeit durch den Kunden + optionale Hinzufügung zu Kundenkonto</b><br>Die Artikel stehen bei der Bestellung als Option zur Verfügung. Zusätzlich  kann der Kunde diese optional zu seinem Kundenkonto hinzufügen. Sofern der Kunde die Artikel seinem Kundenkonto hinzugefügt hat, werden diesem automatisch hinzugefügt, wenn er sich einloggt.<br><br>
                      <b>Versandkosten</b><br>Hierunter werden mögliche Liefer- / Versandkosten erfasst.<br><br>
                      <b>Versandkosten [Auswahlmöglichkeit]</b><br>Der Kunde kann zwischen einer Versandart wählen. Es ist keine Mehrfachauswahl möglich. Diese Konfiguration wird z.B. eingesetzt, wenn der Kunde zwischen DHL oder UPS wählen kann. Es ist vorgesehen, dass mindestens eine Option ausgewählt ist.<br><br>
                      <b>Zugabeartikel</b><br><br>
                      <b>Sonstiges</b><br>Dieser Kategorie können alle Artikel zugeordnet werden, welche sich nicht zu den oben genannten Kategorien eingliedern lassen.<br><br>
                    </p>
                  </ng-template>
                </p-overlayPanel>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Name</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="name" placeholder="Name">
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Artikelnummer</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="articleNumber" placeholder="Artikelnummer">
                </div>
              </div>

            </div>
          </div>
          <div class="columns">
            <div class="column is-half-tablet">
              <label class="checkbox">
                <input type="checkbox" formControlName="setArticleAmountFromCart">
                Artikelanzahl von der Anzahl der Artikel im Warenkorb übernehmen, die die Bedingungen erfüllen
              </label>
              <div *ngIf="form.get('setArticleAmountFromCart')?.value == false" class="field mt-2">
                <label class="label">Artikel-Anzahl</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="articleAmount" placeholder="Artikel-Anzahl">
                </div>
              </div>
            </div>
          </div>
          <div class="price-container">
            <div class="columns is-multiline">
              <div class="column is-4 is-12-mobile">
                <div class="field">
                  <label class="label">Netto-Preis</label>
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="netPrice" placeholder="Netto-Preis">
                  </div>
                </div>

              </div>
              <div class="column is-4 is-12-mobile">
                <div class="field">
                  <label class="label">Netto-Preis pro Artikel</label>
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="netPricePerArticle" placeholder="Netto-Preis pro Artikel">
                  </div>
                </div>
              </div>
              <div class="column is-4 is-12-mobile">
                <div class="field">
                  <label class="label">Aufschlag pro Artikel ab Menge <span class="is-pointer"><fa-icon [icon]="faInfoCircle" (click)="opInfoPricePerArticleFromAmount.toggle($event, infoPricePerArticleAmount)"></fa-icon></span></label>
                  <div class="control">
                    <input class="input is-small" type="text" #infoPricePerArticleAmount
                           formControlName="pricePerArticleFromAmount" placeholder="Aufschlag pro Artikel ab Menge">
                    <p-overlayPanel  #opInfoPricePerArticleFromAmount>
                      <ng-template pTemplate="content">
                        <p class="m-0">
                          Der Aufschlag <b>pro Artikel</b> wird erst ab der erreichten Menge erfasst.
                        </p>
                      </ng-template>
                    </p-overlayPanel>
                  </div>
                </div>
              </div>
              <div class="column is-4 is-12-mobile">
                <div class="field">
                  <label class="label">Mehrwertsteuer in %</label>
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="taxRate" placeholder="Mehrwertsteuer in %">
                  </div>
                </div>
              </div>

    <!--          <div class="column is-4 is-12-mobile">
                <div class="field">
                  <label class="label">Netto-Gebühr</label>
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="netFee" placeholder="Netto-Gebühr">
                  </div>
                </div>
              </div>

              <div class="column is-4 is-12-mobile">
                <div class="field">
                  <label class="label">Brutto-Gebühr</label>
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="grossFee" placeholder="Brutto-Gebühr">
                  </div>
                </div>

              </div>-->
              <div class="column is-4 is-12-mobile">
                <div class="field">
                  <label class="label">Netto-Pfand</label>
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="netDeposit" placeholder="Netto-Pfand">
                  </div>
                </div>
              </div>

              <div class="column is-4 is-12-mobile">


                <div class="field">
                  <label class="label">Brutto-Pfand</label>
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="grossDeposit" placeholder="Brutto-Pfand">
                  </div>
                </div>
              </div>
              <div class="column is-4 is-12-mobile">
                <div class="field">
                  <label class="label">Prozentualer Wert vom Netto-Warenwert</label>
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="percentageFromNetMerchandiseValue" placeholder="Prozentualer Wert">
                  </div>
                </div>
              </div>
              <div class="column is-4 is-12-mobile">
                <div class="field">
                  <label class="label">Prozentualer Wert von der Netto-Summe</label>
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="percentageFromNetTotalSum" placeholder="Prozentualer Wert">
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div class="columns mt-2">
            <div class="column">
              <label class="checkbox">
                <input type="checkbox" formControlName="addAutomatically">
                Automatische Erfassung des dynamischen Artikel, wenn Bedingungen erfüllt sind
              </label><br>
              <label class="checkbox">
                <input type="checkbox" formControlName="deletable">
                Artikel kann vom Kunden aus dem Warenkorb gelöscht werden
              </label>
            </div>
          </div>
        </div>
        <div class="tab-container" [ngClass]="formHandler.activeTab!='conditions' ? 'is-hidden' : ''">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Ab Artikelmenge</label>
                <div class="control">
                  <input class="input is-small" type="number"
                         formControlName="fromCartAmount" placeholder="Ab Artikelmenge">
                </div>
              </div>
              <div class="field">
                <label class="label">Ab Datum</label>
                <p class="control has-icons-left" (click)="from.open()">
                  <input [matDatepicker]="from" class="input is-small" placeholder="Ab Datum" formControlName="fromDate" >
                  <span class="icon is-small is-left">
                          <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
                          <mat-datepicker #from></mat-datepicker>
                        </span>
                </p>
              </div>
              <div class="field">
                <label class="label">Ab Netto-Warenwert</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="fromNetMerchandiseValue" placeholder="Ab Netto-Warenwert">
                </div>
              </div>
              <div class="field">
                <label class="label">Ab Brutto-Warenwert</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="fromGrossMerchandiseValue" placeholder="Ab Brutto-Warenwert">
                </div>
              </div>
              <div class="field">
                <label class="label">Ab Gewicht in Kg</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="fromWeight" placeholder="Ab Gewicht in Kg">
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Bis Artikelmenge</label>
                <div class="control">
                  <input class="input is-small" type="number"
                         formControlName="toCartAmount" placeholder="Bis Artikelmenge">
                </div>
              </div>
              <div class="field">
                <label class="label">Bis Datum</label>
                <p class="control has-icons-left" (click)="to.open()">
                  <input [matDatepicker]="to" class="input is-small" placeholder="Ab Datum" formControlName="toDate" >
                  <span class="icon is-small is-left">
                          <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
                          <mat-datepicker #to></mat-datepicker>
                        </span>
                </p>
              </div>
              <div class="field">
                <label class="label">Bis Netto-Warenwert</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="toNetMerchandiseValue" placeholder="Bis Netto-Warenwert">
                </div>

              </div>
              <div class="field">
                <label class="label">Bis Brutto-Warenwert</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="toGrossMerchandiseValue" placeholder="Bis Brutto-Warenwert">
                </div>
              </div>
              <div class="field">
                <label class="label">Bis Gewicht in Kg</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="toWeight" placeholder="Bis Gewicht in Kg">
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-4 is-12-mobile">
              <div class="field ">
                <label class="label"></label>
                <div class="control">
                  <button (click)="addField('requiredArticleNumbers')" class="button is-small add"><span class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Erforderliche Artikelnummer/n</span></button>
                </div>
                <div [attr.di-error-group]="'requiredArticleNumbers'">
                  <div class="" formArrayName="requiredArticleNumbers"
                       *ngFor="let item of getFormArray('requiredArticleNumbers').controls; let i = index;">
                    <p class="control has-icon-left  mt-2">
                      <input class="input is-small" placeholder="Artikelnummer" [formControlName]="i">
                      <span>
                          <fa-icon [icon]="faTrashCan" (click)="removeField('requiredArticleNumbers', i)" class=" delete-field"></fa-icon>
                        </span>
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <div class="column  is-4 is-12-mobile">
              <div class="field ">
                <label class="label"></label>
                <div class="control">
                  <button (click)="addField('requiredPriceGroups')" class="button is-small add"><span class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Erforderliche Preisgruppe/n</span></button>
                </div>
                <div [attr.di-error-group]="'requiredPriceGroups'">
                  <div class="" formArrayName="requiredPriceGroups"
                       *ngFor="let item of getFormArray('requiredPriceGroups').controls; let i = index;">
                    <p class="control has-icon-left  mt-2">
                      <input class="input is-small" placeholder="Preisgruppe" [formControlName]="i">
                      <span>
                          <fa-icon [icon]="faTrashCan" (click)="removeField('requiredPriceGroups', i)" class="delete-field"></fa-icon>
                        </span>
                    </p>
                  </div>
                </div>

              </div>
            </div>

            <div class="column is-4 is-12-mobile">
              <div class="field ">
                <label class="label"></label>
                <div class="control">
                  <button (click)="addField('requiredCustomerNumbers')" class="button is-small add"><span class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Erforderliche für Kundennummer/n</span></button>
                </div>
                <div [attr.di-error-group]="'requiredCustomerNumbers'">
                  <div class="" formArrayName="requiredCustomerNumbers"
                       *ngFor="let item of getFormArray('requiredCustomerNumbers').controls; let i = index;">
                    <p class="control has-icon-left  mt-2">
                      <input class="input is-small" placeholder="Kundennummer" [formControlName]="i">
                      <span>
                          <fa-icon [icon]="faTrashCan" (click)="removeField('requiredCustomerNumbers', i)" class="delete-field"></fa-icon>
                        </span>
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <div class="column is-4 is-12-mobile">
              <div class="field ">
                <label class="label"></label>
                <div class="control">
                  <button (click)="addField('requiredCountries')" class="button is-small add"><span class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Erforderliche Länder</span></button>
                </div>
                <div [attr.di-error-group]="'requiredCountries'">
                  <div class="" formArrayName="requiredCountries"
                       *ngFor="let item of getFormArray('requiredCountries').controls; let i = index;">
                    <p class="control has-icon-left  mt-2">
                      <input class="input is-small" placeholder="Land" [formControlName]="i">
                      <span>
                          <fa-icon [icon]="faTrashCan" (click)="removeField('requiredCountries', i)" class="delete-field"></fa-icon>
                        </span>
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <div class="column  is-4 is-12-mobile">
              <div class="field ">
                <label class="label"></label>
                <div class="control">
                  <button (click)="addField('excludedGroups')" class="button is-small add"><span class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Warengruppe ausschließen</span></button>
                </div>
                <div [attr.di-error-group]="'excludedGroups'">
                  <div class="" formArrayName="excludedGroups"
                       *ngFor="let item of getFormArray('excludedGroups').controls; let i = index;">
                    <p class="control has-icon-left  mt-2">
                      <input class="input is-small" placeholder="Warengruppe" [formControlName]="i">
                      <span>
                          <fa-icon [icon]="faTrashCan" (click)="removeField('excludedGroups', i)" class="delete-field"></fa-icon>
                        </span>
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <div class="column  is-4 is-12-mobile">
              <div class="field ">
                <label class="label"></label>
                <div class="control">
                  <button (click)="addField('excludedCountries')" class="button is-small add"><span class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Länder ausschließen</span></button>
                </div>
                <div [attr.di-error-group]="'excludedCountries'">
                  <div class="" formArrayName="excludedCountries"
                       *ngFor="let item of getFormArray('excludedCountries').controls; let i = index;">
                    <p class="control has-icon-left  mt-2">
                      <input class="input is-small" placeholder="Land" [formControlName]="i">
                      <span>
                          <fa-icon [icon]="faTrashCan" (click)="removeField('excludedCountries', i)" class="delete-field"></fa-icon>
                        </span>
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-6 is-12-mobile">
              <shared-form-autocomplete [autocompleteModel]="autocompleteDynamicArticles"
                                        (editItem)="editDynamicArticle($event)"
                                        (onChangeItems)="setRemoveDynamicArticles($event)"></shared-form-autocomplete>
            </div>
            <div class="column is-6 is-12-mobile">
              <div class="field">
                <p class="label">Dynamische Artikel der nachfolgenden Kategorien aus dem Warenkorb entfernen, mit Ausnahme von diesem Artikel</p>
                <label class="checkbox is-block mt-2">
                  <input type="checkbox" (click)="toggleRemoveDynamicArticleCategory('selection')" [checked]="isRemoveDynamicArticleCategory('selection')">
                  Auswahlmöglichkeit durch den Kunden
                </label>
                <label class="checkbox is-block mt-2">
                  <input type="checkbox" (click)="toggleRemoveDynamicArticleCategory('selectionWithOptionalMappingToCustomer')" [checked]="isRemoveDynamicArticleCategory('selectionWithOptionalMappingToCustomer')">
                  Auswahlmöglichkeit durch Kunden + optionale Hinzufügung zu Kundenkonto
                </label>
                <label class="checkbox is-block">
                  <input type="checkbox" (click)="toggleRemoveDynamicArticleCategory('delivery')" [checked]="isRemoveDynamicArticleCategory('delivery')">
                  Versandkosten
                </label>
                <label class="checkbox is-block">
                  <input type="checkbox" (click)="toggleRemoveDynamicArticleCategory('deliverySelectable')" [checked]="isRemoveDynamicArticleCategory('deliverySelectable')">
                  Versandkosten [auswählbar]
                </label>
                <label class="checkbox is-block">
                  <input type="checkbox" (click)="toggleRemoveDynamicArticleCategory('additionalArticle')" [checked]="isRemoveDynamicArticleCategory('additionalArticle')">
                  Zugabeartikel
                </label>
                <label class="checkbox is-block">
                  <input type="checkbox" (click)="toggleRemoveDynamicArticleCategory('others')" [checked]="isRemoveDynamicArticleCategory('others')">
                  Sonstige
                </label>
              </div>

            </div>
          </div>



        </div>
        <div class="tab-container" [ngClass]="formHandler.activeTab!='description' ? 'is-hidden' : ''">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Beschreibung</label>
                <div class="control">
                  <textarea class="textarea" placeholder="Beschreibung" formControlName="description"></textarea>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="tab-container" [ngClass]="formHandler.activeTab!='images' ? 'is-hidden' : ''">
          <shared-upload (changedFiles)="changedFiles($event)" [filePaths]="filePaths"></shared-upload>
        </div>
      </div>
      <div class="content-foot has-text-centered">
        <shared-form-submit-button (submit)="submit()" [formStatus]="formHandler.status"></shared-form-submit-button>
      </div>
    </form>
  </div>
</div>

