import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map} from 'rxjs/operators';
import * as ChangedDetectionActions from '@store/actions/change-detection.action';

@Injectable()
export class ChangeDetectionEffects {

  constructor(
    private actions$: Actions) {
  }


  setChangedItem$ = createEffect(() => this.actions$.pipe(
    ofType(ChangedDetectionActions.setChangedItem),
    map((action) => {
      return ChangedDetectionActions.resetChangedItems();
    })
  ));
}
