import {Component, Input, OnChanges, OnInit, Renderer2} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfigService} from '@shared/config/services/config.service';
import {FormHelperDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {AppState} from '@store/state/app.state';
import {Store} from '@ngrx/store';
import {finalize} from 'rxjs/operators';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {faAngleDown, faAngleUp, faBars} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'shared-config-sort-set-default-article',
  templateUrl: './sort-set-default-article.component.html',
  styleUrls: ['./sort-set-default-article.component.scss']
})
export class SortSetDefaultArticleComponent implements OnInit, OnChanges {
  @Input() sortNames: string[] = [];

  formHandler: FormHelperDeprecated;

  form: FormGroup = new FormGroup({
    id: new FormControl(''),
    sortArticleList: new FormArray([]),
    default: new FormControl(''),
    offers: new FormControl(''),
    novelties: new FormControl('')
  });

  visibilitySortArticleList: boolean = false;

  constructor(private configService: ConfigService, private store: Store<AppState>, private renderer: Renderer2, private fb: FormBuilder) {
    this.formHandler = new FormHelperDeprecated(
      this.store,
      '',
      this.form,
      [],
      this.renderer
    );
    this.formHandler.notification = '';
  }

  dropSortArticleListe(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.getSortArticleListAsFormArray().controls, event.previousIndex, event.currentIndex);
  }

  ngOnChanges() {
    this.getSortArticleListAsFormArray().clear();
    this.getSortArticleListAsFormArray().push(
      this.fb.group({
        available: this.fb.control(false),
        type: this.fb.control('sort'),
        nameInternal: this.fb.control('Bezeichnung aufsteigend'),
        name: this.fb.control('Bezeichnung aufsteigend'),
        field: this.fb.control('name'),
        direction: this.fb.control(1),
        pos: this.fb.control(999)
      })
    );
    this.getSortArticleListAsFormArray().push(
      this.fb.group({
        available: this.fb.control(false),
        type: this.fb.control('sort'),
        name: this.fb.control('Bezeichnung absteigend'),
        nameInternal: this.fb.control('Bezeichnung absteigend'),
        field: this.fb.control('name'),
        direction: this.fb.control(-1),
        pos: this.fb.control(999)
      })
    );
    this.getSortArticleListAsFormArray().push(
      this.fb.group({
        available: this.fb.control(false),
        type: this.fb.control('sortArticlePrice'),
        name: this.fb.control('Preis aufsteigend'),
        nameInternal: this.fb.control('Preis aufsteigend'),
        field: this.fb.control(null),
        direction: this.fb.control(1),
        pos: this.fb.control(999)
      })
    );
    this.getSortArticleListAsFormArray().push(
      this.fb.group({
        available: this.fb.control(false),
        type: this.fb.control('sortArticlePrice'),
        name: this.fb.control('Preis absteigend'),
        nameInternal: this.fb.control('Preis absteigend'),
        field: this.fb.control(null),
        direction: this.fb.control(-1),
        pos: this.fb.control(999)
      })
    );
    this.getSortArticleListAsFormArray().push(
      this.fb.group({
        available: this.fb.control(false),
        type: this.fb.control('sort'),
        name: this.fb.control('Gebinde aufsteigend'),
        nameInternal: this.fb.control('Gebinde aufsteigend'),
        field: this.fb.control('unit'),
        direction: this.fb.control(1),
        pos: this.fb.control(999)
      })
    );
    this.getSortArticleListAsFormArray().push(
      this.fb.group({
        available: this.fb.control(false),
        type: this.fb.control('sort'),
        name: this.fb.control('Gebinde absteigend'),
        nameInternal: this.fb.control('Gebinde absteigend'),
        field: this.fb.control('unit'),
        direction: this.fb.control(-1),
        pos: this.fb.control(999)
      })
    );
    this.getSortArticleListAsFormArray().push(
      this.fb.group({
        available: this.fb.control(false),
        type: this.fb.control('sort'),
        name: this.fb.control('Alkoholgehalt aufsteigend'),
        nameInternal: this.fb.control('Alkoholgehalt aufsteigend'),
        field: this.fb.control('alcoholicStrength'),
        direction: this.fb.control(1),
        pos: this.fb.control(999)
      })
    );
    this.getSortArticleListAsFormArray().push(
      this.fb.group({
        available: this.fb.control(false),
        type: this.fb.control('sort'),
        name: this.fb.control('Alkoholgehalt absteigend'),
        nameInternal: this.fb.control('Alkoholgehalt absteigend'),
        field: this.fb.control('alcoholicStrength'),
        direction: this.fb.control(-1),
        pos: this.fb.control(999)
      })
    );




    this.sortNames.map(s => {
      this.getSortArticleListAsFormArray().push(
        this.fb.group({
          available: this.fb.control(false),
          type: this.fb.control('sortIndividual'),
          name: this.fb.control(s),
          nameInternal: this.fb.control(s),
          field: this.fb.control(null),
          direction: this.fb.control(-1),
          pos: this.fb.control(999)
        })
      );
    });
    this.configService.getConfig().subscribe(
      r => {
        if (r.result) {
          this.form.get('id')?.setValue(r.result.configs.data[0].id);
          this.form.get('default')?.setValue(r.result.configs.data[0].sortArticle.default);
          this.form.get('offers')?.setValue(r.result.configs.data[0].sortArticle.offers);
          this.form.get('novelties')?.setValue(r.result.configs.data[0].sortArticle.novelties);
          let i: number = 0;

          r.result.configs.data[0].sortArticleList.map(
            sal => {
              this.getSortArticleListAsFormArray().controls.map(
                x => {
                  if (sal.nameInternal == x.get('nameInternal')?.value) {
                    x.get('available')?.setValue(true);
                    x.get('name')?.setValue(sal.name);
                    x.get('pos')?.setValue(i);
                    i++;
                  }
                }
              );

            }
          )
          this.getSortArticleListAsFormArray().controls.sort((a, b) => (a.get('pos')?.value < b.get('pos')?.value ? -1 : 1));
        }
      }
    )
  }

  ngOnInit(): void {

  }

  getSortArticleListAsFormArray(): FormArray {
    return this.form.get('sortArticleList') as FormArray;
  }

  submitForm() {
    this.formHandler.validateForm();
    this.formHandler.displayErrors();
    if (!this.formHandler.status &&
      !this.formHandler.isFormErrors()) {
      this.formHandler.status = 'loading';
      let sortArticleList: any = [];
      this.getSortArticleListAsFormArray().controls.map(
        x => {
          let item: any = {};
          if (x.get('available')?.value == true) {
            item.type = x.get('type')?.value;
            item.name = x.get('name')?.value;
            item.nameInternal = x.get('nameInternal')?.value;
            if (x.get('field')?.value) {
              item.field = x.get('field')?.value;
            }
            if (x.get('direction')?.value) {
              item.direction = x.get('direction')?.value;
            }
            sortArticleList.push(item)
          }

        }
      )
      this.configService.updateConfig({
        data: {
          id: this.form.get('id')?.value,
          sortArticleList,
          sortArticle: {
            default: this.form.get('default')?.value || '',
            offers: this.form.get('offers')?.value || '',
            novelties: this.form.get('novelties')?.value || '',
          }
        }
      })
        .pipe(
          finalize(() => {
            setTimeout(() => {
              this.formHandler.status = null;
            }, 2000);
          })
        )
        .subscribe(
          r => {
            if (r.result?.configs.updated) {
              this.formHandler.status = 'success';
            } else {
              this.formHandler.status = 'error';
            }
          }
        )
    }
  }

  protected readonly faAngleDown = faAngleDown;
  protected readonly faAngleUp = faAngleUp;
  protected readonly faBars = faBars;
}
