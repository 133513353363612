import {NgModule} from '@angular/core';
import {
  ArticleRecommendationScriptComponent
} from '@shared/article/components/article-recommendation-script/article-recommendation-script.component';
import {
  CustomerAddressListComponent
} from '@shared/customer/components/list/customer-address-list/customer-address-list.component';
import {UserListComponent} from '@shared/user/components/list/user-list.component';
import {ListFilterDateComponent} from '@shared/list/components/list-filter-date/list-filter-date.component';
import {ListFilterInputComponent} from '@shared/list/components/list-filter-input/list-filter-input.component';
import {ListHeaderColumnComponent} from '@shared/list/components/list-header-column/list-header-column.component';
import {ListHeadlineComponent} from '@shared/list/components/list-headline/list-headline.component';
import {ListNotificationComponent} from '@shared/list/components/list-notification/list-notification.component';
import {ListSearchComponent} from '@shared/list/components/list-search/list-search.component';
import {
  ListButtonEditTableComponent
} from '@shared/list/components/list-button-edit-table/list-button-edit-table.component';
import {ListDeleteItemComponent} from '@shared/list/components/list-delete-item/list-delete-item.component';
import {ListButtonExportComponent} from '@shared/list/components/list-button-export/list-button-export.component';
import {ListHistoryItemComponent} from '@shared/list/components/list-history-item/list-history-item.component';
import {ClickOutsideDirective} from '@shared/click-outside/click-outside.directive';
import {DebounceClickDirective} from '@shared/debounce-click/debounce-click';
import {SubmitButtonComponent} from '@shared/forms/submit-button/submit-button.component';
import {CustomerUserMapperComponent} from '@shared/customer/components/mapper/customer-user-mapper.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MaterialModule} from '@shared/material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CustomerFormComponent} from '@shared/customer/components/forms/customer/customer-form.component';
import {UserFormComponent} from '@shared/user/components/form/user-form.component';
import {ArticleFormComponent} from '@shared/article/components/form/article-form.component';
import {SalesFormComponent} from '@shared/sales-documents/components/form/sales-form.component';
import {DialogComponent} from './dialog/components/dialog/dialog.component';
import {
  SortSetDefaultArticleComponent
} from '@shared/config/components/sort-set-default-article/sort-set-default-article.component';
import {
  SortSetDefaultGroupComponent
} from '@shared/config/components/sort-set-default-group/sort-set-default-group.component';
import {
  ConfigAvailabilityStatusComponent
} from '@shared/config/components/availability-status/config-availability-status.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {
  SortSetDefaultGroup1Component
} from '@shared/config/components/sort-set-default-group1/sort-set-default-group1.component';
import {NewsFormComponent} from './news/components/form/news-form.component';
import {UploadComponent} from './uploads/components/uploads/upload.component';
import {ListComponent} from '@shared/list/components/list/list.component';
import {DynamicArticleFormComponent} from './dynamic-articles/components/form/dynamic-article-form.component';
import {
  DeliveryTourFormComponent
} from './delivery-tours/components/form/delivery-tour-form/delivery-tour-form.component';
import {AutocompleteComponent} from './forms/components/autocomplete/autocomplete.component';
import {DeliveryAreaFormComponent} from './delivery-areas/components/form/delivery-area/delivery-area-form.component';
import {
  DeliveryBlockedDaysFormComponent
} from './delivery-blocked-days/components/form/delivery-blocked-days-form/delivery-blocked-days-form.component';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {CustomDateAdapter} from '@shared/material/models/custom-date-adapter';
import {OrdersComponent} from './dashboard/components/orders/orders.component';
import {TableModule} from 'primeng/table';
import {ChartModule} from 'primeng/chart';
import {CommonModule} from '@angular/common';
import {EditorModule} from 'primeng/editor';
import {EmailServerComponent} from './config/components/email-server/email-server.component';
import {
  ListFiltersAdditionalComponent
} from './list/components/list-filters-additional/list-filters-additional.component';
import {VoucherFormComponent} from './vouchers/components/form/voucher-form.component';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {CountriesComponent} from './config/components/countries/countries.component';
import {PaymentMethodsComponent} from './config/components/payment-methods/payment-methods.component';
import {DealerComponent} from './config/components/dealer/dealer.component';
import {EmailSettingsComponent} from './config/components/email-settings/email-settings.component';
import {DynamicPageFormComponent} from "@shared/dynamic-pages/components/form/dynamic-page/dynamic-page-form.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {TooltipModule} from "primeng/tooltip";
import {DialogModule} from "primeng/dialog";
import {InputTextModule} from "primeng/inputtext";
import {ListButtonImportComponent} from "@shared/list/components/list-button-import/list-button-import.component";
import {UploadCsvComponent} from "@shared/uploads/components/upload-csv/upload-csv.component";
import {MenubarModule} from "primeng/menubar";
import {UploadsStorageFormComponent} from "@shared/upload-storage/components/form/uploads-storage-form.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {AccordionModule} from "primeng/accordion";
import {FormErrorComponent} from "@shared/forms/components/error/form-error.component";
import {LoadingContentComponent} from "@shared/loading/content/loading-content.component";
import {SplitterModule} from "primeng/splitter";
import {DropdownModule} from "primeng/dropdown";
import {CalendarModule} from "primeng/calendar";
import {
  CustomerNoteListComponent
} from "@shared/customer/components/list/customer-note-list/customer-note-list.component";
import {CustomerNoteFormComponent} from "@shared/customer/components/forms/customer-note/customer-note-form.component";
import {MenuModule} from "primeng/menu";

const sharedComponents = [
  ArticleRecommendationScriptComponent,
  ArticleFormComponent,
  CustomerAddressListComponent,
  CustomerNoteListComponent,
  CustomerNoteFormComponent,
  CustomerUserMapperComponent,
  CustomerFormComponent,
  FormErrorComponent,
  UserListComponent,
  UserFormComponent,
  UploadsStorageFormComponent,
  ListFilterDateComponent,
  ListFilterInputComponent,
  ListHeaderColumnComponent,
  ListHeadlineComponent,
  ListNotificationComponent,
  ListSearchComponent,
  ListButtonEditTableComponent,
  ListDeleteItemComponent,
  ListButtonExportComponent,
  ListHistoryItemComponent,
  LoadingContentComponent,
  ClickOutsideDirective,
  DebounceClickDirective,
  SubmitButtonComponent,
  SalesFormComponent,
  SortSetDefaultArticleComponent,
  ConfigAvailabilityStatusComponent,
  SortSetDefaultGroupComponent,
  PaymentMethodsComponent,
  DialogComponent,
  EmailServerComponent

];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        DragDropModule,
        MaterialModule,
        ColorPickerModule,
        TableModule,
        ChartModule,
        EditorModule,
        CdkAccordionModule,
        FontAwesomeModule,
        TooltipModule,
        DialogModule,
        InputTextModule,
        MenubarModule,
        OverlayPanelModule,
        AccordionModule,
        SplitterModule,
        DropdownModule,
        CalendarModule,
        MenuModule
    ],
  declarations: [
    ...sharedComponents,
    SortSetDefaultGroup1Component,
    NewsFormComponent,
    UploadComponent,
    ListComponent,
    DynamicArticleFormComponent,
    DeliveryTourFormComponent,
    AutocompleteComponent,
    DeliveryAreaFormComponent,
    DeliveryBlockedDaysFormComponent,
    OrdersComponent,
    ListFiltersAdditionalComponent,
    VoucherFormComponent,
    CountriesComponent,
    PaymentMethodsComponent,
    DealerComponent,
    EmailSettingsComponent,
    DynamicPageFormComponent,
    UploadCsvComponent,
    ListButtonImportComponent,
    FormErrorComponent
  ],
  exports: [
    CommonModule,
    ...sharedComponents,
    DragDropModule,
    MaterialModule,
    ColorPickerModule,
    SortSetDefaultGroup1Component,
    ListComponent,
    OrdersComponent,
    CountriesComponent,
    DealerComponent,
    EmailSettingsComponent,
    UploadCsvComponent,
    UploadComponent
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: DateAdapter, useClass: CustomDateAdapter}
  ]

})
export class SharedModule {

}
