import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FormStatusDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {ConfigService} from '@shared/config/services/config.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ApiConfigsUpdateRequest} from '@shared/api/configs';
import {faPlusSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {faBars} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'shared-config-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss']
})
export class CountriesComponent implements OnInit {


  configId?: string;
  form: FormGroup = this.fb.group(
    {
      country: new FormControl(''),
      defaultCountry: new FormControl('')
    }
  );
  formStatus: FormStatusDeprecated = null;

  countries: string[] = [];

  constructor(private fb: FormBuilder, private configService: ConfigService) {
  }

  ngOnInit(): void {
    this.configService.getConfig().subscribe(
      r => {
        this.configId = r.result?.configs.data[0].id;
        if (r.result?.configs.data[0].countries) {
          this.countries = r.result?.configs.data[0].countries;
        }
        if (r.result?.configs.data[0].defaultCountry) {
          this.form.get('defaultCountry')?.setValue(r.result?.configs.data[0].defaultCountry)
        }
      }
    )
  }

  addCountry() {
    const country: string = this.form.get('country')?.value;
    if (!this.countries.filter(c => c == country)[0] &&
      country.length > 0) {
      this.countries.push(country);
    }
    this.form.get('country')?.setValue('');
  }

  dropCountry(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.countries, event.previousIndex, event.currentIndex);
  }

  deleteCountry(country: string) {
    this.countries = this.countries.filter(c => c !== country);
  }

  update() {
    if (this.configId) {
      this.formStatus = 'loading';
      let data: ApiConfigsUpdateRequest['params']['data'] = {
        id: this.configId,
        countries: this.countries,
        defaultCountry: this.form.get('defaultCountry')?.value
      };
      this.configService.updateConfig({data}).subscribe(
        {
          next: (r) => {
            if (r.result?.configs.updated) {
              this.formStatus = 'success';
              setTimeout(() => {
                this.formStatus = null;
              }, 1000);
            } else {
              this.formStatus = 'error';
              setTimeout(() => {
                this.formStatus = null;
              }, 1000);
            }
          },
          error: () => {
            this.formStatus = 'error';
            setTimeout(() => {
              this.formStatus = null;
            }, 1000);
          }
        }
      )
    }
  }


  protected readonly faPlusSquare = faPlusSquare;
  protected readonly faTrashCan = faTrashCan;
  protected readonly faBars = faBars;
}
