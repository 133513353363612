<div *ngIf="!verified">
  <div  *ngIf="loading" class="has-text-centered" >
    <button class="button is-loading authentication" ></button>
  </div>
  <div *ngIf="!loading" class="alert alert-warning" role="alert">
    <h1 class="is-size-3">Ich bin kein Roboter.</h1>
    <h2 class="is-size-5">{{question}}</h2>
    <div class="is-flex">
      <div *ngFor="let answer of answers">
        <div class="container-answers">
            <fa-icon [icon]="faCheckCircle" *ngIf="answer.verify == true" class="icon fa fa-check-circle ok"></fa-icon>
          <fa-icon [icon]="faBan" *ngIf="answer.verify == false" class="icon fa fa-ban error"></fa-icon>
          <span class="helper"></span>
          <img *ngIf="answer.verify == undefined" alt="" (click)="verify(answer.id)" class="answer"
               src="{{urlS3 + answer.img}}" fill=""/>
        </div>
      </div>
    </div>
  </div>
</div>
