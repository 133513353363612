<button *ngIf="loading" class="button is-loading content"></button>
<div *ngIf="!loading && rowData.length==0" class="p-2 has-background-white pb-4">
  <h1  class="is-title is-size-3 has-text-black is-inline-block">Dashboard</h1>
  <p>Für Ihren Account sind noch keine Umsätze erfasst.</p>
</div>
<div *ngIf="!loading && rowData.length > 0">
  <div class="p-2 has-background-white">
    <h1  class="is-title is-size-3 has-text-black is-inline-block">Dashboard</h1>
    <div class="card" >
      <p-table [columns]="cols" [value]="rowData" responsiveLayout="scroll" styleClass="p-datatable-sm">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field == 'month' ? 'monthNumber' : col.field" >
              <p-sortIcon field="{{col.field}}" *ngIf="col.field != 'month' && col.field !== 'monthNumber'"></p-sortIcon>
              <p-sortIcon field="monthNumber" *ngIf="col.field == 'month'"></p-sortIcon>
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns" style="text-align:right;">
              <span *ngIf="col.field != 'month'">{{getFormatNumber(rowData[col.field])}}</span>
              <span *ngIf="col.field == 'month'">{{rowData[col.field]}}</span>

            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer" let-columns>
          <tr>
            <td *ngFor="let col of columns" style="text-align:right;" >
          <span *ngIf="col.field != 'month' && col.field !== 'monthNumber'">
            {{sumColumn(col.field)}}
          </span>

            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="card">
      <h5>Umsätze</h5>
      <p-chart type="bar" [data]="chartsMerchandiseValues" [options]="basicOptions"></p-chart>
    </div>
    <div class="column">
      <div class="card">
        <h5>Bestellungen</h5>
        <p-chart type="bar" [data]="chartsOrders" [options]="basicOptions"></p-chart>
      </div>
    </div>
  </div>
</div>
