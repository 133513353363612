import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Autocomplete, AutocompleteItem} from '@shared/forms/models/autocomplete';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {FormControl, FormGroup} from '@angular/forms';
import {
  faArrowDown,
  faArrowUp,
  faBars,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import {faEdit} from "@fortawesome/free-regular-svg-icons";

@Component({
  selector: 'shared-form-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {

  @ViewChild('autocompleteContainer') private autocompleteContainer!: ElementRef;

  @Input() autocompleteModel!: Autocomplete;
  @Input() myFormControl: FormControl = new FormControl('');
  @Input() fillFormWithSelectedItemName: boolean = false;
  @Input() showSearchIcon: boolean = true;

  @Output() editItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeInputValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChangeItems: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() onSelectItem: EventEmitter<AutocompleteItem> = new EventEmitter<AutocompleteItem>();

  _myFormControl: FormControl = new FormControl('');

  selectedIndex: number = -1;
  isOpen: boolean = false;

  constructor() {

  }

  ngOnInit(): void {
    this._myFormControl.setValue(this.myFormControl.value);
    this.autocompleteModel.excludeItemName = this.myFormControl.value;
    this._myFormControl?.valueChanges.subscribe(
      r => {
        this.myFormControl.setValue(r);
        this.autocompleteModel.excludeItemName = r;
        this.searchItems(r, true);
        this.onChangeInputValue.emit(r);
      }
    );
  }

  onKeyDown(isArrowDown: boolean) {
    if (isArrowDown) {
      if (this.selectedIndex < this.autocompleteModel.getItems().length - 1) this.selectedIndex++;
    } else {
      if (this.selectedIndex > 0) this.selectedIndex--;
    }

    if (this.selectedIndex > 6) {
      this.autocompleteContainer.nativeElement.scrollTop = 35 * (this.selectedIndex - 6);
    } else {
      this.autocompleteContainer.nativeElement.scrollTop = 0;
    }

  }

  changedItems() {
    this.onChangeItems.emit(
      this.autocompleteModel.getSelectedItems().map(r => {
        return r.value;
      })
    );
  }

  toggleDropDownButton() {
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
      this.searchItems('', false);
    }
  }

  searchItems(value: string, considerStartAfterTypedCharacters: boolean) {
    if (!considerStartAfterTypedCharacters ||
      this._myFormControl?.value.length >= this.autocompleteModel.startSearchAfterTypedCharacters) {
      this.selectedIndex = -1;
      this.isOpen = true;
      this.autocompleteModel.loadItems(value);
    }
  }

  selectItem(event: any, index: number) {
    const items = this.autocompleteModel.getItems();
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      if (index == this.selectedIndex) {
        this.onSelectItem.emit(item);
        if (this.fillFormWithSelectedItemName) {
          this.myFormControl.setValue(item.name,);
          this._myFormControl?.setValue(item.name, {emitEvent: false});
          this.isOpen = false;
          this.autocompleteModel.setItems([]);
        } else {
          this.autocompleteModel.selectItem(item);
          this.changedItems();
        }
        break;
      }
    }
    event.preventDefault();
    event.stopPropagation();
  }

  deleteSelectedItem(item: any) {
    this.autocompleteModel.deleteSelectedItem(item);
    this.changedItems();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.autocompleteModel.getSelectedItems(), event.previousIndex, event.currentIndex);
    this.changedItems();
  }

  protected readonly faSearch = faSearch;
  protected readonly faBars = faBars;
  protected readonly faEdit = faEdit;
  protected readonly faArrowDown = faArrowDown;
  protected readonly faArrowUp = faArrowUp;


}
