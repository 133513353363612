import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NgxCsvParser, NgxCSVParserError} from "ngx-csv-parser";
import {
  faBars, faCheck, faCircleExclamation, faExclamation,
  faFileCsv,
  faLeftRight,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import {FormStatusDeprecated} from "@shared/forms/models/form-helper-deprecated";

export const Encoding = require('encoding-japanese');

export const DataTypeInt = 'int';
export const DataTypeFloat = 'float';
export const DataTypeBoolean = 'boolean';

export interface UploadSchema {
  id?: string,
  type: string,
  info?: string,
  fields: {
    field: string,
    label: string,
    required?: boolean,
    dataType?: typeof DataTypeInt | typeof DataTypeFloat | typeof DataTypeBoolean
  }[]
}

@Component({
  selector: 'shared-uploads-upload-csv',
  templateUrl: './upload-csv.component.html',
  styleUrls: ['./upload-csv.component.scss']
})
export class UploadCsvComponent implements OnInit, OnDestroy {
  @Output()
  onUploadAndMappingSuccess = new EventEmitter();
  @Output()
  uploadClose = new EventEmitter();
  @Input() uploadConfirmations: { status: 'new' | 'update' | 'error', fields: string[] }[] = [];
  @Input() uploadLoadingMessage?: string;
  @Input() uploadSynchronisationDone?: boolean;
  @Input() uploadFormStatus: FormStatusDeprecated = null;


  @Input() uploadSchema!: UploadSchema;
  @Input() active!: boolean;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  data: any;
  dataHeader: boolean = false;
  dataSkipFirstRow: boolean = false;
  dataMappedCells: { field: string, cellNumber: number }[] = [];

  errors?: { type: string, message: string }[];
  startUpload: boolean = false;


  constructor(private ngxCsvParser: NgxCsvParser) {
  }

  ngOnInit(): void {
    this.reset();
  }

  reset() {
    this.uploadFormStatus = null;
    this.uploadSynchronisationDone = false;
    this.uploadConfirmations = [];
    this.errors = undefined;
    this.data = undefined;
    this.dataMappedCells = [];
    this.startUpload = false;
  }

  detectEncoding(file: File): Observable<string> {
    let result = new Subject<string>();

    const reader = new FileReader();
    reader.onload = (e) => {
      const codes = new Uint8Array(e.target?.result as ArrayBuffer);
      const detectedEncoding = Encoding.detect(codes);
      result.next(detectedEncoding);
    };
    reader.readAsArrayBuffer(file);

    return result.asObservable();
  }

  fileChangeListener($event: any): void {
    const files = $event.target.files;
    this.reset();
    if (files && files.length > 0) {
      let file: File | null = files.item(0);
      if (file) {
        this.detectEncoding(file).subscribe(
          encoding => {
            this.dataHeader = (this.dataHeader as unknown as string) === 'true' || this.dataHeader;
            let csvEncoding: any;
            if (encoding == 'UTF8') {
              csvEncoding = 'utf-8';
            } else {
              csvEncoding = 'iso-8859-2';
            }

            this.ngxCsvParser.parse(files[0], {
              header: this.dataHeader,
              delimiter: ';',
              encoding: csvEncoding
            })
              .pipe().subscribe(
              {
                next: (result): void => {
                  if (Array.isArray(result)) {
                    this.setCellMappingByFirstRow(result[0]);
                    this.data = result;
                  }
                },
                error: (error: NgxCSVParserError): void => {
                  console.log('Error', error);
                  this.errors = [{type: 'upload', message: error.message}];
                }

              }
            )
          });
      }
    }
  }

  setCellMappingByFirstRow(firstRow: any[]) {
    this.dataSkipFirstRow = false;
    this.uploadSchema.fields.map(f => {
      for (let i = 0; i < firstRow.length; i++) {
        if (firstRow[i] == f.label) {
          this.dataSkipFirstRow = true;
          this.dataMappedCells.push({field: f.field, cellNumber: i});
        }
      }
    })
  }

  setCellMapping(field: string, cellNumber: any) {
    this.dataMappedCells = this.dataMappedCells.filter(c => c.field !== field);
    if (cellNumber !== 'null') {
      cellNumber = parseInt(cellNumber);
      this.dataMappedCells.push({field, cellNumber});
    }
  }

  isCellMapping(field: string): boolean {
    let x = this.dataMappedCells.filter(c => c.field == field)[0];
    if (x) {
      return true;
    }
    return false;
  }

  getStep(): 1 | 2 | 3 {
    if (this.uploadSynchronisationDone) {
      return 3;
    }

    if (this.data) {
      return 2;
    }

    return 1;

  }

  getPreparedCell(field: string, value: string): any {
    let data: any = {}, splitField;

    // Array Object
    splitField = field.split('.0.');
    if (splitField.length > 2) {
      let newField = field.replace(new RegExp(splitField[0] + '.0.', "g"), '');
      data = [{}];
      data[0][splitField[1]] = this.getPreparedCell(newField, value);
      return data;
    } else if (splitField.length > 1) {
      data = [{}];
      data[0][splitField[1]] = value;
      return data;
    }

    // Array
    splitField = field.split('.0');
    if (splitField.length > 1) {
      if (value &&
        value.length > 0) {
        return data[splitField[0]] = value.split(';');
      } else {
        return;
      }

    }

    // Object
    splitField = field.split('.');
    if (splitField.length > 2) {
      let newField = field.replace(new RegExp(splitField[0] + '.', "g"), '');
      data[splitField[0]] = {};
      return data[splitField[0]][splitField[1]] = this.getPreparedCell(newField, value);
    } else if (splitField.length > 1) {
      data[splitField[0]] = {};
      return data[splitField[0]][splitField[1]] = value;
    }

    return data[field] = value;
  }

  getPreparedRow(cells: string[]): any {
    let data: any = {}, field: string, value: any, splitField;
    Object.entries(cells).forEach(
      ([key, valueCSV], index) => {
        field = this.dataMappedCells.filter(f => f.cellNumber == index)[0]?.field;
        switch (this.uploadSchema.fields.filter(f => f.field == field)[0]?.dataType) {
          case DataTypeInt:
            value = parseInt(valueCSV?.replace(',', '.') ?? '');
            if (isNaN(value)) {
              return;
            }
            break;

          case DataTypeFloat:
            value = parseFloat(valueCSV?.replace(',', '.') ?? '');
            if (isNaN(value)) {
              return;
            }
            break;

          case DataTypeBoolean:
            value = false;
            if (valueCSV?.toLowerCase() == 'true') {
              value = true;
            }
            break;


          default:
            value = valueCSV;
            break;
        }

        if (field) {
          splitField = field.split('.0');
          let x;
          if (data[splitField[0]]) {
            x = this.getPreparedCell(field, value);
            data[splitField[0]][0] = {...data[splitField[0]][0], ...this.getPreparedCell(field, value)[0]};
          } else {
            x = this.getPreparedCell(field, value);
            data[splitField[0]] = this.getPreparedCell(field, value);
          }
          let y = true;

        }
      }
    );
    return data;
  }

  getData() {
    let rows: any[] = [...this.data];
    if (this.dataSkipFirstRow) {
      rows.shift()
    }
    let data: any = [], key = 0;

    rows.map(cells => {
      data[key] = this.getPreparedRow(cells);
      key++;
    });
    return data;
  }

  getSecondRow(cellNumber: number): string {
    if (this.data[1] &&
      this.data[1][cellNumber]) {
      return this.data[1][cellNumber];
    }
    return '';
  }

  getSelectedCellNumber(field: string): number | null {
    let cellNumber = this.dataMappedCells.filter(f => f.field == field)[0]?.cellNumber;
    if (cellNumber !== undefined) {
      return cellNumber;
    }
    return null;
  }

  isMappingSuccess(): boolean {
    let isSuccess: boolean = true;
    this.errors = this.errors?.filter(e => e.type != 'mapping') ?? [];
    this.uploadSchema.fields.map(
      f => {
        if (f.required &&
          this.getSelectedCellNumber(f.field) == null) {
          isSuccess = false;
        }
      }
    );
    if (!isSuccess &&
      this.startUpload) {
      this.errors?.push({
        type: 'mapping',
        message: 'Bitte wählen Sie bei den rot markierten Auswahlfeldern eine Zuordnung aus.'
      });
    }
    return isSuccess;
  }

  areIdsUnique() {
    let ids: string[] = [], isUnique: boolean = true, row: number = 1;
    if (!this.uploadSchema.id) {
      return true;
    }
    this.errors = this.errors?.filter(e => e.type !== 'idError');
    this.getData().map((d: any) => {
      if (this.uploadSchema.id &&
        d[this.uploadSchema.id] !== undefined &&
        ids.includes(d[this.uploadSchema.id])) {
        isUnique = false;
        this.errors?.push({
          type: 'idError',
          message: 'Zeile ' + row + ': ' + this.uploadSchema.id + ' ' + d[this.uploadSchema.id] + ' existiert bereits.'
        })
      }
      if (this.uploadSchema.id) {
        ids.push(d[this.uploadSchema.id]);
      }
      row++;
    });
    return isUnique;
  }

  isConfirmationError(): boolean {
    if (this.uploadConfirmations.filter(u => u.status == 'error').length > 0) {
      return true;
    }
    return false;
  }

  submit() {
    this.startUpload = true;
    if (!this.isMappingSuccess()) {
      return;
    }
    if (!this.areIdsUnique()) {
      return;
    }
    this.onUploadAndMappingSuccess.emit(this.getData())

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected readonly faUpload = faUpload;
  protected readonly faBars = faBars;
  protected readonly faFileCsv = faFileCsv;
  protected readonly faLeftRight = faLeftRight;
  protected readonly faCheck = faCheck;
  protected readonly faExclamation = faExclamation;
}
