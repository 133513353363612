import {
  AbstractControl,
  FormArray, FormControl, FormGroup,
  ValidatorFn
} from "@angular/forms";

export class FormDynamicArray {
  formControl: AbstractControl<any> | null;
  validators?:  ValidatorFn | ValidatorFn[];

  constructor(formControl: AbstractControl<any> | null, validators?: ValidatorFn | ValidatorFn[]) {
    this.formControl = formControl;
    this.validators = validators;
  }

  add(defaultValue?: string) {
    const formArray = this.getFormArray();
    formArray.push(new FormControl(defaultValue, this.validators));
  }

  remove(index: number) {
    const formArray = this.getFormArray();
    formArray.removeAt(index);
  }

  getFormArray(): FormArray {
    return this.formControl as FormArray;
  }

}
