import {Component, Input, OnInit} from '@angular/core';
import {Window} from '@shared/window-manager/interfaces/window.model';
import {faCheck, faCircleCheck, faExclamationTriangle, faCopy} from "@fortawesome/free-solid-svg-icons";
import {UploadFilePath} from "@shared/uploads/components/uploads/upload.component";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {UploadsStorageService} from "@shared/upload-storage/services/uploads-storage.service";
import {ApiUploadStorage} from "@shared/api/upload-storage";
import {environment} from "@environments/environment";

@Component({
  selector: 'shared-uploads-storage-form',
  templateUrl: './uploads-storage-form.component.html',
  styleUrls: ['./uploads-storage-form.component.scss']
})
export class UploadsStorageFormComponent implements OnInit {
  @Input() window?: Window;


  uploadFilePaths: UploadFilePath[] = [];

  activeTab: 'upload' | 'uploadedFiles' = 'upload';

  s3Url: string = environment.s3Url;

  apiError?: {
    notification: string,
    errors?: { key: string, error: string }[]
  };

  copyToClipboardValue: string = '';

  loading: boolean = true;

  uploadsStorage: ApiUploadStorage[] = [];

  constructor(
    private uploadsStorageService: UploadsStorageService
  ) {

  }

  ngOnInit() {
    this.loadItems();
  }

  changedFiles(files: any){

    this.uploadFilePaths = [];
    this.uploadsStorageService.set(
      {
        path: files[0].filePath,
        public: true
      }
    ).subscribe(
      r => {
        if(r.error){
          this.apiError = {notification:  r.error?.message };
          if (r.error?.input) {
            this.apiError.errors = r.error.input;
          }
        } else {
          this.loadItems();
        }
      }
    )
  }

  delete(id: string){
    this.loading = true;
    this.uploadsStorageService.delete(id).subscribe(
      {
        next: (r) => {
          if(r.error){
            this.apiError = {notification:  r.error?.message };
            if (r.error?.input) {
              this.apiError.errors = r.error.input;
            }
          }
          this.loadItems();
        },
        error: () => {
          this.loadItems();
        }
      }
    )

  }

  isCopyToClipboard(value: string): boolean{
    if(this.copyToClipboardValue == value){
      setTimeout(() => {
        this.copyToClipboardValue = '';
      }, 2000)
      return true;
    }
    return false;
  }

  copyToClipboard(value: string){
    navigator.clipboard.writeText(value)
      .then(() => { this.copyToClipboardValue = value;})
      .catch(err => console.log('Error in copying text: ', err));
  }

  loadItems(){
    this.loading = true;
    this.uploadsStorageService.get({uploadsStorage: {sort: [{field: 'dateCreated', direction: -1}]}}).subscribe(
      {
        next: (r) => {
          {
            if(r.result?.uploadsStorage.data){
              this.uploadsStorage = r.result?.uploadsStorage.data;
            }
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      }
    );
  }




  protected readonly faExclamationTriangle = faExclamationTriangle;
  protected readonly faCheck = faCheck;
  protected readonly faTrashCan = faTrashCan;
  protected readonly faCopy = faCopy;
  protected readonly faCircleCheck = faCircleCheck;
}
