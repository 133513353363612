<div *ngIf="loading" class="content-content has-text-centered">
  <button class="button is-loading"></button>
</div>
<div #formTag>
  <div *ngIf="!loading">
    <form [formGroup]="form">
      <div class="tabs">
        <ul>
          <li [ngClass]="activeTab=='details' ? 'is-active' : ''" (click)="activeTab='details'">
            <a class="is-danger">
              <span *ngIf="isFormKeyInvalid('name') || isFormKeyInvalid('days') || isFormKeyInvalid('position')"
                    class="is-error mr-2"><fa-icon [icon]="faExclamationCircle"></fa-icon></span>

              Details

            </a></li>
          <li [ngClass]="activeTab=='conditions' ? 'is-active' : ''"
              (click)="activeTab='conditions'">
            <a>
              <span *ngIf="isFormKeyInvalid('requiredPriceGroups') " class="is-error mr-2"><fa-icon
                [icon]="faExclamationCircle"></fa-icon></span>
              Bedingungen
            </a></li>
          <li [ngClass]="activeTab=='orderConditions' ? 'is-active' : ''"
              (click)="activeTab='orderConditions'">
            <a>
              <span
                *ngIf="isFormKeyInvalid('minimumMerchandiseValue.excludedGroups') || isFormKeyInvalid('minimumArticles.excludedGroups') || isFormKeyInvalid('minimumArticles.requiredArticleUnitTypes')"
                class="is-error mr-2"><fa-icon [icon]="faExclamationCircle"></fa-icon></span>

              Bestellbedingungen
            </a></li>
          <li [ngClass]="activeTab=='dynamicArticles' ? 'is-active' : ''"
              (click)="activeTab='dynamicArticles'">
            <a>Dynamische Artikel
            </a></li>
        </ul>
      </div>
      <div class="content-content is-tabs">
        <shared-form-error [apiError]="formHandler.getFormError()"></shared-form-error>
        <div *ngIf="activeTab=='details'" class="tab-container">
          <div class="columns">
            <div class="column">
              <label class="checkbox">
                <input type="checkbox" formControlName="default">
                Standardtour. Sofern die Bedingungen dieser Tour zutreffen, wird diese Tour verwendet. Dies trifft auch zu, wenn für das Liefergebiet eine separate Tour hinterlegt wurde. Sofern es mehrere Standardtouren gibt und / oder für das Liefergebiet separate Touren, wird die Reihenfolge über die Position gesteuert (aufsteigend).
              </label>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Name</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="name" placeholder="Name">
                  <p class="help is-danger" *ngIf="isFormKeyInvalid('name')">Bitte hinterlegen Sie einen Namen.</p>

                </div>
              </div>
            </div>
            <div class="column is-4 is-full-mobile">
              <div class="field">
                <label class="label">Position <!--<span class="is-pointer">
                  <fa-icon [icon]="faInfoCircle" (click)="opPositionInfo.toggle($event, positionInfo)"></fa-icon>
                </span>--></label>
                <div class="control">
                  <input class="input is-small" type="number"
                         formControlName="position" placeholder="Position" #positionInfo>
                  <p class="help is-danger" *ngIf="isFormKeyInvalid('position')">Bitte hinterlegen Sie eine
                    Position.</p>
                </div>
             <!--   <p-overlayPanel #opPositionInfo>
                  <ng-template pTemplate="content">
                    <p class="m-0">
                      Die Position ist relevant, wenn es z.B. eine Standardtour ohne Bedingungen und eine Tour mit
                      definierten Preisgruppen hinterlegt sind.

                      In diesem Fall würden für den Kunden mit der entsprechenden Preisgruppe beide Touren zutreffen und das
                      System verwendet die Tour, welche die niedrigere Position hat.
                    </p>
                  </ng-template>
                </p-overlayPanel>-->
              </div>
            </div>
          </div>
          <p-accordion [multiple]="true" [activeIndex]="[0]">
            <p-accordionTab>
              <ng-template pTemplate="header">
                    <span class="flex align-items-center gap-2 w-full">
                      <span *ngIf="isFormKeyInvalid('days') " class="is-error mr-2"><fa-icon
                        [icon]="faExclamationCircle"></fa-icon></span> Liefertage
                    </span>
              </ng-template>
              <div class="tabs is-boxed" formGroupName="days">
                <ul class="m-0">
                  <li *ngFor="let item of days; let i = index;" [formGroupName]="item.key"
                      [ngClass]="(item.key == activeTabDay) ? 'is-active' : ''" (click)="activeTabDay = item.key">
                    <a>
                  <span *ngIf="isFormKeyInvalid('days.'+item.key) " class="is-error mr-2"><fa-icon
                    [icon]="faExclamationCircle"></fa-icon></span>
                      <label class="checkbox pr-2">
                        <input type="checkbox" formControlName="available">
                      </label>

                      {{ item.day }}
                    </a>
                  </li>
                </ul>
              </div>
              <div formGroupName="days">
                <div *ngFor="let item of days; let i = index;" [formGroupName]="item.key" class="tab-container"
                     [ngClass]="(item.key !== activeTabDay) ? 'is-hidden' : ''">
                  <p-accordion [multiple]="true" [activeIndex]="[0, 1]">
                    <p-accordionTab header="Bestelldetails">
                      <div class="columns">
                        <div class="column">

                          <div class="field">
                            <label class="label">Spätester Bestellzeitpunkt für den Tag</label>
                            <div class="control">
                              <select class="select" formControlName="latestOrderTimeInDays">
                                <option value="0">Selber Tag</option>
                                <option value="1">1 Tag vorher (Vortag)</option>
                                <option value="2">2 Tage vorher</option>
                                <option value="3">3 Tage vorher</option>
                                <option value="4">4 Tage vorher</option>
                                <option value="5">5 Tage vorher</option>
                                <option value="6">6 Tage vorher</option>
                                <option value="7">7 Tage vorher</option>
                              </select>
                            </div>
                            <p class="help">Der komplette Tag wird im Kalender ausgeblendet, sobald der Zeitpunkt
                              überschritten ist.</p>
                          </div>
                        </div>
                        <div class="column">
                          <div class="field">
                            <label class="label">Bestelleingang bis</label>
                            <div class="control">
                              <input type="time" class="input" value="00:00" formControlName="latestOrderTime">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="columns">

                        <div class="column is-half">
                          <div class="field">
                            <label class="label">Startdatum für 2 wöchiges Lieferintervall (Optional)</label>
                            <p class="control has-icons-left" (click)="dateForSecondWeekInterval.open()">
                              <input [matDatepicker]="dateForSecondWeekInterval" class="input is-small"
                                     placeholder="Startdatum für 2 wöchiges Lieferintervall"
                                     formControlName="dateForSecondWeekInterval">
                              <span class="icon is-small is-left">
                          <mat-datepicker-toggle [for]="dateForSecondWeekInterval"></mat-datepicker-toggle>
                          <mat-datepicker #dateForSecondWeekInterval></mat-datepicker>
                        </span>
                            </p>
                          </div>
                        </div>

                      </div>
                    </p-accordionTab>
                    <p-accordionTab>
                      <ng-template pTemplate="header">
                        <span class="flex align-items-center gap-2 w-full">
                          <span *ngIf="isFormKeyInvalid('days.'+item.key+'.deliveryPeriods') " class="is-error mr-2"><fa-icon
                            [icon]="faExclamationCircle"></fa-icon></span> Lieferzeiträume
                        </span>
                      </ng-template>

                      <div formGroupName="deliveryPeriodCustomer" class="columns">
                        <div class="column">
                          <div class="is-flex">
                            <div>
                              <label class="checkbox is-user-select-none">
                                <input formControlName="available" type="checkbox" [value]="true">
                                Standardlieferzeitraum des Kunden als Auswahl ermöglichen
                              </label>
                            </div>
                            <div>
                              <fa-icon [icon]="faCog" (click)="toggleDeliveryPeriodCustomerSettings(item.key)"
                                       class="ml-2 is-pointer"
                                       [ngClass]="isDeliveryPeriodCustomerSetting(item.key) ? 'has-text-danger-dark' : ''"></fa-icon>
                            </div>
                          </div>
                          <div [ngClass]="isDeliveryPeriodCustomerSettingVisibility(item.key) ? 'mt-1': 'is-hidden'">
                            <div class="columns">

                              <div class="column is-5">
                                <div class="field">
                                  <label class="label">Späteste Auswahlmöglichkeit für den Lieferzeitraum</label>
                                  <div class="control">
                                    <select formControlName="latestOrderTimeInDays" class="select">
                                      <option [value]="null">Selber Tag</option>
                                      <option value="1">1 Tag vorher (Vortag)</option>
                                      <option value="2">2 Tage vorher</option>
                                      <option value="3">3 Tage vorher</option>
                                      <option value="4">4 Tage vorher</option>
                                      <option value="5">5 Tage vorher</option>
                                      <option value="6">6 Tage vorher</option>
                                      <option value="7">7 Tage vorher</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div class="column is-5">
                                <div class="field">
                                  <label class="label">Bis</label>
                                  <div class="control">
                                    <input formControlName="latestOrderTime" type="time" class="input" value="00:00">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="columns">
                              <div class="column is-11">
                                <div class="field">
                                  <label class="label">Bemerkung für den Kunden</label>
                                  <div class="control">
                                    <p-editor formControlName="note" [style]="{'height':'80px'}"></p-editor>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <div class="field ">
                            <label class="label"></label>
                            <div class="control">
                              <button (click)="addDeliveryPeriod(item.key)" class="button is-small add"><span
                                class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Lieferzeitraum hinzufügen</span>
                              </button>
                            </div>
                            <div formArrayName="deliveryPeriods">
                              <div
                                *ngFor="let x of getDeliveryPeriodsAsFormArray(item.key, 'deliveryPeriods').controls; let i = index;"
                                [ngClass]="i % 2 !== 0 ? 'has-background-white' : ''" [formGroupName]='i'>
                                <div class="columns is-mobile p-0 m-0 mt-1">
                                  <div class="column is-4 p-0">
                                    <input class="input is-small" placeholder="Lieferzeitraum"
                                           formControlName="deliveryPeriod">
                                    <p class="help is-danger"
                                       *ngIf="getDeliveryPeriodsAsFormArray(item.key, 'deliveryPeriods').at(i).invalid">
                                      Bitte hinterlegen Sie einen Lieferzeitraum.</p>
                                  </div>
                                  <div class="column is-1 p-0">
                                    <fa-icon [icon]="faCog" (click)="toggleDeliveryPeriodSettings(item.key, i)"
                                             class=" ml-2 is-pointer"
                                             [ngClass]="isDeliveryPeriodSetting(item.key, i) ? 'has-text-danger-dark' : ''"></fa-icon>
                                    <fa-icon [icon]="faTrashCan" (click)="removeDeliveryPeriod(item.key, i)"
                                             class="delete-field ml-2"></fa-icon>
                                  </div>
                                </div>

                                <div
                                  [ngClass]="isDeliveryPeriodSettingVisibility(item.key, i) ? 'mt-2 mb-2': 'is-hidden'">
                                  <div class="columns">
                                    <div class="column is-5">
                                      <div class="field">
                                        <label class="label">Späteste Auswahlmöglichkeit für den Lieferzeitraum</label>
                                        <div class="control">
                                          <select class="select" formControlName="latestOrderTimeInDays">
                                            <option [value]="null">Selber Tag</option>
                                            <option value="1">1 Tag vorher (Vortag)</option>
                                            <option value="2">2 Tage vorher</option>
                                            <option value="3">3 Tage vorher</option>
                                            <option value="4">4 Tage vorher</option>
                                            <option value="5">5 Tage vorher</option>
                                            <option value="6">6 Tage vorher</option>
                                            <option value="7">7 Tage vorher</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="column is-5">
                                      <div class="field">
                                        <label class="label">Bis</label>
                                        <div class="control">
                                          <input formControlName="latestOrderTime" type="time" class="input"
                                                 value="00:00">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="columns">
                                    <div class="column is-11">
                                      <div class="field">
                                        <label class="label">Bemerkung für den Kunden</label>
                                        <div class="control">
                                          <p-editor formControlName="note" [style]="{'height':'80px'}"></p-editor>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </p-accordionTab>
                    <p-accordionTab header="Bemerkungen für den Kunden">
                      <div class="columns">
                        <div class="column">
                          <div class="field">
                            <label class="label">Bemerkung für den Kunden</label>
                            <div class="control">
                              <p-editor formControlName="note" [style]="{'height':'80px'}"></p-editor>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <div class="field">

                            <label class="label">Bemerkung einblenden</label>
                            <div class="control">
                              <select formControlName="noteVisibilityBeforeDeliveryDateInDays" class="select">
                                <option [value]="null">Immer</option>
                                <option value="0">Selber Tag</option>
                                <option value="1">1 Tag vorher (Vortag)</option>
                                <option value="2">2 Tage vorher</option>
                                <option value="3">3 Tage vorher</option>
                                <option value="4">4 Tage vorher</option>
                                <option value="5">5 Tage vorher</option>
                                <option value="6">6 Tage vorher</option>
                                <option value="7">7 Tage vorher</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="column">
                          <div class="field">
                            <label class="label">Ab</label>
                            <div class="control">
                              <input formControlName="noteVisibilityBeforeDeliveryDateTime" type="time" class="input"
                                     value="23:59">
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-accordionTab>
                  </p-accordion>
                </div>
              </div>
            </p-accordionTab>
            <p-accordionTab>
              <ng-template pTemplate="header">
                    <span class="flex align-items-center gap-2 w-full">
                      Sonstiges
                    </span>
              </ng-template>
              <div class="columns">
                <div class="column">
                  <label class="checkbox">
                    <input type="checkbox" formControlName="pickupTour">
                    Abholtour. Freie Eingabe der Lieferadresse möglich.
                  </label>
                </div>

              </div>
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">Alternativname für Liefertag (z.B. Abholtermin)</label>
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="nameDeliveryDate" placeholder="Name für Liefertag">
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">Alternativname für Liefertermine (z.B. Abholtermine)</label>
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="nameDeliveryDates" placeholder="Name für Liefertermine">
                    </div>
                  </div>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>

        </div>
        <div *ngIf="activeTab=='conditions'" class="tab-container">
          <div class="columns">
            <div class="column">
              <shared-form-autocomplete [autocompleteModel]="autocompleteRequiredDynamicArticles"

                                        (editItem)="editDynamicArticle($event)"
                                        (onChangeItems)="setRequiredDynamicArticleIds($event)"></shared-form-autocomplete>
            </div>
            <div class="column">
              <shared-form-autocomplete [autocompleteModel]="autocompleteExcludedDynamicArticles"
                                        label="Tour greift, wenn folgende dynamische Artikel NICHT ausgewählt sind"
                                        (editItem)="editDynamicArticle($event)"
                                        (onChangeItems)="setExcludedDynamicArticleIds($event)"></shared-form-autocomplete>
            </div>
          </div>
          <div class="columns">
            <div class="column  is-4 is-full-mobile pb-0">
              <button (click)="addDynamicField('requiredPriceGroups')" class="button is-small add"><span
                class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Gilt ausschließlich für die folgenden Preisgruppen</span>
              </button>
            </div>

          </div>
          <div>
            <div class="" formArrayName="requiredPriceGroups"
                 *ngFor="let x of getDynamicFieldAsFormArray('requiredPriceGroups').controls; let i = index;">
              <div class="columns is-mobile">
                <div class="column is-4 is-11-mobile">
                  <input class="input is-small" placeholder="Preisgruppe" [formControlName]="i">
                  <p class="help is-danger" *ngIf="getDynamicFieldAsFormArray( 'requiredPriceGroups').at(i).invalid">
                    Bitte
                    hinterlegen Sie eine Preisgruppe.</p>
                </div>
                <div class="column pl-0">
                  <fa-icon [icon]="faTrashCan" (click)="removeDynamicField('requiredPriceGroups', i)"
                           class=" delete-field ml-0"></fa-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activeTab=='orderConditions'" class="tab-container">
          <div class="columns">
            <div class="column">
              <label class="checkbox">
                <input type="checkbox" formControlName="disableOrderIfConditionsFailed">
                Bestellungen sperren, wenn die Bestellbedingungen nicht erreicht wurden
              </label>
            </div>
          </div>
          <div class="columns">
            <div class="column" formGroupName="minimumArticles">
              <p class="title is-size-4 mb-3 ">Mindestabnahme</p>
              <div class="field">
                <label class="label">Mindestabnahme in Artikeln</label>
                <div class="control">
                  <input class="input is-small" type="number"
                         formControlName="value" placeholder="Mindestabnahme">
                </div>
              </div>
              <div class="field">
                <label class="label">Hinweis der eingeblendet werden soll, wenn die Bedingung nicht erfüllt ist:</label>
                <div class="control">
                  <p-editor formControlName="note" [style]="{'height':'80px'}"></p-editor>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label"></label>
                    <div class="control">
                      <button (click)="addMinimumArticlesField('excludedGroups')" class="button is-small add"><span
                        class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Warengruppen bei Abnahmemenge nicht berücksichtigen</span>
                      </button>
                    </div>
                    <div>
                      <div class="is-flex mt-2" formArrayName="excludedGroups"
                           *ngFor="let x of getMinimumArticlesFieldAsFormArray('excludedGroups').controls; let i = index;">
                        <div class="is-flex-grow-1">
                          <p class="control has-icon-left ">
                            <input class="input is-small" placeholder="Warengruppe" [formControlName]="i">
                          <p class="help is-danger"
                             *ngIf="getMinimumArticlesFieldAsFormArray('excludedGroups').at(i).invalid">Bitte
                            hinterlegen
                            Sie eine Warengruppe.</p>
                        </div>
                        <div>
                          <fa-icon [icon]="faTrashCan" (click)="removeMinimumArticlesField('excludedGroups', i)"
                                   class="delete-field ml-2"></fa-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <div class="control">
                      <button (click)="addMinimumArticlesField('requiredArticleUnitTypes')"
                              class="button is-small add"><span class="icon is-small"><fa-icon
                        [icon]="faPlusSquare"></fa-icon></span><span>Auschließlich folgende Artikel-Gebinde-Typen berücksichtigen</span>
                      </button>
                    </div>
                    <div>
                      <div class="is-flex mt-2" formArrayName="requiredArticleUnitTypes"
                           *ngFor="let x of getMinimumArticlesFieldAsFormArray('requiredArticleUnitTypes').controls; let i = index;">
                        <div class="is-flex-grow-1">
                          <input class="input is-small" placeholder="Gebinde" [formControlName]="i">
                          <p class="help is-danger"
                             *ngIf="getMinimumArticlesFieldAsFormArray('requiredArticleUnitTypes').at(i).invalid">Bitte
                            hinterlegen Sie das Gebinde.</p>
                        </div>
                        <div (click)="removeMinimumArticlesField('requiredArticleUnitTypes', i)">
                          <fa-icon [icon]="faTrashCan"
                                   class=" delete-field ml-2"></fa-icon>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="column" formGroupName="minimumMerchandiseValue">
              <p class="title  is-size-4 mb-3 ">Mindestwarenwert</p>
              <div class="is-flex">
                <div class="field is-flex-grow-1">
                  <label class="label">Mindestbestellwert Netto</label>
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="net" placeholder="Name">
                  </div>
                </div>
                <div class="field ml-4 is-flex-grow-1">
                  <label class="label">Mindestbestellwert Brutto</label>
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="gross" placeholder="Name">
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Hinweis der eingeblendet werden soll, wenn die Bedingung nicht erfüllt ist:</label>
                <div class="control">
                  <p-editor formControlName="note" [style]="{'height':'80px'}"></p-editor>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label"></label>
                    <div class="control">
                      <button (click)="addMinimumMerchandiseValueField('excludedGroups')"
                              class="button is-small add"><span
                        class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Warengruppen bei Mindestwarenwert nicht berücksichtigen</span>
                      </button>
                    </div>
                    <div>
                      <div class="is-flex mt-2" formArrayName="excludedGroups"
                           *ngFor="let x of getMinimumMerchandiseValueAsFormArray('excludedGroups').controls; let i = index;">
                        <div class="is-flex-grow-1">
                          <input class="input is-small" placeholder="Warengruppe" [formControlName]="i">
                          <p class="help is-danger"
                             *ngIf="getMinimumMerchandiseValueAsFormArray('excludedGroups').at(i).invalid">Bitte
                            hinterlegen Sie eine Warengruppe.</p>
                        </div>
                        <div>
                          <fa-icon [icon]="faTrashCan" (click)="removeMinimumMerchandiseValueField('excludedGroups', i)"
                                   class="delete-field ml-2"></fa-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activeTab=='dynamicArticles'" class="tab-container">
          <shared-form-autocomplete [autocompleteModel]="autocompleteDynamicArticles"

                                    (editItem)="editDynamicArticle($event)"
                                    (onChangeItems)="setDynamicArticleIds($event)"></shared-form-autocomplete>
        </div>
      </div>
      <div class="content-foot has-text-centered">
        <shared-form-submit-button (submit)="submit()" [formStatus]="formHandler.getFormStatus()"></shared-form-submit-button>
      </div>
    </form>
  </div>
</div>

