<shared-list [list]="list"></shared-list>
<p-dialog
  [header]="showItemId ? 'Notiz bearbeiten' : 'Notiz anlegen'"
  [(visible)]="showNotesDialog"
  position="center"
  [modal]="true"
  [style]="{ width: '100vw', 'max-width': '1000px' }">
  <shared-customer-note-form [customerNumber]="customerNumber ?? ''" [itemId]="showItemId" (onSetSuccess)="showNotesDialog = false" (onUpdateSuccess)="showNotesDialog = false"></shared-customer-note-form>
</p-dialog>


