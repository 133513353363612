<button *ngIf="(list.loading.list | async)! " class="button is-loading list"></button>
<div class="container-list" [ngClass]="(list.loading.list | async) ? 'is-loading' : ''">
  <app-list-headline [title]="list.headline" [count]="list.count" [list]="list">

    <div *ngIf="list.menuBar">
      <p-menubar [model]="list.menuBar">
        <ng-template pTemplate="end">
          <div class="is-flex-desktop">
            <div class="pr-2 has-text-right">
              <app-list-search (setFilter)="list.setFilter($event)"
                               [filters]="list.getFilters()"></app-list-search>
            </div>
            <div class="pr-2 mt-2-touch has-text-right" *ngIf="list.download || list.getExternalLinks('export').length > 0">
              <app-list-button-export [list]="list"></app-list-button-export>
            </div>
            <div *ngIf="list.getExternalLinks('upload').length > 0" class="pr-2 mt-2-touch has-text-right">
              <app-list-button-import [list]="list"></app-list-button-import>
            </div>
            <div class="pr-2 mt-2-touch has-text-right">
              <app-list-button-edit-table [list]="list"></app-list-button-edit-table>
            </div>
            <div class="pr-2  mt-2-touch has-text-right">
              <app-list-notification [list]="list"></app-list-notification>
            </div>
          </div>
        </ng-template>
      </p-menubar>
    </div>

    <div *ngIf="!list.menuBar" class="columns mt-0 is-gapless mb-0 is-mobile">
      <div class="column pt-0" *ngIf="list.newItem">
        <button class="button is-success is-small"
                (click)="list.newItem.action()">
     <span class="icon is-small">
       <fa-icon [icon]="faPlusSquare"></fa-icon>
     </span>
          <span>{{ list.newItem.label }} </span>
        </button>
      </div>
      <div class="column pt-0 pr-0 mr-0">
        <div class="is-flex">
          <div class="mr-2">

          </div>
          <div class="is-flex-grow-3">
            <app-list-search (setFilter)="list.setFilter($event)"
                             [filters]="list.getFilters()"></app-list-search>
          </div>

        </div>
      </div>
      <div class="column pt-0 is-flex-wrap-nowrap">
        <div class="is-pulled-right mb-2 ml-2">
          <app-list-notification [list]="list"></app-list-notification>
        </div>
        <div class="is-pulled-right mb-2">
          <app-list-button-edit-table [list]="list"></app-list-button-edit-table>
        </div>
        <div class="is-pulled-right mb-2 mr-2" *ngIf="list.download || list.getExternalLinks('export').length > 0">
          <app-list-button-export [list]="list"></app-list-button-export>
        </div>
        <div *ngIf="list.getExternalLinks('upload').length > 0" class="is-pulled-right mb-2 mr-2">
          <app-list-button-import [list]="list"></app-list-button-import>
        </div>
      </div>
    </div>
  </app-list-headline>
  <div class="is-flex">

    <div *ngIf="visibilityFilter" class="pr-2 pt-2 mt-2">
      <shared-list-filters-additional [list]="list"></shared-list-filters-additional>
    </div>


    <div class="container-table" [ngClass]="list.loading.list ? 'loading' : ''"
         style="width: 100%; overflow-x:scroll; overflow-y: hidden;">

      <table
        class="table is-fullwidth is-hoverable is-striped is-bordered mt-4"
        [ngStyle]="{'min-width.px': minWidth}">
        <thead>
        <tr>
          <th class="details" *ngIf="list.row.details">
            <button *ngIf="list.filtersAdditional" class="button is-small"
                    [ngClass]="(list.loading.notification | async) ? 'is-loading' : '' "
                    (click)="visibilityFilter = !visibilityFilter">
              <fa-icon [icon]="faCaretLeft" class="mr-2" aria-hidden="true"
                       [ngClass]="visibilityFilter? '' : 'is-hidden'"></fa-icon>
              <fa-icon [icon]="faFilter"></fa-icon>
              <fa-icon [icon]="faCaretRight" class=" ml-2" aria-hidden="true"
                       [ngClass]="visibilityFilter? 'is-hidden' : ''"></fa-icon>
            </button>
          </th>
          <th *ngFor="let column of list.getColumns('userStorage'); ">
            <app-list-header-column label="{{column.label}}" field="{{column.field}}"
                                    (setSort)="list.setSort($event)" [sort]="list.sort"
                                    [sortable]="column.isSortable"></app-list-header-column>
          </th>
        </tr>
        <tr>
          <th class="details" *ngIf="list.row.details"></th>
          <th *ngFor="let column of list.getColumns('userStorage') " [ngClass]="column.class ?? ''">
            <div *ngIf="(column.dataType == 'string' || column.filterCallback) && column.isFilter ">
              <app-list-filter-input [listColumn]="column" [list]="list"></app-list-filter-input>
            </div>
            <div *ngIf=" (column.dataType == 'date' || column.dataType == 'dateTime') &&  column.isFilter ">
              <app-list-filter-date [listColumn]="column" [list]="list"></app-list-filter-date>

            </div>
          </th>
        </tr>
        </thead>
        <tbody (scroll)="list.scroll($event)">
        <tr *ngIf="list.count.filtered === 0 && !(list.loading.list | async)">
          <td>Keine Daten vorhanden.</td>
        </tr>
        <tr *ngFor="let item of list.getItems(); let index = index"

            (dblclick)="list.row.doubleClick(item)">
          <td class="details" *ngIf="list.row.details">
            <div class="is-flex is-justify-content-flex-end">
              <div>
                <fa-icon [icon]="faTrashCan" *ngIf="list.row.details.delete" class="delete-item is-size-6"
                         (click)="list.row.details.delete.action(item)"></fa-icon>
              </div>
              <div class="is-flex-grow-1 is-flex is-justify-content-flex-end">
                <fa-icon [icon]="faEye" *ngIf="list.row.details.view" class=" view ml-2 is-size-6"
                         (click)="list.row.details.view(item)"></fa-icon>
                <fa-icon [icon]="faEdit" *ngIf="list.row.details.edit" class="edit ml-2 is-size-6"
                         (click)="list.row.details.edit(item)"></fa-icon>

                <ng-container *ngFor="let button of list.row.details.buttons; let buttonIndex = index">
                  <ng-container *ngIf="button.type === 'overlayPanel'">
                    <span
                      *ngIf="button.overlayPanel && button.overlayPanel(item).length == 0"
                      [ngClass]="[button.class ?? '',  'has-text-grey-lighter']"
                      [style]="button.style"
                      pTooltip="{{button.title}}" tooltipPosition="right"
                      (click)="button.click(item); "
                    >
                      <fa-icon *ngIf="button.icon" [icon]="button.icon"></fa-icon>
                      </span>
                    <span
                      *ngIf="button.overlayPanel && button.overlayPanel(item).length > 0"
                      [ngClass]="[button.class ?? '']"
                      [style]="button.style"
                      pTooltip="{{button.title}}" tooltipPosition="right"
                      (click)="button.click(item); " (mouseenter)="op.show($event)" (mouseleave)="op.hide()"
                    >
                    <fa-icon *ngIf="button.icon" [icon]="button.icon"></fa-icon>
                    <p-overlayPanel #op>
                      <ng-container *ngIf="button.overlayPanel">
                        <div [style.max-width]="'800px'" [innerHTML]="button.overlayPanel(item)"></div>
                      </ng-container>
                    </p-overlayPanel>
                    </span>
                  </ng-container>

                  <ng-container *ngIf="button.type === 'menu'">
                    <span *ngIf="(button.menu && button.menu(item) !== undefined)"
                          [ngClass]="button.class ?? ''"
                          [style]="button.style"
                          pTooltip="{{button.title}}" tooltipPosition="right"
                          (click)="button.click(item); menu.toggle($event)"
                    >
                    <fa-icon *ngIf="button.icon" [icon]="button.icon"></fa-icon>
                        <p-menu #menu [model]="button.menu(item)" [popup]="true"/>
                    </span>
                  </ng-container>

                  <ng-container *ngIf="button.type === undefined || button.type === 'default'">
                    <span [ngClass]="button.class ?? ''"
                          [style]="button.style"
                          pTooltip="{{button.title}}" tooltipPosition="right"
                          (click)="button.click(item);">
                      <fa-icon *ngIf="button.icon" [icon]="button.icon"></fa-icon>
                    </span>
                  </ng-container>

                </ng-container>


                <span *ngIf="list.row.details.history" class="ml-2"> </span>
                <span class="ml-2 is-size-6">
                     <app-list-history-item [dateCreated]="item.dateCreated"
                                            [lastUpdate]="item.lastUpdate"></app-list-history-item>

                </span>
              </div>

            </div>
            <label *ngIf="list.row.details.checkbox" class="checkbox">
              <input type="checkbox">
            </label>


          </td>
          <td *ngFor="let column of list.getColumns('userStorage')" [ngClass]="column.class ?? ''">
          <span
            *ngIf="column.dataType == 'dateTime'">{{ getItemValue(column.readKeyInList ?? column.field, item) | date:'dd.MM.yyyy H:mm' }}
            Uhr</span>
            <span
              *ngIf="column.dataType == 'date'">{{ getItemValue(column.readKeyInList ?? column.field, item) | date:'dd.MM.yyyy' }}</span>
            <span
              *ngIf="column.dataType == 'number'">{{ getNumber(getItemValue(column.readKeyInList ?? column.field, item)) }}</span>
            <span *ngIf="column.dataType == 'string' || !column.dataType">
            <p *ngIf="!column.isHtml && !column.isLink"
               title="{{getItemValue(column.readKeyInList ?? column.field, item)}}">{{ getItemValue(column.readKeyInList ?? column.field, item) }}</p>
            <span *ngIf="column.isHtml" [innerHTML]="getItemValue(column.readKeyInList ?? column.field, item) "></span>
            <a *ngIf="column.isLink && getItemValue(column.readKeyInList ?? column.field, item).length > 0" [href]="getItemValue(column.readKeyInList ?? column.field, item)" target="_blank">{{getItemValue(column.readKeyInList ?? column.field, item)}}</a>

            <fa-icon [icon]="faEdit" *ngIf="column.buttons?.edit" class="edit ml-2"
                     (click)="column.buttons?.edit(item)"></fa-icon>
          </span>
            <span *ngIf="column.dataType == 'resolver'">
            <span *ngFor="let a of item[column.readKeyInList ?? column.field] ">
              {{ a.name }}
              <span *ngFor="let button of $any(a).buttons" [ngClass]="$any(button).class ?? ''" [style]="button.style"
                    pTooltip="{{button.title}}" tooltipPosition="right"
                    (click)="$any(button).click($any(button).item ?? item)"
              >
                <fa-icon *ngIf="button.icon" [icon]="$any(button).icon"></fa-icon>

              </span><br>
            </span>
          </span>
            <span *ngIf="column.dataType == 'boolean'">
            <span *ngIf="getItemValue(column.readKeyInList ?? column.field, item) == true">Ja</span>
            <span *ngIf="getItemValue(column.readKeyInList ?? column.field, item) == false">Nein</span>
          </span>
            <span *ngIf=" column.dataType == 'array'">
              <span class="pr-2"
                    *ngFor="let item of getItemValue(column.readKeyInList ?? column.field, item); let isLast = last">{{ item }}
                <ng-container *ngIf="!isLast">; </ng-container></span>
          </span>
            <span *ngIf="column.dataType == 'object' ">
            <pre>{{ getJSONString(getItemValue(column.readKeyInList ?? column.field, item)) }}</pre>
          </span>
          </td>
        </tr>

        </tbody>
      </table>
    </div>
  </div>


</div>
