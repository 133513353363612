<form [formGroup]="form">
  <div class="field has-addons">
    <p *ngIf="!listColumn.isId" class="control">
    <span class="select is-small p-0">
      <select (change)="setFilter()" formControlName="filterType">
        <option value="*">*</option>
        <option value="=">=</option>
      </select>
    </span>
    </p>
    <p class="control">
      <input class="input is-small" placeholder="Suche" formControlName="input" (input)="setFilter()">
      <span *ngIf="form.invalid" class="help is-danger">Ungültige Eingabe</span>
    </p>
  </div>


</form>

