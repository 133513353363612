import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {login} from '@store/actions/authentication.action';
import {AppState} from '@store/state/app.state';
import {FormHelperDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {takeUntil} from 'rxjs/operators';
import {faUser} from '@fortawesome/free-regular-svg-icons';
import {faLock} from '@fortawesome/free-solid-svg-icons';

interface Error {
  key: string;
  error: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  @ViewChild('formTag', {static: false}) formTag: ElementRef | undefined;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  faUser = faUser;
  faLock = faLock;

  errorMsg$?: Observable<string | undefined>;
  loading$?: Observable<boolean | undefined>;

  form: UntypedFormGroup = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]]
  });

  formHandler: FormHelperDeprecated;

  constructor(private fb: UntypedFormBuilder, private router: Router, private route: ActivatedRoute, private store: Store<AppState>, private renderer: Renderer2) {
    this.formHandler = new FormHelperDeprecated(
      this.store,
      '',
      this.form,
      [
        {key: 'username', error: 'Bitte hinterlegen Sie einen Benutzernamen.'},
        {key: 'password', error: 'Bitte hinterlegen Sie ein Passwort.'},
      ],
      this.renderer
    );
    this.formHandler.notification = '';
  }

  ngOnInit(): void {

    this.loading$ = this.store.select(state => state.authentication.isLoading);
    this.errorMsg$ = this.store.select(state => state.authentication.errorMsg);

    this.store.select(state => state.authentication)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        r => {
          this.formHandler.status = null;
          if (r.success) {
            this.router.navigate(['/shop/dashboard']).then();
            this.form.get('username')?.setValue('');
            this.form.get('password')?.setValue('');
          } else {

            if (r.errorMsg) {
              this.formHandler.setErrorMessages([{error: r.errorMsg, key: 'password'}]);
              this.formHandler.displayErrors();
            }
          }
        })
  }

  ngAfterViewInit() {
    this.formHandler.htmlRef = this.formTag;
  }

  setLogin() {
    this.formHandler.validateForm();
    this.formHandler.displayErrors();
    if (!this.formHandler.status &&
      !this.formHandler.isFormErrors()) {
      this.formHandler.status = 'submitForm';
      this.store.dispatch(login({
        username: this.form.get('username')?.value,
        password: this.form.get('password')?.value
      }));
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


}

