<app-authentication-container>
  <form [formGroup]="form" #formTag>
      <h1 class="title is-5 mt-6 mb-4 is-block">Login</h1>
      <div class="field mt-4">
        <div class="control has-icons-left has-icons-right">
          <input class="input" type="text" formControlName="username" placeholder="Benutzername" value="">
          <span class="icon is-small is-left"><fa-icon [icon]="faUser"></fa-icon></span>
        </div>
      </div>
      <div class="field mt-2 mb-2">
        <p class="control has-icons-left">
          <input class="input" type="password" formControlName="password" placeholder="Passwort" value="">
          <span class="icon is-small is-left"><fa-icon [icon]="faLock"></fa-icon></span>
        </p>
      </div>
      <a class="is-pulled-right" routerLink="/password-lost">Passwort vergessen?</a>
      <button [ngClass]="{ 'is-loading': (loading$ | async) }" class="button " (click)="setLogin()">Anmelden
      </button><br>

  </form>
</app-authentication-container>
