import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {
  ApiOrdersDeleteRequest,
  ApiOrdersDeleteResponse,
  ApiOrdersGetRequest,
  ApiOrdersGetResponse
} from '../../api/orders';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient) {

  }

  download(params: ApiOrdersGetRequest['params'], type: 'csv' | 'xlsx'): Observable<any> {
    const body: ApiOrdersGetRequest = {
      id: 'downloadOrders',
      command: 'orders/get',
      params
    };
    return this.http.post<any>(environment.apiUrl + 'download.php?type=' + type, JSON.stringify(body), {responseType: 'blob' as 'json'});
  }

  get(params: ApiOrdersGetRequest['params']): Observable<ApiOrdersGetResponse> {
    const body: ApiOrdersGetRequest = {
      id: 'getOrders',
      command: 'orders/get',
      params
    };
    return this.http.post<ApiOrdersGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  delete(params: ApiOrdersDeleteRequest['params']): Observable<ApiOrdersDeleteResponse> {
    const body: ApiOrdersDeleteRequest = {
      id: 'getOrders',
      command: 'orders/delete',
      params
    };
    return this.http.post<ApiOrdersDeleteResponse>(environment.apiUrl, JSON.stringify(body));
  }

}
