<div *ngIf="loading" class="content-content has-text-centered">
  <button class="button is-loading"></button>
</div>
<div #formTag>
  <div [ngClass]="loading ? 'is-hidden' : ''">
    <div class="tabs">
      <ul>
        <li [ngClass]="activeTab=='overview' ? 'is-active' : ''"
            (click)="activeTab = 'overview'">
          <a>
            <span *ngIf="isFormKeyInvalid('name') || isFormKeyInvalid('articleNumber') || isFormKeyInvalid('groups') || isFormKeyInvalid('quantity') || isFormKeyInvalid('unit')"  class="is-error mr-2">
              <fa-icon [icon]="faExclamationCircle"></fa-icon>
            </span>
            Übersicht
          </a>
        </li>
        <li [ngClass]="activeTab=='prices' ? 'is-active' : ''"
            (click)="activeTab = 'prices'">

          <a>
            <span *ngIf="articlePrices.length == 0 && formHandler.getFormStatus() == 'submitForm'"  class="is-error mr-2">
              <fa-icon [icon]="faExclamationCircle"></fa-icon>
            </span>
            Preise

          </a></li>
        <li [ngClass]="activeTab=='details' ? 'is-active' : ''"
            (click)="activeTab = 'details'">

          <a>
            <span *ngIf="isFormKeyInvalid('notifications') || isFormKeyInvalid('visibilityGroups') || isFormKeyInvalid('allergenIngredients') || isFormKeyInvalid('additives') "  class="is-error mr-2">
              <fa-icon [icon]="faExclamationCircle"></fa-icon>
            </span>
            Details</a></li>
        <li [ngClass]="activeTab=='images' ? 'is-active' : ''"
            (click)="activeTab = 'images'"><a>Bilder</a>
        </li>
        <li [ngClass]="activeTab=='articleRecommendations' ? 'is-active' : ''"
            (click)="activeTab = 'articleRecommendations'"><a>Artikelempfehlungen</a>

        </li>
        <li [ngClass]="activeTab=='relatedArticles' ? 'is-active' : ''"
            (click)="activeTab = 'relatedArticles'"><a>Artikelgruppierung</a>
        </li>
        <li [ngClass]="activeTab=='SEO' ? 'is-active' : ''"
            (click)="activeTab = 'SEO'"><a>SEO</a>
      </ul>
    </div>
    <div class="mb-4">
      <shared-form-error [apiError]="formHandler.getFormError()"></shared-form-error>
    </div>
    <div class="content-content is-tabs">
      <form [formGroup]="form">
        <!-- overview -->
        <div class="tab-container" [ngClass]="activeTab!='overview' ? 'is-hidden' : ''">
          <div class="columns">
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Name</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="name" placeholder="Name">
                      <p class="help is-danger" *ngIf="isFormKeyInvalid('name')">Bitte hinterlegen Sie einn Namen.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Artikelnummer</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="articleNumber" placeholder="Artikelnummer">
                      <p class="help is-danger" *ngIf="isFormKeyInvalid('articleNumber')">Bitte hinterlegen Sie eine
                        Artikelnummer.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="columns">
            <div class="column">

              <div class="field">
                <div class="field-body">
                  <div class="field ">
                    <label class="label"></label>
                    <div class="control">
                      <button (click)="formDynamicFieldsGroup.add()" class="button is-small add"><span
                        class="icon is-small"><fa-icon
                        [icon]="faPlusSquare"></fa-icon></span><span>Gruppe hinzufügen</span>
                      </button>
                      <p class="help is-danger" *ngIf="isFormKeyInvalid('groups')">Bitte hinterlegen Sie eine
                        Gruppe.</p>
                    </div>
                    <div formArrayName="groups">
                      <div *ngFor="let group of formDynamicFieldsGroup.getFormArray().controls; let i=index">
                        <div [formGroupName]="i" class="mt-2">
                          <div class="is-flex">
                            <div>
                              <p>{{ i + 1 }}. Gruppe)</p>
                            </div>
                            <div class="is-flex-grow-1 has-text-right">
                              <button class="delete is-small is-right"
                                      (click)="formDynamicFieldsGroup.remove(i)"></button>
                            </div>
                          </div>
                          <div class="columns">
                            <div class="column">
                              <div class="field is-horizontal">
                                <div class="field-label is-normal">
                                  <label class="label">Gruppe</label>
                                </div>
                                <div class="field-body">
                                  <div class="field">
                                    <div class="control">
                                      <shared-form-autocomplete [fillFormWithSelectedItemName]="true"
                                                                [showSearchIcon]="false"
                                                                [autocompleteModel]="getAutocomplete('groups_'+i, 'groups')"
                                                                [myFormControl]="getFormControl(form.get('groups.'+i)?.get('groupName'))"></shared-form-autocomplete>
                                      <p class="help is-danger" *ngIf="isFormKeyInvalid('groups.'+i+'.groupName')">
                                        Bitte hinterlegen Sie einen Namen.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="column">
                              <div class="field is-horizontal">
                                <div class="field-label is-normal">
                                  <label class="label">Gruppe 1</label>
                                </div>
                                <div class="field-body">
                                  <div class="field">
                                    <div class="control">
                                      <shared-form-autocomplete [fillFormWithSelectedItemName]="true"
                                                                [showSearchIcon]="false"
                                                                [autocompleteModel]="getAutocomplete('groups1_'+i, 'groups1')"
                                                                [myFormControl]="getFormControl(form.get('groups.'+i)?.get('group1Name'))"></shared-form-autocomplete>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field ">
                <div class="field-label is-normal">
                  <label class="label">Beschreibung</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                        <textarea class="textarea is-small" formControlName="description"
                                  placeholder="Beschreibung"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="columns mt-2">
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Anzahl / Zusatz</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="number"
                             formControlName="quantity" placeholder="Anzahl">
                      <p class="help is-danger" *ngIf="isFormKeyInvalid('quantity')">Bitte hinterlegen Sie eine
                        Anzahl.</p>
                    </div>
                  </div>
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="quantityAddition" placeholder="Anzahl-Zusatz">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column"></div>
          </div>
          <div class="columns">
            <div class="column">

              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label" title="Gebinde / Maßeinheit / Zusatz">Gebinde / Maßeinheit / Zusatz</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="number"
                             formControlName="unit" placeholder="Gebinde">
                      <p class="help is-danger" *ngIf="isFormKeyInvalid('unit')">Bitte hinterlegen Sie ein
                        Gebinde.</p>
                    </div>
                  </div>
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="unitOfMeasurement" placeholder="Maßeinheit">
                    </div>
                  </div>
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="unitAddition" placeholder="Gebinde-Zusatz">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Gesamtgewicht in KG</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="totalWeightInKg" placeholder="Gesamtgewicht in KG">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Gebinde-Typ</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="packageType" placeholder="Gebinde-Typ">
                    </div>
                  </div>
                </div>
              </div>

              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Gebinde-Typ Pfand</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="depositPackageType" placeholder="Gebinde-Typ Pfand">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!-- prices -->
      <div class="tab-container" [ngClass]="activeTab!='prices' ? 'is-hidden' : ''">
        <form [formGroup]="form">
          <div class="columns">
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Mehrwertsteuer</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="taxRate" placeholder="Mehrwertsteuer">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal mt-2">
                <div class="field-label is-normal">
                  <label class="label"></label>
                </div>
                <div class="field-body">
                  <label class="checkbox">
                    <input type="checkbox" formControlName="considerQuantityInCart">
                    Artikel wird im Warenkorb für einen Mengenrabatt berücksichtigt
                  </label>
                </div>
              </div>
            </div>
            <div class="column">

            </div>
          </div>
          <button (click)="addPrice(); dt1.reset()" class="button is-small add mb-4"><span
            class="icon is-small"><fa-icon
            [icon]="faPlusSquare"></fa-icon></span><span>Preis hinzufügen</span>
          </button>
        </form>
        <p-table #dt1 [value]="articlePrices" [tableStyle]="{'min-width': '50rem'}"
                 [rows]="50" [paginator]="true"
                 scrollHeight="600px"
                 [showCurrentPageReport]="true"
                 styleClass="p-datatable-sm"
                 currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
                 [rowsPerPageOptions]="[50, 100, 250, 500]"
        >
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th class="is-no-wrap" pSortableColumn="priceGroup">Preisgruppe
                <p-sortIcon field="priceGroup"></p-sortIcon>
              </th>
              <th class="is-no-wrap" pSortableColumn="netPrice">Netto-Preis
                <p-sortIcon field="netPrice"></p-sortIcon>
              </th>
              <th class="is-no-wrap" pSortableColumn="netDeposit">Netto-Pfand
                <p-sortIcon field="netDeposit"></p-sortIcon>
              </th>
              <th class="is-no-wrap" pSortableColumn="netFee">Netto-Gebühr
                <p-sortIcon field="netFee"></p-sortIcon>
              </th>
              <th class="is-no-wrap" pSortableColumn="fromArticleQuantity">Ab Artikelmenge
                <p-sortIcon field="fromArticleQuantity"></p-sortIcon>
              </th>
              <th class="is-no-wrap" pSortableColumn="fromQuantity">Ab Menge (Warenkorb)
                <p-sortIcon field="fromCartQuantity"></p-sortIcon>
              </th>
              <th class="is-no-wrap" pSortableColumn="validFrom">Gültig von
                <p-sortIcon field="validFrom"></p-sortIcon>
              </th>
              <th class="is-no-wrap" pSortableColumn="validTo">Gültig bis
                <p-sortIcon field="validTo"></p-sortIcon>
              </th>
            </tr>
            <tr>
              <th></th>
              <th>
                <input pInputText type="text "
                       (input)="dt1.filter($any($event.target).value ?? '', 'priceGroup', 'contains')"
                       [value]="$any(dt1.filters['priceGroup'])?.value" placeholder="Filter Preisgruppe" class="input is-small">
              </th>
              <th>
                <input pInputText type="text" use
                       (input)="dt1.filter($any($event.target).value, 'netPrice', 'contains')"
                       [value]="$any(dt1.filters['netPrice'])?.value" placeholder="Filter Preis" class="input is-small">
              </th>
              <th>
                <input pInputText type="text"
                       (input)="dt1.filter($any($event.target).value, 'netDeposit', 'contains')"
                       [value]="$any(dt1.filters['netDeposit'])?.value" placeholder="Filter Pfand" class="input is-small">
              </th>
              <th>
                <input pInputText type="text" (input)="dt1.filter($any($event.target).value, 'netFee', 'contains')"
                       [value]="$any(dt1.filters['netFee'])?.value" placeholder="Filter Gebühr" class="input is-small">
              </th>
              <th>
                <input pInputText type="text"
                       (input)="dt1.filter($any($event.target).value, 'fromArticleQuantity', 'contains')"
                       [value]="$any(dt1.filters['fromArticleQuantity'])?.value" placeholder="Filter Anzahl"
                       class="input is-small">
              </th>
              <th>
                <input pInputText type="text"
                       (input)="dt1.filter($any($event.target).value, 'fromCartQuantity', 'contains')"
                       [value]="$any(dt1.filters['fromCartQuantity'])?.value" placeholder="Filter Anzahl" class="input is-small">
              </th>
              <th>
                <input pInputText type="text"
                       (input)="dt1.filter($any($event.target).value, 'validFrom', 'contains')"
                       [value]="$any(dt1.filters['validFrom'])?.value" placeholder="Filter ab" class="input is-small">
              </th>
              <th>
                <input pInputText type="text" (input)="dt1.filter($any($event.target).value, 'validTo', 'contains')"
                       [value]="$any(dt1.filters['validTo'])?.value" placeholder="Filter bis" class="input is-small">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-articlePrices let-editing="editing" let-rowIndex="rowIndex">
            <tr [pSelectableRow]="articlePrices" >
              <td>
                <button (click)="deletePrice(rowIndex)" class="delete is-small is-right"></button>
              </td>
              <td>
                <span class="p-column-title">Preisgruppe</span>
                <input
                  pInputText
                  type="text"
                  class="input is-small"
                  placeholder="Preisgruppe"
                  [(ngModel)]="articlePrices.priceGroup"/>
              </td>
              <td>
                <span class="p-column-title">Netto-Preis</span>
                <input
                  pInputText
                  type="number"
                  class="input is-small"
                  placeholder="Netto-Preis"
                  [(ngModel)]="articlePrices.netPrice"/>
              </td>
              <td>
                <span class="p-column-title">Netto-Pfand</span>
                <input
                  pInputText
                  type="number"
                  class="input is-small"
                  placeholder="Netto-Pfand"
                  [(ngModel)]="articlePrices.netDeposit"/>
              </td>
              <td>
                <span class="p-column-title">Netto-Gebühr</span>
                <input
                  pInputText
                  type="number"
                  class="input is-small"
                  [(ngModel)]="articlePrices.netFee"/>
              </td>
              <td>
                <span class="p-column-title">Ab Artikelmenge</span>
                <input
                  pInputText
                  type="number"
                  placeholder="Ab Menge"
                  class="input is-small"
                  [(ngModel)]="articlePrices.fromArticleQuantity"/>
              </td>
              <td [pEditableColumn]="articlePrices.fromCartQuantity" pEditableColumnField="fromQuantity">
                <span class="p-column-title">Ab Artikelmenge (Warenkorb)</span>
                <input
                  pInputText
                  type="number"
                  class="input is-small"
                  placeholder="Ab Menge (Warenkorb)"
                  [(ngModel)]="articlePrices.fromCartQuantity"/>
              </td>
              <td>
                <span class="p-column-title">Gültig ab</span>
                <p class="control has-icons-left" (click)="fromPrice.open()">
                  <input [matDatepicker]="fromPrice" [(ngModel)]="articlePrices.validFrom" class="input is-small" placeholder="Gültig ab">
                  <span class="icon is-small is-left">
                                      <mat-datepicker-toggle matSuffix [for]="fromPrice"></mat-datepicker-toggle>
                                      <mat-datepicker #fromPrice></mat-datepicker>
                                    </span>
                </p>
              </td>
              <td>
                <span class="p-column-title">Gültig bis</span>
                <p class="control has-icons-left" (click)="toPrice.open()">
                  <input [matDatepicker]="toPrice" [(ngModel)]="articlePrices.validTo" class="input is-small" placeholder="Gültig bis">
                  <span class="icon is-small is-left">
                                      <mat-datepicker-toggle matSuffix [for]="toPrice"></mat-datepicker-toggle>
                                      <mat-datepicker #toPrice></mat-datepicker>
                                    </span>
                </p>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8">Keine Preise gefunden.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <form [formGroup]="form">
        <!-- details -->
        <div class="tab-container" [ngClass]="activeTab!='details' ? 'is-hidden' : ''">
          <div class="columns">
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Hinweise </label>
                </div>
                <div class="field-body">
                  <div class="is-flex-grow-1">
                    <div>
                      <button (click)="formNotifications.add()" class="button is-small add"><span
                        class="icon is-small"><fa-icon
                        [icon]="faPlusSquare"></fa-icon></span><span>Hinweis hinzufügen</span>
                      </button>
                    </div>
                    <div formArrayName="notifications">
                      <div *ngFor="let group of formNotifications.getFormArray().controls; let i=index">
                        <div [formGroupName]="i" class="mt-4">
                          <div class="field">
                            <div class="is-flex">
                              <label class="label">Hinweis</label>
                              <div class="is-flex-grow-1 has-text-right">
                                <button class="delete is-small is-right"
                                        (click)="formNotifications.remove(i)"></button>
                              </div>
                            </div>
                            <div class="control">
                              <input class="input is-small" type="text"
                                     formControlName="category" placeholder="Hinweis">
                              <p class="help is-danger" *ngIf="isFormKeyInvalid('notifications.'+i+'.category')">Bitte
                                hinterlegen Sie ein Hinweis.</p>
                            </div>
                          </div>
                          <div class="is-flex mt-2">
                            <div class="is-flex">
                              <label class="label mt-2 mr-2">Von</label>
                              <div class="field-body">
                                <div class="field">
                                  <p class="control has-icons-left" (click)="from.open()">
                                    <input [matDatepicker]="from" class="input is-small" placeholder="Ab Datum"
                                           formControlName="validFrom">
                                    <span class="icon is-small is-left">
                                      <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
                                      <mat-datepicker #from></mat-datepicker>
                                    </span>

                                  </p>
                                  <p class="help is-danger" *ngIf="isFormKeyInvalid('notifications.'+i+'.validFrom')">Bitte
                                    hinterlegen Sie ein Datum.</p>
                                </div>
                              </div>
                            </div>
                            <div class="is-flex">
                              <label class="label mt-2 mr-2 ml-2">Bis</label>
                              <div class="field-body">
                                <div class="field">
                                  <p class="control has-icons-left" (click)="to.open()">
                                    <input [matDatepicker]="to" class="input is-small" placeholder="Bis Datum"
                                           formControlName="validTo">
                                    <span class="icon is-small is-left">
                                    <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
                                    <mat-datepicker #to></mat-datepicker>
                                  </span>
                                  </p>
                                  <p class="help is-danger" *ngIf="isFormKeyInvalid('notifications.'+i+'.validTo')">Bitte
                                    hinterlegen Sie ein Datum.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Sichtbar für Gruppen:</label>
                </div>
                <div class="field-body">
                  <div class="is-flex-grow-1">
                    <div>
                      <button (click)="formDynamicFieldsVisibilityGroups.add()"
                              class="button is-small add"><span
                        class="icon is-small"><fa-icon
                        [icon]="faPlusSquare"></fa-icon></span><span>Gruppe hinzufügen</span>
                      </button>
                    </div>
                    <div formArrayName="visibilityGroups">
                      <div *ngFor="let group of formDynamicFieldsVisibilityGroups.getFormArray().controls; let i=index">
                        <div class="mt-4">
                          <div class="field">
                            <div class="is-flex">
                              <div class="control is-flex-grow-1">
                                <input class="input is-small" type="text"
                                       [formControlName]="i" placeholder="Gruppe">
                                <p class="help is-danger" *ngIf="isFormKeyInvalid('visibilityGroups.'+i)">Bitte
                                  hinterlegen
                                  Sie eine Gruppe.</p>
                              </div>
                              <div class="ml-2 has-text-right">
                                <button class="delete mt-2 is-small is-right"
                                        (click)="formDynamicFieldsVisibilityGroups.remove(i)"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns mt-2">
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Verfügbarkeit</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="availability" placeholder="Verfügbarkeit">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">EAN Flasche</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="eanBottle" placeholder="EAN Flasche">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Bestand</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="number"
                             formControlName="inventory" placeholder="Bestand">
                    </div>
                  </div>

                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">EAN Kiste</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="eanPackage" placeholder="EAN Kiste">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns mt-2">
            <div class="column">
              <div class="field">
                <div class="field-label is-normal">
                  <label class="label">Zusätzliche Angaben</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <textarea class="textarea is-small" type="text"
                                formControlName="additionalData" placeholder="Zusätzliche Angaben"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns mt-2">
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Hersteller</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="manufacturer" placeholder="Hersteller">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Herstellungsort</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="manufacturingLocation" placeholder="Herstellungsort">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Marke</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="brand" placeholder="Marke">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Kategorie</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="category" placeholder="Kategorie">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Zertifikate</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <textarea class="textarea is-small" type="text"
                                formControlName="certificates" placeholder="Zertifikate"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Herstelleradresse </label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="manufacturerCompanyAddress" placeholder="Herstelleradresse">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Hersteller-Internetadresse</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="manufacturerInternetAddress" placeholder="Hersteller-Internetadresse">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Land</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="country" placeholder="Land">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Region</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="region" placeholder="Region">
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="columns mt-2">
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label"></label>
                </div>
                <div class="field-body">
                  <label class="checkbox">
                    <input type="checkbox" formControlName="createVoucherWhenOrdering">
                    Bei der Bestellung von diesem Artikel einen Gutschein in der Höhe des Preises erstellen
                  </label>
                </div>
              </div>
            </div>
            <div class="column">

            </div>
          </div>
          <div class="columns mt-2">
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Empfehlung</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <textarea class="textarea is-small" type="text"
                                formControlName="recommendation" placeholder="Empfehlung"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Geschmacksbeschreibung kurz</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <textarea class="textarea is-small" type="text"
                                formControlName="flavorDescriptionShort"
                                placeholder="Geschmacksbeschreibung kurz"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Geschmacksbeschreibung lang</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <textarea class="textarea is-small" type="text"
                                formControlName="flavorDescriptionLong"
                                placeholder="Geschmacksbeschreibung lang"></textarea>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Empfehlung Filter</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <textarea class="textarea is-small" type="text"
                                formControlName="recommendationFilter" placeholder="Empfehlung Filter"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Geschmacksbeschreibung lang2</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <textarea class="textarea is-small" type="text"
                                formControlName="flavorDescriptionLong2"
                                placeholder="Geschmacksbeschreibung lang2"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Fazit</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <textarea class="textarea is-small" type="text"
                                formControlName="conclusion" placeholder="Fazit"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns mt-2">
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Verkehrsbezeichnung</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="salesDescription" placeholder="Verkehrsbezeichnung">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Inverkehrbringer</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="distributor" placeholder="Inverkehrbringer">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Allergene Zutaten:</label>
                </div>
                <div class="field-body">
                  <div class="is-flex-grow-1">
                    <div>
                      <button (click)="formDynamicFieldsAllergenIngredients.add()"
                              class="button is-small add"><span
                        class="icon is-small"><fa-icon
                        [icon]="faPlusSquare"></fa-icon></span><span>Allergene Zutat hinzufügen</span>
                      </button>
                    </div>
                    <div formArrayName="allergenIngredients">
                      <div
                        *ngFor="let value of formDynamicFieldsAllergenIngredients.getFormArray().controls; let i=index">
                        <div class="mt-1">
                          <div class="field">
                            <div class="is-flex">
                              <div class="control is-flex-grow-1">
                                <input class="input is-small" type="text"
                                       [formControlName]="i" placeholder="Allergene Zutat">
                                <p class="help is-danger" *ngIf="isFormKeyInvalid('allergenIngredients.'+i)">Bitte
                                  hinterlegen Sie eine Allergene Zutat.</p>
                              </div>
                              <div class="ml-2 has-text-right">
                                <button class="delete mt-2 is-small is-right"
                                        (click)="formDynamicFieldsAllergenIngredients.remove(i)"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Zusatzstoffe:</label>
                </div>
                <div class="field-body">
                  <div class="is-flex-grow-1">
                    <div>
                      <button (click)="formDynamicFieldsAdditives.add()"
                              class="button is-small add"><span
                        class="icon is-small"><fa-icon
                        [icon]="faPlusSquare"></fa-icon></span><span>Zusatzstoff hinzufügen</span>
                      </button>
                    </div>
                    <div formArrayName="additives">
                      <div *ngFor="let value of formDynamicFieldsAdditives.getFormArray().controls; let i=index">
                        <div class="mt-1">
                          <div class="field">
                            <div class="is-flex">
                              <div class="control is-flex-grow-1">
                                <input class="input is-small" type="text"
                                       [formControlName]="i" placeholder="Allergene Zutat">
                                <p class="help is-danger" *ngIf="isFormKeyInvalid('additives.'+i)">Bitte hinterlegen Sie
                                  einen Zusatzstoff.</p>
                              </div>
                              <div class="ml-2 has-text-right">
                                <button class="delete mt-2 is-small is-right"
                                        (click)="formDynamicFieldsAdditives.remove(i)"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Zutatenverzeichnis</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <textarea class="textarea is-small" type="text"
                                formControlName="foodDirectory" placeholder="Zutatenverzeichnis"></textarea>
                    </div>
                  </div>
                </div>
              </div>


              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Lagerfähigkeit</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="shelfLife" placeholder="Lagerfähigkeit">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Mindesthaltbarkeit</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="minimumDurability" placeholder="Mindesthaltbarkeit">
                    </div>
                  </div>

                </div>
              </div>

            </div>


            <div class="column">


              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Alkoholgehalt</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="alcoholicStrength" placeholder="Alkoholgehalt">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Koffeingehalt</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="caffeine" placeholder="Koffeingehalt">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Energie in Kilojoule</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="energyInKilojoule" placeholder="Energie in Kilojoule">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Energie in Kilokalorie</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="energyInKilokalorie" placeholder="Energie in Kilokalorie">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Eiweiß</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="protein" placeholder="Eiweiß">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Kohlenhydrate</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="carbohydrates" placeholder="Kohlenhydrate">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Fettgehalt </label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="fatRate" placeholder="Fettgehalt">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Gesättigte Fettsäuren </label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="saturatedFattyAcids" placeholder="Gesättigte Fettsäuren">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Ballaststoffe</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="dietaryFibers" placeholder="Ballaststoffe">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Natrium</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="natrium" placeholder="Natrium">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Salz</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="salt" placeholder="Salz">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Zucker</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="sugar" placeholder="Zucker">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns mt-2">
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Weintyp</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="wineType" placeholder="Weintyp">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Weintyp-Filter</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <textarea class="textarea is-small" type="text"
                                formControlName="wineTypeFilter" placeholder="Weintyp-Filter"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Weingut</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="winery" placeholder="Weingut">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Winzerinfo</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <textarea class="textarea is-small" type="text"
                                formControlName="growerInfo" placeholder="Winzerinfo"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Bodenart</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="soilType" placeholder="Bodenart">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Restzucker</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="residualSugar" placeholder="Restzucker">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Säuregehalt</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="acid" placeholder="Säuregehalt">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Rebsorte</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="variety" placeholder="Rebsorte">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Geschmack</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="flavor" placeholder="Geschmack">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Qualität</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="quality" placeholder="Qualität">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Farbnuance</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="colorNuance" placeholder="Farbnuance">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Trinktemperatur</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="drinkingTemperature" placeholder="Trinktemperatur">
                    </div>
                  </div>
                </div>
              </div>

              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Duft</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="scent" placeholder="Duft">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Vinifizierung</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="vinification" placeholder="Vinifizierung">
                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
        <!-- images -->
        <div class="tab-container" [ngClass]="activeTab!='images' ? 'is-hidden' : ''">
          <shared-upload [additionalInputFields]="imageAdditionalInputFields" (changedFiles)="changedImages($event)" [filePaths]="imagePaths"
                         labelButton="Bilder hochladen..."></shared-upload>
        </div>
        <!-- recommandations -->
        <div class="tab-container" [ngClass]="activeTab!='articleRecommendations' ? 'is-hidden' : ''">
          <shared-form-autocomplete
            [autocompleteModel]="getAutocomplete('articleRecommandations', 'articleRecommandations')"
            (editItem)="editArticle($event)"></shared-form-autocomplete>

        </div>
        <!-- articleGroup-->
        <div class="tab-container" [ngClass]="activeTab!='relatedArticles' ? 'is-hidden' : ''">
          <div class="columns">
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Artikelgruppierung</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div *ngIf="!loading" class="control">
                      <shared-form-autocomplete [fillFormWithSelectedItemName]="true"
                                                (onChangeInputValue)="loadRelatedArticles($event)"
                                                (onSelectItem)="loadRelatedArticles($event.name)"
                                                [showSearchIcon]="false"
                                                [autocompleteModel]="getAutocomplete('relatedArticleGroup', 'relatedArticleGroup')"
                                                [myFormControl]="getFormControl(form.get('relatedArticleGroup'))"></shared-form-autocomplete>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p-table [value]="relatedArticles" [columns]="relatedArticleCols" [reorderableColumns]="true"
                   [tableStyle]="{'min-width': '50rem'}">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th style="width:3rem"></th>
                <th *ngFor="let col of columns" pReorderableColumn>
                  {{ col.header }}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
              <tr [pReorderableRow]="index">
                <td>
                  <span class="pi pi-bars" pReorderableRowHandle></span>
                </td>
                <td *ngFor="let col of columns" pReorderableRowHandle>
                  {{ rowData[col.field] }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <!-- SEO-->
        <div class="tab-container" [ngClass]="activeTab!='SEO' ? 'is-hidden' : ''" formGroupName="SEO">
          <div class="columns is-multiline">
            <div class="column is-12-mobile is-6">
              <div class="field">
                <label class="label">Seitentitel</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="title" placeholder="Seitentitel">
                </div>
              </div>
            </div>
            <div class="column is-12-mobile is-6">
              <div class="field">
                <label class="label">Keywords</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="keywords" placeholder="Keywords">
                </div>
              </div>
            </div>
            <div class="column is-12">
              <div class="field">
                <label class="label">Kurzbeschreibung (max. 200 Zeichen)</label>
                <div class="control">
                    <textarea class="textarea" formControlName="description"
                              placeholder="Kurzbeschreibung (max. 200 Zeichen)"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

    </div>

    <div class="content-foot has-text-centered">
      <shared-form-submit-button [formStatus]="formHandler.getFormStatus()" (submit)="submit()"></shared-form-submit-button>
    </div>
  </div>
</div>
