import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {FormHelperDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ConfigService} from '@shared/config/services/config.service';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'shared-config-sort-set-default-group',
  templateUrl: './sort-set-default-group.component.html',
  styleUrls: ['./sort-set-default-group.component.scss']
})
export class SortSetDefaultGroupComponent implements OnInit {
  @Input() sortNames: string[] = [];

  formHandler: FormHelperDeprecated;

  form: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    default: new UntypedFormControl(''),
  });

  constructor(private configService: ConfigService, private store: Store<AppState>, private renderer: Renderer2) {
    this.formHandler = new FormHelperDeprecated(
      this.store,
      '',
      this.form,
      [],
      this.renderer
    );
    this.formHandler.notification = '';
  }

  ngOnInit(): void {
    this.configService.getConfig().subscribe(
      r => {
        if (r.result) {
          this.form.get('id')?.setValue(r.result.configs.data[0].id);
          this.form.get('default')?.setValue(r.result.configs.data[0].sortGroup.default);

        }
      }
    )
  }

  submitForm() {
    this.formHandler.validateForm();
    this.formHandler.displayErrors();
    if (!this.formHandler.status &&
      !this.formHandler.isFormErrors()) {
      this.formHandler.status = 'loading';
      this.configService.updateConfig({
        data: {
          id: this.form.get('id')?.value,
          sortGroup: {
            default: this.form.get('default')?.value || '',
          }
        }
      })
        .pipe(
          finalize(() => {
            setTimeout(() => {
              this.formHandler.status = null;
            }, 2000);
          })
        )
        .subscribe(
          r => {
            if(r.result?.configs.updated){
              this.formHandler.status = 'success';
            } else {
              this.formHandler.status = 'error';
            }
          }
        )
    }
  }

}
