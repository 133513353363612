import {Action, createReducer, on} from '@ngrx/store';
import * as WindowsActions from '../../store/actions/windows.action';
import {WindowsState, initialWindowsState} from '../../store/state/windows.state';
import {StorageState} from '../state/storage.state';

export const initialState = initialWindowsState();

const WindowsReducer = createReducer(
  initialState,
  on(WindowsActions.setWindow, (state: WindowsState, {window, windowOptions}): WindowsState => {
    // Falls die Id schon existiert > Nur das Window auf Active setzen und alle anderen auf InActive
   if (state.items.filter((item) => item.id === window.id).length > 0) {
      return {
        ...state, items: [
          ...state.items
            .map(
              (item) => {
                if (item.id === window.id) {
                  return {
                    ...item,
                    options: {
                      ...item.options, active: true, zIndex: 1001, minimized: false
                    }
                  };
                } else {
                  return {
                    ...item,
                    options: {
                      ...item.options, active: false, zIndex: 1000
                    }
                  };
                }
              }
            )
        ]
      };
    } else {
      return {
        ...state, items: [
          ...state.items.map(
            item => ({
              ...item,
              options: {
                ...item.options,
                active: false,
                zIndex: 1000
              }
            })
          ),
          {...window, options: windowOptions}
        ]
      };
    }
  }),
  on(WindowsActions.setActiveWindow, (state: WindowsState, {id}) => {
    return {
      ...state, items: [
        ...state.items
          .map(
            (item) => {
              if (item.id === id) {
                return {
                  ...item,
                  options: {
                    ...item.options, active: true, zIndex: 1001, minimized: false
                  }
                };
              } else {
                return {
                  ...item,
                  options: {
                    ...item.options, active: false, zIndex: 1000
                  }
                };
              }
            }
          )
      ]
    };
  }),
  on(WindowsActions.setMinimizeWindow, (state: WindowsState, {id}) => {
    return {
      ...state, items: [
        ...state.items
          .map(
            (item) => {
              if (item.id === id) {
                return {
                  ...item,
                  options: {
                    ...item.options,
                    minimized: true,
                    zIndex: 1000,
                    active: false,
                    width: item.options?.minimizedWidth,
                    height: item.options?.minimizedHeight
                  }
                };
              } else {
                return item;
              }
            }
          )
      ]
    };
  }),
  on(WindowsActions.setMinimizeWindows, (state: WindowsState) => {
    return {
      ...state, items: [
        ...state.items
          .map(
            (item) => {
              return {
                ...item,
                options: {
                  ...item.options, minimized: true, zIndex: 1000, active: false
                }
              };
            }
          )
      ]
    };
  }),
  on(WindowsActions.setFullSizeWindow, (state: WindowsState, {id}) => {
    return {
      ...state, items: [
        ...state.items
          .map(
            (item) => {
              if (item.id === id) {
                return {
                  ...item,
                  options: {
                    ...item.options,
                    fullSize: true,
                    width: null,
                    height: null,
                    transform: null,
                    dragX: 0,
                    dragY: 0,
                    minimizedWidth: item.options?.width,
                    minimizedHeight: item.options?.height,
                    minimizedTransform: item.options?.transform,
                    minimizedDragX: item.options?.dragX,
                    minimizedDragY: item.options?.dragY
                  }
                };
              } else {
                return item;
              }
            }
          )
      ]
    };
  }),
  on(WindowsActions.setMinimizedSizeWindow, (state: WindowsState, {id}) => {
    return {
      ...state, items: [
        ...state.items
          .map(
            (item) => {
              if (item.id === id) {
                return {
                  ...item,
                  options: {
                    ...item.options,
                    fullSize: false,
                    width: item.options?.minimizedWidth || 0,
                    height: item.options?.minimizedHeight || null,
                    transform: item.options?.minimizedTransform || null,
                    dragX: item.options?.minimizedDragX || undefined,
                    dragY: item.options?.minimizedDragY || undefined,
                    minimizedWidth: null,
                    minimizedHeight: null,
                    minimizedTransform: null,
                    minimizedDragX: null,
                    minimizedDragY: null
                  }
                };
              } else {
                return item;
              }
            }
          )
      ]
    };
  }),
  on(WindowsActions.updateWindowOptions, (state: WindowsState, {id, windowOptions}) => ({
    ...state, items: [
      ...state.items
        .map(
          (item) => {
            if (item.id === id) {
              return {
                ...item,
                options: {
                  ...item.options, ...windowOptions
                }
              };
            } else {
              return item;
            }
          }
        )
    ]
  })),
  on(WindowsActions.updateWindow, (state: WindowsState, {id, window}) => ({
    ...state, items: [
      ...state.items
        .map(
          (item) => {
            if (item.id === id) {
              return {
                ...item,
                title: window.title,
                data: {
                  ...item.data, ...window.data
                },
                options: {
                  ...item.options, ...window.options
                }
              };
            } else {
              return item;
            }
          }
        )
    ]
  })),
  on(WindowsActions.deleteWindow, (state: WindowsState, {id}) => {
    return {...state, items: [...state.items.filter(item => item.id !== id)]};
  })
);

export function reducer(state: WindowsState | undefined, action: Action) {
  return WindowsReducer(state, action);
}
