import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {setWindow} from '@store/actions/windows.action';
import {CustomerService} from '@shared/customer/services/customer.service';

@Component({
  selector: 'shared-customer-address-list',
  templateUrl: './customer-address-list.component.html',
  styleUrls: ['./customer-address-list.component.scss']
})
export class CustomerAddressListComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();



  loadItems$: Subscription | undefined;

  constructor(private store: Store<AppState>,
              private customerService: CustomerService) {
  }

  ngOnInit() {

  }

  editItem(itemId: string, title: string, component: any) {
    this.store.dispatch(setWindow({id: 'u' + itemId, title, component, data: {itemId}}))
  }

  deleteItem(id: string) {
/*    this.customerService.deleteCustomer(id).subscribe(
      r => {
        if(r.result?.users.deleted) {
          this.list.deleteItemSuccess(id)
        }
      }
    )*/
  }

  loadItemAndUpdateList(id: string) {
    this.customerService.get({customers: {}, filters: [{fields: ['id'], values: [id]}]}).subscribe(
      r => {
        if(r.result &&
          r.result.customers.data.length > 0){
        }
      }
    )
  }

  loadItems(data?: {
    filters?: { fields?: ['id'], values: any[] }[],
    filterDates?: { field: 'dateCreated' | 'lastUpdate', greaterThan?: string, greaterThanEqual?: string, lessThan?: string, lessThanEqual?: string }[],
    sort?: { field: 'dateCreated', direction: 1 | -1 }[],
    start?: number
  }): void {

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
