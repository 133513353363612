import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {
  ApiVouchersDeleteRequest, ApiVouchersDeleteResponse,
  ApiVouchersGetRequest, ApiVouchersGetResponse,
  ApiVouchersSetRequest, ApiVouchersSetResponse,
  ApiVouchersUpdateRequest, ApiVouchersUpdateResponse
} from '@shared/api/vouchers';

@Injectable({
  providedIn: 'root'
})
export class VouchersService {
  constructor(private http: HttpClient) {
  }

  set(data: ApiVouchersSetRequest['params']['data']): Observable<ApiVouchersSetResponse> {
    const body: ApiVouchersSetRequest = {
      id: 'setVouchers#INVALID_PARAMS',
      command: 'vouchers/set',
      params: {
        data
      }
    };
    return this.http.post<ApiVouchersSetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  get(params: ApiVouchersGetRequest['params']): Observable<ApiVouchersGetResponse> {
    const body: ApiVouchersGetRequest = {
      id: 'getVouchers',
      command: 'vouchers/get',
      params: params
    };
    return this.http.post<ApiVouchersGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  update(data: ApiVouchersUpdateRequest['params']['data'], unset?: ['validTo']): Observable<ApiVouchersUpdateResponse> {
    const body: ApiVouchersUpdateRequest = {
      id: 'updateVouchers#INVALID_PARAMS',
      command: 'vouchers/update',
      params: {
        data,
        unset
      }
    };
    return this.http.post<ApiVouchersUpdateResponse>(environment.apiUrl, JSON.stringify(body));
  }

  delete(id: string): Observable<ApiVouchersDeleteResponse> {
    const body: ApiVouchersDeleteRequest = {
      id: 'deleteVouchers',
      command: 'vouchers/delete',
      params: {
        data: {
          code: id
        }
      }
    };
    return this.http.post<ApiVouchersDeleteResponse>(environment.apiUrl, JSON.stringify(body));
  }
}
