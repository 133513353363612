<div *ngIf="loading" class="has-text-centered">
  <button class="button is-loading"></button>
</div>
<div *ngIf="!loading">
  <p *ngIf="!customerNumber">Notizen können erst hinterlegt werden, wenn der Kunde eine Kd.-Nr. hinterlegt hat.</p>
  <div *ngIf="customerNumber">
    <form [formGroup]="form">
      <div *ngIf="formHandler.getFormError()" class="mb-4">
        <shared-form-error [apiError]="formHandler.getFormError()"></shared-form-error>
      </div>
      <div class="field mt-2">
        <label class="label">Kd-Nr.</label>
        <div class="control">
          <input class="input is-small" type="text"
                 formControlName="customerNumber" placeholder="Kd-Nr.">
        </div>
      </div>
      <div class="field mt-2">
        <label class="label">Titel</label>
        <div class="control">
          <input class="input is-small" type="text"
                 formControlName="title" placeholder="Titel">
          <p class="help is-danger" *ngIf="isFormKeyInvalid('title')">Bitte hinterlegen Sie einen Titel.</p>
        </div>
      </div>
      <div class="field">
        <label class="label">Text</label>
        <div class="control">
          <p-editor formControlName="note" [style]="{'height':'150px'}"></p-editor>
        </div>
      </div>
      <div class="has-text-centered">
        <shared-form-submit-button (submit)="submit()"
                                   [formStatus]="formHandler.getFormStatus()"></shared-form-submit-button>
      </div>
    </form>
  </div>
</div>

