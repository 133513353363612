<div class="dropdown has-text-left is-right" (clickOutside)="dropDownVisibility = false"
     [ngClass]="dropDownVisibility ? 'is-active' : ''">
  <div class="dropdown-trigger">
    <button (click)="dropDownToggle()" class="button is-small" aria-haspopup="true" aria-controls="dropdown-menu">
      <span class="icon">
        <fa-icon [icon]="faCogs"></fa-icon>
      </span>
      <span>Tabelle anpassen</span>
      <span class="icon is-small">
        <fa-icon [icon]="faAngleUp" [ngClass]="!dropDownVisibility ? 'is-hidden' : ''"></fa-icon>
        <fa-icon [icon]="faAngleDown" aria-hidden="true" [ngClass]="dropDownVisibility ? 'is-hidden' : ''"></fa-icon>
      </span>
    </button>
  </div>
  <div class="dropdown-menu pt-0" id="" role="menu">
    <div class="dropdown-content pb-0">
      <div class="has-text-right p-1 has-background-light">
        <fa-icon [icon]="faCheckSquare"  class="ml-2 is-pointer" (click)="toggleColumnsVisibility(false)" pTooltip="Alle auswählen" tooltipPosition="left"></fa-icon>
        <fa-icon [icon]="faSquare"  class="ml-2 is-pointer" (click)="toggleColumnsVisibility(true)" pTooltip="Alle abwählen" tooltipPosition="left"></fa-icon>
        <fa-icon [icon]="faRotateRight" class=" ml-2 mr-2 is-pointer" (click)="setDefaults()"  pTooltip="Standardauswahl wiederherstellen" tooltipPosition="left"></fa-icon>
      </div>
      <div style="max-height: 300px; overflow: auto;">
        <a *ngFor="let column of columns;" class="dropdown-item" (click)="toggleColumnVisibility(column.id)">
          <input type="checkbox" [checked]="column.isHidden?  false : true">
          {{column.label}}
        </a>
      </div>
      <div class="has-text-centered mt-2 p-1 has-background-light">
        <button class="button is-small" (click)="save(); dropDownToggle()">
          Übernehmen
        </button>
      </div>
    </div>
  </div>
</div>
