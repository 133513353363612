import {createAction, props} from '@ngrx/store';
import {WindowOptions, Window} from '@shared/window-manager/interfaces/window.model';

export const setWindow = createAction('[Windows] Set window',
  (window: Window, windowOptions: WindowOptions = {
    width: null,
    height: null,
    transform: null,
    dragX: 0,
    dragY: 0,
    minimizedWidth: null,
    minimizedHeight: null,
    minimizedTransform: null,
    minimizedDragX: 0,
    minimizedDragY: 0,
    minimized: false,
    active: true,
    fullSize: false,
    zIndex: 1001,
    showOnlyThisWindow: false
  }) => ({window, windowOptions}));

export const setActiveWindow = createAction('[Windows] Set activeWindow', props<{ id: string }>());
export const setMinimizeWindow = createAction('[Windows] Set minimizedWindow', props<{ id: string }>());
export const setMinimizeWindows = createAction('[Windows] Set minimizeWindows');
export const setFullSizeWindow = createAction('[Windows] Set full size', props<{ id: string }>());
export const setMinimizedSizeWindow = createAction('[Windows] Set minimized size', props<{ id: string }>());
export const updateWindowOptions = createAction('[Windows] Update window options', props<{ id: string, windowOptions: WindowOptions }>());
export const updateWindow = createAction('[Windows] Update window', props<{
  id: string,
  window: {
    title: string,
    data: any,
    options: WindowOptions

  }
}>());
export const deleteWindow = createAction('[Windows] Delete window', props<{ id: string }>());

