
export interface AuthenticationState{
  isLoading?: boolean,
  success?: boolean,
  errorMsg?: string
}

export const initialAuthenticationState = () => {
  return {
  };
};
