import {Window} from '@shared/window-manager/interfaces/window.model';

export interface WindowsState {
  items:  Window[];
}

export const initialWindowsState = (): WindowsState => {
  return {items: []};

};
