import {Component, Input, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {faHistory} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-list-history-item',
  templateUrl: './list-history-item.component.html',
  styleUrls: ['./list-history-item.component.scss']
})
export class ListHistoryItemComponent implements OnInit {

  @Input() dateCreated?: Date;
  @Input() lastUpdate?: Date;

  historyClass: string = '';
  historyMessage: string = '';

  constructor() {
  }

  ngOnInit(): void {
    this.setHistory();
  }

  setHistory() {

    const datetime = new Date(this.lastUpdate || '');

    const datetimeHi1 = new Date().getTime() - (2 * 60 * 60 * 1000);
    const datetimeHi2 = new Date().getTime() - (8 * 60 * 60 * 1000);

    if (datetimeHi1 < datetime.getTime()) {
      this.historyClass = 'hi1';
    } else if (datetimeHi2 < datetime.getTime()) {
      this.historyClass = 'hi2';
    }

    if (this.dateCreated === this.lastUpdate) {
      this.historyMessage = 'Erstellt ';
    } else {
      this.historyMessage = 'Letzte Änderung ';
    }
    let datePipe = new DatePipe('en-GB');
    this.historyMessage = this.historyMessage + ' am ' + datePipe.transform(datetime.getTime(), 'dd.MM.yyyy H:mm') +      'Uhr';
  }

  protected readonly faHistory = faHistory;
}
