import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Customer} from '@shared/customer/models/customer';
import {Subject, Subscription} from 'rxjs';
import {CustomerService} from '@shared/customer/services/customer.service';
import {finalize, map, takeUntil} from 'rxjs/operators';
import {ShopAccessForCustomer} from '@shared/user/interfaces/user';
import {CustomerShopAccessForCustomer} from '@shared/customer/interfaces/customer';
import {faBars, faSearch} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-customer-user-mapper',
  templateUrl: './customer-user-mapper.component.html',
  styleUrls: ['./customer-user-mapper.component.scss']
})
export class CustomerUserMapperComponent implements OnInit, OnDestroy {
  @Input() label: string = 'Kunden zuordnen';
  @Input() loadItems: ShopAccessForCustomer[] = [];
  @Input() selectedItems: CustomerShopAccessForCustomer[] = [];
  @Input() limit: number = 0;
  @Input() draggable: boolean = false;

  @Output() changeMapping: EventEmitter<any> = new EventEmitter<any>();

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  changeDetectionGroup: string = 'customers';
  runChangeDetectionOnGroups: string[] = [this.changeDetectionGroup];
  changeDetection$ = this.store.select(state => state.changeDetection.items)
    .pipe(
      takeUntil(this.ngUnsubscribe),
      map(items => items?.filter(item => this.runChangeDetectionOnGroups.includes(item.group)))
    );

  request$: Subscription | undefined;
  loading = false;

  items: Customer[] = [];

  constructor(private customerService: CustomerService, private store: Store<AppState>) {
  }

  ngOnInit() {
    if(this.loadItems){
      this._loadItems(this.loadItems);
    }
    this.changeDetection$.subscribe(r => {
      r?.map((item) => {
        let items: ShopAccessForCustomer[] = [];
        this.selectedItems.map(item => {
          items.push(item.shopAccessForCustomer)
        })
        this._loadItems(items);
      });
    });
  }

  _loadItems(items: ShopAccessForCustomer[]){
    items.map(item => {
      this.customerService.get(
        {
          customers: {},
          filters:
            [
              {fields: ['id'], values: [item.customerId]}
            ]
        }
      ).subscribe(
        r => {
          if(r.result?.customers.data[0]){
            this.selectedItems.push({...r.result.customers.data[0], shopAccessForCustomer: item});
            if(!item.permissions.addressIds){
              item.permissions.addressIds = undefined;
            }
          }
        }
      )
    })
  }

  loadData(searchString: string) {

    if (this.request$) {
      this.request$.unsubscribe();
    }

    if (searchString.length === 0) {
      this.loading = false;
      this.items = [];
      return;
    }

    const filters: any = [{values: [searchString], useRegex: 1, fieldOperator: 'or'}];


    this.loading = true;
    this.request$ = this.customerService.get({filters, customers: {}})
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        r => {
          this.items = [];
          if (r.result?.customers.data) {
            r.result?.customers.data.map(
              data => {
                this.items.push(data);
              }
            );
          }
        }
      );
  }

  setItem(item: Customer) {
    this.selectedItems.push({...item, shopAccessForCustomer: {customerId: item.id, permissions: {grant: ['users', 'invoices', 'prices', 'favorites', 'customerAddresses']}} });
    this.changeMapping.emit(this.selectedItems);
  }

  toggleAddress(customerId: string, addressId: string){
    this.selectedItems.map(
      item => {
        if(item.id == customerId){
          if(item.shopAccessForCustomer.permissions.addressIds){
            let index = item.shopAccessForCustomer.permissions.addressIds.indexOf(addressId);
            if (index === -1) {
              item.shopAccessForCustomer.permissions.addressIds.push(addressId);
            } else {
              item.shopAccessForCustomer.permissions.addressIds.splice(index, 1);
            }
          // Noch keine Adresse definiert
          } else {
            let addressIds: string[] = [];
            item.addresses.map(address => {
              if(address.addressId && address.addressId !== addressId){
                addressIds.push(address.addressId);
              }
            });
            item.shopAccessForCustomer.permissions.addressIds = addressIds;
          }

          // Es dürfen Bestellungen für alle Adressen aufgegeben werden
          if(item.shopAccessForCustomer.permissions.addressIds.length == item.addresses.length){
            item.shopAccessForCustomer.permissions.addressIds = undefined;
          }
        }
      }
    );
    this.changeMapping.emit(this.selectedItems);
  }

  toggleGrant(customerId: string, grant: string){
    this.selectedItems.map(
      item => {
        if(item.id == customerId){
          if(item.shopAccessForCustomer.permissions.grant){
            let index = item.shopAccessForCustomer.permissions.grant.indexOf(grant);
            if (index === -1) {
              item.shopAccessForCustomer.permissions.grant.push(grant);
            } else {
              item.shopAccessForCustomer.permissions.grant.splice(index, 1);
            }
            // Noch keine Adresse definiert
          } else {
            item.shopAccessForCustomer.permissions.grant = [grant];
          }
        }
      }
    );
    this.changeMapping.emit(this.selectedItems);
  }

  deleteItem(id: string) {
    this.selectedItems = this.selectedItems.filter(val =>
      val.id !== id
    );
    this.changeMapping.emit(this.selectedItems);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedItems, event.previousIndex, event.currentIndex);
    this.changeMapping.emit(this.selectedItems);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected readonly faSearch = faSearch;
  protected readonly faBars = faBars;
}
