import {Component, OnInit, ViewChild} from '@angular/core';
import {OrdersService} from '@shared/orders/services/orders.service';
import {finalize} from 'rxjs/operators';

interface Chart {
  name: number;
  series: {
    value: number;
    name: string;
  }[]
}

@Component({
  selector: 'shared-dashboard-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {


  rowData: any[] = [];
  cols: any[] = [];
  loading: boolean = true;

  chartsMerchandiseValues: any;
  chartsOrders: any;
  basicOptions: any;

  sumNetTotalChart: Chart[] = [];
  sumOrdersChart: Chart[] = [];
  months: any[] = ['', 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']

  constructor(private orderService: OrdersService) {
  }

  ngOnInit(): void {
    let date = new Date();
    console.log(date.getMonth());
    this.getGroupedOrdersByYearMonth(37 + date.getMonth());
  }

  sumColumn(column: string) {
    let sum = 0;
    this.rowData.map(
      d => {
        if (d[column]) {
          sum = sum + parseFloat(d[column]);
        }


      }
    )
    return new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2}).format(sum);
    ;


  }

  getFormatNumber(number: number) {
    if (!number) {
      return '-';
    }
    return new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(number);
  }

  getGroupedOrdersByYearMonth(limit: number = 36) {
    this.sumNetTotalChart = [];
    this.sumOrdersChart = [];
    let years: number[] = [];

    this.cols.push({
      field: 'Monat'
    });
    this.rowData = [];
    this.orderService.get({
      filters: [{
        fields: ['status'],
        values: ['open', 'progressStarted', 'inProgress', 'inDelivery', 'finished', 'invoiced'],
        useRegex: 0
      }],
      group: 'yearMonth',
      orders: {start: 0, limit}
    })
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        r => {
          let fieldSumOrders;
          let fieldSumTotalNet;
          let data: any;

          let datasetsMerchandise: any[] = [];
          let datasetsOrders: any[] = [];

          r.result?.orders.group?.yearMonth?.map(
            d => {
              fieldSumTotalNet = 'umsatz' + d.year;
              fieldSumOrders = 'order' + d.year;

              if (years.filter(y => y == d.year).length == 0) {
                years.push(d.year);
                this.cols.push({
                  field: fieldSumTotalNet,
                  header: 'Umsatz ' + d.year
                });
                this.cols.push({
                  field: fieldSumOrders,
                  header: 'Bestellungen ' + d.year
                });
              }

              if (this.rowData &&
                this.rowData.filter(r => r.month == this.months[d.month]).length > 0) {
                data = this.rowData.filter(r => r.month == this.months[d.month])[0];

                /*   data[fieldSumOrders] = new Intl.NumberFormat('de-DE').format(d.sumOrders);
                   data[fieldSumTotalNet] = new Intl.NumberFormat('de-DE').format(d.sumTotalNet);*/
                data[fieldSumOrders] = d.sumOrders;
                data[fieldSumTotalNet] = d.sumTotalNet;
                this.rowData.filter(r => r[0] == d.month)[0] = data;
              } else {

                data = {};
                data['month'] = this.months[d.month];
                data['monthNumber'] = d.month;
                /* data[fieldSumOrders] = new Intl.NumberFormat('de-DE').format(d.sumOrders);
                 data[fieldSumTotalNet] = new Intl.NumberFormat('de-DE').format(d.sumTotalNet);*/
                data[fieldSumOrders] = d.sumOrders;
                data[fieldSumTotalNet] = d.sumTotalNet;
                this.rowData?.push(data)
              }
            }
          )
          this.cols.sort((a, b) => a.field > b.field ? -1 : a.field > b.field ? 1 : 0);
          this.rowData.sort((a, b) => a.monthNumber < b.monthNumber ? -1 : a.monthNumber > b.monthNumber ? 1 : 0);
          this.cols.unshift({
            field: 'month',
            header: 'Monat'
          });


          let backgroundColors: string[] = ["#e18236", "#3c94e7", "#91bae1", '#ddebfa']
          let i: number = 0;
          let o: number = 0;
          this.cols.map(c => {
            if (!c.field.search('order')) {
              let data = [];
              for(let i=1 ; i < 13 ; i++){
                let x  = this.rowData.filter(d => d.monthNumber == i);
                if(x.length > 0){
                  data.push(x[0][c.field]);
                } else {
                  data.push(0)
                }
              }
              datasetsOrders.push({
                label: c.header,
                data,
                backgroundColor: backgroundColors[o]
              });
              o++;
            }
            if (!c.field.search('umsatz')) {
              let data = [];
              for (let i = 1; i < 13; i++) {
                let x = this.rowData.filter(d => d.monthNumber == i);
                if (x.length > 0) {
                  data.push(x[0][c.field]);
                } else {
                  data.push(0)
                }
              }
              datasetsMerchandise.push({
                label: c.header,
                data: data,
                backgroundColor: backgroundColors[i]
              });
              i++;
            }

          });

          this.chartsMerchandiseValues = {
            labels: this.months.slice(1),
            datasets: datasetsMerchandise
          };

          this.chartsOrders = {
            labels: this.months.slice(1),
            datasets: datasetsOrders
          };
        }
      )
  }

}

