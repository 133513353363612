import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {
  ApiDeliveryToursDeleteRequest,
  ApiDeliveryToursDeleteResponse,
  ApiDeliveryToursGetRequest, ApiDeliveryToursGetResponse, ApiDeliveryToursSetRequest,
  ApiDeliveryToursSetResponse, ApiDeliveryToursUpdateRequest, ApiDeliveryToursUpdateResponse
} from '@shared/api/delivery-tours';

@Injectable({
  providedIn: 'root'
})
export class DeliveryToursService {

  constructor(private http: HttpClient) {
  }

  set(data: ApiDeliveryToursSetRequest['params']['data']): Observable<ApiDeliveryToursSetResponse> {
    const body: ApiDeliveryToursSetRequest = {
      id: 'setDeliveryTour#INVALID_PARAMS',
      command: 'deliveryTours/set',
      params: {
        data
      }
    };
    return this.http.post<ApiDeliveryToursSetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  get(params: ApiDeliveryToursGetRequest['params']): Observable<ApiDeliveryToursGetResponse> {
    const body: ApiDeliveryToursGetRequest = {
      id: 'getDeliveryTours',
      command: 'deliveryTours/get',
      params: params
    };
    return this.http.post<ApiDeliveryToursGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  update(data: ApiDeliveryToursUpdateRequest['params']['data']): Observable<ApiDeliveryToursUpdateResponse> {
    const body: ApiDeliveryToursUpdateRequest = {
      id: 'updateDeliveryTours#INVALID_PARAMS',
      command: 'deliveryTours/update',
      params: {
        data
      }
    };
    return this.http.post<ApiDeliveryToursUpdateResponse>(environment.apiUrl, JSON.stringify(body));
  }

  delete(id: string): Observable<ApiDeliveryToursDeleteResponse> {
    const body: ApiDeliveryToursDeleteRequest = {
      id: 'deleteDeliveryTours',
      command: 'deliveryTours/delete',
      params: {
        data: {
          id
        }
      }
    };
    return this.http.post<ApiDeliveryToursDeleteResponse>(environment.apiUrl, JSON.stringify(body));
  }

  async resolveIdsToNames(ids: string[]): Promise<{id: string, name: string}[]> {
    let data: {id: string, name: string}[] = [];
    const body: ApiDeliveryToursGetRequest[] = [];
    ids.filter((value, index, self) => self.indexOf(value) === index).map(id => {
      if(id){
        body.push({
          id,
          command: 'deliveryTours/get',
          params: {
            filters: [{fields: ['id'], values: [id]}],
            deliveryTours: {
              expectedData: {
                id: 1,
                name: 1
              }
            }
          }
        })
      }

    });
    if(body.length > 0){
      await this.http.post<ApiDeliveryToursGetResponse[]>(environment.apiUrl, JSON.stringify(body)).toPromise().then(
        r => {
          if(r){
            ids.map(id => {
              let filter = r.filter(data => data.id == id);
              if (filter[0]?.result &&
                filter[0].result.deliveryTours.data.length > 0) {
                data.push({id, name:filter[0].result.deliveryTours.data[0].name})
              }

            })
          }

        }
      )
    }
    return data;
  }
}
