import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {List} from '@shared/list/models/list';
import {faRefresh, faRemove} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-list-notification',
  templateUrl: './list-notification.component.html',
  styleUrls: ['./list-notification.component.scss']
})
export class ListNotificationComponent implements OnInit {

  @Input() list!: List<any, any, any>;

  constructor() { }

  ngOnInit(): void {
  }

  protected readonly faRemove = faRemove;
  protected readonly faRefresh = faRefresh;
}
