<div class="field" (clickOutside)="isOpen = false">
  <label class="label">{{ autocompleteModel.label }}</label>
  <div
    *ngIf="autocompleteModel.maxSelectedItems == 0 || autocompleteModel.getSelectedItems().length < autocompleteModel.maxSelectedItems">
    <div class="is-flex">
      <div class="control  is-small has-icons-right is-flex-grow-1"
           [ngClass]="{'is-loading' : autocompleteModel.loading, 'has-icons-left': showSearchIcon} ">
        <div>
          <input class="input is-small no-shadow " type="text" placeholder="{{autocompleteModel.placeholder}}"
                 (keydown.ArrowDown)="onKeyDown(true)"
                 (keydown.ArrowUp)="onKeyDown(false)"
                 (keydown.Enter)="selectItem($event, selectedIndex)"
                 [formControl]="_myFormControl">
          <span *ngIf="showSearchIcon" class="icon is-small is-left"><fa-icon [icon]="faSearch"></fa-icon></span>
        </div>
      </div>
      <div class="has-background-blue px-2" (click)="toggleDropDownButton()">
          <span class="icon is-size-7 is-small is-right has-text-white is-pointer">
            <fa-icon [icon]="faArrowDown" [ngClass]="!isOpen ? 'is-hidden' : ''"></fa-icon>
            <fa-icon [icon]="faArrowUp" [ngClass]="isOpen ? 'is-hidden' : ''"></fa-icon>
          </span>
      </div>
    </div>
    <div *ngIf="isOpen" class="panel pl-0" #autocompleteContainer>
      <a *ngFor="let item of autocompleteModel.getItems(); let i = index" class="panel-block"
         (click)="selectItem($event, i)"
         [class.selected]="selectedIndex === i"
         (mouseenter)="selectedIndex = i"

      >
        <span class="name" [ngClass]="{'pl-0' : !showSearchIcon} ">{{ item.name }}</span>
      </a>
    </div>
  </div>
  <div class="mt-4 panel-list panel is-primary" [ngClass]="{ 'is-draggable' : autocompleteModel.sortable, 'is-hidden': fillFormWithSelectedItemName }" cdkDropList
       (cdkDropListDropped)="drop($event)">
    <div class="panel-block" cdkDrag *ngFor="let item of autocompleteModel.getSelectedItems()">
      <fa-icon [icon]="faBars" class=" drag-handle" cdkDragHandle></fa-icon>
      <span class="name">{{ item.name }}<br>
        <span class="id"> <fa-icon [icon]="faEdit" *ngIf="autocompleteModel.editable" class="edit"
                                   (click)="editItem.emit(item)"></fa-icon> </span>
      </span>
      <button class="delete is-small is-right" (click)="deleteSelectedItem(item)"></button>
    </div>
  </div>
</div>
