import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormHelperDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {UserService} from '@shared/user/services/user.service';
import {Window} from '@shared/window-manager/interfaces/window.model';
import {finalize} from 'rxjs/operators';
import {setChangedItem} from '@store/actions/change-detection.action';
import {ShopAccessForCustomer} from '@shared/user/interfaces/user';
import {CustomerShopAccessForCustomer} from '@shared/customer/interfaces/customer';
import {faCheck, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

interface ShopAccessForCustomers{
  customerId: string;
  permissions: {
    addressIds: string[],
    grant: string[]
  }
}

@Component({
  selector: 'shared-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  @ViewChild('formTag', {static: false}) formTag: ElementRef | undefined;
  @Input() window?: Window;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  changeDetectionGroup: string = 'users';

  form = new UntypedFormGroup({
    role: new UntypedFormControl('', Validators.required),
    username: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email]),
    password: new UntypedFormControl(null),
    shopAccessForCustomers: new UntypedFormControl(null)
  });

  formHandler: FormHelperDeprecated;


  errors = null;
  activeTab = '';
  msg = '';
  data: any;


  constructor(
    private userService: UserService,
    private store: Store<AppState>,
    private renderer: Renderer2
  ) {
    this.formHandler = new FormHelperDeprecated(
      this.store,
      this.changeDetectionGroup,
      this.form,
      [
        {key: 'role', error: 'Bitte wählen Sie einen User-Level aus.'},
        {key: 'username', error: 'Bitte hinterlegen Sie einen Benutzernamen.'},
        {key: 'email', error: 'Bitte hinterlegen Sie eine E-Mail.'},
        {key: 'password', error: 'Bitte hinterlegen Sie ein Passwort.'},
        {key: 'shopAccessForCustomers', error: 'Bitte ordnen Sie dem User Kunden zu.'}
      ],
      this.renderer
    );
  }

  ngAfterViewInit() {
    this.formHandler.htmlRef = this.formTag;
  }

  ngOnInit() {
    if (this.window?.data?.itemId) {
      this.loadItem(this.window.data.itemId);
    }
  }

  loadItem(id: string) {
    this.formHandler.status = 'loading';
    this.userService.getUser(id)
      .pipe(
        finalize(() => {
          this.formHandler.status = null;
        })
      )
      .subscribe(
        r => {
          if (r.result?.users.data[0]) {
            this.formHandler?.fillForm(r.result.users.data[0]);
          }
        }
      )
  }

  setShopAccessForCustomers(items: CustomerShopAccessForCustomer[]){
    let shopAccessForCustomers: ShopAccessForCustomer[] = [];
    items.map(item => {
      shopAccessForCustomers.push(item.shopAccessForCustomer)
    });
    this.form.get('shopAccessForCustomers')?.setValue(shopAccessForCustomers);
  }

  submit() {
    this.formHandler.validateForm();
    this.formHandler.displayErrors();
    if (!this.formHandler.status &&
      !this.formHandler.isFormErrors()) {
      this.formHandler.status = 'submitForm';
      // Update
      if (this.window?.data &&
        this.window.data.itemId) {
        let data = this.formHandler?.getFormValues();
        data.id = this.window?.data.itemId;
        this.userService.updateUser(data)
          .subscribe(
            r => {
              this.formHandler?.handleApiResult({r, windowId: this.window?.id, callbackOnSuccess: () => {
                  this.store.dispatch(setChangedItem({item: {itemId: data.id, date: new Date().getTime(), userId: '', action: 'update', group: this.changeDetectionGroup}}));
                } });
            },
            error => {
                this.formHandler.notification = error;
                this.formHandler.status = null;
                this.formHandler.displayErrors();
            }
          )
        // Insert
      } else {
        this.userService.setUser(this.formHandler?.getFormValues())
          .subscribe(
            r => {
              this.formHandler?.handleApiResult({r, windowId: this.window?.id, callbackOnSuccess: () => {
                  this.store.dispatch(setChangedItem({item: {itemId: r.result?.users.data.id || '', date: new Date().getTime(), userId: '', action: 'update', group: this.changeDetectionGroup}}))
                } });
            },
            error => {
                this.formHandler.notification = error;
                this.formHandler.status = null;
                this.formHandler.displayErrors();
            }
          )
      }

    }
  }

  ngOnDestroy() {

  }


  protected readonly faExclamationTriangle = faExclamationTriangle;
  protected readonly faCheck = faCheck;
}
