import {createAction, props} from '@ngrx/store';

export const login = createAction('[Auth] login', props<{ username: string, password: string }>());
export const loginSuccess = createAction('[Auth] login success', props<{ userId: string, accessExpiredIn: number, config: string }>());
export const loginFailure = createAction('[Auth] login failure', props<{ errorMsg: string }>());

export const setToken = createAction('[Auth] set token', props<{ accessToken: string, accessExpiredIn: number }>());

export const refreshToken = createAction('[Auth] refresh token');
export const refreshTokenSuccess = createAction('[Auth] refresh token success', props<{ accessExpiredIn: number}>());
export const refreshTokenFailure = createAction('[Auth] refresh token failure');

export const logout = createAction('[Auth] logout');
export const logoutSuccess = createAction('[Auth] logout success');
export const logoutFailure = createAction('[Auth] logout failure');




