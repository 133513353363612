import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map} from 'rxjs/operators';
import {AppState} from '@store/state/app.state';
import {Store} from '@ngrx/store';
import {UserService} from '@shared/user/services/user.service';
import * as UserActions from '@store/actions/user.action';
import * as AuthenticationActions from '@store/actions/authentication.action';
import * as StorageActions from '@store/actions/storage.action';

@Injectable()
export class UserEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService) {
  }

  setUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.setUser),
    concatMap((action) => this.userService.getUsersAdmin(action.userId)
      .pipe(
        map(r => {
          if (r.result?.accountBackendUsers?.data[0]) {
            return UserActions.setUserSuccess(
              {userAdmin: r.result?.accountBackendUsers.data[0]}
            )
          } else {
            console.log('setUser$ AuthenticationActions.logout()');
            return AuthenticationActions.logout();
          }
        })
      )
    ),
    )
  );

  setUserSuccess = createEffect(() => this.actions$.pipe(
    ofType(UserActions.setUserSuccess),
    map((action) => {
      return StorageActions.userId({userId: action.userAdmin.id});
    })
  ));


}
