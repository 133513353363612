import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {
  ApiNewsDeleteRequest,
  ApiNewsDeleteResponse,
  ApiNewsGetRequest,
  ApiNewsGetRequestParams,
  ApiNewsGetResponse,
  ApiNewsSetRequest,
  ApiNewsSetResponse, ApiNewsUpdateRequest, ApiNewsUpdateResponse,
  NewsSetParamsData, NewsUpdateParamsData
} from '@shared/api/news';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  constructor(private http: HttpClient) {
  }

  set(data: NewsSetParamsData): Observable<ApiNewsSetResponse>{
    const body: ApiNewsSetRequest = {
      id: 'setNews#INVALID_PARAMS',
      command: 'news/set',
      params: {
        data
      }
    };
    return this.http.post<ApiNewsSetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  get(params: ApiNewsGetRequestParams): Observable<ApiNewsGetResponse>{
    const body: ApiNewsGetRequest = {
      id: 'getNews',
      command: 'news/get',
      params: params
    };
    return this.http.post<ApiNewsGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  update(data: NewsUpdateParamsData): Observable<ApiNewsUpdateResponse>{
    const body: ApiNewsUpdateRequest = {
      id: 'updateNews#INVALID_PARAMS',
      command: 'news/update',
      params: {
        data
      }
    };
    return this.http.post<ApiNewsUpdateResponse>(environment.apiUrl, JSON.stringify(body));
  }

  delete(id: string): Observable<ApiNewsDeleteResponse>{
    const body: ApiNewsDeleteRequest = {
      id: 'deleteNews',
      command: 'news/delete',
      params: {
        data: {
          id
        }
      }
    };
    return this.http.post<ApiNewsDeleteResponse>(environment.apiUrl, JSON.stringify(body));
  }
}
