import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {FormHelperDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {AuthenticationService} from '@core/services/authentication.service';
import {AccountBackendUsersService} from '@shared/account-backend-users/services/account-backend-users.service';
import {faSignIn} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  @ViewChild('formTag', {static: false}) formTag: ElementRef | undefined;
  form: FormGroup = this.fb.group({
    password: [null, [
      Validators.required,
      Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
    ]],
    passwordRetype: ['']
  });
  passwordChanged: boolean = false;

  formHandler: FormHelperDeprecated;

  constructor(private authenticationService: AuthenticationService,  private accountBackendUsersService: AccountBackendUsersService, private fb: FormBuilder, private store: Store<AppState>, private renderer: Renderer2) {
    this.formHandler = new FormHelperDeprecated(
      this.store,
      '',
      this.form,
      [
        {
          key: 'password',
          error: 'Ihr Passwort muss 8 Zeichen haben und mindestens einen Kleinbuchstaben, Großbuchstaben sowie ein Sonderzeichen enthalten. '
        },
        {
          key: 'passwordRetype',
          error: 'Die Passwörter stimmen nicht überein.'
        }
      ],
      this.renderer
    );
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.formHandler.htmlRef = this.formTag;
  }

  submit() {
    if(this.form.get('password')?.value != this.form.get('passwordRetype')?.value){
      this.form.get('passwordRetype')?.setErrors({'passwordRetype': true});
      this.form.updateValueAndValidity();
    }
    this.formHandler.validateForm();
    this.formHandler.displayErrors();
    if (!this.formHandler.status &&
      !this.formHandler.isFormErrors()) {
      this.formHandler.status = 'loading';
      this.accountBackendUsersService.update({id: this.authenticationService.getPayloadFromInjectionToken().userId, password: this.form.get('password')?.value}).subscribe(
        r => {
          this.formHandler.handleApiResult({
            r, callbackOnSuccess: () => {
              if (r.result?.accountBackendUsers.updated.matched == 1) {
                this.passwordChanged = true;
              }
            }
          });
        }
      )
    }

  }


  protected readonly faSignIn = faSignIn;
}
