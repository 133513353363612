import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Window} from '@shared/window-manager/interfaces/window.model';
import {Subject} from 'rxjs';
import {FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {Autocomplete, AutocompleteItem} from '@shared/forms/models/autocomplete';
import {FormHelperDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {DeliveryToursService} from '@shared/delivery-tours/services/delivery-tours.service';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {finalize, map, takeUntil} from 'rxjs/operators';
import {setWindow} from '@store/actions/windows.action';
import {setChangedItem} from '@store/actions/change-detection.action';
import {DeliveryAreasService} from '@shared/delivery-areas/services/delivery-areas.service';

@Component({
  selector: 'shared-delivery-areas-form',
  templateUrl: './delivery-area-form.component.html',
  styleUrls: ['./delivery-area-form.component.scss']
})
export class DeliveryAreaFormComponent implements OnInit, OnDestroy {
  @ViewChild('formTag', {static: false}) formTag: ElementRef | undefined;
  @Input() window?: Window;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  changeDetectionGroup: string = 'deliveryAreas';
  runChangeDetectionOnGroups: string[] = ['deliveryTours'];
  changeDetection$ = this.store.select(state => state.changeDetection.items)
    .pipe(
      takeUntil(this.ngUnsubscribe),
      map(items => items?.filter(item => this.runChangeDetectionOnGroups.includes(item.group)))
    );

  form: FormGroup;


  autocompleteDeliveryTour: Autocomplete = new Autocomplete(
    {
      label: 'Tourauswahl',
      placeholder: 'Tour suchen...',
      loadItems: (searchString: string) => {
        this.loadDeliveryTours(searchString)
      }
    }
  );
  formHandler: FormHelperDeprecated;

  loading: boolean = false;





  constructor(
    private deliveryAreasService: DeliveryAreasService,
    private deliveryToursService: DeliveryToursService,
    private store: Store<AppState>,
    private renderer: Renderer2,
    private fb: UntypedFormBuilder
  ) {

    this.changeDetection$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(r => {
        r?.map(async (item) => {
            await this.resolveDeliveryTourId();
          }
        )
      });

    this.form = new FormGroup({
      id: this.fb.control(null),
      postcode: this.fb.control(null, Validators.required),
      town: this.fb.control(null, Validators.required),
      deliveryTourId: this.fb.control(null)
    });

    this.formHandler = new FormHelperDeprecated(
      this.store,
      this.changeDetectionGroup,
      this.form,
      [
        {key: 'postcode', error: 'Bitte hinterlegen Sie einen Postleitzahl.'},
        {key: 'town', error: 'Bitte hinterlegen Sie eine Stadt.'}
      ],
      this.renderer
    );
    this.formHandler.activeTab = 'details';

    this.autocompleteDeliveryTour.sortable = false;
    this.autocompleteDeliveryTour.maxSelectedItems = 1;
  }

  async resolveDeliveryTourId(){
    const deliveryTours = await this.deliveryToursService.resolveIdsToNames([this.form.get('deliveryTourId')?.value]);
    deliveryTours.map((r: {id: string, name: string}) => {
      this.autocompleteDeliveryTour.selectItem({name: r.name, value: r.id})
    });
  }

  ngAfterViewInit() {
    this.formHandler.htmlRef = this.formTag;
  }

  ngOnInit() {
    if (this.window?.data?.itemId) {
      this.loadItem(this.window.data.itemId);
    }
  }

  loadDeliveryTours(searchString: string) {
    this.autocompleteDeliveryTour.loading = true;
    this.deliveryToursService.get({
      deliveryTours: {sort: [{field: 'name', direction: 1}]}
    })
      .pipe(
        finalize(() => {
          this.autocompleteDeliveryTour.loading = false;
        })
      )
      .subscribe(
        r => {
          let items: AutocompleteItem[] = [];
          if (r.result?.deliveryTours.data) {
            r.result?.deliveryTours.data.map(
              item => {
                items.push({value: item.id, name: item.name})
              }
            )
          }
          this.autocompleteDeliveryTour.setItems(items);
        }
      )

  }

  loadItem(id: string) {
    this.loading = true;
    this.deliveryAreasService.get({deliveryAreas: {}, filters: [{fields: ['id'], values: [id]}]})
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        async r => {

          if (r.result?.deliveryAreas.data[0]) {
            let data: any = r.result?.deliveryAreas.data[0];
            this.formHandler?.fillForm(data);
            await this.resolveDeliveryTourId();
          }
        }
      )
  }

  editDeliveryTours(item: AutocompleteItem) {
    this.store.dispatch(setWindow({
      id: 'deliveryTours' + item.value,
      title: 'Tour: ' + item.name,
      component: 'shared-delivery-tours-form',
      data: {itemId: item.value}
    }))
  }

  setDeliveryTours(items: string[]) {
    this.form.get('deliveryTourId')?.setValue(items[0]);
  }

  submit() {
    this.formHandler.validateForm();
    this.formHandler.displayErrors();
    this.formHandler.status = 'submitForm';
    if (
      !this.formHandler.isFormErrors()) {
      this.formHandler.status = 'loading';
      let data: any;

      data = {
        postcode: this.form.get('postcode')?.value,
        town: this.form.get('town')?.value,
      }

      if (this.window?.data && this.window?.data.itemId) {
        data.id = this.window?.data.itemId;
      }
      if(this.form.get('deliveryTourId')?.value){
        data.deliveryTourId = this.form.get('deliveryTourId')?.value;
      }

      // Update
      if (this.window?.data &&
        this.window.data.itemId) {

        this.deliveryAreasService.update(data)
          .subscribe(
            {
              next: (r) => {
                this.formHandler?.handleApiResult({
                  r, windowId: this.window?.id, callbackOnSuccess: () => {
                    this.store.dispatch(setChangedItem({
                      item: {
                        itemId: data.id,
                        date: new Date().getTime(),
                        userId: 'myself',
                        action: 'update',
                        group: this.changeDetectionGroup
                      }
                    }));
                  }
                });
              },
              error: (error) => {
                this.formHandler.notification = error;
                this.formHandler.status = null;
                this.formHandler.displayErrors();
              }
            }
          )
        // Insert
      } else {
        this.deliveryAreasService.set(data)
          .subscribe(
            {
              next: (r) => {
                this.formHandler?.handleApiResult({
                  r, windowId: this.window?.id, callbackOnSuccess: () => {
                    this.store.dispatch(setChangedItem({
                      item: {
                        itemId: r.result?.deliveryAreas.data.id || '',
                        date: new Date().getTime(),
                        userId: '',
                        action: 'set',
                        group: this.changeDetectionGroup
                      }
                    }))
                  }
                });
              },
              error: (error) => {
                this.formHandler.notification = error;
                this.formHandler.status = null;
                this.formHandler.displayErrors();
              }
            }
          )
      }

    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


}
