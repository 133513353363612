import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {
  Validators,
  FormBuilder
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {finalize, take} from 'rxjs/operators';
import {faPlusSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {FormHandler} from "@shared/forms/models/form-handler";
import {CustomerNoteService} from "@shared/customer/services/customer-note.service";
import {ApiCustomerNotesSetRequest, ApiCustomerNotesUpdateRequest} from "@shared/api/customer-notes";
import {Window} from "@shared/window-manager/interfaces/window.model";

@Component({
  selector: 'shared-customer-note-form',
  templateUrl: './customer-note-form.component.html',
  styleUrls: ['./customer-note-form.component.scss']
})
export class CustomerNoteFormComponent implements OnInit, OnChanges {

  @Input() window?: Window;
  @Input() itemId?: string;
  @Input() customerNumber!: string;
  @Input() disableDbStore: boolean = false;

  @Output() onUpdateSuccess = new EventEmitter<ApiCustomerNotesUpdateRequest['params']['data']>();
  @Output() onSetSuccess = new EventEmitter<ApiCustomerNotesSetRequest['params']['data']>();

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  changeDetectionGroup: string = 'customerNotes';

  form = this.fb.group({
    userId: this.fb.control('', Validators.required),
    title: this.fb.control('', Validators.required),
    note: this.fb.control(''),
    customerNumber: this.fb.control({value: '', disabled: true}, [Validators.required, Validators.minLength(1)]),
    });

  formHandler: FormHandler;

  activeTab = 'details';

  loading: boolean = false;

  constructor(
    private customerNoteService: CustomerNoteService,
    private store: Store<AppState>,
    private fb: FormBuilder
  ) {
    this.formHandler = new FormHandler(this.store);
  }

  ngOnInit() {

  }

  ngOnChanges(){
    this.form.reset();
    if(this.customerNumber){
      this.form.get('customerNumber')?.setValue(this.customerNumber);
    }
    if (this.itemId) {
      this.loadItem(this.itemId);
    } else {
      let userId = '';
      this.store.select(state => state.user.id).pipe(take(1)).subscribe(r => { userId = r ?? '';});
      this.form.get('userId')?.setValue(userId);
    }
  }

  isFormKeyInvalid(key: string, displayIfSubmitForm: boolean = true): boolean {
    let form = this.form?.get(key);
    return !!(form?.invalid &&
      (
        this.formHandler.getFormStatus() == 'submitForm' &&
        displayIfSubmitForm
      )
    );
  }
  loadItem(id: string) {
    this.loading= true;
    this.customerNoteService.get({customerNotes: {}, filters: [{fields: ['id'], values:[id]}]})
      .pipe(
        finalize(() => {
          this.loading= false;
        })
      )
      .subscribe(
        r => {
          if (r.result?.customerNotes.data[0]) {
            let data = r.result?.customerNotes.data[0];
            this.form.get('customerNumber')?.setValue(data?.customerNumber);
            this.form.get('title')?.setValue(data?.title);
            this.form.get('note')?.setValue(data?.note ?? '');
          }
        }
      )
  }

  submit() {

    if (this.form.invalid || !this.customerNumber) {
      this.formHandler.setFormStatus({formStatus: 'submitForm'});
      return;
    }
    if (this.formHandler.getFormStatus() == 'loading') {
      return;
    }
    this.formHandler.setFormStatus({formStatus: 'loading'});
    let getData = (): ApiCustomerNotesSetRequest['params']['data'] => {
      let data: ApiCustomerNotesSetRequest['params']['data'] = {
        userId: this.form.get('userId')?.value ?? '',
        customerNumber: this.form.get('customerNumber')?.value ?? '',
        title: this.form.get('title')?.value ?? '',
        note: this.form.get('note')?.value ?? ''
      };
      return data;
    };

      this.formHandler.setFormStatus({formStatus: 'loading'});
      // Update
      if (this.itemId) {
        let data: ApiCustomerNotesUpdateRequest['params']['data'] = {...getData(), id: this.itemId};
        if(this.disableDbStore){
          this.formHandler.setFormStatus(
            {
              formStatus: 'success',
              resetStatusAfterTimeoutTo: null,
              callbackAfterTimeout: () => {
                this.onUpdateSuccess.emit(data);
              }
            }
          )
          return;
        }
        this.customerNoteService.update(data)
          .subscribe(
            {
              next: (r) => {
                if (r.error) {
                  this.formHandler.setFormError(r.error);
                  this.formHandler.setFormStatus(
                    {
                      formStatus: 'error',
                      resetStatusAfterTimeoutTo: null
                    }
                  );
                } else {

                  this.formHandler.setFormStatus(
                    {
                      formStatus: 'success',
                      resetStatusAfterTimeoutTo: null,
                      callbackAfterTimeout: () => {
                        this.onUpdateSuccess.emit(data);
                        this.formHandler.setItemUpdate(data.id ?? '', this.changeDetectionGroup)
                      }
                    }
                  )
                }
              },
              error: (error) => {
                this.formHandler.setFormError({code: 'SUBSCRIPTION_ERROR', message: JSON.stringify(error)});
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'error',
                    resetStatusAfterTimeoutTo: null
                  }
                );
              }
            }
          )
        // Insert
      } else {
        if(this.disableDbStore){
          this.formHandler.setFormStatus(
            {
              formStatus: 'success',
              resetStatusAfterTimeoutTo: null,
              callbackAfterTimeout: () => {
                this.onSetSuccess.emit(getData());
              }
            }
          )
          return;
        }
        this.customerNoteService.set(getData())
          .subscribe(
            {
              next: (r) => {
                if (r.error) {
                  this.formHandler.setFormError(r.error);
                  this.formHandler.setFormStatus(
                    {
                      formStatus: 'error',
                      resetStatusAfterTimeoutTo: null
                    }
                  );
                } else {

                  this.formHandler.setFormStatus(
                    {
                      formStatus: 'success',
                      resetStatusAfterTimeoutTo: null,
                      callbackAfterTimeout: () => {
                        this.onSetSuccess.emit(getData());
                        this.formHandler.setItemUpdate(r.result?.customerNotes.data.id ?? '', this.changeDetectionGroup)
                      }
                    }
                  )
                }
              },
              error: (error) => {
                this.formHandler.setFormError({code: 'SUBSCRIPTION_ERROR', message: JSON.stringify(error)});
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'error',
                    resetStatusAfterTimeoutTo: null
                  }
                );
              }
            }
          )
      }

  }

  protected readonly faPlusSquare = faPlusSquare;
  protected readonly faTrashCan = faTrashCan;

}
