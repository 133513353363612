<form [formGroup]="form">
  <article *ngIf="apiError" class="message is-danger">
    <div class="message-body">
      <b>{{apiError.notification}}</b>
      <span *ngFor="let error of apiError?.errors" class="is-block">{{error.key}}: {{error.error}}</span>
    </div>
  </article>
  <div class="is-block">
    <shared-upload (changedFiles)="changedLogo($event)" [filePaths]="relativeLogoPath" label="Logo"
                   fileType="publicFile" [maxFiles]="1" labelButton="Logo hochladen..."></shared-upload>
  </div>
  <div class="is-block">
    <shared-upload (changedFiles)="changedEmailLogo($event)" [filePaths]="relativeLogoEmailPath" fileType="publicFile"
                   label="Logo E-Mail" [maxFiles]="1" labelButton="Logo für E-Mail hochladen..."></shared-upload>
  </div>
  <div class="p-2 has-background-white">
    <div class="columns is-multiline">
      <div class="column is-6">
        <div class="field">
          <label class="label">Firma</label>
          <div class="control">
            <input class="input is-small" type="text" placeholder="Firma" formControlName="company">
            <p *ngIf="isFormRequestError('company')" class="help is-danger">Bitte hinterlegen Sie eine Firma.</p>

          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="field">
          <label class="label">Straße</label>
          <div class="control">
            <input class="input is-small" type="text" placeholder="Straße" formControlName="street">
            <p *ngIf="isFormRequestError('street')" class="help is-danger">Bitte hinterlegen Sie eine Straße.</p>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">Postleitzahl</label>
          <div class="control">
            <input class="input is-small" type="text" placeholder="Postleitzahl" formControlName="postcode">
            <p *ngIf="isFormRequestError('postcode')" class="help is-danger">Bitte hinterlegen Sie eine
              Postleitzahl.</p>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">Stadt</label>
          <div class="control">
            <input class="input is-small" type="text" placeholder="Stadt"
                   formControlName="town">
            <p *ngIf="isFormRequestError('town')" class="help is-danger">Bitte hinterlegen Sie eine Stadt.</p>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">Telefon</label>
          <div class="control">
            <input class="input is-small" type="text" placeholder="Telefon" formControlName="phone">
            <p *ngIf="isFormRequestError('phone')" class="help is-danger">Bitte hinterlegen Sie eine Telefonnummer.</p>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">E-Mail</label>
          <div class="control">
            <input class="input is-small" type="email" placeholder="E-Mail"
                   formControlName="email">
            <p *ngIf="isFormRequestError('email')" class="help is-danger">Bitte hinterlegen Sie eine E-Mail.</p>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="field">
          <label class="label">Shop-URL</label>
          <div class="control">
            <input class="input is-small" type="text" placeholder="https://meine-adresse.de/" formControlName="shopUrl">
          </div>
          <p *ngIf="isFormRequestError('shopUrl')" class="help is-danger">Bitte hinterlegen Sie eine URL.</p>
        </div>
      </div>
    </div>
    <div class="has-text-centered">
      <shared-form-submit-button [formStatus]="formStatus" (submit)="submit()"
                              submitLabel="Speichern"></shared-form-submit-button>
    </div>

  </div>
</form>

