
export interface ChangeDetectionItem{
  itemId: string;
  group: string;
  action: string;
  userId: string;
  date: number;
}

export interface ChangeDetectionState {
  items: null | ChangeDetectionItem[];
}

export const initialChangeDetectionState = () => {
  return {
    items: null
  };
};
