import {Component, OnInit} from '@angular/core';
import {FormStatusDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {
  FormControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {ConfigService} from '@shared/config/services/config.service';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {finalize} from 'rxjs/operators';
import {faPlusSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {faBars, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {ApiConfig} from "@shared/api/configs";

@Component({
  selector: 'shared-config-availability-status',
  templateUrl: './config-availability-status.component.html',
  styleUrls: ['./config-availability-status.component.scss']
})
export class ConfigAvailabilityStatusComponent implements OnInit {

  formStatus: FormStatusDeprecated = null;

  apiError?: {
    notification: string,
    errors?: { key: string, error: string }[]
  };

  loading: boolean = true;
  dialog: boolean = false;


  form = this.fb.group({
    id: new FormControl(''),
    articleAvailabilities: this.fb.array([])
  });

  constructor(private fb: FormBuilder, private configService: ConfigService) {

  }

  ngOnInit(): void {
    this.loadConfig()
  }

  loadConfig(){
    (this.form.get('articleAvailabilities') as FormArray).clear();
    this.configService.getConfig()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        r => {
          if (r.result) {
            this.form.get('id')?.setValue(r.result.configs.data[0].id ?? '')
            r.result.configs.data[0].articleAvailabilities.map(
              availability => {
                this.addArticleAvailability({
                  fromInventory: availability.fromInventory,
                  toInventory: availability.toInventory,
                  color: availability.color,
                  availability: availability.availability
                })

              }
            )
          }
        }
      )
  }

  drop(event: CdkDragDrop<string[]>) {
    const availabilities = this.articleAvailabilities;

    let newIndex: number = event.currentIndex;
    if (newIndex === -1) {
      newIndex = availabilities.length - 1;
    } else if (newIndex == availabilities.length) {
    }

    const currentGroup = availabilities.at(event.previousIndex);
    availabilities.removeAt(event.previousIndex);
    availabilities.insert(newIndex, currentGroup)
  }

  get articleAvailabilities() {
    return this.form.get('articleAvailabilities') as FormArray;
  }

  addArticleAvailability(params?: {
    fromInventory?: number,
    toInventory?: number,
    color: string,
    availability: string
  }) {
    this.articleAvailabilities.push(new FormGroup({
      fromInventory: new FormControl(params?.fromInventory ?? null),
      toInventory: new FormControl(params?.toInventory ?? null),
      availability: new FormControl(params?.availability ?? null, Validators.required),
      color: new FormControl(params?.color ?? null, Validators.required)
    }));
  }

  isFormError(field: string, index: number) {
    return !!(this.articleAvailabilities.at(index).get(field)?.errors &&
      this.formStatus == 'submitForm');
  }

  removeAvailability(index: any) {
    const availabilities = this.articleAvailabilities;
    availabilities.removeAt(index);
  }

  changeColor(index: number, color: string) {
    this.form.get('articleAvailabilities.' + index + '.color')?.setValue(color);
  }

  submit() {
    this.formStatus = 'submitForm';
    if (this.form.invalid) {
      return;
    }
    this.formStatus = 'loading';

    let data: ApiConfig['articleAvailabilities'] = [];
    this.articleAvailabilities.controls.map(a => {
      let status: {
        availability: string,
        color: string,
        fromInventory?: number,
        toInventory?: number
      } = {
        color: a.get('color')?.value ?? '',
        availability: a.get('availability')?.value ?? ''
      };
      if (isNaN(parseInt(a.get('fromInventory')?.value)) === false) {
        status.fromInventory = parseInt(a.get('fromInventory')?.value)
      }
      if (isNaN(parseInt(a.get('toInventory')?.value)) === false) {
        status.toInventory = parseInt(a.get('toInventory')?.value)
      }
      data.push(status);
    });
    this.configService.updateConfig({data: {id: this.form.get('id')?.value ?? '', articleAvailabilities: data}})
      .subscribe(
        {
          next: (r) => {
            if (r.error) {

              this.apiError = {notification: r.error.message};
              if (r.error.input) {
                this.apiError.errors = r.error.input;
              }
            }
            if (r.result?.configs.updated) {
              this.formStatus = 'success';
              this.loadConfig();
              setTimeout(() => {
                this.formStatus = null;
              }, 1000);
            } else {
              this.formStatus = 'error';
              setTimeout(() => {
                this.formStatus = null;
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                });
              }, 1000);
            }
          },
          error: () => {
            this.formStatus = 'error';
            setTimeout(() => {
              this.formStatus = null;
            }, 1000);
          }
        }
      )
  }

  protected readonly faPlusSquare = faPlusSquare;
  protected readonly faTrashCan = faTrashCan;
  protected readonly faBars = faBars;
  protected readonly faInfoCircle = faInfoCircle;
}
