import {Component, ElementRef, OnInit, Optional, TemplateRef, ViewChild} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';


@Component({
  selector: 'shared-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  @ViewChild('contentDialog', {static: false}) contentDialog!: TemplateRef<any>;
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  showDialog(){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = {top: '70px'};
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = 'calc(100vw - 40px)';
    this.dialog.open(this.contentDialog, dialogConfig);
  }

}
