import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, forkJoin, Subject, Subscription} from 'rxjs';
import {finalize, map, takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {AppState} from '@store/state/app.state';
import {Store} from '@ngrx/store';
import {UserService} from '@shared//user/services/user.service';
import {User} from '@shared/user/models/user';
import {setWindow} from '@store/actions/windows.action';
import {ConfigService} from '@shared/config/services/config.service';

interface UserList extends User {
  delete?: boolean;
  update?: boolean;
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  title: string = 'User verwalten';



  constructor(private route: ActivatedRoute,
              private store: Store<AppState>,
              private userService: UserService,
              private configService: ConfigService) {
  }

  ngOnInit() {

  }

  editItem(itemId: string, title: string, component: any) {
    this.store.dispatch(setWindow({id: 'u' + itemId, title, component, data: {itemId}}))
  }

  deleteItem(id: string) {
    this.userService.deleteUser(id).subscribe(
      r => {
        if (r.result?.users.deleted) {
        }
      }
    )
  }

  customerShopLogin(id: string) {
    forkJoin([this.userService.getUserToken(id), this.configService.getConfig()])
      .subscribe(r => {
        if (r[0].result?.userTokens &&
          r[0].result?.userTokens.data.accessToken) {
          window.open(r[1].result?.configs.data[0].shopUrl + '?token=' + r[0].result.userTokens.data.accessToken + '&action=login');
        }
      });
  }

  loadItemAndUpdateList(id: string) {
    this.userService.getUser(id).subscribe(
      r => {


      }
    )
  }

  loadItems(data?: {
    searchString?: string,
    filters?: { fields?: ['username'], values: any[] }[],
    filterDates?: { fields: ['dateCreated' | 'lastUpdate'], greaterThan?: string, greaterThanEqual?: string, lessThan?: string, lessThanEqual?: string }[],
    sort?: { field: 'username', direction: 1 | -1 }[],
    start?: number
  }): void {

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

