import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Window} from '@shared/window-manager/interfaces/window.model';
import {Subject} from 'rxjs';
import {
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {FormHelperDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {UploadFilePath} from '@shared/uploads/components/uploads/upload.component';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {finalize} from 'rxjs/operators';
import {setChangedItem} from '@store/actions/change-detection.action';
import {DynamicArticlesService} from '@shared/dynamic-articles/services/dynamic-articles.service';
import {Autocomplete, AutocompleteItem} from '@shared/forms/models/autocomplete';
import {setWindow} from '@store/actions/windows.action';
import {faPlusSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'shared-dynamic-article-form',
  templateUrl: './dynamic-article-form.component.html',
  styleUrls: ['./dynamic-article-form.component.scss']
})
export class DynamicArticleFormComponent implements OnInit {

  @ViewChild('formTag', {static: false}) formTag: ElementRef | undefined;
  @Input() window?: Window;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  autocompleteDynamicArticles: Autocomplete = new Autocomplete(
    {
      label: 'Folgende dynamische Artikel automatisch aus dem Warenkorb entfernen, wenn dieser Artikel hinzugefügt wird',
      placeholder: 'Dynamische Artikel suchen...',
      loadItems: (searchString: string) => {
        this.loadDynamicArticles(searchString)
      }
    }
  );

  dialogCategorie: boolean = false;

  changeDetectionGroup: string = 'dynamicArticles';

  form = new UntypedFormGroup({
    id: new FormControl(null),
    category: new FormControl(null, Validators.required),
    name: new FormControl(null, Validators.required),
    position: new FormControl(0, Validators.required),
    addAutomatically: new FormControl(false, Validators.required),
    deletable: new FormControl(false),
    articleAmount: new FormControl(1),
    setArticleAmountFromCart: new FormControl(false, Validators.required),
    articleNumber: new FormControl(null, Validators.required),
    netPrice: new FormControl('0', Validators.required),
    netPricePerArticle: new FormControl('0', Validators.required),
    pricePerArticleFromAmount: new FormControl(0, Validators.required),
    netDeposit: new FormControl('0', Validators.required),
    grossDeposit: new FormControl('0', Validators.required),
    netFee: new FormControl('0', Validators.required),
    grossFee: new FormControl('0', Validators.required),
    taxRate: new FormControl(19, Validators.required),
    percentageFromNetMerchandiseValue: new FormControl(0, Validators.required),
    percentageFromNetTotalSum: new FormControl(0, Validators.required),
    description: new FormControl(null),
    fromCartAmount: new FormControl(null),
    toCartAmount: new FormControl(null),
    fromDate: new FormControl(null),
    toDate: new FormControl(null),
    fromWeight: new FormControl(null),
    toWeight: new FormControl(null),
    fromNetMerchandiseValue: new FormControl(null),
    toNetMerchandiseValue: new FormControl(null),
    fromGrossMerchandiseValue: new FormControl(null),
    toGrossMerchandiseValue: new FormControl(null),
    requiredArticleNumbers: this.fb.array([]),
    requiredCustomerNumbers: this.fb.array([]),
    requiredPriceGroups: this.fb.array([]),
    requiredCountries: this.fb.array([]),
    excludedGroups: this.fb.array([]),
    excludedCountries: this.fb.array([]),
    removeDynamicArticleIds: this.fb.array([]),
    removeDynamicArticleCategories: this.fb.array([]),
    images: new FormControl([])
  });

  formHandler: FormHelperDeprecated;
  filePaths: UploadFilePath[] = [];


  errors = null;
  activeTab = '';
  msg = '';
  data: any;

  loading: boolean = false;


  constructor(
    private dynamicArticlesService: DynamicArticlesService,
    private store: Store<AppState>,
    private renderer: Renderer2,
    private fb: UntypedFormBuilder,
  ) {

    this.formHandler = new FormHelperDeprecated(
      this.store,
      this.changeDetectionGroup,
      this.form,
      [
        {key: 'name', error: 'Bitte hinterlegen Sie einen Namen.'},
        {key: 'position', error: 'Bitte hinterlegen Sie eine Position.'},
        {key: 'articleNumber', error: 'Bitte hinterlegen Sie eine Artikelnummer.'},
        {key: 'netPrice', error: 'Bitte hinterlegen Sie einen Preis.'},
        {key: 'netDeposit', error: 'Bitte hinterlegen Sie einen Pfand.'},
        {key: 'netPricePerArticle', error: 'Bitte hinterlegen Sie einen Preis pro Artikel.'},
        {key: 'taxRate', error: 'Bitte hinterlegen Sie eine Mehrwertsteuer.'},
      ],
      this.renderer
    );
    this.formHandler.activeTab = 'details';
  }

  ngAfterViewInit() {
    this.formHandler.htmlRef = this.formTag;
  }

  ngOnInit() {
    if (this.window?.data?.itemId) {
      this.loadItem(this.window.data.itemId);
    }
  }

  loadItem(id: string) {
    this.loading = true;
    this.dynamicArticlesService.get({dynamicArticles: {}, filters: [{fields: ['id'], values:[id]}]})
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        async r => {
          if (r.result?.dynamicArticles.data[0]) {
            if (r.result?.dynamicArticles.data[0].images) {
              this.filePaths = r.result?.dynamicArticles.data[0].images;
            }
            if (r.result.dynamicArticles.data[0].requiredArticleNumbers) {
              r.result.dynamicArticles.data[0].requiredArticleNumbers.map(
                value => {
                  this.getFormArray('requiredArticleNumbers').push(this.fb.control(value, Validators.required));
                }
              )
            }
            if (r.result.dynamicArticles.data[0].requiredCustomerNumbers) {
              r.result.dynamicArticles.data[0].requiredCustomerNumbers.map(
                value => {
                  this.getFormArray('requiredCustomerNumbers').push(this.fb.control(value, Validators.required));
                }
              )
            }
            if (r.result.dynamicArticles.data[0].requiredPriceGroups) {
              r.result.dynamicArticles.data[0].requiredPriceGroups.map(
                value => {
                  this.getFormArray('requiredPriceGroups').push(this.fb.control(value, Validators.required));
                }
              )
            }
            if (r.result.dynamicArticles.data[0].requiredCountries) {
              r.result.dynamicArticles.data[0].requiredCountries.map(
                value => {
                  this.getFormArray('requiredCountries').push(this.fb.control(value, Validators.required));
                }
              )
            }
            if (r.result.dynamicArticles.data[0].excludedGroups) {
              r.result.dynamicArticles.data[0].excludedGroups.map(
                value => {
                  this.getFormArray('excludedGroups').push(this.fb.control(value, Validators.required));
                }
              )
            }
            if (r.result.dynamicArticles.data[0].excludedCountries) {
              r.result.dynamicArticles.data[0].excludedCountries.map(
                value => {
                  this.getFormArray('excludedCountries').push(this.fb.control(value, Validators.required));
                }
              )
            }
            if (r.result.dynamicArticles.data[0].removeDynamicArticleCategories) {
              r.result.dynamicArticles.data[0].removeDynamicArticleCategories.map(
                value => {
                  this.getFormArray('removeDynamicArticleCategories').push(this.fb.control(value, Validators.required));
                }
              )
            }
            if (r.result.dynamicArticles.data[0].removeDynamicArticleIds) {
              this.setRemoveDynamicArticles(r.result.dynamicArticles.data[0].removeDynamicArticleIds);
              await this.resolveDynamicArticleIds()
            }

            this.formHandler?.fillForm(r.result.dynamicArticles.data[0]);
          }
        }
      )
  }

  changedFiles(images: any){
    this.form.get('images')?.setValue(images);
  }

  addField(type: 'requiredCountries' | 'excludedCountries' | 'requiredArticleNumbers' | 'requiredCustomerNumbers' | 'requiredPriceGroups' | 'excludedGroups'){
    this.getFormArray(type).push(this.fb.control('', Validators.required));
    this.formHandler.htmlRef = this.formTag;
  }

  removeField(type: 'requiredCountries' | 'excludedCountries' |'requiredArticleNumbers' | 'requiredCustomerNumbers' | 'requiredPriceGroups' | 'excludedGroups', index: number){
    const formArray = this.getFormArray(type);
    formArray.removeAt(index);
  }

  getFormArray(type: 'removeDynamicArticleCategories' | 'requiredCountries' | 'excludedCountries' | 'requiredArticleNumbers' | 'requiredCustomerNumbers' | 'requiredPriceGroups' | 'excludedGroups'): UntypedFormArray {
    return this.form.get(type) as UntypedFormArray;
  }

  toggleRemoveDynamicArticleCategory(category: string){
    let set = true;
    this.getFormArray('removeDynamicArticleCategories').controls.forEach((x, index) => {
      if(x.value == category){
        this.getFormArray('removeDynamicArticleCategories').removeAt(index);
        set = false;
      }
    });
    if(set){
      this.getFormArray('removeDynamicArticleCategories').push(this.fb.control(category));
    }
  }

  isRemoveDynamicArticleCategory(category: string): boolean{
    let result = false;
    this.getFormArray('removeDynamicArticleCategories').controls.forEach((x, index) => {
      if(x.value == category){
        result = true;
      }
    });
    return result;
  }

  submit() {
    this.formHandler.validateForm();
    this.formHandler.displayErrors();
    this.formHandler.status = 'submitForm';
    if (!this.formHandler.isFormErrors()) {
      this.formHandler.status = 'loading';

      let data = this.formHandler?.getFormValues();
      if(data.addAutomatically){
        data.addAutomatically = true;
      } else {
        data.addAutomatically = false;
      }
      if(data.deletable){
        data.deletable = true;
      } else {
        data.deletable = false;
      }
      if(data.setArticleAmountFromCart){
        data.setArticleAmountFromCart = true;
      } else {
        data.setArticleAmountFromCart = false;
      }

      data.netPrice = parseFloat(data.netPrice?.toString().replace(',', '.') ?? '');
      data.netDeposit = parseFloat(data.netDeposit?.toString().replace(',', '.') ?? '');
      data.grossDeposit = parseFloat(data.grossDeposit?.toString().replace(',', '.') ?? '');
      data.netFee = parseFloat(data.netFee?.toString().replace(',', '.') ?? '');
      data.grossFee = parseFloat(data.grossFee?.toString().replace(',', '.') ?? '');
      data.netPricePerArticle = parseFloat(data.netPricePerArticle?.toString().replace(',', '.') ?? '');
      data.pricePerArticleFromAmount = parseInt(data.pricePerArticleFromAmount);
      data.taxRate = parseFloat(data.taxRate?.toString().replace(',', '.') ?? '');
      data.percentageFromNetMerchandiseValue = parseFloat(data.percentageFromNetMerchandiseValue?.toString().replace(',', '.') ?? '');
      data.percentageFromNetTotalSum = parseFloat(data.percentageFromNetTotalSum?.toString().replace(',', '.') ?? '');
      if(data.fromCartAmount){
        data.fromCartAmount = parseInt(data.fromCartAmount);
      }
      if(data.toCartAmount){
        data.toCartAmount = parseInt(data.toCartAmount);
      }
      if(data.fromNetMerchandiseValue){
        data.fromNetMerchandiseValue = parseInt(data.fromNetMerchandiseValue);
      }
      if(data.toNetMerchandiseValue){
        data.toNetMerchandiseValue = parseInt(data.toNetMerchandiseValue);
      }

      if(data.fromGrossMerchandiseValue){
        data.fromGrossMerchandiseValue = parseInt(data.fromGrossMerchandiseValue);
      }
      if(data.toGrossMerchandiseValue){
        data.toGrossMerchandiseValue = parseInt(data.toGrossMerchandiseValue);
      }
      if(data.fromWeight){
        data.fromWeight = parseFloat(data.fromWeight?.toString().replace(',', '.') ?? '');
      }
      if(data.toWeight){
        data.toWeight = parseFloat(data.toWeight?.toString().replace(',', '.') ?? '');
      }

      let unset: any = [];
      if(!data.fromDate){
        unset.push('fromDate');
      }
      if(!data.toDate){
        unset.push('toDate');
      }

      // Update
      if (this.window?.data &&
        this.window.data.itemId) {
        data.id = this.window?.data.itemId;
        this.dynamicArticlesService.update(data, unset)
          .subscribe(
            {
              next: (r) => {
                this.formHandler?.handleApiResult({
                  r, windowId: this.window?.id, callbackOnSuccess: () => {
                    this.store.dispatch(setChangedItem({
                      item: {
                        itemId: data.id,
                        date: new Date().getTime(),
                        userId: '',
                        action: 'update',
                        group: this.changeDetectionGroup
                      }
                    }));
                  }
                });
              },
              error: (error) => {
                this.formHandler.notification = error;
                this.formHandler.status = null;
                this.formHandler.displayErrors();
              }
            }
          )
        // Insert
      } else {
        this.dynamicArticlesService.set(data)
          .subscribe(
            {
              next: (r) => {
                this.formHandler?.handleApiResult({r, windowId: this.window?.id, callbackOnSuccess: () => {
                    this.store.dispatch(setChangedItem({item: {itemId: r.result?.dynamicArticles.data.id || '', date: new Date().getTime(), userId: '', action: 'set', group: this.changeDetectionGroup}}))
                  } });
              },
              error: (error) => {
                this.formHandler.notification = error;
                this.formHandler.status = null;
                this.formHandler.displayErrors();
              }
            }
          )
      }

    }
  }

  async resolveDynamicArticleIds() {
    const dynamicArticles = await this.dynamicArticlesService.resolveIdsToNames(this.form.get('removeDynamicArticleIds')?.value);
    dynamicArticles.map((r: { id: string, name: string }) => {
      this.autocompleteDynamicArticles.selectItem({name: r.name, value: r.id})
    });
  }

  loadDynamicArticles(searchString: string) {
    this.autocompleteDynamicArticles.loading = true;
    this.dynamicArticlesService.get({
      dynamicArticles: {sort: [{field: 'name', direction: 1}]}
    })
      .pipe(
        finalize(() => {
          this.autocompleteDynamicArticles.loading = false;
        })
      )
      .subscribe(
        r => {
          let items: AutocompleteItem[] = [];
          if (r.result?.dynamicArticles.data) {
            r.result?.dynamicArticles.data.map(
              item => {
                items.push({value: item.id, name: item.name})
              }
            )
          }
          this.autocompleteDynamicArticles.setItems(items);
        }
      )

  }

  editDynamicArticle(item: AutocompleteItem) {
    this.store.dispatch(setWindow({
      id: 'dynamicArticles' + item.value,
      title: 'Dynamischer Artikel: ' + item.name,
      component: 'shared-dynamic-article-form',
      data: {itemId: item.value}
    }))
  }

  setRemoveDynamicArticles(items: string[]) {
    //this.form.get('dynamicArticleIds')?.setValue(this.fb.control(''));
    const formArray = this.form.get('removeDynamicArticleIds') as FormArray;
    formArray.clear();
    items.map((r: any) => {
      formArray.push(this.fb.control(r));
    })
  }

  ngOnDestroy() {

  }


  protected readonly faPlusSquare = faPlusSquare;
  protected readonly faTrashCan = faTrashCan;
    protected readonly faInfoCircle = faInfoCircle;
}
