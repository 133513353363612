import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {List} from "@shared/list/models/list";

@Component({
  selector: 'app-list-headline',
  templateUrl: './list-headline.component.html',
  styleUrls: ['./list-headline.component.scss']
})
export class ListHeadlineComponent implements OnInit {
  @Input() title?: string;
  @Input() count?: { total: number, filtered: number };
  @Input() list!: List<any, any, any>;

  constructor() { }

  ngOnInit(): void {
  }

  protected readonly faTimes = faTimes;
}
