import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from '@environments/environment';
import {
  ApiArticleRecommendationsDeleteRequest,
  ApiArticleRecommendationsDeleteResponse,
  ApiArticleRecommendationsGetRequest,
  ApiArticleRecommendationsGetResponse, ApiArticleRecommendationsSetRequest, ApiArticleRecommendationsSetResponse
} from '../../api/article-recommendations';

@Injectable({
  providedIn: 'root'
})
export class ArticleRecommendationService {

  constructor(private http: HttpClient) {
  }

  setArticleRecommendations(data: {articleNumber: string, recommendations: string[]}[]): Observable<ApiArticleRecommendationsSetResponse> {
    let i: number = 0;
    const body: ApiArticleRecommendationsSetRequest[] = data.map(data => {
      data.articleNumber
      return {
        id: 'setArticleRecommendations' + i++,
        command: 'articleRecommendations/set',
        params: {
          data: {
            articleNumber: data.articleNumber,
            recommendations: data.recommendations
          }
        }
      };
    });

    return this.http.post<ApiArticleRecommendationsSetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  getArticleRecommendations(articleNumber?: string): Observable<ApiArticleRecommendationsGetResponse> {
    const body: ApiArticleRecommendationsGetRequest = {
      id: 'getArticleRecommendations',
      command: 'articleRecommendations/get',
      params: {
        articleRecommendations: {
          limit: 50000
        }
      }
    };
    if (articleNumber) {
      body.params.filters = [
        {fields: ['articleNumber'], values: [articleNumber]}
      ]
    }
    return this.http.post<ApiArticleRecommendationsGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  deleteArticleRecommendations(articleNumbers: string[]): Observable<ApiArticleRecommendationsDeleteResponse> {
    let i: number = 0;
    const body: ApiArticleRecommendationsDeleteRequest[] = articleNumbers.map(articleNumber => {
      return {
        id: 'articleRecommendations/delete_' + i++,
        command: 'articleRecommendations/delete',
        params: {
          data: {
            articleNumber
          }
        }
      };
    });
    return this.http.post<ApiArticleRecommendationsDeleteResponse>(environment.apiUrl, JSON.stringify(body));
  }
}
