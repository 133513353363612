<div class="mb-2">
  <h1 class="is-size-3 has-text-black is-inline-block">{{title}}</h1>
  <p class="is-inline-block ml-2" *ngIf="count?.total">
    <span *ngIf="count?.filtered===count?.total"><b>{{count?.total}}</b> Einträge.</span>
    <span
      *ngIf="count?.filtered!==count?.total"><b>{{count?.filtered}}</b> gefilterte Einträge von insgesamt <b>{{count?.total}}</b>. </span>
  </p>
</div>

<ng-content></ng-content>
<p *ngIf="list.isFilter()" (click)="list.resetFilters()" class="reset-filter"><fa-icon
 [icon]="faTimes"></fa-icon> Alle Filter zurücksetzen</p>
