import {Injectable} from '@angular/core';
import {filter, take} from 'rxjs/operators';
import {setUser, setUserSuccess} from '@store/actions/user.action';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {deleteAccessToken, setInjectionToken, userId} from '@store/actions/storage.action';
import {AuthenticationService} from '@core/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppInitStorageService {

  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  /*  injectToken(): Promise<void>{
      return new Promise<void>((resolve, reject) => {

      });
    }*/

  init() {
    return new Promise<void>((resolve, reject) => {
      this.store.dispatch(deleteAccessToken());
      let params = new URLSearchParams(window.location.search);
      let token = params.get('token');
      if (token) {
        let payloadString = token.split('.');
        if (payloadString[1]) {
          this.store.dispatch(userId({userId: null}));
          // Vorab ausloggen, damit die Authentication-Cookies (insbesondere die Captcha-Authentifizierung) gelöscht werden
          let payload: any = JSON.parse(atob(payloadString[1]));
          this.store.dispatch(setInjectionToken({token, expiredIn: payload.exp}));
          if (params.get('action') == 'login') {
            this.store.dispatch(setUserSuccess({
              userAdmin: {
                id: payload.userId,
                email: payload.email,
                username: payload.username
              }
            }));
            this.router.navigate(["/shop/dashboard"]).then();
          }
        }
      }

      // Der Kunde hat einen aktiven Token
      if(this.authenticationService.isAuthentication()){
        this.store.select(state => state).pipe(
          take(1)
        )
          .subscribe(
            state => {
              // Keine UserId, aber im Storage ist eine erfasst > User neu laden
              if (!state.user.id &&
                state.storage.userId) {
                this.store.dispatch(setUser({userId: state.storage.userId}))
              }
            }
          );
      } else {
        this.store.dispatch(userId({userId: null}))
      }

      this.store.select(state => state)
        .pipe(
          filter(state =>
            (
              state.authentication.isLoading === false ||
              !state.authentication.isLoading
            ) &&
            (
              state.user.isLoading === false ||
              !state.storage.userId)
          ),
          take(1)
        )
        .subscribe(
          r => {
            resolve()
          }
        )
    });
  }
}
