import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {EMPTY, Subject, Subscription, timer} from 'rxjs';
import {debounce} from 'rxjs/operators';

@Directive({
  selector: '[debounce-click]'
})
export class DebounceClickDirective implements OnInit, OnDestroy {
  @Input()
  time = Date.now();
  counter: number = 0;

  @Output()
  debounceClick = new EventEmitter();

  private clicks = new Subject();

  constructor() {

  }

  ngOnInit() {

  }

  ngOnDestroy() {
  }

  @HostListener('click', ['$event'])
  @HostListener('touch', ['$event'])
  clickEvent(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if( (Date.now() - this.time) > ( 1 * 1000) ){
      this.debounceClick.emit(event)
    }
    this.time = Date.now();
    //this.counter++;
    //this.clicks.next(event);
  }
}
