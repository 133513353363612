import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {
  ApiCustomerGetRequestParams,
  ApiCustomersGetResponse, ApiCustomersDeleteRequest,
  ApiCustomersDeleteResponse,
  ApiCustomersGetRequest,
  ApiCustomersSetRequest,
  ApiCustomersSetResponse,
  ApiCustomersUpdateRequest,
  ApiCustomersUpdateResponse
} from '@shared/api/customers';
import {CustomerArticleStocks} from '@shared/customer/interfaces/customer';
import {
  ApiCustomerDocumentsGetRequest,
  ApiCustomerDocumentsGetResponse
} from '@shared/api/customer-documents';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {


  customerId: string = '';

  constructor(private http: HttpClient) {
  }


  downloadDocument(id: string, customerNumber: string) {
    const body: ApiCustomerDocumentsGetRequest = {
      id: 'downloadDocument',
      command: 'customerDocuments/get',
      params: {
        filters: [
          {fields: ['customerNumber'], values: [customerNumber]},
          {fields: ['id'], values: [id]},
          {fields: ['type'], values: ['invoice']}
        ],
        customerDocuments: {
          start: 0,
          limit: 1,
          expectedData: {
            fileBase64: 1
          }
        }
      }
    };
    this.http.post<ApiCustomerDocumentsGetResponse>(environment.apiUrl, JSON.stringify(body))
      .subscribe(
        r => {
          if (r.result?.customerDocuments.data[0].fileBase64) {
            const byteArray = new Uint8Array(atob(r.result?.customerDocuments.data[0].fileBase64).split('').map(char => char.charCodeAt(0)));
            const file = new Blob([byteArray], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        }
      )
  }

  set(data: ApiCustomersSetRequest['params']['data']): Observable<ApiCustomersSetResponse> {
    const body: ApiCustomersSetRequest = {
      id: 'setCustomer#INVALID_PARAMS',
      command: 'customers/set',
      params: {
        data
      }
    };
    return this.http.post<ApiCustomersSetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  setBatch(items: Omit<ApiCustomersSetRequest, 'command'>[]): Observable<ApiCustomersSetResponse[]> {
    let body: ApiCustomersSetRequest[] = [];

    items.map(r => {
      body.push({...r, command: 'customers/set'})
    });
    return this.http.post<ApiCustomersSetResponse[]>(environment.apiUrl, JSON.stringify(body));
  }


  update(data: ApiCustomersUpdateRequest['params']['data']): Observable<ApiCustomersUpdateResponse> {
    const body: ApiCustomersUpdateRequest = {
      id: 'updateCustomer#INVALID_PARAMS',
      command: 'customers/update',
      params: {
        data
      }
    };
    return this.http.post<ApiCustomersUpdateResponse>(environment.apiUrl, JSON.stringify(body));
  }

  updateBatch(items: Omit<ApiCustomersUpdateRequest, 'command'>[]): Observable<ApiCustomersUpdateResponse[]> {
    let body: ApiCustomersUpdateRequest[] = [];

    items.map(r => {
      body.push({...r, command: 'customers/update'})
    });
    return this.http.post<ApiCustomersUpdateResponse[]>(environment.apiUrl, JSON.stringify(body));
  }

  download(params: ApiCustomerGetRequestParams, type: 'csv' | 'xlsx'): Observable<any> {
    const body: ApiCustomersGetRequest = {
      id: 'downloadCustomers',
      command: 'customers/get',
      params
    };
    return this.http.post<any>(environment.apiUrl + 'download.php?type=' + type, JSON.stringify(body), {responseType: 'blob' as 'json'});
  }

  getDocuments(customerNumber: string, types: string[], start: number, limit: number): Observable<ApiCustomerDocumentsGetResponse> {
    const body: ApiCustomerDocumentsGetRequest = {
      id: 'customerGetDocuments',
      command: 'customerDocuments/get',
      params: {
        filters: [
          {fields: ['customerNumber'], values: [customerNumber]},
          {fields: ['type'], values: types}
        ],
        customerDocuments: {
          start,
          limit,
          sort: [
            {field: 'date', direction: -1}
          ],
          expectedData: {
            id: 1,
            amount: 1,
            date: 1,
            uniqueNumber: 1,
            openAmount: 1
          }
        }
      }
    };
    return this.http.post<ApiCustomerDocumentsGetRequest>(environment.apiUrl, JSON.stringify(body));
  }

  updateFavorites(customerId: string, favoritesList: string[] | []): Observable<ApiCustomersUpdateResponse> {
    const body: ApiCustomersUpdateRequest = {
      id: 'updateCustomerFavorites',
      command: 'customers/update',
      params: {
        data: {
          id: customerId,
          favoritesList
        }
      }
    };
    return this.http.post<ApiCustomersUpdateRequest>(environment.apiUrl, JSON.stringify(body));
  }

  updateArticleStocks(customerId: string, articleStocks: CustomerArticleStocks[] | []): Observable<ApiCustomersUpdateResponse> {
    const body: ApiCustomersUpdateRequest = {
      id: 'updateArticleStocks',
      command: 'customers/update',
      params: {
        data: {
          id: customerId,
          articleStocks
        }
      }
    };
    return this.http.post<ApiCustomersUpdateRequest>(environment.apiUrl, JSON.stringify(body));
  }


  get(params: ApiCustomerGetRequestParams): Observable<ApiCustomersGetResponse> {
    const body: ApiCustomersGetRequest = {
      id: 'getCustomer',
      command: 'customers/get',
      params
    };
    return this.http.post<ApiCustomersGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  deleteCustomer(id: string): Observable<ApiCustomersDeleteResponse> {
    const body: ApiCustomersDeleteRequest = {
      id: 'deleteCustomer',
      command: 'customers/delete',
      params: {
        data: {
          id
        }
      }
    };
    return this.http.post<ApiCustomersDeleteResponse>(environment.apiUrl, JSON.stringify(body));
  }
}
