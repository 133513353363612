import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Window} from '@shared/window-manager/interfaces/window.model';
import {forkJoin, Subject} from 'rxjs';
import {FormControl, FormGroup, UntypedFormBuilder, Validators,} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {finalize} from 'rxjs/operators';
import {ArticleService} from '@shared/article/services/article.service';
import {ApiArticle, ApiArticlePrice, ApiArticlesSetRequest, ApiArticlesUpdateRequest} from '@shared/api/articles';
import {Autocomplete, AutocompleteItem} from '@shared/forms/models/autocomplete';
import {setWindow} from '@store/actions/windows.action';
import {ArticleRecommendationService} from '@shared/article/services/article-recommendation.service';
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {UploadAdditionalInputFields, UploadFilePath} from "@shared/uploads/components/uploads/upload.component";
import {faPlusSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {FormDynamicArrayFormGroup} from "@shared/forms/models/form-dynamic-array-form-group";
import {FormHandler} from "@shared/forms/models/form-handler";
import {FormDynamicArray} from "@shared/forms/models/form-dynamic-array";

@Component({
  selector: 'shared-article-form',
  templateUrl: './article-form.component.html',
  styleUrls: ['./article-form.component.scss']
})
export class ArticleFormComponent implements OnInit {


  @ViewChild('formTag', {static: false}) formTag: ElementRef | undefined;
  @Input() window?: Window;

  loading: boolean = false;
  activeTab: string = 'overview';

  relatedArticles: ApiArticle[] = [];
  relatedArticleCols: { field: string, header: string }[] = [];

  formHandler: FormHandler;

  articlePrices: ApiArticlePrice[] = [];

  imagePaths: UploadFilePath[] = [];
  imageAdditionalInputFields: UploadAdditionalInputFields[]  = [{type: "select", name: 'type', value:'', options: [{name: 'Gebinde wählen', value: ''}, {value: 'Flasche'}, { value: 'Kiste'}, {value: 'Sonstiges'}]}];

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  changeDetectionGroup: string = 'articles';

  isFormDisabled: boolean = false;


  form = new FormGroup({
    // overview
    id: this.fb.control({value: '', disabled: this.isFormDisabled}),
    name: this.fb.control({value: '', disabled: this.isFormDisabled}, Validators.required),
    articleNumber: this.fb.control({value: '', disabled: this.isFormDisabled}, Validators.required),
    groups: this.fb.array([], Validators.required),
    description: this.fb.control({value: '', disabled: this.isFormDisabled}),
    quantity: this.fb.control({value: '', disabled: this.isFormDisabled}, Validators.required),
    quantityAddition: this.fb.control({value: '', disabled: this.isFormDisabled}),
    unit: this.fb.control({value: '', disabled: this.isFormDisabled}, Validators.required),
    unitAddition: this.fb.control({value: '', disabled: this.isFormDisabled}),
    unitOfMeasurement: this.fb.control({value: '', disabled: this.isFormDisabled}),

    totalWeightInKg: this.fb.control({value: '', disabled: this.isFormDisabled}),
    packageType: this.fb.control({value: '', disabled: this.isFormDisabled}),
    depositPackageType: this.fb.control({value: '', disabled: this.isFormDisabled}),

    // images
    images: this.fb.control([]),

    // details
    notifications: this.fb.array([]),
    visibilityGroups: this.fb.array([]),
    availability: this.fb.control(''),
    inventory: this.fb.control({value: 0, disabled: this.isFormDisabled}),
    eanBottle: this.fb.control({value: '', disabled: this.isFormDisabled}),
    eanPackage: this.fb.control({value: '', disabled: this.isFormDisabled}),

    additionalData: this.fb.control({value: '', disabled: this.isFormDisabled}),

    manufacturer: this.fb.control({value: '', disabled: this.isFormDisabled}),
    manufacturerCompanyAddress: this.fb.control({value: '', disabled: this.isFormDisabled}),
    manufacturingLocation: this.fb.control({value: '', disabled: this.isFormDisabled}),
    manufacturerInternetAddress: this.fb.control({value: '', disabled: this.isFormDisabled}),
    brand: this.fb.control({value: '', disabled: this.isFormDisabled}),
    country: this.fb.control({value: '', disabled: this.isFormDisabled}),
    region: this.fb.control({value: '', disabled: this.isFormDisabled}),
    category: this.fb.control({value: '', disabled: this.isFormDisabled}),
    certificates: this.fb.control({value: '', disabled: this.isFormDisabled}),

    createVoucherWhenOrdering: this.fb.control(false),

    recommendation: this.fb.control({value: '', disabled: this.isFormDisabled}),
    recommendationFilter: this.fb.control({value: '', disabled: this.isFormDisabled}),
    characteristics: this.fb.control({value: '', disabled: this.isFormDisabled}),
    flavorDescriptionShort: this.fb.control({value: '', disabled: this.isFormDisabled}),
    flavorDescriptionLong: this.fb.control({value: '', disabled: this.isFormDisabled}),
    flavorDescriptionLong2: this.fb.control({value: '', disabled: this.isFormDisabled}),
    conclusion: this.fb.control({value: '', disabled: this.isFormDisabled}),

    salesDescription: this.fb.control({value: '', disabled: this.isFormDisabled}),
    distributor: this.fb.control({value: '', disabled: this.isFormDisabled}),
    allergenIngredients: this.fb.array([]),
    additives: this.fb.array([]),
    foodDirectory: this.fb.control({value: '', disabled: this.isFormDisabled}),
    shelfLife: this.fb.control({value: '', disabled: this.isFormDisabled}),
    minimumDurability: this.fb.control({value: '', disabled: this.isFormDisabled}),
    wineType: this.fb.control({value: '', disabled: this.isFormDisabled}),
    wineTypeFilter: this.fb.control({value: '', disabled: this.isFormDisabled}),
    winery: this.fb.control({value: '', disabled: this.isFormDisabled}),
    growerInfo: this.fb.control({value: '', disabled: this.isFormDisabled}),
    alcoholicStrength: this.fb.control({value: '', disabled: this.isFormDisabled}),
    caffeine: this.fb.control({value: '', disabled: this.isFormDisabled}),
    energyInKilojoule: this.fb.control({value: '', disabled: this.isFormDisabled}),
    energyInKilokalorie: this.fb.control({value: '', disabled: this.isFormDisabled}),
    protein: this.fb.control({value: '', disabled: this.isFormDisabled}),
    carbohydrates: this.fb.control({value: '', disabled: this.isFormDisabled}),
    fatRate: this.fb.control({value: '', disabled: this.isFormDisabled}),
    saturatedFattyAcids: this.fb.control({value: '', disabled: this.isFormDisabled}),
    dietaryFibers: this.fb.control({value: '', disabled: this.isFormDisabled}),
    natrium: this.fb.control({value: '', disabled: this.isFormDisabled}),
    salt: this.fb.control({value: '', disabled: this.isFormDisabled}),
    sugar: this.fb.control({value: '', disabled: this.isFormDisabled}),
    soilType: this.fb.control({value: '', disabled: this.isFormDisabled}),
    residualSugar: this.fb.control({value: '', disabled: this.isFormDisabled}),
    acid: this.fb.control({value: '', disabled: this.isFormDisabled}),
    variety: this.fb.control({value: '', disabled: this.isFormDisabled}),
    flavor: this.fb.control({value: '', disabled: this.isFormDisabled}),
    quality: this.fb.control({value: '', disabled: this.isFormDisabled}),
    colorNuance: this.fb.control({value: '', disabled: this.isFormDisabled}),
    drinkingTemperature: this.fb.control({value: '', disabled: this.isFormDisabled}),
    scent: this.fb.control({value: '', disabled: this.isFormDisabled}),
    vinification: this.fb.control({value: '', disabled: this.isFormDisabled}),

    taxRate: this.fb.control({value: 19, disabled: this.isFormDisabled}),


    considerQuantityInCart: this.fb.control({value: false, disabled: this.isFormDisabled}),

    SEO: this.fb.group({
      title: this.fb.control(''),
      description: this.fb.control(''),
      keywords: this.fb.control('')
    }),

    relatedArticleGroup: this.fb.control({value: '', disabled: this.isFormDisabled}),


  });

  formDynamicFieldsGroup: FormDynamicArrayFormGroup = new FormDynamicArrayFormGroup(this.form.get('groups'), ['groupName', 'group1Name'], {groupName: Validators.required});
  formNotifications: FormDynamicArrayFormGroup = new FormDynamicArrayFormGroup(this.form.get('notifications'), ['category', 'validFrom', 'validTo'], {
    category: Validators.required,
    validFrom: Validators.required,
    validTo: Validators.required
  });
  formDynamicFieldsVisibilityGroups: FormDynamicArray = new FormDynamicArray(this.form.get('visibilityGroups'), Validators.required);
  formDynamicFieldsAllergenIngredients: FormDynamicArray = new FormDynamicArray(this.form.get('allergenIngredients'), Validators.required);
  formDynamicFieldsAdditives: FormDynamicArray = new FormDynamicArray(this.form.get('additives'), Validators.required);

  autocompletes: { id: string, autocomplete: Autocomplete }[] = [];

  notifications: {
    category: string;
    from: string;
    to: string;
  }[] = [];

  groups: {
    groupName: string;
    group1Name?: string;
  }[] = [];

  visibilityGroups: string[] = [];
  allergenIngredients: string[] = [];
  additives: string[] = [];

  errors = null;
  msg = '';
  data: any;


  constructor(
    private fb: UntypedFormBuilder,
    private articleService: ArticleService,
    private articleRecommendationService: ArticleRecommendationService,
    private store: Store<AppState>
  ) {
    this.formHandler = new FormHandler(this.store);
  }

  ngOnInit() {
    if (this.window?.data?.itemId) {
      this.form.get('articleNumber')?.disable();
      this.loadItem(this.window.data.itemId, this.window.data.articleNumber);
    }

  }

  getFormControl(control?: any): FormControl {
    // Before passing to child component, make sure this control exists and is an instance of FormControl
    if (control instanceof FormControl) {
      return control as FormControl;
    } else {
      // return null or a new FormControl instance
      // based on the requirement of <shared-form-autocomplete>
      return new FormControl('');
    }
  }

  addPrice(){
    let copy = [...this.articlePrices];
    copy.unshift({priceGroup: "1", netPrice: 0, netDeposit: 0, netFee: 0, fromArticleQuantity: 1, fromCartQuantity: 1});
    this.articlePrices = copy;
  }

  deletePrice(index: number){
    let copy = [...this.articlePrices];
    copy.splice(index, 1);
    this.articlePrices = copy;
  }

  getAutocomplete(id: string, type: 'groups' | 'groups1' | 'articleRecommandations' | 'relatedArticleGroup'): Autocomplete {
    let params: {
      label: string,
      placeholder: string,
      loadItems: (searchString: string) => void,
      sortable?: boolean,
      editable?: boolean,
      maxSelectedItems?: number,
      startSearchAfterTypedCharacters?: number,
      limit?: number
    };
    switch (type) {
      case 'groups':
        params = {
          label: '',
          placeholder: 'Gruppe',
          startSearchAfterTypedCharacters: 0,
          loadItems: (searchString) => this.loadArticleGroups(searchString, this.getAutocomplete(id, type))
        }
        break;

      case 'groups1':
        params = {
          label: '',
          placeholder: 'Gruppe 1',
          startSearchAfterTypedCharacters: 0,
          loadItems: (searchString) => this.loadArticleGroups1(searchString, this.getAutocomplete(id, type))
        }
        break;

      case "articleRecommandations":
        params = {
          label: 'Artikelempfehlung hinzufügen',
          placeholder: 'Artikel suchen...',
          startSearchAfterTypedCharacters: 2,
          loadItems: (searchString) => this.loadArticles(searchString, this.getAutocomplete(id, type))
        }
        break;

      case "relatedArticleGroup":
        params = {
          label: '',
          placeholder: 'Artikelgruppierung',
          startSearchAfterTypedCharacters: 0,
          loadItems: (searchString) => this.loadRelatedArticleGroups(searchString, this.getAutocomplete(id, type))
        }
        break;


    }
    let autocompleteFilter = this.autocompletes.filter(a => a.id == id)[0];
    let autocomplete: Autocomplete;
    if (autocompleteFilter) {
      autocomplete = autocompleteFilter.autocomplete;
    } else {
      autocomplete = new Autocomplete(params);
      this.autocompletes.push({id, autocomplete})
    }
    return autocomplete;
  }

  isFormKeyInvalid(key: string, displayIfSubmitForm: boolean = true): boolean {
    let form = this.form?.get(key);
    return !!(form?.invalid &&
      (
        this.formHandler.getFormStatus() == 'submitForm' &&
        displayIfSubmitForm
      )
    );
  }

  loadItem(id: string, articleNumber: string) {
    this.loading = true;
    this.form.get('groups')?.reset();

    forkJoin([
      this.articleService.getArticles({filters: [{fields: ['id'], values: [id]}], articles: {}}),
      this.articleService.getArticlePrices(articleNumber),
      this.articleRecommendationService.getArticleRecommendations(articleNumber)
    ])
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(async r => {
        if (r[0].result?.articles &&
          r[0].result?.articles.data[0]) {
          let data = r[0].result?.articles.data[0];
          this.form.get('name')?.setValue(data.name);
          this.form.get('articleNumber')?.setValue(data.articleNumber);
          this.form.get('description')?.setValue(data.description);
          this.form.get('quantity')?.setValue(data.quantity);
          this.form.get('quantityAddition')?.setValue(data.quantityAddition);
          this.form.get('unit')?.setValue(data.unit);
          this.form.get('unitOfMeasurement')?.setValue(data.unitOfMeasurement);
          this.form.get('unitAddition')?.setValue(data.unitAddition);
          this.form.get('totalWeightInKg')?.setValue(data.totalWeightInKg);
          this.form.get('packageType')?.setValue(data.packageType);
          this.form.get('depositPackageType')?.setValue(data.depositPackageType);

          this.form.get('availability')?.setValue(data.availability);
          this.form.get('inventory')?.setValue(data.inventory);
          this.form.get('eanBottle')?.setValue(data.eanBottle);
          this.form.get('eanPackage')?.setValue(data.eanPackage);
          this.form.get('additionalData')?.setValue(data.additionalData);
          this.form.get('manufacturer')?.setValue(data.manufacturer);
          this.form.get('manufacturerCompanyAddress')?.setValue(data.manufacturerCompanyAddress);
          this.form.get('manufacturingLocation')?.setValue(data.manufacturingLocation);
          this.form.get('manufacturerInternetAddress')?.setValue(data.manufacturerInternetAddress);
          this.form.get('brand')?.setValue(data.brand);
          this.form.get('country')?.setValue(data.country);
          this.form.get('region')?.setValue(data.region);
          this.form.get('category')?.setValue(data.category);
          this.form.get('certificates')?.setValue(data.certificates);
          this.form.get('createVoucherWhenOrdering')?.setValue(data.createVoucherWhenOrdering);
          this.form.get('recommendation')?.setValue(data.recommendation);
          this.form.get('recommendationFilter')?.setValue(data.recommendationFilter);
          this.form.get('characteristics')?.setValue(data.characteristics);
          this.form.get('flavorDescriptionShort')?.setValue(data.flavorDescriptionShort);
          this.form.get('flavorDescriptionLong')?.setValue(data.flavorDescriptionLong);
          this.form.get('flavorDescriptionLong2')?.setValue(data.flavorDescriptionLong2);
          this.form.get('conclusion')?.setValue(data.conclusion);
          this.form.get('salesDescription')?.setValue(data.salesDescription);
          this.form.get('distributor')?.setValue(data.distributor);
          this.form.get('foodDirectory')?.setValue(data.foodDirectory);
          this.form.get('shelfLife')?.setValue(data.shelfLife);
          this.form.get('wineType')?.setValue(data.wineType);
          this.form.get('wineTypeFilter')?.setValue(data.wineTypeFilter);
          this.form.get('winery')?.setValue(data.winery);
          this.form.get('growerInfo')?.setValue(data.growerInfo);
          this.form.get('alcoholicStrength')?.setValue(data.alcoholicStrength);
          this.form.get('caffeine')?.setValue(data.caffeine);
          this.form.get('energyInKilojoule')?.setValue(data.energyInKilojoule);
          this.form.get('energyInKilokalorie')?.setValue(data.energyInKilokalorie);
          this.form.get('protein')?.setValue(data.protein);
          this.form.get('carbohydrates')?.setValue(data.carbohydrates);
          this.form.get('fatRate')?.setValue(data.fatRate);
          this.form.get('saturatedFattyAcids')?.setValue(data.saturatedFattyAcids);
          this.form.get('dietaryFibers')?.setValue(data.dietaryFibers);
          this.form.get('natrium')?.setValue(data.natrium);
          this.form.get('salt')?.setValue(data.salt);
          this.form.get('sugar')?.setValue(data.sugar);
          this.form.get('soilType')?.setValue(data.soilType);
          this.form.get('residualSugar')?.setValue(data.residualSugar);
          this.form.get('acid')?.setValue(data.acid);
          this.form.get('variety')?.setValue(data.variety);
          this.form.get('flavor')?.setValue(data.flavor);
          this.form.get('quality')?.setValue(data.quality);
          this.form.get('colorNuance')?.setValue(data.colorNuance);
          this.form.get('drinkingTemperature')?.setValue(data.drinkingTemperature);
          this.form.get('scent')?.setValue(data.scent);
          this.form.get('vinification')?.setValue(data.vinification);

          this.form.get('taxRate')?.setValue(data.taxRate);
          this.form.get('considerQuantityInCart')?.setValue(data.considerQuantityInCart);

          this.form.get('relatedArticleGroup')?.setValue(data.relatedArticleGroup);

          this.form.get('SEO')?.get('title')?.setValue(data.SEO?.title);
          this.form.get('SEO')?.get('description')?.setValue(data.SEO?.description);
          this.form.get('SEO')?.get('keywords')?.setValue(data.SEO?.keywords);

          if (r[0].result?.articles.data[0].groups) {
            r[0].result?.articles.data[0].groups.map(
              x => {
                this.formDynamicFieldsGroup.add({groupName: x.groupName, group1Name: x.group1Name ?? ''});
              }
            )
          }
          r[0].result?.articles.data[0].notifications?.map(
            x => {
              this.formNotifications.add(x);
            }
          )
          r[0].result?.articles.data[0].visibilityGroups?.map(
            x => {
              this.formDynamicFieldsVisibilityGroups.add(x);
            }
          )
          r[0].result?.articles.data[0].allergenIngredients?.map(
            x => {
              this.formDynamicFieldsAllergenIngredients.add(x);
            }
          )
          r[0].result?.articles.data[0].additives?.map(
            x => {
              this.formDynamicFieldsAdditives.add(x);
            }
          )
          this.imagePaths = [];
          if (r[0].result?.articles.data[0].images) {
            r[0].result?.articles.data[0].images.map(
              i => {
                this.imagePaths.push({
                  filePath: i.filePath,
                  thumbnails: i.thumbnails,
                  additionalInputFields: {
                    'type': i.type ?? ''
                  }
                })
              }
            );
          }
          if (r[1].result?.articlePrices) {
            this.articlePrices = r[1].result?.articlePrices.data.map(item => {
              const { id, articleNumber, dateCreated, ...rest } = item;
              return rest as unknown as ApiArticlePrice;
            });
          }
          if (r[2].result?.articleRecommendations.data[0]) {
            await this.resolveArticleRecommendation(r[2].result?.articleRecommendations.data[0].recommendations)
          }
          await this.loadRelatedArticles(r[0].result?.articles.data[0].relatedArticleGroup ?? '');
        }
      });
  }

  async resolveArticleRecommendation(articleNumbers: string[]) {
    const articles = await this.articleService.resolveArticleNumbersToNames(articleNumbers);
    articles.map((r: { articleNumber: string, name: string }) => {
      this.getAutocomplete('articleRecommandations', 'articleRecommandations').selectItem({
        name: r.name,
        value: r.articleNumber
      })
    });
  }

  async loadRelatedArticles(relatedArticleGroup: string) {
    this.relatedArticleCols = [];
    this.relatedArticles = [];
    this.articleService.getArticles({
      filters: [{fields: ['relatedArticleGroup'], values: [relatedArticleGroup]}],
      articles: {
        sort: [{field: 'relatedArticleGroupPos', direction: 1}],
        expectedData: {
          relatedArticleGroupPos: 1,
          articleNumber: 1,
          name: 1,
          quantity: 1,
          quantityAddition: 1,
          unit: 1,
          unitAddition: 1
        }
      }
    }).subscribe(
      r => {
        this.relatedArticleCols = [];
        this.relatedArticles = [];
        if (r.result?.articles) {
          this.relatedArticleCols = [
            {
              header: 'Pos',
              field: 'relatedArticleGroupPos'
            },
            {
              header: 'Name',
              field: 'name'
            },
            {
              header: 'Artikel-Nr.',
              field: 'articleNumber'
            },
            {
              header: 'Anzahl',
              field: 'quantity'
            },
            {
              header: 'Anzahl Zusatz',
              field: 'quantityAddition'
            },
            {
              header: 'Gebinde',
              field: 'unit'
            },
            {
              header: 'Gebinde-Zusatz',
              field: 'unitAddition'
            }
          ];
          this.relatedArticles = r.result.articles.data;
        }


      }
    )
  }

  loadArticles(searchString: string, autocomplete: Autocomplete) {
    autocomplete.loading = true;
    this.articleService.getArticles({
      filters: [{values: ['.*' + searchString + '.*'], useRegex: 1}],
      articles: {sort: [{field: 'name', direction: 1}]}
    })
      .pipe(
        finalize(() => {
          autocomplete.loading = false;
        })
      )
      .subscribe(
        r => {
          let items: AutocompleteItem[] = [];
          if (r.result?.articles?.data) {
            r.result?.articles.data.map(
              item => {
                items.push({value: item.articleNumber, name: item.name})
              }
            )
          }
          autocomplete.setItems(items);
        }
      )
  }

  loadArticleGroups(searchString: string, autocomplete: Autocomplete) {
    autocomplete.loading = true;
    this.articleService.getArticles({
      filters: [{fields: ['groups.groupName'], values: ['.*' + searchString + '.*'], useRegex: 1}],
      groups: {}
    })
      .pipe(
        finalize(() => {
          autocomplete.loading = false;
        })
      )
      .subscribe(
        r => {
          let items: AutocompleteItem[] = [];
          if (r.result?.groups?.data) {
            r.result?.groups?.data?.map(
              item => {
                items.push({value: item.value, name: item.value})
              }
            )
          }
          autocomplete.setItems(items);
        }
      )
  }

  loadArticleGroups1(searchString: string, autocomplete: Autocomplete) {
    autocomplete.loading = true;
    this.articleService.getArticles({
      filters: [{
        fields: ['groups.group1Name'],
        values: ['.*' + searchString + '.*'],
        useRegex: 1
      }],

      groups1: {}
    })
      .pipe(
        finalize(() => {
          autocomplete.loading = false;
        })
      )
      .subscribe(
        r => {
          let items: AutocompleteItem[] = [];
          if (r.result?.groups1?.data) {
            r.result?.groups1?.data?.map(
              item => {
                items.push({value: item.value, name: item.value})
              }
            )
          }
          autocomplete.setItems(items);
        }
      )
  }

  loadRelatedArticleGroups(searchString: string, autocomplete: Autocomplete) {
    autocomplete.loading = true;
    this.articleService.getArticles({
      relatedArticleGroups: {
        searchString: '.*' + searchString + '.*'
      }
    })
      .pipe(
        finalize(() => {
          autocomplete.loading = false;
        })
      )
      .subscribe(
        r => {
          let items: AutocompleteItem[] = [];
          if (r.result?.relatedArticleGroups?.data) {
            r.result?.relatedArticleGroups?.data?.map(
              item => {
                items.push({value: item.value, name: item.value})
              }
            )
          }
          autocomplete.setItems(items);
        }
      )
  }

  editArticle(item: AutocompleteItem) {
    this.store.dispatch(setWindow({
      id: 'article' + item.value,
      title: 'Artikel: ' + item.name,
      component: 'shared-article-form',
      data: {itemId: item.value}
    }))
  }



  changedImages(images: any) {
    this.imagePaths = images;
  }

  submit() {
    if (this.form.invalid) {
      this.formHandler.setFormStatus({formStatus: 'submitForm'});
      return;
    }
    if (this.formHandler.getFormStatus() == 'loading') {
      return;
    }
    if(this.articlePrices.length == 0){
      return;
    }
    this.formHandler.setFormStatus({formStatus: 'loading'});
    let getData = (): ApiArticlesSetRequest['params']['data'] => {
      let data: ApiArticlesSetRequest['params']['data'] =  {
        name: this.form.get('name')?.value,
        articleNumber: this.form.get('articleNumber')?.value,
        groups: this.form.get('groups')?.value ?? [],
        description: this.form.get('description')?.value,
        quantity: parseFloat(this.form.get('quantity')?.value ?? ''),
        quantityAddition: this.form.get('quantityAddition')?.value,
        unit: parseFloat(this.form.get('unit')?.value ?? ''),
        unitAddition: this.form.get('unitAddition')?.value,
        unitOfMeasurement: this.form.get('unitOfMeasurement')?.value,


        packageType: this.form.get('packageType')?.value,
        depositPackageType: this.form.get('depositPackageType')?.value,

        taxRate: parseFloat(this.form.get('taxRate')?.value ?? ''),
        considerQuantityInCart: this.form.get('considerQuantityInCart')?.value,
        prices: this.articlePrices,

        notifications: this.form.get('notifications')?.value,
        visibilityGroups: this.form.get('visibilityGroups')?.value,
        availability: this.form.get('availability')?.value,
        eanBottle: this.form.get('eanBottle')?.value,
        eanPackage: this.form.get('eanPackage')?.value,

        additionalData: this.form.get('additionalData')?.value,

        manufacturer: this.form.get('manufacturer')?.value,
        manufacturerCompanyAddress: this.form.get('manufacturerCompanyAddress')?.value,
        manufacturingLocation: this.form.get('manufacturingLocation')?.value,
        manufacturerInternetAddress: this.form.get('manufacturerInternetAddress')?.value,
        brand: this.form.get('brand')?.value,
        country: this.form.get('country')?.value,
        region: this.form.get('region')?.value,
        category: this.form.get('category')?.value,
        certificates: this.form.get('certificates')?.value,

        createVoucherWhenOrdering: this.form.get('createVoucherWhenOrdering')?.value,

        recommendation: this.form.get('recommendation')?.value,
        recommendationFilter: this.form.get('recommendationFilter')?.value,
        characteristics: this.form.get('characteristics')?.value,
        flavorDescriptionShort: this.form.get('flavorDescriptionShort')?.value,
        flavorDescriptionLong: this.form.get('flavorDescriptionLong')?.value,
        flavorDescriptionLong2: this.form.get('flavorDescriptionLong2')?.value,
        conclusion: this.form.get('conclusion')?.value,

        salesDescription: this.form.get('salesDescription')?.value,
        distributor: this.form.get('distributor')?.value,
        allergenIngredients: this.form.get('allergenIngredients')?.value,
        additives: this.form.get('additives')?.value,
        foodDirectory: this.form.get('foodDirectory')?.value,
        shelfLife: this.form.get('shelfLife')?.value,
        minimumDurability: this.form.get('minimumDurability')?.value,
        wineType: this.form.get('wineType')?.value,
        wineTypeFilter: this.form.get('wineTypeFilter')?.value,
        winery: this.form.get('winery')?.value,
        growerInfo: this.form.get('growerInfo')?.value,
        alcoholicStrength: this.form.get('alcoholicStrength')?.value,
        caffeine: this.form.get('caffeine')?.value,
        energyInKilojoule: this.form.get('energyInKilojoule')?.value,
        energyInKilokalorie: this.form.get('energyInKilokalorie')?.value,
        protein: this.form.get('protein')?.value,
        carbohydrates: this.form.get('carbohydrates')?.value,
        fatRate: this.form.get('fatRate')?.value,
        saturatedFattyAcids: this.form.get('fatRate')?.value,
        dietaryFibers: this.form.get('dietaryFibers')?.value,
        natrium: this.form.get('natrium')?.value,
        salt: this.form.get('salt')?.value,
        sugar: this.form.get('sugar')?.value,
        soilType: this.form.get('soilType')?.value,
        residualSugar: this.form.get('residualSugar')?.value,
        acid: this.form.get('acid')?.value,
        variety: this.form.get('variety')?.value,
        flavor: this.form.get('flavor')?.value,
        quality: this.form.get('quality')?.value,
        colorNuance: this.form.get('colorNuance')?.value,
        drinkingTemperature: this.form.get('drinkingTemperature')?.value,
        scent: this.form.get('scent')?.value,
        vinification: this.form.get('vinification')?.value,

        relatedArticleGroup: this.form.get('relatedArticleGroup')?.value ?? '',

        priceChanges: []

      };

      if((this.form.get('totalWeightInKg')?.value?.length ?? 0) > 0){
        data.totalWeightInKg = parseFloat(this.form.get('totalWeightInKg')?.value ?? '');
      }
      data.images = [];
      this.imagePaths.map(
        i => {

          if(i.thumbnails &&
            data.images){
            data.images.push({
              filePath: i.filePath,
              type: i.additionalInputFields?.type ?? '',
              thumbnails: i.thumbnails
            })
          }
        }
      )

      if((this.form.get('inventory')?.value?.length ?? 0) > 0){
        data.inventory = parseFloat(this.form.get('inventory')?.value ?? '');
      }

      if((this.form.get('SEO')?.get('title')?.value?.length ?? 0) > 0){
        data.SEO = {
          title:  this.form.get('SEO')?.get('title')?.value ?? '',
          description:  this.form.get('SEO')?.get('description')?.value ?? '',
          keywords:  this.form.get('SEO')?.get('keywords')?.value ?? '',
        };
      }
      return data;
    };

    // Update
    if (this.window?.data &&
      this.window.data.itemId) {
      let unset: any = [];
      let data: ApiArticlesUpdateRequest['params']['data'] = {
        ...getData(),
        id: this.window.data.itemId,
      };
      this.articleService.update(data, unset)
        .subscribe(
          {
            next: (r) => {
              if (r.error) {
                this.formHandler.setFormError(r.error);
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'error',
                    resetStatusAfterTimeoutTo: null
                  }
                );
              } else {
                this.updateRelatedArticles();
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'success',
                    resetStatusAfterTimeoutTo: null,
                    closeWindowId: this.window?.id,
                    callbackAfterTimeout: () => {
                      this.formHandler.setItemUpdate(data.id ?? '', this.changeDetectionGroup)
                    }
                  }
                )
              }
            },
            error: (error) => {
              this.formHandler.setFormError({code: 'SUBSCRIPTION_ERROR', message: JSON.stringify(error)});
              this.formHandler.setFormStatus(
                {
                  formStatus: 'error',
                  resetStatusAfterTimeoutTo: null
                }
              );
            }
          }
        );
    // Insert
    } else {
      let data: ApiArticlesSetRequest['params']['data'] = {
        ...getData()
      };
      this.articleService.set(data)
        .subscribe(
          {
            next: (r) => {
              if (r.error) {
                this.formHandler.setFormError(r.error);
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'error',
                    resetStatusAfterTimeoutTo: null
                  }
                );
              } else {
                this.updateRelatedArticles();
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'success',
                    resetStatusAfterTimeoutTo: null,
                    closeWindowId: this.window?.id,
                    callbackAfterTimeout: () => {
                      this.formHandler.setItemUpdate(r.result?.articles.data.id ?? '', this.changeDetectionGroup)
                    }
                  }
                )
              }
            },
            error: (error) => {
              this.formHandler.setFormError({code: 'SUBSCRIPTION_ERROR', message: JSON.stringify(error)});
              this.formHandler.setFormStatus(
                {
                  formStatus: 'error',
                  resetStatusAfterTimeoutTo: null
                }
              );
            }
          }
        );
    }

  }

  updateRelatedArticles(){
    this.articleRecommendationService.deleteArticleRecommendations([this.form.get('articleNumber')?.value]).subscribe(
      r => {
        if (this.getAutocomplete('articleRecommandations', 'articleRecommandations').getSelectedItems().length > 0) {
          this.articleRecommendationService.setArticleRecommendations([{
            articleNumber: this.form.get('articleNumber')?.value,
            recommendations: this.getAutocomplete('articleRecommandations', 'articleRecommandations').getSelectedItems().map(r => {
              return r.value
            })
          }]).subscribe()
        }
      }
    );

    if (this.relatedArticles.length > 0) {
      let batch: Omit<ApiArticlesUpdateRequest, 'command'>[] = [];
      this.relatedArticles.map((r, i) => {
        batch.push({id: 'a' + i, params: {data: {articleNumber: r.articleNumber, relatedArticleGroupPos: i}}})
      })
      this.articleService.updateBatch(batch).subscribe()
    }
  }

  protected readonly faTrashCan = faTrashCan;
  protected readonly faPlusSquare = faPlusSquare;
  protected readonly FormControl = FormControl;
  protected readonly faExclamationCircle = faExclamationCircle;
}
