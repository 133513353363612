import {Component, Input, OnInit} from '@angular/core';
import {List} from '@shared/list/models/list';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {setListConfig} from '@store/actions/storage.action';
import {
  faCheckSquare,
  faSquare
} from "@fortawesome/free-regular-svg-icons";
import {
  faAngleDown,
  faAngleUp,
  faCogs,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: 'app-list-button-edit-table',
  templateUrl: './list-button-edit-table.component.html',
  styleUrls: ['./list-button-edit-table.component.scss']
})
export class ListButtonEditTableComponent implements OnInit {

  @Input() list!: List<any, any, any>;


  dropDownVisibility: boolean = false;
  columns: {id: string, field: string, label: string, isHidden?: boolean}[] = [];

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.list.getColumns('all').map(r => {
      this.columns.push({id: r.id, field: r.field + (r.readKeyInList || ''), label: r.label, isHidden: true});
    });
    this.list.getColumns('userStorage').map(
      c => {
        this.columns.filter(cf => cf.id == c.id).map(cm => {
          cm.isHidden = false;
        } )
      }
    );
  }

  setDefaults(){
    this.list.getColumns('all').map(
      c => {
        this.columns.filter(cf => c.id == cf.id).map(cm => {
          cm.isHidden = c.isHidden;
        } )
      }
    );
  }

  toggleColumnsVisibility(isHidden: boolean){
    this.columns.map(f => f.isHidden = isHidden);
  }

  toggleColumnVisibility(id: string) {
    this.columns.filter(f => f.id == id)[0].isHidden = !this.columns.filter(f => f.id == id)[0].isHidden;
  }

  dropDownToggle(){
    this.dropDownVisibility = !this.dropDownVisibility;
  }

  save() {
    let visibleColumnFields: string[] = [];
    this.columns.map(r => {
      if(!r.isHidden){
        visibleColumnFields.push(r.field);
      }
    })
    this.store.dispatch(setListConfig({id: this.list.id, visibleColumnFields}))
    this.list.reload();
  }

  protected readonly faCogs = faCogs;
  protected readonly faAngleUp = faAngleUp;
  protected readonly faAngleDown = faAngleDown;
  protected readonly faCheckSquare = faCheckSquare;
  protected readonly faSquare = faSquare;
  protected readonly faRotateRight = faRotateRight;
}
