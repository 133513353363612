import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {OrdersService} from '@shared/orders/services/orders.service';
import {finalize, map, switchMap} from 'rxjs/operators';
import {EMPTY, of, zip} from 'rxjs';
import {ArticleRecommendationService} from '@shared//article/services/article-recommendation.service';
import {ApiOrder, ApiOrdersGetRequest} from '@shared/api/orders';
import {FormStatusDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {DatePipe} from '@angular/common';

interface ArticleAlsoBought {
  articleNumber: string,
  alsoBought: { articleNumber: string, amount: number }[]
}

interface ArticleRecommendation {
  articleNumber: string,
  recommendations: string[]
}

@Component({
  selector: 'app-article-recommendation-script',
  templateUrl: './article-recommendation-script.component.html',
  styleUrls: ['./article-recommendation-script.component.scss']
})
export class ArticleRecommendationScriptComponent implements OnInit {

  formStatus: FormStatusDeprecated = null;
  loadingMessage: string = '';

  form = new UntypedFormGroup({
    from: new UntypedFormControl(null),
    to: new UntypedFormControl(null)
  });

  constructor(private orderService: OrdersService, private articleRecommendationService: ArticleRecommendationService) {
  }

  ngOnInit(): void {
  }

  getArticleRecommendationsFromOrders(data: ApiOrder[]): ArticleRecommendation[] {
    let articlesAlsoBought: ArticleAlsoBought[] = [];
    let articleR: { articleNumber: string, alsoBought: { articleNumber: string, amount: number }[] };
    data.map(order => {
      order.items?.articles?.map(article => {
        articleR = articlesAlsoBought.filter(articleRecommendation => articleRecommendation.articleNumber == article.articleNumber)[0];
        // Artikel existiert noch nicht im Array
        if (articleR === undefined) {
          articlesAlsoBought.push({
            articleNumber: article.articleNumber,
            alsoBought: order.items.articles.filter(x => x.articleNumber !== article.articleNumber).map(a => {
              return {articleNumber: a.articleNumber, amount: 1}
            }) || []
          })

        } else {
          let alsoBought: { articleNumber: string, amount: number };
          order.items.articles.filter(x => x.articleNumber !== article.articleNumber).map(a => {
            alsoBought = articleR.alsoBought.filter(ab => ab.articleNumber == a.articleNumber)[0];
            if (alsoBought) {
              articleR.alsoBought.filter(ab => ab.articleNumber == a.articleNumber).map(ab => {
                ab.amount++;
              })
            } else {
              articleR.alsoBought.push({articleNumber: article.articleNumber, amount: 1});
            }
          })
        }
      })
    });
    articlesAlsoBought.map(ar => {
      ar.alsoBought.sort((a, b) => b.amount - a.amount)
    });
    let articleRecommendations: ArticleRecommendation[] = [];
    articlesAlsoBought.map(
      article => {
        articleRecommendations.push({
          articleNumber: article.articleNumber,
          recommendations: article.alsoBought.filter(a => a.amount >= 2).map(x => {
            return x.articleNumber
          })
        });
      }
    );
    return articleRecommendations.filter(x => x.recommendations.length > 0);
  }

  setArticleRecommendations() {

    if (confirm("Alle aktuellen Artikelempfehlungen werden gelöscht und neu gesetzt. Sind Sie wirklich sicher?") == true) {
      let params: ApiOrdersGetRequest['params'] = {
        filters: [
          {fields: ['type'], values: ['order']},
          {
            fields: ['status'], values: ["open",
              "progressStarted",
              "inProgress",
              "inDelivery",
              "finished"
            ]
          },
        ],
        orders: {limit: 10000, expectedData: {items: 1}}
      };
      if (this.form.get('from')?.value || this.form.get('to')?.value) {
        let datePipe = new DatePipe('en-GB');
        let filterDate: { fields: ['dateCreated'], greaterThanEqual?: string, lessThanEqual?: string } = {fields: ['dateCreated']};

        if (this.form.get('from')?.value) {
          filterDate.greaterThanEqual = datePipe.transform(this.form.get('from')?.value, 'dd.MM.yyyy') + ' 00:00';
        }
        if (this.form.get('to')?.value) {
          filterDate.lessThanEqual = datePipe.transform(this.form.get('to')?.value, 'dd.MM.yyyy') + ' 23:59';
        }
        params.filterDates = [filterDate];

      }
      this.formStatus = 'loading';
      this.loadingMessage = 'Hole Bestelldaten...';


      this.orderService.get(params)
        .pipe(
          switchMap(o => {
            let articleRecommendations: ArticleRecommendation[] = [];
            this.loadingMessage = 'Verarbeite Bestelldaten...';
            if (o.result?.orders) {
              articleRecommendations = this.getArticleRecommendationsFromOrders(o.result.orders.data)
            }
            this.loadingMessage = 'Hole aktuelle Empfehlungen...';
            return zip(of(articleRecommendations), this.articleRecommendationService.getArticleRecommendations())
          }),
          switchMap(([o, aR]) => {
            let articleNumbers: string[] = [];
            aR.result?.articleRecommendations.data.map(data => {
              articleNumbers.push(data.articleNumber);
            });
            this.loadingMessage = 'Lösche aktuelle Empfehlungen...';
            if (articleNumbers.length > 0) {
              return zip(of(o), this.articleRecommendationService.deleteArticleRecommendations(articleNumbers));
            }
            return zip(of(o), of(null));
          }),
          switchMap(([o, d]) => {
            this.loadingMessage = 'Schreibe neue Empfehlungen...';
            if (o.length > 0) {
              return this.articleRecommendationService.setArticleRecommendations(o);
            }
            return EMPTY;
          }),
          finalize(() => {
            this.loadingMessage = '';
            setTimeout(() => {
              this.formStatus = null;
            }, 2000);
          })
        )
        .subscribe(s => {
          this.formStatus = 'success';
        })
    }


  }
}
