import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UploadFilePath} from "@shared/uploads/components/uploads/upload.component";
import {FormStatusDeprecated} from "@shared/forms/models/form-helper-deprecated";
import {ConfigService} from "@shared/config/services/config.service";
import {ApiConfigsUpdateRequest} from "@shared/api/configs";
import {faPlusSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {ApiError, FormHandler} from "@shared/forms/models/form-handler";
import {AppState} from "@store/state/app.state";
import {Store} from "@ngrx/store";
import {faBars, faCog, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {Autocomplete, AutocompleteItem} from "@shared/forms/models/autocomplete";
import {lastValueFrom, Subject} from "rxjs";
import {DynamicArticlesService} from "@shared/dynamic-articles/services/dynamic-articles.service";
import {setWindow} from "@store/actions/windows.action";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {map, takeUntil} from "rxjs/operators";

@Component({
  selector: 'shared-config-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit, OnChanges, OnDestroy{

  @Input() type: 'orderRequest' | 'order' = 'order';

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  loading: boolean = true;

  runChangeDetectionOnGroups: string[] = ['dynamicArticles'];
  changeDetection$ = this.store.select(state => state.changeDetection.items)
    .pipe(
      takeUntil(this.ngUnsubscribe),
      map(items => items?.filter(item => this.runChangeDetectionOnGroups.includes(item.group)))
    );

  form: FormGroup = this.fb.group(
    {
      id: this.fb.control(''),
      recipients: this.fb.array<string[]>([]),
      subjects: this.fb.array<{ subject: string, requiredDynamicArticleIds: [] }[]>([]),
      textFieldTop: this.fb.control(''),
      textFieldBottom: this.fb.control(''),
      visibilitySinglePrice: this.fb.control(true),
      visibilityUnitPrice: this.fb.control(false),
      attachments: this.fb.array<string[]>([])
    }
  );
  formHandler: FormHandler;

  attachments: UploadFilePath[] = [];

  orderEmailSettings: ApiConfigsUpdateRequest['params']['data']['orderEmailSettings'];

  visiblitySubjectDetails: number[] = [];

  autocompleteDynamicArticles: Autocomplete = new Autocomplete(
    {
      label: 'Betreff wird verwendet, wenn folgende dynamische Artikel enthalten sind',
      placeholder: 'Dynamische Artikel suchen...',
      loadItems: () => {
        this.loadDynamicArticles().then(() => {     });
      }
    }
  );

  constructor(private fb: FormBuilder, private configService: ConfigService, private store: Store<AppState>, private dynamicArticlesService: DynamicArticlesService,) {
    this.formHandler = new FormHandler(store);
    this.autocompleteDynamicArticles.sortable = false;

    this.changeDetection$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(r => {
        r?.map(async () => {
            await this.resolveDynamicArticleIds();
          }
        )
      });

  }

  ngOnInit(): void {
    this.loadSettings();
  }

  ngOnChanges() {
    this.loadSettings();
  }

  loadSettings() {
    setTimeout(() => {
      this.loading = true;
    }, 20)
    this.configService.getConfig().subscribe(
      {
        next: ( r) => {
          if (r.result) {
            const config = r.result.configs.data[0];
            this.form.get('id')?.setValue(config.id);
            this.orderEmailSettings = config.orderEmailSettings;
            config.orderEmailSettings?.filter(r => r.orderType == this.type).map(async r => {
              this.recipients.clear();
              r.recipients.map(
                recipient => {
                  this.recipients.push(this.fb.control(recipient.email, [Validators.required, Validators.email]));
                }
              );
              this.subjects.clear();
              if (r.subjects) {
                r.subjects.forEach(subject => {
                  this.addSubject(subject.subject, subject.requiredDynamicArticleIds);
                })
              }
              await this.resolveDynamicArticleIds();
              this.form.get('textFieldTop')?.setValue(r.textFieldTop ?? '');
              this.form.get('textFieldBottom')?.setValue(r.textFieldBottom ?? '');
              this.form.get('visibilitySinglePrice')?.setValue(r.visibilitySinglePrice ?? true);
              this.form.get('visibilityUnitPrice')?.setValue(r.visibilityUnitPrice ?? false);
              this.attachments = [];
              r.attachments.map(a => {
                this.attachments.push({filePath: a});
              });
            });
          }
        },
        complete: () => {
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      }
    );
  }

  changedAttachments(files: any) {
    this.attachments = files;
  }

  isFormRequestError(value: string): boolean {
    let form = this.form?.get(value);
    return !!(form?.errors &&
      this.formHandler.getFormStatus() == 'submitForm');
  }

  get recipients() {
    return this.form.get('recipients') as FormArray;
  }

  addRecipient() {
    this.recipients.push(this.fb.control('', [Validators.required, Validators.email]))
  }

  removeRecipient(i: number) {
    this.recipients.removeAt(i);
  }

  get subjects() {
    return this.form.get('subjects') as FormArray;
  }

  addSubject(subject?: string, requiredDynamicArticleIds?: string[]) {
    this.subjects.push(
      this.fb.group(
        {
          subject: this.fb.control(subject ?? '', [Validators.required]),
          requiredDynamicArticleIds: this.fb.array(requiredDynamicArticleIds?.map(id => this.fb.control(id)) ?? [])
        }
      )
    )
  }

  removeSubject(i: number) {
    this.subjects.removeAt(i);
  }

  toggleSubjectDetails(key: number) {
    if (this.visiblitySubjectDetails.filter(x => x == key).length > 0) {
      this.visiblitySubjectDetails = this.visiblitySubjectDetails.filter(x => x != key);
    } else {
      this.visiblitySubjectDetails.push(key);
    }
  }

  isSubjectDetails(key: number): boolean {
    if (this.subjects.at(key).get('requiredDynamicArticleIds')?.value.length > 0) {
      return true;
    }
    return false;
  }

  isSubjectDetailsVisibility(key: number): boolean {
    if (this.visiblitySubjectDetails.filter(x => x == key).length > 0) {
      return true;
    }
    return false;
  }

  async resolveDynamicArticleIds() {
    let ids: string[] = [];
    this.subjects.controls.map(
      subject => {
        ids = [...ids, ...subject.get('requiredDynamicArticleIds')?.value]
      }
    );
    const dynamicArticles = await this.dynamicArticlesService.resolveIdsToNames(ids);
    dynamicArticles.map((r: { id: string, name: string }) => {
        this.autocompleteDynamicArticles.selectItem({name: r.name, value: r.id})
    });
  }

  async loadDynamicArticles() {
    let r = await lastValueFrom(this.dynamicArticlesService.get({
      dynamicArticles: {sort: [{field: 'name', direction: 1}]}
    }));
    this.autocompleteDynamicArticles.loading = false;
    let items: AutocompleteItem[] = [];
    if (r.result?.dynamicArticles.data) {
      r.result?.dynamicArticles.data.map(
        item => {
          items.push({value: item.id, name: item.name})
        }
      )
    }
    this.autocompleteDynamicArticles.setItems(items);
  }

  editRequiredDynamicArticle(item: AutocompleteItem) {
    this.store.dispatch(setWindow({
      id: 'dynamicArticles' + item.value,
      title: 'Dynamischer Artikel: ' + item.name,
      component: 'shared-dynamic-article-form',
      data: {itemId: item.value}
    }))
  }

  setRequiredDynamicArticleIds(key:number, items: string[]) {
    //this.form.get('dynamicArticleIds')?.setValue(this.fb.control(''));
    const formArray = this.subjects.at(key).get('requiredDynamicArticleIds') as FormArray;
    formArray.clear();
    items.map((r: any) => {
      formArray.push(this.fb.control(r));
    })
  }

  dropRequiredDynamicArticle(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.subjects.controls, event.previousIndex, event.currentIndex);
  }

  submit() {
    if (this.form.invalid) {
      this.formHandler.setFormStatus({formStatus: 'submitForm'});
      return;
    }
    if (this.formHandler.getFormStatus() == 'loading') {
      return;
    }
    this.formHandler.setFormStatus({formStatus: 'loading'});
    this.orderEmailSettings?.filter(r => r.orderType == this.type).map(r => {
      r.recipients = [];
      this.recipients.controls.map(
        recipient => {
          r.recipients.push({email: recipient.value, type: 'dealer'});
        }
      );
      r.subjects = [];
      this.subjects.controls.map(
        subject => {
          r.subjects?.push({
            subject: subject.get('subject')?.value,
            requiredDynamicArticleIds: subject.get('requiredDynamicArticleIds')?.value ?? []
          });
        }
      );
      r.textFieldTop = this.form.get('textFieldTop')?.value;
      r.textFieldBottom = this.form.get('textFieldBottom')?.value;
      r.visibilitySinglePrice = (this.form.get('visibilitySinglePrice')?.value == 'true' || this.form.get('visibilitySinglePrice')?.value == true);
      r.visibilityUnitPrice = (this.form.get('visibilityUnitPrice')?.value == 'true' || this.form.get('visibilitySinglePrice')?.value == true);
      r.attachments = [];
      this.attachments.map(a => {
        r.attachments.push(a.filePath)
      })
    });
    let data: ApiConfigsUpdateRequest['params']['data'] = {
      id: this.form.get('id')?.value,
      orderEmailSettings: this.orderEmailSettings
    };


    this.configService.updateConfig({data}).subscribe(
      {
        next: (r) => {
          if (r.error) {

            this.formHandler.setFormError(r.error);
            this.formHandler.setFormStatus(
              {
                formStatus: 'error',
                resetStatusAfterTimeoutTo: null
              }
            );
          }
          if (r.result?.configs.updated) {
            this.formHandler.setFormStatus(
              {
                formStatus: 'success',
                resetStatusAfterTimeoutTo: null,
                callbackAfterTimeout: () => {
                  this.loadSettings();
                }
              }
            );
          } else {
            this.formHandler.setFormStatus(
              {
                formStatus: 'error',
                resetStatusAfterTimeoutTo: null
              }
            );
          }
        },
        error: () => {
          this.formHandler.setFormStatus(
            {
              formStatus: 'error',
              resetStatusAfterTimeoutTo: null
            }
          );
        }
      }
    )
  }

  ngOnDestroy(){
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  protected readonly faPlusSquare = faPlusSquare;
  protected readonly faTrashCan = faTrashCan;
  protected readonly faCog = faCog;
  protected readonly faBars = faBars;
  protected readonly faInfoCircle = faInfoCircle;
}
