import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {
  ApiUploadsFileRequest,
  ApiUploadsFileRequestParams, ApiUploadsFileResponse,
  ApiUploadsImageRequest,
  ApiUploadsImageRequestParams,
  ApiUploadsImageResponse
} from '@shared/api/uploads';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private http: HttpClient) {
  }

  file(params: ApiUploadsFileRequestParams): Observable<HttpEvent<ApiUploadsFileResponse>> {
    const body: ApiUploadsFileRequest = {id: 'uploads/file#INVALID_PARAMS', command: 'uploads/file', params};
    return this.http.post<ApiUploadsFileResponse>(environment.apiUrl, JSON.stringify(body), {
      reportProgress: true,
      observe: 'events'
    });
  }

  image(params: ApiUploadsImageRequestParams): Observable<HttpEvent<ApiUploadsImageResponse>> {
    const body: ApiUploadsImageRequest = {id: 'uploads/image#INVALID_PARAMS', command: 'uploads/image', params};
    return this.http.post<ApiUploadsImageResponse>(environment.apiUrl, JSON.stringify(body), {
      reportProgress: true,
      observe: 'events'
    });
  }
}
