<div class="container">
  <div class="placeholder is-hidden-mobile"></div>
  <div class="columns is-tablet">
    <div class="column is-2"></div>
    <div class="column is-8">
      <div class="columns is-tablet">
        <div class="column ">

        </div>
      </div>
    </div>
  </div>

  <div class="columns is-tablet">
    <div class="column is-2"></div>
    <div class="column is-8">
      <div class="columns is-tablet container-login">
        <div class="column is-8 has-background-white">
          <section class="section logo pb-2">
            <img *ngIf="isDicom()" ngSrc="./assets/design/logo-gesoft.jpg" height="47" width="200"/>
            <img *ngIf="!isDicom()" src="./assets/design/iw_logo.svg" class="mt-2" style="color:#000; max-height: 20px;"/>
          </section>
          <section class="section pt-0 is-flex is-relative" style="flex-flow: column; height: calc(100% - 80px);">
            <ng-content></ng-content>
            <div class="notification is-warning is-light mt-4 is-size-7 p-2">
              Falls Sie noch unser altes Shopsystem verwenden, finden Sie <a href="https://drinks.v2.indenware.de/" style="color:inherit">hier</a> den Zugang.
            </div>
          </section>
        </div>
        <div class="column is-4 contact">
          <p class="text-a has-text-white m-5">
            <span *ngIf="isDicom()">
               <b>Fragen?</b><br><br>
            +49 (0)7633 / 95 20 70<br>
            service@dicom-software.de<br><br>



            DICOM Dischinger Computer GmbH<br>
            Im Ebnet 6<br>
            79238 Ehrenkirchen<br>
            </span>
            <span *ngIf="!isDicom()">
                  <b>Fragen?</b><br><br>
           +49 (0)2771 / 87999 50<br>
            info@indenware.de<br><br>
            IndenWare GmbH<br>
            Driedorfstr. 13<br>
            35683 Dillenburg
            </span>
           </p>
        </div>
      </div>
    </div>
    <div class="column is-2"></div>
  </div>
</div>
