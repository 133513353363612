import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {
  ApiUploadsStorageDeleteRequest, ApiUploadsStorageDeleteResponse,
  ApiUploadsStorageGetRequest,
  ApiUploadsStorageGetResponse,
  ApiUploadsStorageSetRequest,
  ApiUploadsStorageSetResponse
} from "@shared/api/upload-storage";

@Injectable({
  providedIn: 'root'
})
export class UploadsStorageService {

  constructor(private http: HttpClient) {
  }

  set(data: ApiUploadsStorageSetRequest['params']['data']): Observable<ApiUploadsStorageSetResponse> {
    const body: ApiUploadsStorageSetRequest = {
      id: 'setUploadsStorage#INVALID_PARAMS',
      command: 'uploadsStorage/set',
      params: {
        data
      }
    };
    return this.http.post<ApiUploadsStorageSetResponse>(environment.apiUrl, JSON.stringify(body));
  }


  get(params: ApiUploadsStorageGetRequest['params']): Observable<ApiUploadsStorageGetResponse> {
    const body: ApiUploadsStorageGetRequest = {
      id: 'getUploadsStorage',
      command: 'uploadsStorage/get',
      params: params
    };
    return this.http.post<ApiUploadsStorageGetResponse>(environment.apiUrl, JSON.stringify(body));
  }


  delete(id: string): Observable<ApiUploadsStorageDeleteResponse> {
    const body: ApiUploadsStorageDeleteRequest = {
      id: 'deleteUploadsStorgae',
      command: 'uploadsStorage/delete',
      params: {
        data: {
          id
        }
      }
    };
    return this.http.post<ApiUploadsStorageDeleteResponse>(environment.apiUrl, JSON.stringify(body));
  }
}
