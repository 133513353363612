<article *ngIf="apiError" class="message is-danger">
  <div class="message-body">
    <b>{{ apiError.notification }}</b>
    <span *ngFor="let error of apiError?.errors" class="is-block">{{ error.key }}: {{ error.error }}</span>
  </div>
</article>
<div *ngIf="maxFiles == 0 || maxFiles > filePaths.length ">
  <div class="file is-boxed pb-4">
    <label class="file-label">
      <span style="color:red;" *ngIf="message">{{ message }}</span>
      <input *ngIf="fileType=='image'" class="file-input" type="file" name="resume" #file type="file" accept='image/*'
             (change)="setFiles(file.files)" multiple>
      <input *ngIf="fileType!='image'" class="file-input" type="file" name="resume" #file type="file" accept='*/*'
             (change)="setFiles(file.files)" multiple>
      <span class="file-cta">
      <span class="file-icon">
        <fa-icon [icon]="faUpload"></fa-icon>
      </span>
      <span class="file-label">
        {{ labelButton }}
      </span>
    </span>
    </label>
  </div>
  <div class="upload-container is-block">
    <div *ngFor="let uploadFile of uploadFiles">
      <div *ngIf="!uploadFile.done" class="progress-container"
           [ngClass]="uploadFile.uploadStatus !== 100 ? 'uploading-to-server' : ''">
        <div *ngIf="uploadFile.uploadStatus !== 100">
          <progress class="progress is-info" value="{{uploadFile.uploadStatus}}" max="100"
                    *ngIf="uploadFile.uploadStatus !== 100"></progress>
          <p class="percent" [style.left]="uploadFile.uploadStatus + '%'"
             *ngIf="uploadFile.uploadStatus !== 100">{{ uploadFile.uploadStatus }}%</p>

          <p class="status-txt">{{ uploadFile.statusTxt }}</p>
        </div>
        <div *ngIf="uploadFile.uploadStatus === 100" class="is-flex">
          <div>
            <button class="button is-small is-loading"></button>
          </div>
          <div><p class="has-text-left">{{ uploadFile.statusTxt }}</p></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="maxFiles != 0 && maxFiles <= filePaths.length ">
  <p>{{ label }}</p>
</div>
<div class="files-container" cdkDropListGroup>
  <div
    *ngFor="let group of fileGroups; let i = index">
    <div cdkDropList cdkDropListOrientation="horizontal"
         [cdkDropListData]="group.files"
         (cdkDropListDropped)="drop($event, i)">
      <div *ngIf="fileType!='image'">
        <div
          *ngFor="let file of group.files"
          class="columns file-path m-1"
          cdkDrag>
          <div *ngIf="fileType == 'privateFile'" class="column is-four-fifths">{{ file.filePath }}</div>
          <div *ngIf="fileType == 'publicFile'" class="column is-four-fifths"><a href="{{s3Url + file.filePath}}"
                                                                                 class="is-overflow-auto is-word-break-break-all"
                                                                                 target="_blank">{{ file.filePath }}</a>
          </div>
          <div class="column has-text-right ">
            <fa-icon [icon]="faTimes" class="delete is-pointer" (click)="deleteFile(i)"></fa-icon>

          </div>
        </div>

      </div>
      <div *ngIf="fileType=='image' && showPreviewImage">
        <div *ngFor="let file of group.files" class="container-drag"  cdkDrag>
          <div class="headline">
            <fa-icon [icon]="faTimes" class="image-delete" (click)="deleteFile(i)"></fa-icon>
          </div>
          <div *ngIf="file.thumbnails &&file.thumbnails['small'] " class="container-image" style=" background: url('{{s3Url + file.thumbnails['small']}}') no-repeat bottom center; background-size: contain; "> </div>
          <div class="container-additional-input-fields" *ngIf="additionalInputFields.length > 0">
            <div  *ngFor="let field of additionalInputFields">
              <div *ngIf="field.type == 'select'" class="select is-small">
                <select (change)="changeAdditionalInputOnFilePath(i, field.name, $any($event.target).value)">
                  <option *ngFor="let option of field.options" [value]="option.value" [selected]="getAdditionalInputValue(i, field.name) == option.value"><span *ngIf="option.name">{{ option.name }}</span><span *ngIf="!option.name">{{ option.value }}</span></option>
                </select>
              </div>
              <div *ngIf="field.type == 'input'" class="field">
                <div class="control">
                  <input class="input is-small" type="text"/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>
  </div>
</div>
