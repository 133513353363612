<div *ngIf="loading" class="content-content has-text-centered">
  <button class="button is-loading"></button>
</div>
<div #formTag>
  <div *ngIf="!loading">
    <form [formGroup]="form">
      <div class="tabs">
        <ul>
          <li [ngClass]="activeTab=='details' ? 'is-active' : ''" (click)="activeTab='details'">
            <a>Details
            </a></li>
        </ul>
      </div>
      <div class="content-content is-tabs">
        <div class="mb-4">
          <shared-form-error [apiError]="formHandler.getFormError()"></shared-form-error>
        </div>
        <div class="tab-container" [ngClass]="activeTab!='details' ? 'is-hidden' : ''">

          <div class="columns">
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Status</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <div class="select is-small">
                        <select formControlName="status">
                          <option value="">Bitte wählen Sie einen Status aus</option>
                          <option value="active">Aktiv</option>
                          <option value="opened">Angebrochen</option>
                          <option value="used">Eingelöst</option>
                          <option value="multipleUse">Mehrfachanlösung möglich</option>
                          <option value="paymentPending">Gutschein nicht bezahlt</option>
                        </select>
                      </div>
                      <p class="help is-danger" *ngIf="isFormKeyInvalid('status')">Bitte hinterlegen Sie einen Status.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Wert</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="number"
                             formControlName="value" placeholder="Wert">
                      <p class="help is-danger" *ngIf="isFormKeyInvalid('value')">Bitte hinterlegen Sie einen Wert.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Rabatt in Prozent</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="number"
                             formControlName="discountInPercent" placeholder="Rabatt in Prozent">
                      <p class="help is-danger" *ngIf="isFormKeyInvalid('discountInPercent')">Bitte hinterlegen Sie einen Rabatt in Prozent.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Code</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="code" placeholder="Code">
                      <p class="help is-danger" *ngIf="isFormKeyInvalid('code')">Bitte hinterlegen Sie einen Code.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Restguthaben</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="number"
                             formControlName="balance" placeholder="Restguthaben">
                      <p class="help is-danger" *ngIf="isFormKeyInvalid('balance')">Bitte hinterlegen Sie ein Restguthaben.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Gültig bis</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control has-icons-left" (click)="to.open()">
                      <input [matDatepicker]="to" class="input is-small" placeholder="Gültig bis" formControlName="validTo" >
                      <span class="icon is-small is-left">
                          <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
                          <mat-datepicker #to></mat-datepicker>
                        </span>
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="content-foot has-text-centered">
        <shared-form-submit-button (submit)="submit()" [formStatus]="formHandler.getFormStatus()"></shared-form-submit-button>
      </div>
    </form>
  </div>
</div>

