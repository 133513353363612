import {
  AbstractControl,
  FormArray, FormControl, FormGroup,
  ValidatorFn
} from "@angular/forms";

export class FormDynamicArrayFormGroup {
  formControl: AbstractControl<any> | null;
  fields: string[];
  fieldValidators?: { [key: string]: ValidatorFn | ValidatorFn[] }

  constructor(formControl: AbstractControl<any> | null, fields: string[], fieldValidators?: {
    [p: string]: ValidatorFn | ValidatorFn[]
  }) {
    this.formControl = formControl;
    this.fields = fields;
    this.fieldValidators = fieldValidators;
  }

  add(values?: { [key: string]: string | boolean }) {
    const formArray = this.getFormArray();
    const newFormGroup = new FormGroup({});
    this.fields.forEach(field => {
      newFormGroup.addControl(field, new FormControl(values ? values[field] : ''));
    });
    if (this.fieldValidators) {
      Object.keys(this.fieldValidators).forEach(key => {
        if (this.fieldValidators) {
          newFormGroup.get(key)?.setValidators(this.fieldValidators[key]);
          newFormGroup.get(key)?.updateValueAndValidity();
        }
      });
    }
    formArray.push(newFormGroup);

  }

  update(index: number, values: { [key: string]: string }) {
    let form = this.getFormArray().at(index);
    Object.keys(values).forEach(key => {
      form.get(key)?.setValue(values[key]);
    });
  }

  remove(index: number) {
    const formArray = this.getFormArray();
    formArray.removeAt(index);
  }

  getFormArray(): FormArray {
    return this.formControl as FormArray;

  }
}
