import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {
  ApiUsersDeleteRequest,
  ApiUsersDeleteResponse,
  ApiUsersGetRequest,
  ApiUsersGetResponse,
  ApiUsersSetRequest,
  ApiUsersSetResponse, ApiUsersUpdateRequest, ApiUsersUpdateResponse
} from '@shared/api/users';
import {ApiUserTokensGetRequest, ApiUserTokensGetResponse} from '@shared/api/user-tokens';
import {ConfigService} from '@shared/config/services/config.service';
import {ApiUsersAdminGetRequest, ApiUsersAdminGetResponse} from '@shared/api/user-admin';
import {
  ApiAccountBackendUserEmailVerificationLostPasswordRequest,
  ApiAccountBackendUserEmailVerificationLostPasswordResponse
} from '@shared/api/account-backend-user-email-verification';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private configService: ConfigService) {
  }

  setUser(data: ApiUsersSetRequest['params']['data']): Observable<ApiUsersSetResponse>{
    const body: ApiUsersSetRequest = {
      id: 'setUser#INVALID_PARAMS',
      command: 'users/set',
      params: {
        data
      }
    };
    return this.http.post<ApiUsersSetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  getUserToken(userId: string): Observable<ApiUserTokensGetResponse>{
    const body: ApiUserTokensGetRequest = {
      id: 'getUser',
      command: 'userTokens/get',
      params: {
        data: {
          userId
        }
      }
    };
    return this.http.post<ApiUserTokensGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  getUsers(params: ApiUsersGetRequest['params']): Observable<ApiUsersGetResponse>{
    const body: ApiUsersGetRequest = {
      id: 'getUser',
      command: 'users/get',
      params: params
    };
    return this.http.post<ApiUsersGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  getUsersAdmin(userId: string): Observable<ApiUsersAdminGetResponse> {
    const body: ApiUsersAdminGetRequest = {
      id: 'getUsersAdmin',
      command: 'accountBackendUsers/get',
      params:{
        filters: [{
          fields: ['id'],
          values: [userId]
        }]

      }
    };
    return this.http.post<ApiUsersAdminGetResponse>(environment.apiAdminUrl, JSON.stringify(body));
  }

  getUser(userId: string): Observable<ApiUsersGetResponse> {
    const body: ApiUsersGetRequest = {
      id: 'getUser',
      command: 'users/get',
      params: {
        filters:[
          {
            fields: ['id'],
            values: [userId]
          }
        ],
        users: {}
      }
    };
    return this.http.post<ApiUsersGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  updateUser(data: ApiUsersUpdateRequest['params']['data']): Observable<ApiUsersUpdateResponse>{
    const body: ApiUsersUpdateRequest = {
      id: 'updateUser#INVALID_PARAMS',
      command: 'users/update',
      params: {
        data
      }
    };
    return this.http.post<ApiUsersUpdateResponse>(environment.apiUrl, JSON.stringify(body));
  }

  sendEmailVerificationLostPassword(username: string): Observable<ApiAccountBackendUserEmailVerificationLostPasswordResponse> {
    const body: ApiAccountBackendUserEmailVerificationLostPasswordRequest = {
      id: 'sendEmailVerificationLostPassword#INVALID_PARAMS',
      command: 'accountBackendUserEmailVerification/lostPassword',
      params: {
        data: {
          username
        }

      }
    };
    return this.http.post<ApiAccountBackendUserEmailVerificationLostPasswordResponse>(environment.apiAdminUrl, JSON.stringify(body));
  }

  deleteUser(username: string): Observable<ApiUsersDeleteResponse>{
    const body: ApiUsersDeleteRequest = {
      id: 'deleteUser',
      command: 'users/delete',
      params: {
        data: {
          username
        }
      }
    };
    return this.http.post<ApiUsersDeleteResponse>(environment.apiUrl, JSON.stringify(body));
  }

  download(params: ApiUsersGetRequest['params'], type: 'csv' | 'xlsx'): Observable<any> {
    const body: ApiUsersGetRequest = {
      id: 'downloadUsers',
      command: 'users/get',
      params
    };
    return this.http.post<any>(environment.apiUrl + 'download.php?type=' + type, JSON.stringify(body), {responseType: 'blob' as 'json'});
  }


  async resolveIdsToNames(ids: string[]): Promise<{id: string, name: string}[]> {
    let data: {id: string, name: string}[] = [];
    const body: ApiUsersGetRequest[] = [];
    ids.filter((value, index, self) => self.indexOf(value) === index).map(id => {
      body.push({
        id,
        command: 'users/get',
        params: {
          filters: [{fields: ['id'], values: [id]}],
          users: {
            expectedData: {
              id: 1,
              username: 1
            }
          }
        }
      })
    });
    if(ids.length > 0){
      await this.http.post<ApiUsersGetResponse[]>(environment.apiUrl, JSON.stringify(body)).toPromise().then(
        r => {
          if(r){
            ids.map(id => {
              let filter = r.filter(data => data.id == id);
              if (filter[0]?.result?.users?.data &&
                filter[0].result.users.data[0]) {
                data.push({id, name:filter[0].result.users.data[0].username})
              }

            })
          }

        }
      )
    }
    return data;
  }

  shopLogin(id: string) {
    forkJoin([this.getUserToken(id), this.configService.getConfig()])
      .subscribe(r => {
        if (r[0].result?.userTokens &&
          r[0].result?.userTokens.data.accessToken) {
          window.open(r[1].result?.configs.data[0].shopUrl + '?token=' + r[0].result.userTokens.data.accessToken + '&action=login');
        }
      });
  }
}
