import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faCheck, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FormStatus} from "@shared/forms/models/form-handler";

@Component({
  selector: 'shared-form-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent implements OnInit {
  @Input() formStatus?: FormStatus;
  @Input() classes?: string = 'is-primary';
  @Input() submitLabel?: string = 'Speichern';
  @Input() readOnlyText?: string = 'Schreibgeschützt';
  @Input() loadingMessage?: string = '';

  @Output() submit: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  _submit(){
    this.submit.emit();
  }

  protected readonly faExclamationTriangle = faExclamationTriangle;
  protected readonly faCheck = faCheck;
}

