<form [formGroup]="form">
  <div class="is-flex">

    <div class="field">
      <p class="control has-icons-left">
        <p-calendar #pFrom
                    inputStyleClass="input is-small"
                    formControlName="from"
                    placeholder="Von"
                    dateFormat="dd.mm.yy"
                    (onFocus)="setTimeIfEmpty('from', 0, 0)"
                    (onClose)="setFilter()"
                    (onClickOutside)="resetFormWithCurrentFilterValue()"
                    [showTime]="true"

                    appendTo="body"
                    hourFormat="24">
          <p-footer>
            <div clasS="has-text-centered">
              <button class="button" (click)="setFilter(); pFrom.hideOverlay()">Datum übernehmen</button>
            </div>
          </p-footer>
        </p-calendar>
        <span class="icon is-small is-left">
          <fa-icon [icon]="faCalendar"></fa-icon>
        </span>
      </p>
    </div>
    <div class="field ml-1">
      <p class="control has-icons-left">
        <p-calendar #pTo
                    inputStyleClass="input is-small"
                    formControlName="to"
                    placeholder="Bis"
                    dateFormat="dd.mm.yy"
                    (onFocus)="setTimeIfEmpty('to', 23, 59)"
                    (onClose)="setFilter()"
                    (onClickOutside)="resetFormWithCurrentFilterValue()"
                    [showTime]="true"
                    appendTo="body"
                    hourFormat="24">
          <p-footer>
            <div clasS="has-text-centered">
              <button class="button" (click)="setFilter(); pTo.hideOverlay()">Datum übernehmen</button>
            </div>
          </p-footer>
        </p-calendar>
        <span class="icon is-small is-left">
          <fa-icon [icon]="faCalendar"></fa-icon>
        </span>
      </p>
    </div>

  </div>
</form>



