import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EffectsModule} from '@ngrx/effects';
import {AuthenticationEffects} from '@store/effects/authentication.effects';
import {UserEffects} from '@store/effects/user.effects';
import {ChangeDetectionEffects} from '@store/effects/change-detection.effects';
import {MetaReducer, StoreModule} from '@ngrx/store';
import {appReducers} from '@store/reducers/app.reducers';
import {environment} from '@environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {localStorageSyncReducer} from '@core/lib/ngrx-local-storage-sync';
import {AppInitStorageService} from '@core/services/app-init/app-init-storage.service';
import {GlobalErrorHandler} from '@core/lib/error-handler';
import {httpInterceptorProviders} from '@core/http-interceptors/_index';
import {NotificationsComponent} from '@core/components/notifications/notifications.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from '@core/components/login/login.component';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {PasswordResetComponent} from '@core/components/password-reset/password-reset.component';
import {PasswordLostComponent} from '@core/components/password-lost/password-lost.component';
import {CaptchaComponent} from '@core/components/captcha/captcha.component';
import { AuthenticationContainerComponent } from './core/components/layout/authentication-container/authentication-container.component';
import {SharedModule} from '@shared/shared.module';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent,
    NotificationsComponent,
    LoginComponent,
    CaptchaComponent,
    PasswordLostComponent,
    PasswordResetComponent,
    AuthenticationContainerComponent
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    EffectsModule.forRoot([AuthenticationEffects, UserEffects, ChangeDetectionEffects]),
    StoreModule.forRoot(appReducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true
      }
    }),
    !environment.production ? StoreDevtoolsModule.instrument({
      maxAge: 20
    }) : [],
    SharedModule,
    FontAwesomeModule,
    NgOptimizedImage,

  ],
  exports: [],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appInit: AppInitStorageService) => () => appInit.init(),
      deps: [AppInitStorageService],
      multi: true
    },
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
