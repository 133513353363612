import {ErrorHandler, Injectable, Injector, NgZone} from "@angular/core";
import {Store} from '@ngrx/store';
import {setNotification} from '@store/actions/global.actions';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private zone: NgZone) {
  }

  handleError(error: Error) {
    console.log(error);
    const store = this.injector.get(Store);
    if(error.stack){
      error.message = error?.message + '<br>' + error.stack;
    }
    this.zone.run(() =>
      store.dispatch(setNotification({notification: error?.message, notificationType: 'error'}))
    );

    return;

    //return throwError(error.message);
  }
}
