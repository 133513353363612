import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Window} from '@shared/window-manager/interfaces/window.model';
import {Subject} from 'rxjs';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Autocomplete, AutocompleteItem} from '@shared/forms/models/autocomplete';
import {FormHelperDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {DeliveryToursService} from '@shared/delivery-tours/services/delivery-tours.service';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {finalize} from 'rxjs/operators';
import {setWindow} from '@store/actions/windows.action';
import {setChangedItem} from '@store/actions/change-detection.action';
import {DeliveryBlockedDaysService} from '@shared/delivery-blocked-days/services/delivery-blocked-days.service';
import {faPlusSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";

@Component({
  selector: 'shared-delivery-blocked-days-form',
  templateUrl: './delivery-blocked-days-form.component.html',
  styleUrls: ['./delivery-blocked-days-form.component.scss']
})
export class DeliveryBlockedDaysFormComponent implements OnInit {
  @ViewChild('formTag', {static: false}) formTag: ElementRef | undefined;
  @Input() window?: Window;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  changeDetectionGroup: string = 'deliveryBlockedDays';

  form: UntypedFormGroup;


  autocompleteDeliveryTour: Autocomplete = new Autocomplete(
    {
      label: 'Ausschließlich Touren sperren',
      placeholder: 'Tour suchen...',
      loadItems: (searchString: string) => {
        this.loadDeliveryTours(searchString)
      }
    }
  );


  formHandler: FormHelperDeprecated;


  errors = null;
  activeTab = '';
  msg = '';
  data: any;

  loading: boolean = false;


  constructor(
    private deliveryBlockedDaysService: DeliveryBlockedDaysService,
    private deliveryToursService: DeliveryToursService,
    private store: Store<AppState>,
    private renderer: Renderer2,
    private fb: UntypedFormBuilder
  ) {
    this.form = new UntypedFormGroup({
      id: this.fb.control(null),
      name: this.fb.control(null, Validators.required),
      from: this.fb.control(null, Validators.required),
      to: this.fb.control(null, Validators.required),
      txt: this.fb.control(null, Validators.required),
      deliveryPeriods: this.fb.array([]),
      deliveryTourIds: this.fb.array([]),
      customerPriceGroups: this.fb.array([])
    });

    this.formHandler = new FormHelperDeprecated(
      this.store,
      this.changeDetectionGroup,
      this.form,
      [
        {key: 'name', error: 'Bitte hinterlegen Sie einen Namen.'},
        {key: 'from', error: 'Bitte wählen Sie einen Tag aus.'},
        {key: 'to', error: 'Bitte wählen Sie einen Tag aus.'},
        {key: 'txt', error: 'Bitte hinterlegen Sie eine Begründung.'}
      ],
      this.renderer
    );
    this.formHandler.activeTab = 'details';
    this.autocompleteDeliveryTour.sortable = false;
  }

  ngAfterViewInit() {
    this.formHandler.htmlRef = this.formTag;
  }

  ngOnInit() {
    if (this.window?.data?.itemId) {
      this.loadItem(this.window.data.itemId);
    }
  }

  loadDeliveryTours(searchString: string) {
    this.autocompleteDeliveryTour.loading = true;
    this.deliveryToursService.get({
      filters: [{values: [searchString], useRegex: 1}],
      deliveryTours: {sort: [{field: 'name', direction: 1}]}
    })
      .pipe(
        finalize(() => {
          this.autocompleteDeliveryTour.loading = false;
        })
      )
      .subscribe(
        r => {
          let items: AutocompleteItem[] = [];
          if (r.result?.deliveryTours.data) {
            r.result?.deliveryTours.data.map(
              item => {
                items.push({value: item.id, name: item.name})
              }
            )
          }
          this.autocompleteDeliveryTour.setItems(items);
        }
      )

  }

  loadItem(id: string) {
    this.loading = true;
    this.deliveryBlockedDaysService.get({deliveryBlockedDays: {}, filters: [{fields: ['id'], values: [id]}]})
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        async r => {

          if (r.result?.deliveryBlockedDays.data[0]) {

            let data: any = r.result?.deliveryBlockedDays.data[0];

            if (data.deliveryPeriods) {
              data.deliveryPeriods.map((r: any) => {
                this.getFormArray('deliveryPeriods').push(this.fb.control(r, Validators.required));
              });
              delete data.deliveryPeriods;
            }

            if (data.customerPriceGroups) {
              data.customerPriceGroups.map((r: any) => {
                this.getFormArray('customerPriceGroups').push(this.fb.control(r, Validators.required));
              });
              delete data.customerPriceGroups;
            } else {
              data.customerPriceGroups = [];
            }

            if (data.deliveryTourIds) {
              const formArray = this.form.get('deliveryTourIds') as UntypedFormArray;
              data.deliveryTourIds.map((r: string) => {
                formArray.push(this.fb.control(r));
              })
              delete data.deliveryTourIds;
            } else {
              data.deliveryTourIds = [];
            }

            this.formHandler?.fillForm(data);
            await this.resolveDeliveryTourIds();
          }
        }
      )
  }

  async resolveDeliveryTourIds() {
    const deliveryTours = await this.deliveryToursService.resolveIdsToNames(this.form.get('deliveryTourIds')?.value);
    deliveryTours.map((r: { id: string, name: string }) => {
      this.autocompleteDeliveryTour.selectItem({name: r.name, value: r.id})
    });
  }

  addField(type: 'deliveryPeriods' | 'customerPriceGroups') {
    this.getFormArray(type).push(this.fb.control('', Validators.required));
    this.formHandler.htmlRef = this.formTag;
  }

  removeField(type: 'deliveryPeriods' | 'customerPriceGroups', index: number) {
    const formArray = this.getFormArray(type);
    formArray.removeAt(index);
  }

  getFormArray(type: 'deliveryPeriods' | 'customerPriceGroups'): UntypedFormArray {
    return this.form.get(type) as UntypedFormArray;
  }

  editDeliveryTours(item: AutocompleteItem) {
    this.store.dispatch(setWindow({
      id: 'deliveryTours' + item.value,
      title: 'Tour: ' + item.name,
      component: 'shared-delivery-tours-form',
      data: {itemId: item.value}
    }))
  }

  setDeliveryTours(items: string[]) {
    const formArray = this.form.get('deliveryTourIds') as UntypedFormArray;
    formArray.clear();
    items.map((r: any) => {
      formArray.push(this.fb.control(r));
    })
  }

  submit() {
    this.formHandler.validateForm();
    this.formHandler.displayErrors();
    this.formHandler.status = 'submitForm';
    if ( !this.formHandler.isFormErrors()) {
      this.formHandler.status = 'loading';
      let data: any;

      let dateTo = new Date(this.form.get('to')?.value);
      data = {
        name: this.form.get('name')?.value,
        from: this.form.get('from')?.value,
        to: dateTo.getFullYear() + '-' + (dateTo.getMonth() + 1) + '-' + dateTo.getDate() + 'T23:59:59',
        txt: this.form.get('txt')?.value,
      }

      if (this.window?.data && this.window?.data.itemId) {
        data.id = this.window?.data.itemId;
      }
      data.deliveryTourIds = [];
      if (this.form.get('deliveryTourIds')?.value.length > 0) {
        data.deliveryTourIds = this.form.get('deliveryTourIds')?.value;
      }
      data.deliveryPeriods = [];
      if (this.form.get('deliveryPeriods')?.value.length > 0) {
        data.deliveryPeriods = this.form.get('deliveryPeriods')?.value;
      }

      if (this.form.get('customerPriceGroups')?.value.length > 0) {
        data.customerPriceGroups = this.form.get('customerPriceGroups')?.value;
      }

      // Update
      if (this.window?.data &&
        this.window.data.itemId) {

        this.deliveryBlockedDaysService.update(data)
          .subscribe(
            {
              next: (r) => {
                this.formHandler?.handleApiResult({
                  r, windowId: this.window?.id, callbackOnSuccess: () => {
                    this.store.dispatch(setChangedItem({
                      item: {
                        itemId: data.id,
                        date: new Date().getTime(),
                        userId: '',
                        action: 'update',
                        group: this.changeDetectionGroup
                      }
                    }));
                  }
                });
              },
              error: (error) => {
                this.formHandler.notification = error;
                this.formHandler.status = null;
                this.formHandler.displayErrors();
              }
            }
          )
        // Insert
      } else {
        this.deliveryBlockedDaysService.set(data)
          .subscribe(
            {
              next: (r) => {
                this.formHandler?.handleApiResult({
                  r, windowId: this.window?.id, callbackOnSuccess: () => {
                    this.store.dispatch(setChangedItem({
                      item: {
                        itemId: r.result?.deliveryBlockedDays.data.id || '',
                        date: new Date().getTime(),
                        userId: 'myself',
                        action: 'set',
                        group: this.changeDetectionGroup
                      }
                    }))
                  }
                });
              },
              error: (error) => {
                this.formHandler.notification = error;
                this.formHandler.status = null;
                this.formHandler.displayErrors();
              }
            }
          )
      }

    }
  }

  ngOnDestroy() {

  }


  protected readonly faPlusSquare = faPlusSquare;
  protected readonly faTrashCan = faTrashCan;
}
