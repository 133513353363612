import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';
import {
  AccountBackendUserUpdateParamsData,
  ApiAccountBackendUsersUpdateRequest,
  ApiAccountBackendUsersUpdateResponse
} from '@shared/api/account-backend-users';

@Injectable({
  providedIn: 'root'
})
export class AccountBackendUsersService {

  constructor(private http: HttpClient) { }

  update(data: AccountBackendUserUpdateParamsData): Observable<ApiAccountBackendUsersUpdateResponse>{
    const body: ApiAccountBackendUsersUpdateRequest = {
      id: 'updateAccountBackendUser#INVALID_PARAMS',
      command: 'accountBackendUsers/update',
      params: {
        data
      }
    };
    return this.http.post<ApiAccountBackendUsersUpdateResponse>(environment.apiAdminUrl, JSON.stringify(body));
  }
}
