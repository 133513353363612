import { Component, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {resetNotifications, visibilities} from '@store/actions/global.actions';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  visibility$ = this.store.select(state => state.global.visibilities);
  notifications$ = this.store.select(state => state.global.notifications);

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
  }

  destroyLogs() {
    this.store.dispatch(resetNotifications());
  }

  toggleNotifications(display: boolean) {
    this.store.dispatch(visibilities({property: 'notifications', value: display}));
  }

}
