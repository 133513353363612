import {
  AfterViewInit,
  Component,
  createNgModuleRef, Injector,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  @ViewChild("windows", {read: ViewContainerRef})
  windows!: ViewContainerRef;



  constructor(
    private store: Store<AppState>,
    private injector: Injector) {

  }

  async loadWindows() {
    const {WindowsModule} = await import("@feature/windows/windows.module");
    const moduleRef = createNgModuleRef(WindowsModule, this.injector)
    const lazyComponent = moduleRef.instance.getWindowComponent();
    this.windows.createComponent(lazyComponent, {ngModuleRef: moduleRef});
  }

  ngAfterViewInit(): void {
    this.loadWindows().then();
  }

}
