import {Component, Input, OnInit} from '@angular/core';
import {Window} from '@shared/window-manager/interfaces/window.model';
import {forkJoin, lastValueFrom} from 'rxjs';
import {
  FormArray,
  Validators, FormBuilder, AbstractControl
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {finalize} from 'rxjs/operators';
import {CustomerService} from '@shared/customer/services/customer.service';
import {CustomerAddress, CustomerArticlePrice, CustomerContactPerson} from '@shared/customer/interfaces/customer';
import {
  faCircleExclamation, faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import {Autocomplete, AutocompleteItem} from "@shared/forms/models/autocomplete";
import {setWindow} from "@store/actions/windows.action";
import {DynamicArticlesService} from "@shared/dynamic-articles/services/dynamic-articles.service";
import {ApiCustomersSetRequest} from "@shared/api/customers";
import {faEdit, faPlusSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {FormDynamicArray} from "@shared/forms/models/form-dynamic-array";
import {FormHandler} from "@shared/forms/models/form-handler";
import {v4 as uuidv4} from 'uuid';
import {FormDynamicArrayFormGroup} from "@shared/forms/models/form-dynamic-array-form-group";
import {CustomerNoteService} from "@shared/customer/services/customer-note.service";
import {ApiCustomerNote, ApiCustomerNotesSetRequest, ApiCustomerNotesUpdateRequest} from "@shared/api/customer-notes";

type PartialApiCustomerNote =
  Omit<ApiCustomerNote, 'id' | 'dateCreated' | 'lastUpdate'>
  & Partial<Pick<ApiCustomerNote, 'id' | 'dateCreated' | 'lastUpdate'>>;

@Component({
  selector: 'shared-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit {

  @Input() window?: Window;

  customerNotes: PartialApiCustomerNote [] = [];
  customerNotesDeletion: PartialApiCustomerNote [] = [];
  customerNumber: string = '';
  showCustomerNoteId?: string;
  _showCustomerNotesDialog: boolean = false;

  customerArticlePrices: CustomerArticlePrice[] = [];

  loading: boolean = false;

  apiError?: {
    notification: string,
    errors?: { key: string, error: string }[]
  };

  addresses: CustomerAddress[] = [];
  showAddressDialog: boolean = false;
  showAddressId: string | null = null;

  contactPersons: CustomerContactPerson[] = [];
  showContactPersonDialog: boolean = false;
  showContactPersonId: string | null = null;

  changeDetectionGroup: string = 'customers';

  editable: boolean = true;

  formHandler: FormHandler;
  formAddressHandler: FormHandler;
  formContactPersonHandler: FormHandler;

  form = this.fb.group({
    visibilityGroups: this.fb.array([]),
    dynamicFields: this.fb.array([]),
    customerNumber: this.fb.control({value: '', disabled: !this.editable}, Validators.required),
    email: this.fb.control({value: '', disabled: !this.editable}, [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i)]),
    internetAddress: this.fb.control({value: '', disabled: !this.editable}, [
      Validators.pattern(
        /^(http|https):\/\/((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i
      )
    ]),
    priceGroup: this.fb.control({value: '', disabled: !this.editable}, Validators.required),
    priceMode: this.fb.control({value: 'gross', disabled: !this.editable}),
    creditLimit: this.fb.control({value: 0, disabled: !this.editable}),
    creditLimitAvailable: this.fb.control({value: 0, disabled: !this.editable}),
    defaultDeliveryPeriod: this.fb.control({value: '', disabled: !this.editable}),
    dynamicArticleIds: new FormArray([]),
    category: this.fb.control({value: '', disabled: !this.editable}),
    industryCategory: this.fb.control({value: '', disabled: !this.editable}),
  });

  formAddress = this.fb.group({
    addressId: this.fb.control({value: '', disabled: true}),
    company: this.fb.control({value: '', disabled: !this.editable}),
    name: this.fb.control({value: '', disabled: !this.editable}, Validators.required),
    firstName: this.fb.control({value: '', disabled: !this.editable}, Validators.required),
    gesoftName1: this.fb.control({value: '', disabled: !this.editable}),
    gesoftName2: this.fb.control({value: '', disabled: !this.editable}),
    street: this.fb.control({value: '', disabled: !this.editable}, Validators.required),
    postcode: this.fb.control({value: '', disabled: !this.editable}, Validators.required),
    town: this.fb.control({value: '', disabled: !this.editable}, Validators.required),
    salutation: this.fb.control({value: '', disabled: !this.editable}),
    title: this.fb.control({value: '', disabled: !this.editable}),
    additionalAddressInformation: this.fb.control({
      value: '',
      disabled: !this.editable
    }),
    country: this.fb.control({value: '', disabled: !this.editable}),
    phone: this.fb.control({value: '', disabled: !this.editable}),
    mobile: this.fb.control({value: '', disabled: !this.editable}),
    floor: this.fb.control({value: 0, disabled: !this.editable}),
    elevator: this.fb.control({value: false, disabled: !this.editable}),
    type: this.fb.control({value: '', disabled: !this.editable})
  });

  formContactPerson = this.fb.group({
    contactPersonId: this.fb.control({value: '', disabled: true}),
    position: this.fb.control({value: '', disabled: !this.editable}),
    salutation: this.fb.control({value: '', disabled: !this.editable}),
    name: this.fb.control({value: '', disabled: !this.editable}, Validators.required),
    firstName: this.fb.control({value: '', disabled: !this.editable}),
    phone: this.fb.control({value: '', disabled: !this.editable}, [Validators.pattern("^(\\+[1-9 ]|0)[0-9 ]{10,}$")]),
    mobile: this.fb.control({value: '', disabled: !this.editable}, [Validators.pattern("^(\\+[1-9 ]|0)[0-9 ]{10,}$")]),
    email: this.fb.control({
      value: '',
      disabled: !this.editable
    }, [Validators.pattern("^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$")]),
    additionalInformation: this.fb.control({
      value: '',
      disabled: !this.editable
    }),
  });


  formDynamicFieldsVisibilityGroups: FormDynamicArray = new FormDynamicArray(this.form.get('visibilityGroups'), Validators.required);
  formDynamicFields: FormDynamicArrayFormGroup = new FormDynamicArrayFormGroup(this.form.get('dynamicFields'), ['label', 'value'], {
    label: Validators.required,
    value: Validators.required
  });

  autocompleteDynamicArticles: Autocomplete = new Autocomplete(
    {
      label: 'Die ausgewählten Artikel werden dem Warenkorb automatisch hinzugefügt.',
      placeholder: 'Dynamische Artikel suchen...',
      loadItems: (searchString: string) => {
        this.loadDynamicArticles(searchString)
      }
    }
  );

  activeTab = 'details';


  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService,
    private customerNoteService: CustomerNoteService,
    private dynamicArticlesService: DynamicArticlesService,
    private store: Store<AppState>
  ) {
    this.formHandler = new FormHandler(this.store);
    this.formAddressHandler = new FormHandler(this.store);
    this.formContactPersonHandler = new FormHandler(this.store);
  }


  ngOnInit() {
    if (this.window?.data?.itemId) {
      this.loadItem(this.window.data.itemId);
    }
  }


  isFormKeyInvalid(key: string, displayIfSubmitForm: boolean = true): boolean {
    let form = this.form?.get(key);
    return !!(form?.invalid &&
      (
        !displayIfSubmitForm ||
        this.formHandler.getFormStatus() == 'submitForm'));

  }


  loadItem(id: string) {
    this.loading = true;
    forkJoin([
      this.customerService.get({filters: [{fields: ['id'], values: [id]}], customers: {}}),
    ])
      .pipe(
        finalize(() => {
          if (!this.editable) {
            this.formHandler.setFormStatus({formStatus: null});
          }
        })
      )
      .subscribe(
        async r => {
          if (r[0].result?.customers.data[0]) {
            const customer = r[0].result.customers.data[0];
            this.form.get('customerNumber')?.setValue(customer.customerNumber);
            this.customerNumber = customer.customerNumber;
            this.form.get('email')?.setValue(customer.email);
            this.form.get('internetAddress')?.setValue(customer.internetAddress ?? '');
            this.form.get('priceGroup')?.setValue(customer.priceGroup);
            this.form.get('priceMode')?.setValue(customer.priceMode ?? 'gross');
            this.form.get('creditLimit')?.setValue(customer.creditLimit ?? 0);
            this.form.get('creditLimitAvailable')?.setValue(customer.creditLimitAvailable ?? 0);
            this.form.get('defaultDeliveryPeriod')?.setValue(customer.defaultDeliveryPeriod ?? null);
            this.form.get('category')?.setValue(customer.category ?? '');
            this.form.get('industryCategory')?.setValue(customer.industryCategory ?? '');
            this.addresses = [];
            this.contactPersons = [];

            if (r[0].result?.customers.data[0].addresses) {
              r[0].result?.customers.data[0].addresses.map(
                a => {
                  this.addresses.push(a);
                }
              );
            }
            if (r[0].result?.customers.data[0].contactPersons) {
              r[0].result?.customers.data[0].contactPersons.map(
                c => {
                  this.contactPersons.push(c);
                }
              );
            }
            if (r[0].result?.customers.data[0].visibilityGroups) {
              r[0].result?.customers.data[0].visibilityGroups.map(group => {
                this.formDynamicFieldsVisibilityGroups.add(group)
              });
            }
            if (r[0].result?.customers.data[0].dynamicFields) {
              r[0].result?.customers.data[0].dynamicFields.map(df => {
                this.formDynamicFields.add({label: df.label, value: df.value});
              });
            }

            if (r[0].result?.customers.data[0].articlePrices) {
              this.customerArticlePrices = r[0].result?.customers.data[0].articlePrices;
            }
            if (r[0].result?.customers.data[0].dynamicArticleIds) {
              this.setDynamicArticles(r[0].result?.customers.data[0].dynamicArticleIds);
            }
            await this.resolveDynamicArticleIds();

            if (customer.customerNumber) {
              await this.loadCustomerNotes(customer.customerNumber)
            }
          }


          this.loading = false;
        }
      )
  }


  loadDynamicArticles(searchString: string) {
    this.autocompleteDynamicArticles.loading = true;
    this.dynamicArticlesService.get({
      dynamicArticles: {sort: [{field: 'name', direction: 1}]}
    })
      .pipe(
        finalize(() => {
          this.autocompleteDynamicArticles.loading = false;
        })
      )
      .subscribe(
        r => {
          let items: AutocompleteItem[] = [];
          if (r.result?.dynamicArticles.data) {
            r.result?.dynamicArticles.data.map(
              item => {
                items.push({value: item.id, name: item.name})
              }
            )
          }
          this.autocompleteDynamicArticles.setItems(items);
        }
      )
  }

  async loadCustomerNotes(customerNumber: string) {
    let r = await lastValueFrom(this.customerNoteService.get({
      filters: [{fields: ['customerNumber'], values: [customerNumber]}],
      customerNotes: {}
    }));

    if (r.result?.customerNotes.data) {
      this.customerNotes = r.result?.customerNotes.data;
    }
  }

  showCustomerNotesDialog(id?: string) {
    this.showCustomerNoteId = id;
    this._showCustomerNotesDialog = true;
  }


  setCustomerNote(data: ApiCustomerNotesSetRequest['params']['data']) {
    this.customerNotes.push({...data, dateCreated: new Date().toString()});
    this.customerNotes = [...this.customerNotes];
    this._showCustomerNotesDialog = false;
  }

  updateCustomerNote(data: ApiCustomerNotesUpdateRequest['params']['data']) {
    this.customerNotes.forEach(n => {
      if (n.id == data.id) {
        Object.assign(n, data);
        n = {...n, lastUpdate: ''}
      }
    });
    this._showCustomerNotesDialog = false;
  }

  deleteCustomerNote(id: string) {
    let note = this.customerNotes.filter(n => n.id == id)[0];
    if (note) {
      this.customerNotesDeletion.push(
        {
          id: note.id,
          userId: note.userId,
          customerNumber: note.customerNumber,
          dateCreated: note.dateCreated,
          lastUpdate: note.lastUpdate,
          title: note.title
        }
      );
    }

    this.customerNotes = this.customerNotes.filter(n => n.id !== id);
  }

  editDynamicArticle(item: AutocompleteItem) {
    this.store.dispatch(setWindow({
      id: 'dynamicArticles' + item.value,
      title: 'Dynamischer Artikel: ' + item.name,
      component: 'shared-dynamic-article-form',
      data: {itemId: item.value}
    }))
  }

  async resolveDynamicArticleIds() {
    const dynamicArticles = await this.dynamicArticlesService.resolveIdsToNames(this.form.get('dynamicArticleIds')?.value ?? []);
    dynamicArticles.map((r: { id: string, name: string }) => {
      this.autocompleteDynamicArticles.selectItem({name: r.name, value: r.id})
    });
  }

  setDynamicArticles(items: string[]) {
    const formArray = this.form.get('dynamicArticleIds') as FormArray;
    formArray.clear();
    items.map((r: any) => {
      formArray.push(this.fb.control(r));
    })
  }


  isFormAddressError(field: string) {
    let form = this.formAddress?.get(field);
    return !!(form?.errors &&
      this.formAddressHandler.getFormStatus() == 'submitForm');
  }

  isFormContactPeronsError(field: string) {
    let form = this.formContactPerson?.get(field);
    return !!(form?.errors &&
      this.formContactPersonHandler.getFormStatus() == 'submitForm');
  }

  isFormError(field: string, debug?: boolean): boolean {
    let form = this.form?.get(field);
    return !!(form?.errors &&
      this.formHandler.getFormStatus() == 'submitForm');
  }


  existAddress(addressId: string): boolean {
    const address = this.addresses.filter(a => a.addressId == addressId)[0];
    return !!address;

  }

  setAddress() {
    this.showAddress();
  }

  showAddress(addressId?: string) {
    this.showAddressId = addressId ?? uuidv4();
    this.showAddressDialog = true;
    const address = this.addresses.filter(a => a.addressId == addressId)[0];
    this.formAddress.get('addressId')?.setValue(address?.addressId ?? this.showAddressId);
    this.formAddress.get('company')?.setValue(address?.company ?? '');
    this.formAddress.get('name')?.setValue(address?.name ?? '');
    this.formAddress.get('firstName')?.setValue(address?.firstName ?? '');
    this.formAddress.get('gesoftName1')?.setValue(address?.gesoftName1 ?? '');
    this.formAddress.get('gesoftName2')?.setValue(address?.gesoftName2 ?? '');
    this.formAddress.get('street')?.setValue(address?.street ?? '');
    this.formAddress.get('postcode')?.setValue(address?.postcode ?? '');
    this.formAddress.get('town')?.setValue(address?.town ?? '');
    this.formAddress.get('salutation')?.setValue(address?.salutation ?? '');
    this.formAddress.get('title')?.setValue(address?.title ?? '');
    this.formAddress.get('additionalAddressInformation')?.setValue(address?.additionalAddressInformation ?? '');
    this.formAddress.get('country')?.setValue(address?.country ?? '');
    this.formAddress.get('phone')?.setValue(address?.phone ?? '');
    this.formAddress.get('mobile')?.setValue(address?.mobile ?? '');
    this.formAddress.get('floor')?.setValue(address?.floor ?? null);
    this.formAddress.get('elevator')?.setValue(address?.elevator ?? false);
    this.formAddress.get('type')?.setValue(address?.type ?? '');
  }

  updateAddress(addressId: string) {
    if (this.formAddress.invalid) {
      this.formAddressHandler.setFormStatus({formStatus: 'submitForm'});
      return;
    }
    if (this.formAddressHandler.getFormStatus() == 'loading') {
      return;
    }
    const address: CustomerAddress = {
      additionalAddressInformation: this.formAddress.get('additionalAddressInformation')?.value ?? '',
      addressId: this.formAddress.get('addressId')?.value ?? '',
      company: this.formAddress.get('company')?.value ?? '',
      country: this.formAddress.get('country')?.value ?? '',
      elevator: this.formAddress.get('elevator')?.value ?? false,
      firstName: this.formAddress.get('firstName')?.value ?? '',
      floor: this.formAddress.get('floor')?.value ?? 0,
      gesoftName1: this.formAddress.get('gesoftName1')?.value ?? '',
      gesoftName2: this.formAddress.get('gesoftName2')?.value ?? '',
      mobile: this.formAddress.get('mobile')?.value ?? '',
      name: this.formAddress.get('name')?.value ?? '',
      phone: this.formAddress.get('phone')?.value ?? '',
      postcode: this.formAddress.get('postcode')?.value ?? '',
      salutation: this.formAddress.get('salutation')?.value ?? '',
      street: this.formAddress.get('street')?.value ?? '',
      title: this.formAddress.get('title')?.value ?? '',
      town: this.formAddress.get('town')?.value ?? '',
      type: (this.formAddress.get('type')?.value as any) ?? 'both'
    }

    this.formAddressHandler.setFormStatus({
      formStatus: "success", resetStatusAfterTimeoutTo: null, callbackAfterTimeout: () => {
        let exist = false;
        for (let i = 0; i < this.addresses.length; i++) {
          if (this.addresses[i].addressId == addressId) {
            exist = true;
            this.addresses[i] = {...address};
            break;
          }
        }
        if (!exist) {
          this.addresses.push(address);
        }
        this.showAddressDialog = false;
      }
    });
  }

  deleteAddress(addressId: string) {
    this.addresses = this.addresses.filter(a => a.addressId !== addressId);
  }

  setContactPerson() {
    this.showContactPerson();
  }

  showContactPerson(contactPersonId?: string) {
    this.showContactPersonId = contactPersonId ?? uuidv4();
    this.showContactPersonDialog = true;

    const contactPerson = this.contactPersons.filter(c => c.contactPersonId == this.showContactPersonId)[0];

    this.showContactPersonDialog = true;
    this.formContactPerson.get('contactPersonId')?.setValue(this.showContactPersonId);
    this.formContactPerson.get('position')?.setValue(contactPerson?.position ?? '');
    this.formContactPerson.get('salutation')?.setValue(contactPerson?.salutation ?? '');
    this.formContactPerson.get('firstName')?.setValue(contactPerson?.firstName ?? '');
    this.formContactPerson.get('name')?.setValue(contactPerson?.name ?? '');
    this.formContactPerson.get('phone')?.setValue(contactPerson?.phone ?? '');
    this.formContactPerson.get('mobile')?.setValue(contactPerson?.mobile ?? '');
    this.formContactPerson.get('email')?.setValue(contactPerson?.mobile ?? '');
    this.formContactPerson.get('additionalInformation')?.setValue(contactPerson?.additionalInformation ?? '');
  }

  updateContactPerson(contactPersonId: string) {
    if (this.formContactPerson.invalid) {
      this.formContactPersonHandler.setFormStatus({formStatus: 'submitForm'});
      return;
    }
    if (this.formContactPersonHandler.getFormStatus() == 'loading') {
      return;
    }
    const contactPerson: CustomerContactPerson = {
      contactPersonId: this.formContactPerson.get('contactPersonId')?.value ?? '',
      position: this.formContactPerson.get('position')?.value ?? '',
      salutation: this.formContactPerson.get('salutation')?.value ?? '',
      firstName: this.formContactPerson.get('firstName')?.value ?? '',
      name: this.formContactPerson.get('name')?.value ?? '',
      phone: this.formContactPerson.get('phone')?.value ?? '',
      mobile: this.formContactPerson.get('mobile')?.value ?? '',
      email: this.formContactPerson.get('email')?.value ?? '',
      additionalInformation: this.formContactPerson.get('additionalInformation')?.value ?? '',
    }

    this.formContactPersonHandler.setFormStatus({
      formStatus: "success", resetStatusAfterTimeoutTo: null, callbackAfterTimeout: () => {
        let exist = false;
        for (let i = 0; i < this.contactPersons.length; i++) {
          if (this.contactPersons[i].contactPersonId == contactPersonId) {
            exist = true;
            this.contactPersons[i] = {...contactPerson};
            break;
          }
        }
        if (!exist) {
          this.contactPersons.push(contactPerson);
        }
        this.showContactPersonDialog = false;

      }
    });
  }

  deleteContactPerson(contactPersonId: string) {
    this.contactPersons = this.contactPersons.filter(c => c.contactPersonId !== contactPersonId);
  }

  addPrice() {
    let copy = [...this.customerArticlePrices];
    copy.unshift({
      articleNumber: '',
      customerNumber: this.form.get('customerNumber')?.value ?? '',
      netPrice: 0,
      netDeposit: 0,
      netFee: 0,
      fromArticleQuantity: 1,
      fromCartQuantity: 1
    });
    this.customerArticlePrices = copy;
  }

  deletePrice(index: number) {
    let copy = [...this.customerArticlePrices];
    copy.splice(index, 1);
    this.customerArticlePrices = copy;
  }

  submit() {
    this.formHandler.setFormStatus({formStatus: 'submitForm'});
    if (this.form.invalid) {
      return;
    }
    this.formHandler.setFormStatus({formStatus: 'loading'});
    let data: ApiCustomersSetRequest['params']['data'] = {
      addresses: [], contactPersons: [], dynamicArticleIds: [],
      customerNumber: this.form.get('customerNumber')?.value ?? '',
      priceGroup: this.form.get('priceGroup')?.value ?? '',
      buyer: this.form.get('buyer')?.value ?? '',
      email: this.form.get('email')?.value ?? '',
      internetAddress: this.form.get('internetAddress')?.value ?? '',
      priceMode: <'net' | 'gross'>this.form.get('priceMode')?.value ?? 'gross',
      creditLimit: this.form.get('creditLimit')?.value ?? 0,
      creditLimitAvailable: this.form.get('creditLimitAvailable')?.value ?? 0,
      defaultDeliveryPeriod: this.form.get('defaultDeliveryPeriod')?.value ?? '',
      industryCategory: this.form.get('industryCategory')?.value ?? '',
      category: this.form.get('category')?.value ?? '',
      visibilityGroups: this.form.get('visibilityGroups')?.value as string[]
    };


    let dynamicFieldsArray = this.form.get('dynamicFields') as FormArray;
    data.dynamicFields = [];
    dynamicFieldsArray.controls.forEach((control: AbstractControl, index: number) => {
      data.dynamicFields?.push({
        label: control.get('label')?.value ?? '',
        value: control.get('value')?.value ?? '',
      })
    });


    data.dynamicArticleIds = [];
    if ((this.form.get('dynamicArticleIds')?.value.length ?? 0) > 0) {
      data.dynamicArticleIds = this.form.get('dynamicArticleIds')?.value ?? [];
    }

    data.articlePrices = [];
    this.customerArticlePrices.map(p => {
      let price: Omit<CustomerArticlePrice, 'customerNumber'> = {
        articleNumber: p.articleNumber,
        netPrice: p.netPrice,
        netFee: 0,
        netDeposit: p.netDeposit,
        fromArticleQuantity: p.fromArticleQuantity,
        fromCartQuantity: p.fromCartQuantity
      };
      if(p.validFrom){
        price.validFrom = p.validFrom;
      }
      if(p.validTo){
        price.validFrom = p.validTo;
      }
      data.articlePrices?.push(price)
    });

    data.addresses = this.addresses;
    data.contactPersons = this.contactPersons;

    const setCustomerNotes = async (): Promise<'success' | 'error'> => {
      let status: 'success' | 'error' = 'success';
      let r;
      for (const n of this.customerNotesDeletion) {
        r = await lastValueFrom(this.customerNoteService.delete(n.id ?? ''));
        if (r.error) {
          this.formHandler.setFormError(r.error);
          status = 'error';
        }
      }

      for (const n of this.customerNotes) {
        // Keine Id > Neuer Eintrag
        if (!n.id) {
          r = await lastValueFrom(this.customerNoteService.set(
            {
              userId: n.userId,
              customerNumber: data.customerNumber ?? '',
              title: n.title,
              note: n.note
            }
          ));
          if (r.error) {
            this.formHandler.setFormError(r.error);
            status = 'error';
          }
          // Kein Wert für das letzte Update, oder die KdNr. hat sich geändert
        } else if (n.lastUpdate == '' ||
          this.customerNumber !== data.customerNumber &&
          n.id) {
          r = await lastValueFrom(this.customerNoteService.update({
            id: n.id ?? '',
            userId: n.userId,
            customerNumber: data.customerNumber,
            title: n.title,
            note: n.note
          }));
          if (r.error) {
            this.formHandler.setFormError(r.error);
            status = 'error';
          }
        }
      }
      return status;
    }

    // Update
    if (this.window?.data &&
      this.window.data.itemId) {
      this.customerService.update({...data, id: this.window?.data.itemId})
        .subscribe(
          {
            next: async (r) => {
              let status = await setCustomerNotes();
              if (r.error || status == 'error') {
                if (r.error) {
                  this.formHandler.setFormError(r.error);
                }
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'error',
                    resetStatusAfterTimeoutTo: null
                  }
                );
              } else if (r.result?.customers.updated) {
                this.formHandler.setFormStatus(
                  {
                    formStatus: status,
                    resetStatusAfterTimeoutTo: null,
                    closeWindowId: this.window?.id,
                    callbackAfterTimeout: () => {
                      this.formHandler.setItemUpdate( this.window?.data.itemId ?? '', this.changeDetectionGroup)
                    }
                  }
                );
              }
            },
            error: (error) => {
              this.formHandler.setFormError({code: 'SUBSCRIPTION_ERROR', message: JSON.stringify(error)});
              this.formHandler.setFormStatus(
                {
                  formStatus: 'error',
                  resetStatusAfterTimeoutTo: null
                }
              );
            }
          }
        )
      // Insert
    } else {
      this.customerService.set(data)
        .subscribe(
          {
            next: async (r) => {
              let status = await setCustomerNotes();
              if (r.error || status == 'error') {
                if (r.error) {
                  this.formHandler.setFormError(r.error);
                }
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'error',
                    resetStatusAfterTimeoutTo: null
                  }
                );
              } else if (r.result?.customers.data.id) {
                this.formHandler.setFormStatus(
                  {
                    formStatus: status,
                    resetStatusAfterTimeoutTo: null,
                    closeWindowId: this.window?.id,
                    callbackAfterTimeout: () => {
                      this.formHandler.setItemUpdate(r.result?.customers.data.id ?? '', this.changeDetectionGroup)
                    }
                  }
                );
              }
            },
            error: (error) => {
              this.formHandler.setFormError({code: 'SUBSCRIPTION_ERROR', message: JSON.stringify(error)});
              this.formHandler.setFormStatus(
                {
                  formStatus: 'error',
                  resetStatusAfterTimeoutTo: null
                }
              );
            }
          }
        );
    }
  }


  protected readonly faCircleExclamation = faCircleExclamation;
  protected readonly faPlusSquare = faPlusSquare;
  protected readonly faTrashCan = faTrashCan;
  protected readonly faEdit = faEdit;
  protected readonly faExclamationCircle = faExclamationCircle;
}
