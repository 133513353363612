import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {
  ApiDeliveryBlockedDaysDeleteRequest,
  ApiDeliveryBlockedDaysDeleteResponse,
  ApiDeliveryBlockedDaysGetRequest,
  ApiDeliveryBlockedDaysGetRequestParams,
  ApiDeliveryBlockedDaysGetResponse,
  ApiDeliveryBlockedDaysSetParamsData,
  ApiDeliveryBlockedDaysSetRequest,
  ApiDeliveryBlockedDaysSetResponse,
  ApiDeliveryBlockedDaysUpdateParamsData,
  ApiDeliveryBlockedDaysUpdateRequest,
  ApiDeliveryBlockedDaysUpdateResponse
} from '@shared/api/api-delivery-blocked-days';

@Injectable({
  providedIn: 'root'
})
export class DeliveryBlockedDaysService {

  constructor(private http: HttpClient) {
  }

  set(data: ApiDeliveryBlockedDaysSetParamsData): Observable<ApiDeliveryBlockedDaysSetResponse> {
    const body: ApiDeliveryBlockedDaysSetRequest = {
      id: 'setDeliveryTour#INVALID_PARAMS',
      command: 'deliveryBlockedDays/set',
      params: {
        data
      }
    };
    return this.http.post<ApiDeliveryBlockedDaysSetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  get(params: ApiDeliveryBlockedDaysGetRequestParams): Observable<ApiDeliveryBlockedDaysGetResponse> {
    const body: ApiDeliveryBlockedDaysGetRequest = {
      id: 'getDeliveryBlockedDays',
      command: 'deliveryBlockedDays/get',
      params: params
    };
    return this.http.post<ApiDeliveryBlockedDaysGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  update(data: ApiDeliveryBlockedDaysUpdateParamsData): Observable<ApiDeliveryBlockedDaysUpdateResponse> {
    const body: ApiDeliveryBlockedDaysUpdateRequest = {
      id: 'updateDeliveryBlockedDays#INVALID_PARAMS',
      command: 'deliveryBlockedDays/update',
      params: {
        data
      }
    };
    return this.http.post<ApiDeliveryBlockedDaysUpdateResponse>(environment.apiUrl, JSON.stringify(body));
  }

  delete(id: string): Observable<ApiDeliveryBlockedDaysDeleteResponse> {
    const body: ApiDeliveryBlockedDaysDeleteRequest = {
      id: 'deleteDeliveryBlockedDays',
      command: 'deliveryBlockedDays/delete',
      params: {
        data: {
          id
        }
      }
    };
    return this.http.post<ApiDeliveryBlockedDaysDeleteResponse>(environment.apiUrl, JSON.stringify(body));
  }
}
