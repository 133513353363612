import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {List} from '@shared/list/models/list';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {
  faEdit,
  faEye,
  faPlusSquare,
  faTrashCan
} from "@fortawesome/free-regular-svg-icons";
import {
  faCaretLeft,
  faCaretRight,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";


@Component({
  selector: 'shared-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  @Input() list!: List<any, any, any>;


  private ngUnsubscribe: Subject<void> = new Subject<void>();

  minWidth: number = 1000;
  visibilityFilter: boolean = false;
  visibilityDialogs: { [key: string] : boolean } = {};

  csvRecords: any;

  constructor() {
  }


  ngOnInit(): void {
    this.list.loading.list
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        r => {
          const columns = this.list.getColumns('userStorage').length;
          if (columns > 6) {
            this.minWidth = columns * 150;
          } else {
            this.minWidth = 1000;
          }
        }
      )
  }

  setVisibilityDialog(key: string){
    if(this.visibilityDialogs[key]) {
      this.visibilityDialogs[key] = false;
    } else {
      this.visibilityDialogs[key] = true;
    }
  }



  getItemId(item: any) {
    return item[this.list.itemIdKey];
  }

  getNumber(number: number) {
    return new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2}).format(number);
  }

  getItemValue(field: string, item: any): any {
    let deepKey = field.split('.');

    if (item[deepKey[0]] == undefined) {
      return '';
    }

    if (deepKey.length > 1) {
      let removedElement = deepKey.shift();
      if (removedElement) {
        return this.getItemValue(deepKey.join('.'), item[removedElement]);
      }
      return '';
    } else {
      return item[field];
    }
  }

  getJSONString(value: object): string {
    return JSON.stringify(value, null, 4);
  }

  replaceString(string: string, searchValue: string, value: string): string {
    return string.replace(searchValue, value);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected readonly faPlusSquare = faPlusSquare;
  protected readonly faCaretLeft = faCaretLeft;
  protected readonly faEye = faEye;
  protected readonly faEdit = faEdit;
  protected readonly faTrashCan = faTrashCan;
  protected readonly faFilter = faFilter;
  protected readonly faCaretRight = faCaretRight;
}
