import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {Window} from '@shared/window-manager/interfaces/window.model';
import {setWindow} from '@store/actions/windows.action';
import {map, take} from 'rxjs/operators';
import {ListColumn} from '@shared/list/models/list-column';
import {IconDefinition} from "@fortawesome/free-regular-svg-icons";
import {MenuItem} from "primeng/api";
import {ApiFilter} from "@shared/api/filter";
import {ApiFilterArrayObject} from "@shared/api/filter-array-objects";

export interface ExternalLink{
  id: string,
  name: string,
  category: 'upload' | 'export'
}

export class List<COLUMN_FIELDS, ItemType, Id extends keyof ItemType> {
  public readonly id: string;
  public headline: string = '';

  menuBar: MenuItem[] | undefined;

  newItem?: {
    label: string;
    action: () => void;
  };

  row: {
    doubleClick: (item?: any) => void,
    details?: {
      edit?: (item: any) => void;
      view?: (item: any) => void
      history?: boolean;
      checkbox?: boolean;
      delete?: {
        action: (item: any) => void;
      },
      buttons?: {
        type?: 'default' | 'overlayPanel' | 'menu',
        title: string,
        style: string,
        class?: string,
        icon?: IconDefinition,
        click: (item: any) => void,
        overlayPanel?: (item: any) => string,
        menu?: (item: any) => MenuItem[] | undefined
      }[]
    }
  } = {
    doubleClick: () => {
    }
  }

  public filtersAdditional?: { id: any, dataType: 'string' | 'date' | 'number', label: string, field: string, comparisonType: 'equal' | '%like%' }[];

  public columns: ListColumn<COLUMN_FIELDS>[] = [];

  private store: Store<AppState>;

  public count: { total: number, filtered: number } = {total: 0, filtered: 0};

  private items: ItemType[] = [];
  public readonly itemIdKey: Id;


  public start: number = 0
  public limit: number = 100;

  public notification?: string;

  public filters: (ApiFilter<string[]> & {id: string; })[] = [];
  public filterArrayObjects: (ApiFilterArrayObject<any, any> & {id: string; })[] = [];
  public filterDates: { id: string, fields?: [string], greaterThan?: string, greaterThanEqual?: string, lessThan?: string, lessThanEqual?: string }[] = [];
  public sort: { field: string, direction: number }[] = [];

  public loading: { notification: BehaviorSubject<boolean>, list: BehaviorSubject<boolean>, download: BehaviorSubject<boolean> } = {
    notification: new BehaviorSubject(false),
    list: new BehaviorSubject(false),
    download: new BehaviorSubject(false)
  };
  public resetItems: boolean = true;

  changeDetection$?: Subscription;

  links: {
    listener$: Subject<{event: 'click', link: ExternalLink}>,
    items?: ExternalLink[],
  } = {
    listener$: new Subject<{event: 'click', link: ExternalLink}>()
  }


  public loadItems: () => void = () => {
  };
  public loadItemAndUpdateList: (id: string) => void = () => {
  };
  public download?: (params: { type: 'csv' | 'xlsx', start: number, limit: number, expectedData: any }) => void;

  constructor(params: { id: string, headline: string, store: Store<AppState>, itemIdKey: Id }) {
    this.id = params.id;
    this.headline = params.headline;
    this.store = params.store;
    this.itemIdKey = params.itemIdKey;
  }

  openWindow(params: Window) {
    this.store.dispatch(setWindow({
      id: params.id,
      title: params.title,
      component: params.component,
      data: params.data
    }, params.options))
  }

  getColumns(visibility: 'all' | 'default' | 'userStorage'): ListColumn<COLUMN_FIELDS>[] {
    switch (visibility) {
      case 'all':
        return this.columns;

      case 'default':
        return this.columns.filter(c => !c.isHidden);

      case 'userStorage':
        let columns: ListColumn<COLUMN_FIELDS>[] = [];
        this.store
          .select(state => state.storage.listConfigs)
          .pipe(
            take(1),
          )
          .subscribe(
            r => {
              let storageList = r.filter(r => r.id == this.id);
              if (storageList.length > 0) {
                storageList.map(
                  s => {
                    s.visibleColumnFields.map(
                      (field: any) => {
                        if (this.columns.filter(cf => cf.field + (cf.readKeyInList || '') == field).length > 0) {
                          columns.push(this.columns.filter(cf => cf.field + (cf.readKeyInList || '') == field)[0]);
                        }
                      }
                    );
                  }
                );
              } else {
                columns = this.columns.filter(c => !c.isHidden);
              }
            }
          );
        return columns;

    }
  }

  getColumnFields(visibility: 'all' | 'default' | 'userStorage', requiredFields: string[] = ['id', 'dateCreated', 'lastUpdate']): any {
    let expectedColumnFields: any = {};
    requiredFields.map(
      r => {
        expectedColumnFields[r] = 1;
      }
    )
    this.getColumns(visibility).map(
      c => {
        expectedColumnFields[c.field] = 1;
      }
    );
    return expectedColumnFields;
  }

  setItems(items: ItemType[], reset: boolean = true) {
    if (reset) {
      this.items = items;
    } else {
      items.map(
        item => {
          this.items.push(item);
        }
      )
    }
  }

  getItems(): ItemType[] {
    return this.items;
  }

  updateItem(params: { id: any, item: ItemType }) {
    this.items = this.items.map(item => {
      if (item[this.itemIdKey] === params.id) {
        item = params.item;
      }
      return item;
    });
  }

  deleteItemSuccess(id: any) {
    this.items = this.items.filter(item => item[this.itemIdKey] !== id);
    this.count.total--;
    this.count.filtered--;
  }

  scroll(event: any) {
    if (
      (event.target.scrollTop * 2) > (event.target.scrollHeight) &&
      this.count?.filtered &&
      this.count.filtered > this.start
    ) {
      this.loading.notification
        .pipe(
          take(1)
        )
        .subscribe(
          r => {
            if (!r) {
              this.loading.notification.next(true);
              this.resetItems = false;
              this.start = this.start + this.limit;
              this.loadItems();
            }
          }
        )
    }
  }

  resetFilter(field: string) {
    this.filters = this.filters.filter(f => f.fields?.indexOf(field));
    this.resetStart();
    this.loadItems();
  }

  resetFilterArrayObject(id: string) {
    this.filterArrayObjects = this.filterArrayObjects.filter(f => f.id == id);
    this.resetStart();
    this.loadItems();
  }

  setFilterArrayObject<KEYS, FIELDS>(filter: ApiFilterArrayObject<KEYS, FIELDS> & { id: string}): void {
    this.filterArrayObjects = this.filterArrayObjects.filter(f => f.id != filter.id);
    if (filter.value != '.*.*' &&
      filter.value != '') {
      this.filterArrayObjects.push(filter);
    }
    this.resetStart();
    this.loadItems();
  }

  setFilter(filter: ApiFilter<string[]> & { id: string}): void {
    this.filters = this.filters.filter(filter => filter.id != filter.id);
    if (filter.values[0] != '.*.*' &&
      filter.values[0] != '') {
      this.filters.push(filter);
    }
    this.resetStart();
    this.loadItems();
  }

  setFilterDate(params: { id: string, fields?: [string], greaterThan?: string, greaterThanEqual?: string, lessThan?: string, lessThanEqual?: string }) {
    let exist: boolean = false;
    if (!params.greaterThan &&
      !params.greaterThanEqual &&
      !params.lessThan &&
      !params.lessThanEqual) {
      this.filterDates = this.filterDates.filter(filter => filter.id != params.id);
    } else {
      this.filterDates.filter(filter => filter.id == params.id).map(
        filter => {
          filter.greaterThan = params.greaterThan;
          filter.greaterThanEqual = params.greaterThanEqual;
          filter.lessThan = params.lessThan;
          filter.lessThanEqual = params.lessThanEqual;
          exist = true;
        }
      )

      if (!exist) {
        this.filterDates.push({
          id: params.id,
          fields: params.fields,
          greaterThan: params.greaterThan,
          greaterThanEqual: params.greaterThanEqual,
          lessThan: params.lessThan,
          lessThanEqual: params.lessThanEqual
        });
      }
    }

    this.resetStart();
    this.loadItems();
  }

  getFilters<T>(): ApiFilter<any>[] {
    let filters: ApiFilter<any>[] = [];
    this.filters.map(
      f => {
        filters.push(
          {
            fields: f.fields,
            values: f.values,
            useRegex: f.useRegex,
            or: f.or
          }
        );
      }
    )
    return filters;
  }

  getFilterArrayObjects<KEYS, FIELDS>(): ApiFilterArrayObject<KEYS, FIELDS>[] {
    let filters: ApiFilterArrayObject<KEYS, FIELDS>[] = [];
    this.filterArrayObjects.map(
      f => {
        filters.push(
          {
            key: f.key,
            field: f.field,
            value: f.value,
            useRegex: f.useRegex,
            or: f.or
          }
        );
      }
    )
    return filters;
  }

  isFilter(): boolean {
    if (this.filters.length > 0 ||
      this.filterDates.length > 0 ||
    this.filterArrayObjects.length > 0) {
      return true;
    }
    return false;
  }

  getFilterDates<T>(): any[] {
    let filterDates: any[] = [];
    this.filterDates.map(
      f => {
        let filterDate: any = {
          fields: f.fields,
          greaterThan: f.greaterThan,
          greaterThanEqual: f.greaterThanEqual,
          lessThan: f.lessThan,
          lessThanEqual: f.lessThanEqual
        };
        if (f.fields) {
          filterDate.fields = f.fields;
        }
        filterDates.push(filterDate);
      }
    )
    return filterDates;
  }

  resetFilters() {
    this.filters = [];
    this.filterArrayObjects = [];
    this.filterDates = [];
    this.reload();
  }

  setSort(sort: { field: string, direction: number }[]) {
    this.sort = sort;
    this.resetStart();
    this.reload();

  }

  getSort<T>(): any {
    if (this.sort.length > 0) {
      return this.sort;
    }
    return undefined;
  }

  resetNotification() {
    this.notification = undefined;
  }

  reset() {
    this.start = 0;
    this.resetItems = true;
    this.filters = [];
  }

  resetStart() {
    this.start = 0;
    this.resetItems = true;
  }

  subscribeChangeDetection(params: {groups: string[], sort?: {field: string, direction: 1 | -1}}){
    this.changeDetection$ = this.store.select(state => state.changeDetection.items)
      .pipe(
        map(items => items?.filter(item => params.groups.includes(item.group)))
      )
      .subscribe(
        r => {
          r?.map((item) => {
            this.handleChangeDetection({
              action: item.action,
              userId: item.userId,
              itemId: item.itemId,
              sortForNewItem: [params.sort || {field: 'dateCreated', direction: -1}]
            });
          });
        }
      );
  }

  handleChangeDetection(params: { action: string, userId: string, itemId: string, sortForNewItem?: [{ field: string, direction: number }] }) {
    if (params.sortForNewItem) {
      this.setSort(params.sortForNewItem);
    }
    switch (params.action) {
      case 'set':
        if (params.userId === 'myself') {
          this.reset();
          this.reload();
        } else {
          this.notification = 'Neue Einträge';
        }
        break;

      case 'update':
        this.loadItemAndUpdateList(params.itemId);
        break;

      case 'delete':
        this.deleteItemSuccess(params.itemId);
        break;
    }
  }

  reload() {
    this.loadItems();
  }

  setLinksExternal(links: ExternalLink[]){
    this.links.items = links;
  }

  getExternalLinks(category: 'upload' | 'export'): ExternalLink[]{
    return this.links.items?.filter(l => l.category == category) ?? [];
  }

  clickExternalLink(link: ExternalLink){
    this.links.listener$.next({event: 'click', link});
  }

  onDestroy(){
    if(this.changeDetection$){
      this.changeDetection$.unsubscribe();
    }
    this.links.listener$.unsubscribe();
  }

}
