<div *ngIf="loading" class="content-content has-text-centered">
  <button class="button is-loading"></button>
</div>
<div #formTag>
  <div *ngIf="!loading">
    <form [formGroup]="form">
      <div class="tabs">
        <ul>
          <li [ngClass]="activeTab=='details' ? 'is-active' : ''" (click)="activeTab='details'">
            <a>Details
            </a></li>
          <li [ngClass]="activeTab=='images' ? 'is-active' : ''" (click)="activeTab='images'">
            <a>Bilder
            </a></li>
          <li [ngClass]="activeTab=='downloads' ? 'is-active' : ''" (click)="activeTab='downloads'">
            <a>Downloads
            </a></li>
        </ul>
      </div>
      <div class="content-content is-tabs">
        <div class="mb-4">
          <shared-form-error [apiError]="formHandler.getFormError()"></shared-form-error>
        </div>
        <div class="tab-container" [ngClass]="activeTab!='details' ? 'is-hidden' : ''">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Positionierung</label>
                <div class="select is-small">
                  <select formControlName="displayOption">
                    <option value="">Standard</option>
                    <option value="popup">Popup einmalig</option>
                    <option value="popupWithoutReadOption">Popup bei jedem öffnen der Seite</option>
                  </select>
                </div>
              </div>

              <div>
                <div class="columns">
                  <div class="column">
                    <div class="field">
                      <label class="label">Online von</label>
                      <p class="control has-icons-left" (click)="from.open()">
                        <input [matDatepicker]="from" class="input is-small" placeholder="Online von" formControlName="onlineFrom" >
                        <span class="icon is-small is-left">
                          <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
                          <mat-datepicker #from></mat-datepicker>
                        </span>
                      </p>
                      <p class="help is-danger" *ngIf="isFormKeyInvalid('onlineFrom')">Bitte hinterlegen Sie ein Datum.</p>
                    </div>
                  </div>
                  <div class="column">
                    <div class="field">
                      <label class="label">Online bis</label>
                      <p class="control has-icons-left" (click)="to.open()">
                        <input [matDatepicker]="to" class="input is-small" placeholder="Online bis" formControlName="onlineTo" >
                        <span class="icon is-small is-left">
                          <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
                          <mat-datepicker #to></mat-datepicker>
                        </span>
                      </p>
                      <p class="help is-danger" *ngIf="isFormKeyInvalid('onlineTo')">Bitte hinterlegen Sie ein Datum.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field mt-2">
                <label class="label">Titel</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="title" placeholder="Titel">
                  <p class="help is-danger" *ngIf="isFormKeyInvalid('title')">Bitte hinterlegen Sie einen Titel.</p>
                </div>
              </div>
              <div class="columns">
                <div class="column is-two-fifths is-full-mobile">
                  <div class="field ">
                    <label class="label"></label>
                    <div class="control">
                      <button (click)="formDynamicFieldsPriceGroups.add()" class="button is-small"><span class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Ausschließlich für Preisgruppe</span></button>
                    </div>
                    <div>
                      <div class="" formArrayName="priceGroups"
                           *ngFor="let item of formDynamicFieldsPriceGroups.getFormArray().controls; let i = index;">
                        <p class="control has-icon-left  mt-2">
                          <input class="input is-small" placeholder="Preisgruppe" [formControlName]="i">
                          <span>
                          <fa-icon [icon]="faTrashCan" (click)="formDynamicFieldsPriceGroups.remove(i)" class=" delete-price-group"></fa-icon>
                        </span>

                        </p>
                        <p class="help is-danger" *ngIf="isFormKeyInvalid('priceGroups.'+i)">Bitte hinterlegen Sie eine Preisgruppe.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Text</label>
                <div class="control">
                  <p-editor formControlName="text" [style]="{'height':'320px'}"></p-editor>
                </div>
              </div>


            </div>

          </div>
        </div>
        <div class="tab-container" [ngClass]="activeTab!='images' ? 'is-hidden' : ''">
          <shared-upload (changedFiles)="changedImages($event)" [filePaths]="imagePaths" labelButton="Bilder hochladen..."></shared-upload>
        </div>
        <div class="tab-container" [ngClass]="activeTab!='downloads' ? 'is-hidden' : ''">
          <shared-upload (changedFiles)="changedFiles($event)" [filePaths]="filePaths" fileType="publicFile"></shared-upload>
        </div>
      </div>
      <div class="content-foot has-text-centered">
        <shared-form-submit-button (submit)="submit()" [formStatus]="formHandler.getFormStatus()"></shared-form-submit-button>
      </div>
    </form>
  </div>
</div>

