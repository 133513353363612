<h1 class="is-size-3 has-text-black is-inline-block">Artikelempfehlungen automatisiert hinterlegen</h1>
<p class="mb-2">Die Artikelempfehlungen werden aus den Käufen des hinterlegten Zeitraums generiert. Falls kein Zeitraum
  angegeben ist, werden alle Bestellungen berücksichtigt. Maximal 10.000 Bestellungen. Der Artikel muss mindestens 2x
  gekauft worden sein, um als Empfehlung zu erscheinen. Pro Bestellungen wird der Artikel 1x gezählt. </p>
<form [formGroup]="form">
  <div class="columns is-desktop">
    <div class="column is-relative">
      <div class="field">
        <p class="control has-icons-left" (click)="from.open()">
          <input [matDatepicker]="from" class="input is-small" placeholder="Ab Datum" formControlName="from" >
          <span class="icon is-small is-left">
                          <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
                          <mat-datepicker #from></mat-datepicker>
                        </span>
        </p>
      </div>
    </div>
    <div class="column is-relative is-marginless-desktop">
      <div class="field">
        <p class="control has-icons-left" (click)="to.open()">
          <input [matDatepicker]="to" class="input is-small" placeholder="Bis Datum" formControlName="to" >
          <span class="icon is-small is-left">
                          <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
                          <mat-datepicker #to></mat-datepicker>
                        </span>
        </p>
      </div>
    </div>
  </div>
  <div class="notification is-warning">
    Bitte beachten Sie, dass manuell gesetzte Artikelempfehlungen hierdurch verloren gehen!
  </div>
  <div class="has-text-centered">
    <shared-form-submit-button [formStatus]="formStatus" (submit)="setArticleRecommendations()"
                           [loadingMessage]="loadingMessage"
                           submitLabel="Artikelempfehlungen setzen"></shared-form-submit-button>
  </div>
</form>

