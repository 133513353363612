import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/state/app.state';
import {setNotification} from '../../store/actions/global.actions';
import {logout} from '../../store/actions/authentication.action';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private store: Store<AppState>) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Es handelt sich um ein Batch
          if(event.body?.length &&
            Array.isArray(event.body) &&
            event.body.length > 1){

            event.body.map( (r: { id: null | string, error?: {type: string, message: string}; }) => {
              if(r.error &&
              r.id?.indexOf('#INVALID_PARAMS') == -1){
                this.store.dispatch(setNotification({notification: '<pre class="mt-2">'+ JSON.stringify(r, null, 4) +'</pre>', notificationType: 'error'}));
              }
            });
          // Einzelner Request
          } else if (event.body?.error &&
            event.body?.id?.indexOf('#INVALID_PARAMS') == -1) {
            this.store.dispatch(setNotification({notification: '<pre class="mt-2">'+ JSON.stringify(event, null, 4) +'</pre>', notificationType: 'error'}));
          }
        }
      }),
      catchError((e: HttpErrorResponse) => {
        console.log('e', e);
        console.log('r', request);
        if(e.error?.error?.type == 'UNAUTHORIZED'){
          console.log('catchError UNAUTHORIZED');
          this.store.dispatch(logout());
        }
        if(e.error?.text.length > 0){
          return throwError({message: '<b>Request</b><br><pre>' + request.body + '</pre><br><br><b>Response</b><br>' + e.error?.text});
        } else if(e.error?.error?.type) {
          return throwError({message: '<b>' + e.error.error.type + '<br>' + e.error.error.message});
        }

        return throwError(e);
      })
    );
  }
}
