import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-list-header-column',
  templateUrl: './list-header-column.component.html',
  styleUrls: ['./list-header-column.component.scss']
})
export class ListHeaderColumnComponent implements OnInit {
  @Input() label: string = '';
  @Input() field: string = '';
  @Input() sortable: boolean = true;
  @Input() sort: {field: string, direction: number}[] = [];
  @Output() setSort: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  toggleSort(field: string) {
    if(!this.sortable){
      return;
    }
    let direction: number | null = null;
    this.sort.filter(sort => sort.field == field).map(
      sort => {
        direction = sort.direction;
        if(direction == 1){
          sort.direction = -1;
        }
      }
    );

    if(direction == -1){
      this.sort = this.sort.filter(sort => sort.field !== field);
    }

    if(direction == null){
      this.sort = [{field, direction: 1}];
    }

    this.setSort.emit(this.sort);
  }


  getSortField(field: string){
    let direction = null;
    this.sort.filter(sort => sort.field == field).map(
      sort => {
        direction = sort.direction;
      }
    );
    return direction;
  }

  protected readonly faArrowUp = faArrowUp;
  protected readonly faArrowDown = faArrowDown;
}
