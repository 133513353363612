import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {ApiFilter} from "@shared/api/filter";

@Component({
  selector: 'app-list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.scss']
})
export class ListSearchComponent implements OnInit {
  @Input() filters?: ApiFilter<any>[] = [];
  @Output() setFilter: EventEmitter<any> = new EventEmitter();

  form = new UntypedFormGroup({
    input: new UntypedFormControl('')
  });

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.filters?.length == 0) {
      this.form.get('input')?.setValue(null);
    }

  }

  _set() {
    this.setFilter.emit({values: ['.*' + this.form.get('input')?.value + '.*'], useRegex: 1})
    //this.setFilter.emit('.*' + this.form.get('input')?.value + '.*');
  }


  protected readonly faSearch = faSearch;
}
