import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as AuthenticationActions from '@store/actions/authentication.action';
import * as StorageActions from '@store/actions/storage.action';
import * as UserActions from '@store/actions/user.action';
import {concatMap, map, tap} from 'rxjs/operators';
import {AuthenticationService} from '@core/services/authentication.service';
import {AppState} from '@store/state/app.state';
import {Store} from '@ngrx/store';
import {resetUser} from '@store/actions/user.action';
import {deleteTokens, setAccessExpire, setAPIConfig, setRefreshExpire, userId} from '@store/actions/storage.action';
import {Router} from '@angular/router';

@Injectable()
export class AuthenticationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private authenticationService: AuthenticationService,
    private router: Router) {
  }

  login$ = createEffect(() => this.actions$.pipe(
      ofType(AuthenticationActions.login),
      // Vor dem Login ein Logout durchführen, damit alle Tokens einschließlich dem injectionToken gelöscht werden
      concatMap((action) => this.authenticationService.login(action.username, action.password)
        .pipe(
          map((r) => {
            if (r.result?.accountBackendUserTokens.data.expiredIn) {
              return AuthenticationActions.loginSuccess(
                {
                  config: r.result?.accountBackendUserTokens.data.config,
                  userId: r.result?.accountBackendUserTokens.data.userId,
                  accessExpiredIn: r.result?.accountBackendUserTokens.data.expiredIn
                }
              )
            }
            return AuthenticationActions.loginFailure(
              {errorMsg: r.error?.message || '[login] Es ist ein unbekannter Fehler aufgetreten.'}
            )
          })
        )
      )
    )
  );

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AuthenticationActions.loginSuccess),
    map((action) => {
        this.store.dispatch(setAPIConfig({config: action.config}));
        this.store.dispatch(setAccessExpire({expiredIn: action.accessExpiredIn}));
        this.store.dispatch(setRefreshExpire({expiredIn: 60 * 60 * 24 * 24}));
        //return StorageActions.userId({userId: '-'});
        return UserActions.setUser({userId: action.userId});
      }
    )
  ));

  refreshToken = createEffect(() => this.actions$.pipe(
    ofType(AuthenticationActions.refreshToken),
    concatMap(() => this.authenticationService.refreshToken()
      .pipe(
        map((r) => {
          if (r.result) {
            return AuthenticationActions.refreshTokenSuccess(
              {
                accessExpiredIn: r.result?.accountBackendUserTokens.data.expiredIn
              }
            )
          } else {
            return AuthenticationActions.refreshTokenFailure()
          }
        })
      )
    )
  ));

  refreshSuccess = createEffect(() => this.actions$.pipe(
    ofType(AuthenticationActions.refreshTokenSuccess),
    map((action) => {
        this.store.dispatch(setAccessExpire({expiredIn: action.accessExpiredIn}));
        return StorageActions.setRefreshExpire({expiredIn: 60 * 60 * 24 * 24});
      }
    )
  ));

  refreshTokenFailure = createEffect(() => this.actions$.pipe(
    ofType(AuthenticationActions.refreshTokenFailure),
    map((action) => {
        return AuthenticationActions.logout();
      }
    )
  ));

  logout$ = createEffect(() => this.actions$.pipe(
      ofType(AuthenticationActions.logout),
      tap(() => {
          this.store.dispatch(resetUser());
          this.store.dispatch(deleteTokens());
          this.store.dispatch(userId({userId: null}));
        }
      ),
      concatMap((action) => this.authenticationService.logout()
        .pipe(
          map(r => {
            if (r.result?.userTokens.deleted) {
              return AuthenticationActions.logoutSuccess();
            }
            return AuthenticationActions.logoutFailure();
          })
        ))
    )
  );

  logoutSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(AuthenticationActions.logoutSuccess),
      tap(() => {
          console.log('logout success');
          this.router.navigate(["/login"]).then();
          //this.router.navigate(['/']).then();
        }
      )
    ), {dispatch: false}
  );
}
