import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from '@core/components/login/login.component';
import {AuthGuard} from '@core/lib/auth-guard';
import {PasswordLostComponent} from '@core/components/password-lost/password-lost.component';
import {PasswordResetComponent} from '@core/components/password-reset/password-reset.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'password-lost', component: PasswordLostComponent},
  {path: 'password-reset', component: PasswordResetComponent},
  {
    path: '', canActivate: [AuthGuard], children: [
      {
        path: 'shop',
        loadChildren: () => import('@feature/shop/shop.module').then(m => m.ShopModule)
      },
      {
        path: 'data',
        loadChildren: () => import('@feature/data/data.module').then(m => m.DataModule)
      },
      {
        path: 'content',
        loadChildren: () => import('@feature/content/content.module').then(m => m.ContentModule)
      },
      {
        path: 'account',
        loadChildren: () => import('@feature/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('@feature/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: '**', redirectTo: 'data/articles/list'
      }
    ]
  },
  // otherwise redirect to home
  {path: '**', redirectTo: '/login'}
];


@NgModule({
  declarations: [],
  imports: [ RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
