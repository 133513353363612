import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {
  ApiCaptchaGetRequest,
  ApiCaptchaGetResponse,
  ApiCaptchaVerifyRequest,
  ApiCaptchaVerifyResponse
} from '@shared/api/captcha';

@Injectable({
  providedIn: 'root'
})
export class CaptchaService {
  constructor(private http: HttpClient) {
  }

  getRandomCaptcha(): Observable<ApiCaptchaGetResponse> {
    const body: ApiCaptchaGetRequest = {id: 'getCaptcha', command: 'captcha/get'};
    return this.http.post<ApiCaptchaGetResponse>(environment.apiAdminUrl, JSON.stringify(body));
  }

  verify(questionId: string, answerId: string): Observable<ApiCaptchaVerifyResponse> {
    const body: ApiCaptchaVerifyRequest = {
      id: 'getCaptcha#INVALID_PARAMS',
      command: 'captcha/verify',
      params: {data: {answerId, questionId}}
    };
    return this.http.post<ApiCaptchaVerifyResponse>(environment.apiAdminUrl, JSON.stringify(body));
  }
}
