import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '@shared/user/services/user.service';
import {FormHelperDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {AppState} from '@store/state/app.state';
import {Store} from '@ngrx/store';
import {faUser} from '@fortawesome/free-regular-svg-icons';
import {faSignIn} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-password-lost',
  templateUrl: './password-lost.component.html',
  styleUrls: ['./password-lost.component.scss']
})
export class PasswordLostComponent implements OnInit {
  @ViewChild('formTag', {static: false}) formTag: ElementRef | undefined;
  form: FormGroup = this.fb.group({
    username: ['', [Validators.required]]
  });
  captcha: boolean = false;
  sentTo: string = '';

  formHandler: FormHelperDeprecated;
  faUser = faUser;
  faSignIn = faSignIn;

  constructor(private userService: UserService, private fb: FormBuilder, private store: Store<AppState>, private renderer: Renderer2
  ) {
    this.formHandler = new FormHelperDeprecated(
      this.store,
      '',
      this.form,
      [
        {key: 'username', error: 'Bitte hinterlegen Sie einen Benutzernamen.'}
      ],
      this.renderer
    );
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.formHandler.htmlRef = this.formTag;
  }

  setCaptcha($event: boolean) {
    setTimeout(() => {
      this.captcha = $event;
    }, 1);

  }

  submit() {
    this.formHandler.validateForm();
    this.formHandler.displayErrors();
    if (!this.formHandler.status &&
      !this.formHandler.isFormErrors()) {
      this.formHandler.status = 'loading';
      this.userService.sendEmailVerificationLostPassword(this.form.get('username')?.value).subscribe(
        r => {
          this.formHandler.handleApiResult({
            r, callbackOnSuccess: () => {
              if (r.result?.accountBackendUserEmailVerification.data.email) {
                this.sentTo = r.result?.accountBackendUserEmailVerification.data.email;
              }

            }
          });
        }
      )
    }

  }

}
