import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {List} from '@shared/list/models/list';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'shared-list-filters-additional',
  templateUrl: './list-filters-additional.component.html',
  styleUrls: ['./list-filters-additional.component.scss']
})
export class ListFiltersAdditionalComponent implements OnInit, OnDestroy {
  @Input() list!: List<any, any, any>;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  form: FormGroup = new FormGroup('');

  constructor() {


  }

  ngOnInit(): void {

    let inputs: FormControl[] = [];
    if(this.list.filtersAdditional){
      this.list.filtersAdditional.map(
        f => {
          inputs[f.id] = new FormControl('');
        }
      )
    }

    this.form = new FormGroup(
      inputs
    );

    this.list.loading.list
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        l => {
          if (this.list.getFilters()?.length == 0) {
            this.form?.reset();
          /*  if(this.list.filtersAdditional){
              this.list.filtersAdditional.map(
                f => {
                  if(this.form.get(f.field) &&
                    this.form.get(f.field)?.value){
                    this.form.get(f.field)?.setValue('');
                  }

                }
              )
            }*/
          }
        }
      );
  }

  setFilter(id: string, field: string) {
      if(this.form?.valid){
        let value: string;
        const comparisonType = this.list.filtersAdditional?.filter(f => f.id == id)[0].comparisonType;
        switch(comparisonType){
          case 'equal':
            value = this.form.get(id)?.value;
            break;

          default:
            value = '.*' + this.form.get(id)?.value + '.*';
            break;
        }
        this.list.setFilter({
          id,
          fields: [field],
          useRegex: 0,
          values: [value]
        });
      }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
