import {Action, createReducer, on} from '@ngrx/store';
import * as UserActions from './../actions/user.action';
import {initialUserState, UserState} from '../state/user.state';


export const initialState: UserState = initialUserState()

const UserReducer = createReducer(
  initialState,
  on(UserActions.setUser, (state: UserState, {userId: string}): UserState => {
    return {
      ...initialState
    };
  }),
  on(UserActions.setUserSuccess, (state: UserState, {userAdmin}): UserState => {
    return {
      ...userAdmin,
      isLoading: false
    };
  }),
  on(UserActions.setUserFailure, (state: UserState): UserState => {
    return initialState;
  }),

  on(UserActions.resetUser, (state: UserState): UserState => {
    return {
      ...initialState
    };
  })
);

export function reducer(state: UserState | undefined, action: Action) {
  return UserReducer(state, action);
}
