import {Action, createReducer, on} from '@ngrx/store';
import * as AuthActions from './../actions/authentication.action';
import {initialAuthenticationState, AuthenticationState} from './../state/authentication.state';

export const initialState: AuthenticationState = initialAuthenticationState()

const AuthenticationReducer = createReducer(
  initialState,
  on(
    AuthActions.login, (state: AuthenticationState, {username, password}): AuthenticationState => {
      return {
        ...state,
        ...{
          isLoading: true,
          errorMsg: undefined
        }
      };
    }),
  on(AuthActions.loginSuccess, (state: AuthenticationState, {
    accessExpiredIn
  }): AuthenticationState => {
    return {
      ...state,
      ...{
        isLoading: false,
        success: true,
        errorMsg: undefined
      }
    };
  }),
  on(AuthActions.loginFailure, (state: AuthenticationState, {errorMsg}): AuthenticationState => {
    return {
      ...state,
      ...{
        isLoading: false,
        success: false,
        errorMsg
      }
    };
  }),

  on(AuthActions.refreshToken, (state: AuthenticationState): AuthenticationState => {
    return {
      ...state,
      ...{
        isLoading: true
      }
    };
  }),
  on(AuthActions.refreshTokenSuccess, (state: AuthenticationState, {
    accessExpiredIn
  }): AuthenticationState => {
    return {
      ...state,
      ...{
        isLoading: false
      }
    };
  }),
  on(AuthActions.refreshTokenFailure, (state: AuthenticationState): AuthenticationState => {
    return initialState;
  }),
  on(AuthActions.logout, (state: AuthenticationState): AuthenticationState => {
    return {
      ...state,
      isLoading: true,
      success: false
    };
  }),
  on(AuthActions.logoutSuccess, (state: AuthenticationState): AuthenticationState => {
    return initialState;
  }),
  on(AuthActions.logoutFailure, (state: AuthenticationState): AuthenticationState => {
    return {...state};
  })
);

export function reducer(state: AuthenticationState | undefined, action: Action) {
  return AuthenticationReducer(state, action);
}
