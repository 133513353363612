import * as fromStorage from '../reducers/storage.reducer';
import * as fromAuthentication from '../reducers/authentication.reducers';
import * as fromApp from './global.reducer';
import * as fromUser from './user.reducer';
import * as fromWindows from './windows.reducer';
import * as fromChangeDetection from './change-detection.reducers';

export const appReducers = {
  storage: fromStorage.reducer,
  global: fromApp.reducer,
  authentication: fromAuthentication.reducer,
  user: fromUser.reducer,
  windows: fromWindows.reducer,
  changeDetection: fromChangeDetection.reducer
};

