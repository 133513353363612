import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormStatusDeprecated} from "@shared/forms/models/form-helper-deprecated";
import {ConfigService} from "@shared/config/services/config.service";
import {ApiConfigsUpdateRequest} from "@shared/api/configs";
import {UploadFilePath} from "@shared/uploads/components/uploads/upload.component";

@Component({
  selector: 'shared-config-dealer',
  templateUrl: './dealer.component.html',
  styleUrls: ['./dealer.component.scss']
})
export class DealerComponent implements OnInit {

  apiError?: {
    notification: string,
    errors?: { key: string, error: string }[]
  };
  form: FormGroup = this.fb.group(
    {
      id: this.fb.control(''),
      relativeLogoPath: this.fb.control(''),
      relativeLogoEmailPath: this.fb.control(''),
      company: this.fb.control('', [Validators.required, Validators.minLength(2)]),
      street: this.fb.control('', [Validators.required, Validators.minLength(2)]),
      postcode: this.fb.control('', [Validators.required, Validators.minLength(2)]),
      town: this.fb.control('', [Validators.required, Validators.minLength(2)]),
      phone: this.fb.control('', [Validators.required, Validators.minLength(2)]),
      email: this.fb.control('', [Validators.required, Validators.email]),
      shopUrl: this.fb.control('', [Validators.required, Validators.minLength(2)])
    }
  );

  relativeLogoPath: UploadFilePath[] = [];
  relativeLogoEmailPath: UploadFilePath[] = [];

  formStatus: FormStatusDeprecated = null;

  constructor(private fb: FormBuilder, private configService: ConfigService) {
  }

  ngOnInit(): void {

    this.configService.getConfig().subscribe(
      r => {
        if (r.result) {
          const config = r.result.configs.data[0];
          this.form.get('id')?.setValue(config.id);
          this.form.get('relativeLogoPath')?.setValue(config.dealer.relativeLogoPath ?? '');
          this.form.get('relativeLogoEmailPath')?.setValue(config.dealer.relativeLogoEmailPath ?? '');
          this.relativeLogoPath = [{filePath: config.dealer.relativeLogoPath, thumbnails: {small: config.dealer.relativeLogoPath, medium: config.dealer.relativeLogoPath, big: config.dealer.relativeLogoPath} }];
          this.relativeLogoEmailPath = [{filePath: config.dealer.relativeLogoEmailPath, thumbnails: {small: config.dealer.relativeLogoEmailPath, medium: config.dealer.relativeLogoEmailPath, big: config.dealer.relativeLogoEmailPath} }];
          this.form.get('company')?.setValue(config.dealer.company ?? '');
          this.form.get('street')?.setValue(config.dealer.street ?? '');
          this.form.get('postcode')?.setValue(config.dealer.postcode ?? '');
          this.form.get('town')?.setValue(config.dealer.town ?? '');
          this.form.get('phone')?.setValue(config.dealer.phone ?? '');
          this.form.get('email')?.setValue(config.dealer.email ?? '');
          this.form.get('shopUrl')?.setValue(config.shopUrl ?? '');
        }
      }
    )
  }

  changedLogo(images: any){
    let filePath = '';
    if(images[0] && images[0].filePath){
      filePath = images[0].filePath;
    }
    this.form.get('relativeLogoPath')?.setValue(filePath);
  }

  changedEmailLogo(images: any){
    let filePath = '';
    if(images[0] && images[0].filePath){
      filePath = images[0].filePath;
    }
    this.form.get('relativeLogoEmailPath')?.setValue(filePath);
  }

  isFormRequestError(value: string): boolean {
    let form = this.form?.get(value);
    return !!(form?.errors &&
      this.formStatus == 'submitForm');
  }

  submit() {
    if (this.formStatus != null &&
      this.formStatus != 'submitForm') {
      return;
    }
    this.formStatus = 'submitForm';
    if (!this.form.valid) {
      return;
    }
    this.formStatus = 'loading';
    let data: ApiConfigsUpdateRequest['params']['data'] = {
      id: this.form.get('id')?.value,
      dealer: {
        relativeLogoPath: this.form.get('relativeLogoPath')?.value,
        relativeLogoEmailPath: this.form.get('relativeLogoEmailPath')?.value,
        company: this.form.get('company')?.value,
        street: this.form.get('street')?.value,
        postcode: this.form.get('postcode')?.value,
        town: this.form.get('town')?.value,
        phone: this.form.get('phone')?.value,
        email: this.form.get('email')?.value
      },
      shopUrl: this.form.get('shopUrl')?.value
    };


    this.configService.updateConfig({data}).subscribe(
      {
        next: (r) => {
          if (r.error) {

            this.apiError = {notification: r.error.message};
            if (r.error.input) {
              this.apiError.errors = r.error.input;
            }
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
            this.formStatus = null;
          }
          if (r.result?.configs.updated) {
            this.formStatus = 'success';
            setTimeout(() => {
              this.formStatus = null;
            }, 1000);
          } else {
            this.formStatus = 'error';
            setTimeout(() => {
              this.formStatus = null;
            }, 1000);
          }
        },
        error: () => {
          this.formStatus = 'error';
          setTimeout(() => {
            this.formStatus = null;
          }, 1000);
        }
      }
    )
  }

}
