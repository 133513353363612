import {Action, createReducer, on} from '@ngrx/store';
import {initialStorageState, StorageState} from '../state/storage.state';
import {
  deleteAccessToken,
  deleteTokens, setAccessExpire, setAPIConfig, setInjectionToken, setListConfig,
  setRefreshExpire,
  userId
} from '../actions/storage.action';

export const initialState: StorageState = initialStorageState();

const StorageReducer = createReducer(
  initialState,
  on(setAPIConfig, (state: StorageState, {config}): StorageState => {
      return {
        ...state,
        APIConfig: config
      }
    }
  ),
  on(setInjectionToken, (state: StorageState, {token, expiredIn}): StorageState => {
      return {
        ...state,
        tokens: {
          access: {
            injectionToken: token,
            expiredIn,
            createdTimestampInSeconds: Date.now() / 1000
          },
          refresh: null
        }
      }
    }
  ),

  on(setAccessExpire, (state: StorageState, {expiredIn}): StorageState => {
      return {
        ...state,
        tokens: {
          refresh: state.tokens.refresh,
          access: {
            expiredIn: expiredIn,
            createdTimestampInSeconds: Date.now() / 1000
          }
        }
      }
    }
  ),
  on(setRefreshExpire, (state: StorageState, {expiredIn}): StorageState => {
      return {
        ...state,
        tokens: {
          access: state.tokens.access,
          refresh: {
            expiredIn: expiredIn,
            createdTimestampInSeconds: Date.now() / 1000
          }
        }
      }
    }
  ),
  on(deleteAccessToken, (state: StorageState): StorageState => {
      return {
        ...state,
        tokens: {
          access: null,
          refresh: state.tokens.refresh
        }
      }
    }
  ),
  on(deleteTokens, (state: StorageState): StorageState => {
      return {
        ...state,
        tokens: {
          access: null,
          refresh: null
        }
      }
    }
  ),
  on(userId, (state: StorageState, {userId}): StorageState => {
      return {
        ...state,
        userId
      }
    }
  ),

  on(setListConfig, (state: StorageState, {id, visibleColumnFields}): StorageState => {
    let listConfigs = state.listConfigs.filter(l => l.id != id)
    listConfigs.push({id, visibleColumnFields});
    return {
      ...state,
      listConfigs
    }
  })
);

export function reducer(state: StorageState | undefined, action: Action) {
  return StorageReducer(state, action);
}
