import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {
  ApiUserTokensDeleteCookieRefreshToken,
  ApiUserTokensRefreshRequest,
  ApiUserTokensRefreshResponse,
  ApiUserTokensSetRequest,
  ApiUserTokensSetResponse
} from '@shared/api/user-tokens';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {AppState} from '@store/state/app.state';
import {Store} from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private httpWithoutInterceptor: HttpClient;

  constructor(private http: HttpClient, private handler: HttpBackend, private store: Store<AppState>) {
    this.httpWithoutInterceptor = new HttpClient(this.handler);
  }

  isInjectionToken(): boolean {
    let isToken: boolean = true;
    this.store.select(state => state.storage.tokens.access?.injectionToken)
      .pipe(
        take(1)
      )
      .subscribe(
        token => {
          if (token) {
            isToken = true;
          } else {
            isToken = false;
          }
        });
    return isToken;
  }

  getInjectionToken(): string {
    let value: string = '';
    this.store.select(state => state.storage.tokens.access?.injectionToken)
      .pipe(
        take(1)
      )
      .subscribe(
        res => {
          if (res) {
            value = res;
          }
        });
    return value;
  }

  getPayloadFromInjectionToken(): { userId: string, exp: number } {
    let value: { userId: string, exp: number } = {userId: '', exp: 0};
    this.store.select(state => state.storage.tokens.access?.injectionToken)
      .pipe(
        take(1)
      )
      .subscribe(
        res => {
          if (res) {
            value = JSON.parse(atob(res.split('.')[1]));
          }
        });
    return value;
  }

  getCreatedAccessTimestampInSeconds(): number {
    let value: number = 0;
    this.store.select(state => state.storage.tokens.access?.createdTimestampInSeconds)
      .pipe(
        take(1)
      )
      .subscribe(
        res => {
          if (res) {
            value = res;
          }
        });
    return value;
  }

  getAccessExpiredInSeconds(): number {
    let value: number = 0;
    this.store.select(state => state.storage.tokens.access?.expiredIn)
      .pipe(
        take(1)
      )
      .subscribe(
        res => {
          if (res) {
            value = res;
          }
        });
    return value;
  }


  getCreatedRefreshTimestampInSeconds(): number {
    let value: number = 0;
    this.store.select(state => state.storage?.tokens.refresh?.createdTimestampInSeconds)
      .pipe(
        take(1)
      )
      .subscribe(
        res => {
          if (res) {
            value = res;
          }
        });
    return value;
  }

  getExpiredRefreshInSeconds(): number {
    let value: number = 0;
    this.store.select(state => state.storage?.tokens.refresh?.expiredIn)
      .pipe(
        take(1)
      )
      .subscribe(
        res => {
          if (res) {
            value = res;
          }
        });
    return value;
  }

  getAPIConfig(): string {
    let value: string = '';
    this.store.select(state => state.storage?.APIConfig)
      .pipe(
        take(1)
      )
      .subscribe(
        res => {
          if (res) {
            value = res;
          }
        });
    return value;
  }

  isAuthentication(): boolean {
    if (
      (
        this.isAccessToken() &&
        !this.isAccessTokenExpired(5)
      ) ||
      (
        this.isRefreshToken() &&
        !this.isRefreshTokenExpired()
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  isAccessToken(): boolean {
    let isToken: boolean = true;
    this.store.select(state => state.storage.tokens.access)
      .pipe(
        take(1)
      )
      .subscribe(
        token => {
          if (token) {
            isToken = true;
          } else {
            isToken = false;
          }
        });
    return isToken;
  }

  isAccessTokenExpired(minutesBefore: number): boolean {
    const createdAccessTimestampInSeconds = this.getCreatedAccessTimestampInSeconds();
    const accessExpiredInSeconds = this.getAccessExpiredInSeconds();
    const secondsRemaining = (this.getCreatedAccessTimestampInSeconds() + this.getAccessExpiredInSeconds() - (minutesBefore * 60)) - (Date.now() / 1000);
    if (secondsRemaining > 0) {
      return false;
    }
    return true;
  }

  isRefreshToken(): boolean {
    let value: boolean = false;
    this.store.select(state => state.storage.tokens.refresh)
      .pipe(
        take(1)
      )
      .subscribe(
        token => {
          if (token) {
            value = true;
          }
        });
    return value;
  }

  isLoading(): boolean {
    let isLoading: boolean = false;
    this.store.select(state => state.authentication.isLoading)
      .pipe(
        take(1)
      )
      .subscribe(
        loading => {
          if (loading) {
            isLoading = true;
          }
        });
    return isLoading;
  }

  isRefreshTokenExpired(): boolean {
    const secondsRemaining = (this.getCreatedRefreshTimestampInSeconds() + this.getExpiredRefreshInSeconds()) - (Date.now() / 1000);
    if (secondsRemaining > 0) {
      return false;
    }
    return true;
  }

  login(username: string, password: string): Observable<ApiUserTokensSetResponse> {

    const body: ApiUserTokensSetRequest = {
      id: 'login',
      command: 'accountBackendUserTokens/set',
      params: {
        data: {
          username,
          password
        }

      }
    };
    return this.httpWithoutInterceptor.post<ApiUserTokensSetResponse>(environment.apiAdminUrl, JSON.stringify(body));
  }

  refreshToken(): Observable<ApiUserTokensRefreshResponse> {
    const bodyRefresh: ApiUserTokensRefreshRequest = {
      id: 'refreshToken',
      command: 'accountBackendUserTokens/refresh',
      params: {
        data: {}
      }
    };
    return this.httpWithoutInterceptor.post<ApiUserTokensRefreshResponse>(environment.apiAdminUrl, JSON.stringify(bodyRefresh));
  }

  logout() {
    const body = {
      id: 'delete',
      command: 'userTokens/delete',
      params: {
        data: {
          source: 'admin'
        }
      }
    };
    return this.httpWithoutInterceptor.post<ApiUserTokensDeleteCookieRefreshToken>(environment.apiAdminUrl, JSON.stringify(body));
  }
}
