import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Window} from '@shared/window-manager/interfaces/window.model';
import {lastValueFrom, Subject} from 'rxjs';
import {
  FormArray, FormBuilder, FormGroup,
  Validators
} from '@angular/forms';
import {DynamicArticlesService} from '@shared/dynamic-articles/services/dynamic-articles.service';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {finalize, map, takeUntil} from 'rxjs/operators';
import {DeliveryToursService} from '@shared/delivery-tours/services/delivery-tours.service';
import {Autocomplete, AutocompleteItem} from '@shared/forms/models/autocomplete';
import {setWindow} from '@store/actions/windows.action';
import {faPlusSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {
  faCog,
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  ApiDeliveryTourDay,
  ApiDeliveryToursSetRequest,
  ApiDeliveryToursUpdateRequest
} from "@shared/api/delivery-tours";
import {FormHandler} from "@shared/forms/models/form-handler";

@Component({
  selector: 'shared-delivery-tours-form',
  templateUrl: './delivery-tour-form.component.html',
  styleUrls: ['./delivery-tour-form.component.scss']
})
export class DeliveryTourFormComponent implements OnInit, OnDestroy {

  @ViewChild('formTag', {static: false}) formTag: ElementRef | undefined;
  @Input() window?: Window;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  changeDetectionGroup: string = 'deliveryTours';
  runChangeDetectionOnGroups: string[] = ['dynamicArticles'];
  changeDetection$ = this.store.select(state => state.changeDetection.items)
    .pipe(
      takeUntil(this.ngUnsubscribe),
      map(items => items?.filter(item => this.runChangeDetectionOnGroups.includes(item.group)))
    );

  activeTab: string = 'details';
  activeTabDay: string = 'monday';
  days: { day: string, key: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday' }[] = [
    {
      day: 'Montag',
      key: 'monday'
    },
    {
      day: 'Dienstag',
      key: 'tuesday'
    },
    {
      day: 'Mittwoch',
      key: 'wednesday'
    },
    {
      day: 'Donnerstag',
      key: 'thursday'
    },
    {
      day: 'Freitag',
      key: 'friday'
    },
    {
      day: 'Samstag',
      key: 'saturday'
    },
    {
      day: 'Sonntag',
      key: 'sunday'
    }
  ];

  form: FormGroup = new FormGroup({
    id: this.fb.control(null),
    default: this.fb.control(null),
    name: this.fb.control(null, Validators.required),
    pickupTour: this.fb.control(null),
    nameDeliveryDate: this.fb.control(null),
    nameDeliveryDates: this.fb.control(null),
    position: this.fb.control('0', Validators.required),
    requiredDynamicArticleIds: this.fb.array([]),
    excludedDynamicArticleIds: this.fb.array([]),
    days: this.fb.group({
      monday: this.getFormGroupForDay(),
      tuesday: this.getFormGroupForDay(),
      wednesday: this.getFormGroupForDay(),
      thursday: this.getFormGroupForDay(),
      friday: this.getFormGroupForDay(),
      saturday: this.getFormGroupForDay(),
      sunday: this.getFormGroupForDay()
    }),
    disableOrderIfConditionsFailed: this.fb.control(false),
    minimumMerchandiseValue: this.fb.group({
      net: this.fb.control('0'),
      gross: this.fb.control('0'),
      excludedGroups: this.fb.array([]),
      note: this.fb.control('')
    }),
    minimumArticles: this.fb.group({
      value: this.fb.control('0'),
      note: this.fb.control(''),
      excludedGroups: this.fb.array([]),
      requiredArticleUnitTypes: this.fb.array([])
    }),
    requiredPriceGroups: this.fb.array([]),
    dynamicArticleIds: this.fb.array([])
  });
  formHandler: FormHandler;


  autocompleteDynamicArticles: Autocomplete = new Autocomplete(
    {
      label: 'Folgende dynamische Artikel automatisch dem Warenkorb hinzufügen, wenn die Tour ausgewählt wurde',
      placeholder: 'Dynamische Artikel suchen...',
      loadItems: (searchString: string) => {
        this.loadDynamicArticles('dynamicArticles').then(() => {      });
      }
    }
  );

  autocompleteRequiredDynamicArticles: Autocomplete = new Autocomplete(
    {
      label: 'Tour greift, wenn folgende dynamische Artikel ausgewählt sind',
      placeholder: 'Dynamische Artikel suchen...',
      loadItems: (searchString: string) => {
        this.loadDynamicArticles('requiredDynamicArticles').then(() => {      })
      }
    }
  );

  autocompleteExcludedDynamicArticles: Autocomplete = new Autocomplete(
    {
      label: 'Tour greift, wenn EINER der folgenden Artikel NICHT ausgewählt ist',
      placeholder: 'Dynamische Artikel suchen...',
      loadItems: (searchString: string) => {
        this.loadDynamicArticles('excludedDynamicArticles').then(() => {      })
      }
    }
  );



  activeDeliveryPeriodSetting: { day: string, key: number } | null = null;
  activeDeliveryPeriodCustomerSetting: { day: string } | null = null;

  data: any;
  loading: boolean = false;


  constructor(
    private deliveryToursService: DeliveryToursService,
    private dynamicArticlesService: DynamicArticlesService,
    private store: Store<AppState>,
    private fb: FormBuilder
  ) {
    this.changeDetection$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(r => {
        r?.map(async () => {
            await this.resolveDynamicArticleIds();
          }
        )
      });

    this.formHandler = new FormHandler(store);
    this.autocompleteDynamicArticles.sortable = false;
  }

  ngOnInit() {
    if (this.window?.data?.itemId) {
      this.loadItem(this.window.data.itemId);
    }
  }

  async loadDynamicArticles(id: 'dynamicArticles' | 'excludedDynamicArticles' | 'requiredDynamicArticles') {
    switch (id) {
      case 'dynamicArticles':
        this.autocompleteDynamicArticles.loading = true;
        break;
      case 'excludedDynamicArticles':
        this.autocompleteExcludedDynamicArticles.loading = true;
        break;
      case 'requiredDynamicArticles':
        this.autocompleteRequiredDynamicArticles.loading = true;
        break;
    }

    let r = await lastValueFrom(this.dynamicArticlesService.get({
      dynamicArticles: {sort: [{field: 'name', direction: 1}]}
    }));

    let items: AutocompleteItem[] = [];
    switch (id) {
      case 'dynamicArticles':
        this.autocompleteDynamicArticles.loading = false;
        if (r.result?.dynamicArticles.data) {
          r.result?.dynamicArticles.data.map(
            item => {
              items.push({value: item.id, name: item.name})
            }
          )
        }
        this.autocompleteDynamicArticles.setItems(items);
        break;

      case 'excludedDynamicArticles':
        this.autocompleteExcludedDynamicArticles.loading = false;
        if (r.result?.dynamicArticles.data) {
          r.result?.dynamicArticles.data.map(
            item => {
              items.push({value: item.id, name: item.name})
            }
          )
        }
        this.autocompleteExcludedDynamicArticles.setItems(items);
        break;

      case 'requiredDynamicArticles':
        this.autocompleteRequiredDynamicArticles.loading = false;
        if (r.result?.dynamicArticles.data) {
          r.result?.dynamicArticles.data.map(
            item => {
              items.push({value: item.id, name: item.name})
            }
          )
        }
        this.autocompleteRequiredDynamicArticles.setItems(items);
        break;
    }

  }

  isFormKeyInvalid(key: string, displayIfSubmitForm: boolean = true): boolean {
    let form = this.form?.get(key);
    return !!(form?.invalid &&
      (
        this.formHandler.getFormStatus() == 'submitForm' &&
        displayIfSubmitForm
      )
    );
  }

  toggleDeliveryPeriodCustomerSettings(day: string) {
    if (this.isDeliveryPeriodCustomerSettingVisibility(day)) {
      this.activeDeliveryPeriodCustomerSetting = null;
    } else {
      this.activeDeliveryPeriodCustomerSetting = {day};
    }
  }

  toggleDeliveryPeriodSettings(day: string, key: number) {
    if (this.isDeliveryPeriodSettingVisibility(day, key)) {
      this.activeDeliveryPeriodSetting = null;
    } else {
      this.activeDeliveryPeriodSetting = {day, key};
    }
  }

  isDeliveryPeriodCustomerSettingVisibility(day: string): boolean {
    return !!(this.activeDeliveryPeriodCustomerSetting &&
      this.activeDeliveryPeriodCustomerSetting.day == day);

  }

  isDeliveryPeriodCustomerSetting(day: string): boolean {
    return !!((
        this.form.get('days')?.get(day)?.get('deliveryPeriodCustomer')?.get('latestOrderTimeInDays')?.value &&
        this.form.get('days')?.get(day)?.get('deliveryPeriodCustomer')?.get('latestOrderTimeInDays')?.value !== 'null'
      )
      ||
      (
        this.form.get('days')?.get(day)?.get('deliveryPeriodCustomer')?.get('note')?.value &&
        this.form.get('days')?.get(day)?.get('deliveryPeriodCustomer')?.get('note')?.value.length > 0
      )
      ||
      this.form.get('days')?.get(day)?.get('deliveryPeriodCustomer')?.get('latestOrderTime')?.value);

  }

  isDeliveryPeriodSettingVisibility(day: string, key: number): boolean {
    return !!(this.activeDeliveryPeriodSetting &&
      this.activeDeliveryPeriodSetting.day == day &&
      this.activeDeliveryPeriodSetting.key == key);

  }

  isDeliveryPeriodSetting(day: string, key: number): boolean {
    if (this.form.get('days')?.get(day)?.get('deliveryPeriods')?.value.length > 0) {
      let dp = this.form.get('days')?.get(day)?.get('deliveryPeriods')?.value[key];
      if (
        (
          dp.latestOrderTimeInDays &&
          dp.latestOrderTimeInDays !== 'null'

        )
        ||
        (
          dp.note &&
          dp.note.length > 0
        )
        ||
        dp.latestOrderTime) {
        return true;
      }
    }
    return false;
  }

  loadItem(id: string) {
    this.loading = true;
    this.deliveryToursService.get({deliveryTours: {}, filters: [{fields: ['id'], values: [id]}]})
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        async r => {

          if (r.result?.deliveryTours.data[0]) {
            let data: any = r.result?.deliveryTours.data[0];
            this.form.get('id')?.setValue(data.id);
            this.form.get('default')?.setValue(data.default);
            this.form.get('name')?.setValue(data.name);
            this.form.get('pickupTour')?.setValue(data.pickupTour ?? '');
            this.form.get('nameDeliveryDate')?.setValue(data.nameDeliveryDate ?? '');
            this.form.get('nameDeliveryDates')?.setValue(data.nameDeliveryDates ?? '');
            this.form.get('position')?.setValue(data.position);


            // Tage
            this.days.map(
              day => {
                data.days[day.key].latestOrderTimeInHours = 23 - data.days[day.key].latestOrderTimeInHours;
                data.days[day.key].latestOrderTimeInMinutes = 60 - data.days[day.key].latestOrderTimeInMinutes;
                data.days[day.key].latestOrderTime = data.days[day.key].latestOrderTimeInHours.toLocaleString('en', {
                  minimumIntegerDigits: 2,
                  minimumFractionDigits: 0,
                  useGrouping: false
                }) + ':' + data.days[day.key].latestOrderTimeInMinutes.toLocaleString('en', {
                  minimumIntegerDigits: 2,
                  minimumFractionDigits: 0,
                  useGrouping: false
                });

                this.form.get('days')?.get(day.key)?.get('available')?.setValue(data.days[day.key].available);
                this.form.get('days')?.get(day.key)?.get('latestOrderTimeInDays')?.setValue(data.days[day.key].latestOrderTimeInDays);
                this.form.get('days')?.get(day.key)?.get('latestOrderTime')?.setValue(data.days[day.key].latestOrderTime);
                this.form.get('days')?.get(day.key)?.get('dateForSecondWeekInterval')?.setValue(data.days[day.key].dateForSecondWeekInterval);
                this.form.get('days')?.get(day.key)?.get('note')?.setValue(data.days[day.key].note);
                this.form.get('days')?.get(day.key)?.get('noteVisibilityBeforeDeliveryDateInDays')?.setValue(data.days[day.key].noteVisibilityBeforeDeliveryDateInDays ?? null);

                if (data.days[day.key].noteVisibilityBeforeDeliveryDateInHours === parseInt(data.days[day.key].noteVisibilityBeforeDeliveryDateInHours) &&
                  data.days[day.key].noteVisibilityBeforeDeliveryDateInMinutes === parseInt(data.days[day.key].noteVisibilityBeforeDeliveryDateInMinutes)) {
                  data.days[day.key].noteVisibilityBeforeDeliveryDateInHours = 23 - data.days[day.key].noteVisibilityBeforeDeliveryDateInHours;
                  data.days[day.key].noteVisibilityBeforeDeliveryDateInMinutes = 60 - data.days[day.key].noteVisibilityBeforeDeliveryDateInMinutes;
                  data.days[day.key].noteVisibilityBeforeDeliveryDateTime = data.days[day.key].noteVisibilityBeforeDeliveryDateInHours.toLocaleString('en', {
                    minimumIntegerDigits: 2,
                    minimumFractionDigits: 0,
                    useGrouping: false
                  }) + ':' + data.days[day.key].noteVisibilityBeforeDeliveryDateInMinutes.toLocaleString('en', {
                    minimumIntegerDigits: 2,
                    minimumFractionDigits: 0,
                    useGrouping: false
                  });
                }

                this.form.get('days')?.get(day.key)?.get('noteVisibilityBeforeDeliveryDateTime')?.setValue(data.days[day.key].noteVisibilityBeforeDeliveryDateTime ?? null);


                // Lieferzeiträume
                // deliveryPeriodsCustomer
                this.form.get('days')?.get(day.key)?.get('deliveryPeriodCustomer')?.get('available')?.setValue(data.days[day.key].deliveryPeriodCustomer.available);
                this.form.get('days')?.get(day.key)?.get('deliveryPeriodCustomer')?.get('latestOrderTimeInDays')?.setValue(data.days[day.key].deliveryPeriodCustomer.latestOrderTimeInDays ?? null);

                if (data.days[day.key].deliveryPeriodCustomer.latestOrderTimeInHours === parseInt(data.days[day.key].deliveryPeriodCustomer.latestOrderTimeInHours) &&
                  data.days[day.key].deliveryPeriodCustomer.latestOrderTimeInMinutes === parseInt(data.days[day.key].deliveryPeriodCustomer.latestOrderTimeInMinutes)
                ) {
                  data.days[day.key].deliveryPeriodCustomer.latestOrderTimeInHours = 23 - data.days[day.key].deliveryPeriodCustomer.latestOrderTimeInHours;
                  data.days[day.key].deliveryPeriodCustomer.latestOrderTimeInMinutes = 60 - data.days[day.key].deliveryPeriodCustomer.latestOrderTimeInMinutes;
                  data.days[day.key].deliveryPeriodCustomer.latestOrderTime = data.days[day.key].deliveryPeriodCustomer.latestOrderTimeInHours.toLocaleString('en', {
                    minimumIntegerDigits: 2,
                    minimumFractionDigits: 0,
                    useGrouping: false
                  }) + ':' + data.days[day.key].deliveryPeriodCustomer.latestOrderTimeInMinutes.toLocaleString('en', {
                    minimumIntegerDigits: 2,
                    minimumFractionDigits: 0,
                    useGrouping: false
                  });
                  this.form.get('days')?.get(day.key)?.get('deliveryPeriodCustomer')?.get('latestOrderTime')?.setValue(data.days[day.key].deliveryPeriodCustomer.latestOrderTime ?? null);
                }
                this.form.get('days')?.get(day.key)?.get('deliveryPeriodCustomer')?.get('note')?.setValue(data.days[day.key].deliveryPeriodCustomer.note ?? null);


                // deliveryPeriods
                data.days[day.key].deliveryPeriods?.map((dp: any) => {

                  if (dp.latestOrderTimeInHours === parseInt(dp.latestOrderTimeInHours) &&
                    dp.latestOrderTimeInMinutes === parseInt(dp.latestOrderTimeInMinutes)) {
                    dp.latestOrderTimeInHours = 23 - dp.latestOrderTimeInHours;
                    dp.latestOrderTimeInMinutes = 60 - dp.latestOrderTimeInMinutes;
                    dp.latestOrderTime = dp.latestOrderTimeInHours.toLocaleString('en', {
                      minimumIntegerDigits: 2,
                      minimumFractionDigits: 0,
                      useGrouping: false
                    }) + ':' + dp.latestOrderTimeInMinutes.toLocaleString('en', {
                      minimumIntegerDigits: 2,
                      minimumFractionDigits: 0,
                      useGrouping: false
                    });
                  }

                  this.getDeliveryPeriodsAsFormArray(day.key, 'deliveryPeriods').push(
                    this.fb.group({
                      deliveryPeriod: this.fb.control(dp.deliveryPeriod, Validators.required),
                      latestOrderTimeInDays: this.fb.control(dp.latestOrderTimeInDays ?? null),
                      latestOrderTime: this.fb.control(dp.latestOrderTime ?? null),
                      note: this.fb.control(dp.note ?? null)
                    })
                  );
                });

              }
            );


            if (data.requiredPriceGroups) {
              data.requiredPriceGroups.map((r: any) => {
                this.getDynamicFieldAsFormArray('requiredPriceGroups').push(this.fb.control(r, Validators.required));
              })
            }

            this.form.get('disableOrderIfConditionsFailed')?.setValue(data.disableOrderIfConditionsFailed ?? false);

            this.form.get('minimumArticles')?.get('value')?.setValue(data.minimumArticles.value ?? 0);
            this.form.get('minimumArticles')?.get('note')?.setValue(data.minimumArticles.note ?? '');
            if (data.minimumArticles.excludedGroups) {
              data.minimumArticles.excludedGroups.map((r: any) => {
                this.getMinimumArticlesFieldAsFormArray('excludedGroups').push(this.fb.control(r, Validators.required));
              })
            }
            if (data.minimumArticles.requiredArticleUnitTypes) {
              data.minimumArticles.requiredArticleUnitTypes.map((r: any) => {
                this.getMinimumArticlesFieldAsFormArray('requiredArticleUnitTypes').push(this.fb.control(r, Validators.required));
              })
            }

            this.form.get('minimumMerchandiseValue')?.get('net')?.setValue(data.minimumMerchandiseValue?.net ?? 0);
            this.form.get('minimumMerchandiseValue')?.get('gross')?.setValue(data.minimumMerchandiseValue?.gross ?? 0);
            this.form.get('minimumMerchandiseValue')?.get('note')?.setValue(data.minimumMerchandiseValue?.note ?? '');
            if (data.minimumMerchandiseValue.excludedGroups) {
              data.minimumMerchandiseValue.excludedGroups.map((r: any) => {
                this.getMinimumMerchandiseValueAsFormArray('excludedGroups').push(this.fb.control(r, Validators.required));
              })
            }


            if (data.dynamicArticleIds) {
              this.setDynamicArticleIds(data.dynamicArticleIds);
            }
            if (data.requiredDynamicArticleIds) {
              this.setRequiredDynamicArticleIds(data.requiredDynamicArticleIds);
            }
            if (data.excludedDynamicArticleIds) {
              this.setExcludedDynamicArticleIds(data.excludedDynamicArticleIds);
            }
            delete data.dynamicArticleIds;

            await this.resolveDynamicArticleIds()
          }
        }
      )
  }

  async resolveDynamicArticleIds() {
    let articleIds = [...this.form.get('requiredDynamicArticleIds')?.value, ...this.form.get('excludedDynamicArticleIds')?.value, ...this.form.get('dynamicArticleIds')?.value];
    const dynamicArticles = await this.dynamicArticlesService.resolveIdsToNames(articleIds);
    dynamicArticles.map((r: { id: string, name: string }) => {
      if(this.form.get('requiredDynamicArticleIds')?.value.filter( (id: string) => id == r.id).length > 0) {
        this.autocompleteRequiredDynamicArticles.selectItem({name: r.name, value: r.id})
      }
      if(this.form.get('excludedDynamicArticleIds')?.value.filter( (id: string) => id == r.id).length > 0) {
        this.autocompleteExcludedDynamicArticles.selectItem({name: r.name, value: r.id})
      }
      if(this.form.get('dynamicArticleIds')?.value.filter( (id: string) => id == r.id).length > 0){
        this.autocompleteDynamicArticles.selectItem({name: r.name, value: r.id})
      }
    });
  }

  getFormGroupForDay(): FormGroup {
    return this.fb.group({
      available: this.fb.control(false),
      deliveryPeriodCustomer: this.fb.group(
        {
          available: this.fb.control(true),
          latestOrderTimeInDays: this.fb.control(null),
          latestOrderTime: this.fb.control(null),
          note: this.fb.control(null),
        }
      ),
      deliveryPeriods: this.fb.array(
        []
      ),
      latestOrderTimeInDays: this.fb.control(1, Validators.required),
      latestOrderTime: this.fb.control('23:59'),
      dateForSecondWeekInterval: this.fb.control(null),
      note: this.fb.control(null),
      noteVisibilityBeforeDeliveryDateInDays: this.fb.control(null),
      noteVisibilityBeforeDeliveryDateTime: this.fb.control(null),
    });
  }

  addDeliveryPeriod(day: string) {
    this.getDeliveryPeriodsAsFormArray(day, 'deliveryPeriods').push(this.fb.group({
      deliveryPeriod: this.fb.control('', Validators.required),
      latestOrderTimeInDays: this.fb.control(null),
      latestOrderTime: this.fb.control(null),
      note: this.fb.control(null)
    }));
  }

  removeDeliveryPeriod(day: string, index: number) {
    const formArray = this.getDeliveryPeriodsAsFormArray(day, 'deliveryPeriods');
    formArray.removeAt(index);
  }

  getDeliveryPeriodsAsFormArray(day: string, type: string): FormArray {
    return this.form.get('days')?.get(day)?.get(type) as FormArray;
  }

  addMinimumArticlesField(field: string) {
    this.getMinimumArticlesFieldAsFormArray(field).push(this.fb.control('', Validators.required));
  }

  removeMinimumArticlesField(field: string, index: number) {
    const formArray = this.getMinimumArticlesFieldAsFormArray(field);
    formArray.removeAt(index);
  }

  getMinimumArticlesFieldAsFormArray(field: string): FormArray {
    return this.form.get('minimumArticles')?.get(field) as FormArray;
  }

  addMinimumMerchandiseValueField(field: string) {
    this.getMinimumMerchandiseValueAsFormArray(field).push(this.fb.control('', Validators.required));
  }

  removeMinimumMerchandiseValueField(field: string, index: number) {
    const formArray = this.getMinimumMerchandiseValueAsFormArray(field);
    formArray.removeAt(index);
  }

  getMinimumMerchandiseValueAsFormArray(field: string): FormArray {
    return this.form.get('minimumMerchandiseValue')?.get(field) as FormArray;
  }

  addDynamicField(field: string) {
    this.getDynamicFieldAsFormArray(field).push(this.fb.control('', Validators.required));
  }

  removeDynamicField(field: string, index: number) {
    const formArray = this.getDynamicFieldAsFormArray(field);
    formArray.removeAt(index);
  }

  getDynamicFieldAsFormArray(field: string): FormArray {
    return this.form.get(field) as FormArray;
  }

  editDynamicArticle(item: AutocompleteItem) {
    this.store.dispatch(setWindow({
      id: 'dynamicArticles' + item.value,
      title: 'Dynamischer Artikel: ' + item.name,
      component: 'shared-dynamic-article-form',
      data: {itemId: item.value}
    }))
  }

  setDynamicArticleIds(items: string[]) {
    //this.form.get('dynamicArticleIds')?.setValue(this.fb.control(''));
    const formArray = this.form.get('dynamicArticleIds') as FormArray;
    formArray.clear();
    items.map((r: any) => {
      formArray.push(this.fb.control(r));
    })
  }

  setExcludedDynamicArticleIds(items: string[]) {
    //this.form.get('dynamicArticleIds')?.setValue(this.fb.control(''));
    const formArray = this.form.get('excludedDynamicArticleIds') as FormArray;
    formArray.clear();
    items.map((r: any) => {
      formArray.push(this.fb.control(r));
    })
  }

  setRequiredDynamicArticleIds(items: string[]) {
    //this.form.get('dynamicArticleIds')?.setValue(this.fb.control(''));
    const formArray = this.form.get('requiredDynamicArticleIds') as FormArray;
    formArray.clear();
    items.map((r: any) => {
      formArray.push(this.fb.control(r));
    })
  }

  submit() {

    if (this.form.invalid) {
      this.formHandler.setFormStatus({formStatus: 'submitForm'});
      return;
    }
    if (this.formHandler.getFormStatus() == 'loading') {
      return;
    }
    this.formHandler.setFormStatus({formStatus: 'loading'});
    let getData = (): Omit<ApiDeliveryToursSetRequest['params']['data'], 'days'> => {
      let data: Omit<ApiDeliveryToursSetRequest['params']['data'], 'days'> = {
        default: this.form.get('default')?.value || false,
        requiredPriceGroups: this.form.get('requiredPriceGroups')?.value,
        requiredDynamicArticleIds: this.form.get('requiredDynamicArticleIds')?.value,
        excludedDynamicArticleIds: this.form.get('excludedDynamicArticleIds')?.value,
        name: this.form.get('name')?.value,
        position: parseInt(this.form.get('position')?.value),
        pickupTour: this.form.get('pickupTour')?.value || false,
        nameDeliveryDate: this.form.get('nameDeliveryDate')?.value ?? '',
        nameDeliveryDates: this.form.get('nameDeliveryDates')?.value ?? '',
        disableOrderIfConditionsFailed: this.form.get('disableOrderIfConditionsFailed')?.value,
        minimumMerchandiseValue: {
          net: parseFloat(this.form.get('minimumMerchandiseValue')?.get('net')?.value ?? 0),
          gross: parseFloat(this.form.get('minimumMerchandiseValue')?.get('gross')?.value ?? 0),
          note: this.form.get('minimumMerchandiseValue')?.get('note')?.value ?? '',
          excludedGroups: this.form.get('minimumMerchandiseValue')?.get('excludedGroups')?.value,
        },
        minimumArticles: {
          value: parseFloat(this.form.get('minimumArticles')?.get('value')?.value),
          note: this.form.get('minimumArticles')?.get('note')?.value ?? '',
          excludedGroups: this.form.get('minimumArticles')?.get('excludedGroups')?.value,
          requiredArticleUnitTypes: this.form.get('minimumArticles')?.get('requiredArticleUnitTypes')?.value
        },
        dynamicArticleIds: []
      };
      if (this.form.get('dynamicArticleIds')?.value.length > 0) {
        data.dynamicArticleIds = this.form.get('dynamicArticleIds')?.value;
      }
      return data;
    };
    let getDay = (day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'): ApiDeliveryTourDay => {
      let data: ApiDeliveryTourDay = {
        available: this.form.get('days')?.get(day)?.get('available')?.value,
        latestOrderTimeInDays: parseInt(this.form.get('days')?.get(day)?.get('latestOrderTimeInDays')?.value),
        latestOrderTimeInHours: 23 - parseInt(this.form.get('days')?.get(day)?.get('latestOrderTime')?.value.split(":")[0]),
        latestOrderTimeInMinutes: 60 - parseInt(this.form.get('days')?.get(day)?.get('latestOrderTime')?.value.split(":")[1]),
        note: this.form.get('days')?.get(day)?.get('note')?.value ?? '',
        noteVisibilityBeforeDeliveryDateInDays: parseInt(this.form.get('days')?.get(day)?.get('noteVisibilityBeforeDeliveryDateInDays')?.value ?? 0),
        noteVisibilityBeforeDeliveryDateInHours: undefined,
        noteVisibilityBeforeDeliveryDateInMinutes: undefined,
        deliveryPeriodCustomer:
          {
            available: this.form.get('days')?.get(day)?.get('deliveryPeriodCustomer')?.get('available')?.value,
            latestOrderTimeInDays: parseInt(this.form.get('days')?.get(day)?.get('deliveryPeriodCustomer')?.get('latestOrderTimeInDays')?.value),
            latestOrderTimeInHours: undefined,
            latestOrderTimeInMinutes: undefined,
            note: this.form.get('days')?.get(day)?.get('deliveryPeriodCustomer')?.get('note')?.value ?? ''
          }
      }
      if (this.form.get('days')?.get(day)?.get('noteVisibilityBeforeDeliveryDateTime')?.value) {
        data.noteVisibilityBeforeDeliveryDateInHours = 23 - parseInt(this.form.get('days')?.get(day)?.get('noteVisibilityBeforeDeliveryDateTime')?.value?.split(":")[0] ?? 0);
        data.noteVisibilityBeforeDeliveryDateInMinutes = 60 - parseInt(this.form.get('days')?.get(day)?.get('noteVisibilityBeforeDeliveryDateTime')?.value?.split(":")[1] ?? 0);
      }
      if (this.form.get('days')?.get(day)?.get('deliveryPeriodCustomer')?.get('latestOrderTime')?.value &&
        data.deliveryPeriodCustomer) {
        data.deliveryPeriodCustomer.latestOrderTimeInHours = 23 - parseInt(this.form.get('days')?.get(day)?.get('deliveryPeriodCustomer')?.get('latestOrderTime')?.value?.split(":")[0] ?? 0);
        data.deliveryPeriodCustomer.latestOrderTimeInMinutes = 60 - parseInt(this.form.get('days')?.get(day)?.get('deliveryPeriodCustomer')?.get('latestOrderTime')?.value?.split(":")[1] ?? 0);
      }
      if (this.form.get('days')?.get(day)?.get('dateForSecondWeekInterval')?.value !== null) {
        data.dateForSecondWeekInterval = this.form.get('days')?.get(day)?.get('dateForSecondWeekInterval')?.value;
      }
      let deliveryPeriods = this.form.get('days')?.get(day)?.get('deliveryPeriods')?.value.map((dp: any) => {
        // Check whether dp.latestOrderTime exists and is in the correct format
        let timeParts = dp.latestOrderTime ? dp.latestOrderTime.split(":") : [0, 0];
        let deliveryPeriod: any = {
          deliveryPeriod: dp.deliveryPeriod,
          latestOrderTimeInDays: dp.latestOrderTimeInDays,
          note: dp.note ?? ''
        };
        if (timeParts[0]) {
          deliveryPeriod.latestOrderTimeInHours = 23 - parseInt(timeParts[0] ?? 0);
        }
        if (timeParts[1]) {
          deliveryPeriod.latestOrderTimeInMinutes = 60 - parseInt(timeParts[1]);
        }
        return deliveryPeriod;
      });
      data.deliveryPeriods = [];
      if (deliveryPeriods.length > 0) {
        data.deliveryPeriods = deliveryPeriods;
      }
      return data;
    }

    let getDays = (): ApiDeliveryToursSetRequest['params']['data']['days'] => {
      return {
        monday: getDay('monday'),
        tuesday: getDay('tuesday'),
        wednesday: getDay('wednesday'),
        thursday: getDay('thursday'),
        friday: getDay('friday'),
        saturday: getDay('saturday'),
        sunday: getDay('sunday')
      };
    }

    // Update
    if (this.window?.data &&
      this.window.data.itemId) {
      let data: ApiDeliveryToursUpdateRequest['params']['data'] = {
        ...getData(),
        days: getDays(),
        id: this.window.data.itemId
      };
      this.deliveryToursService.update(data)
        .subscribe(
          {
            next: (r) => {
              if (r.error) {
                this.formHandler.setFormError(r.error);
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'error',
                    resetStatusAfterTimeoutTo: null
                  }
                );
              } else {
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'success',
                    resetStatusAfterTimeoutTo: null,
                    closeWindowId: this.window?.id,
                    callbackAfterTimeout: () => {
                      this.formHandler.setItemUpdate(data.id, this.changeDetectionGroup)
                    }
                  }
                )
              }
            },
            error: (error) => {
              this.formHandler.setFormError({code: 'SUBSCRIPTION_ERROR', message: JSON.stringify(error)});
              this.formHandler.setFormStatus(
                {
                  formStatus: 'error',
                  resetStatusAfterTimeoutTo: null
                }
              );
            }
          }
        )
      // Insert
    } else {
      let data: ApiDeliveryToursSetRequest['params']['data'] = {
        ...getData(),
        days: getDays()
      };
      this.deliveryToursService.set(data)
        .subscribe(
          {
            next: (r) => {
              if (r.error) {
                this.formHandler.setFormError(r.error);
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'error',
                    resetStatusAfterTimeoutTo: null
                  }
                )
              } else {
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'success',
                    resetStatusAfterTimeoutTo: null,
                    closeWindowId: this.window?.id,
                    callbackAfterTimeout: () => {
                      this.formHandler.setItemSet(r.result?.deliveryTours.data.id ?? '', this.changeDetectionGroup)
                    }
                  }
                )
              }
            },
            error: (error) => {
              this.formHandler.setFormError({code: 'deliveryToursService.get', message: JSON.stringify(error)});
              this.formHandler.setFormStatus(
                {
                  formStatus: 'error',
                  resetStatusAfterTimeoutTo: null
                }
              );
            }
          }
        )
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }


  protected readonly faPlusSquare = faPlusSquare;
  protected readonly faTrashCan = faTrashCan;
  protected readonly faCog = faCog;
  protected readonly faInfoCircle = faInfoCircle;
  protected readonly faExclamationCircle = faExclamationCircle;
}
