import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {UploadService} from '@shared/uploads/services/upload.service';
import {CdkDragDrop, transferArrayItem} from '@angular/cdk/drag-drop';
import {HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';
import {environment} from '@environments/environment';
import {faTimes, faUpload} from "@fortawesome/free-solid-svg-icons";
import {mergeMap, Observable, of, Subject} from "rxjs";
import {delay, map, takeUntil} from "rxjs/operators";
import {ApiUploadsImageResponse} from '@shared/api/uploads';
import {FormBuilder, FormGroup} from "@angular/forms";

export interface UploadAdditionalInputFields {
  type: 'select' | 'input',
  name: string,
  value: string | number,
  options?: {name?: string, value: string}[]
}

export interface UploadFilePath {
  filePath: string;
  thumbnails?: {
    small: string;
    medium: string;
    big: string;
  }
  additionalInputFields?: { [key: string]: string };
}

@Component({
  selector: 'shared-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('file') uploadedFile!: ElementRef;
  @Output() changedFiles: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUploadImagesSuccess = new EventEmitter<{
    originalFilename: string,
    filePath: string,
    thumbnails: {
      small: string;
      medium: string;
      big: string;
    }
  }[]>();
  @Input() showPreviewImage: boolean = true;
  @Input() labelButton: string = 'Dateien hochladen...';
  @Input() label: string = 'Datei/en';
  @Input() maxFiles: number = 0;
  @Input() fileType: 'image' | 'privateFile' | 'publicFile' = 'image';
  @Input() filePaths: UploadFilePath[] = [];
  @Input() additionalInputFields: UploadAdditionalInputFields[] = [];

  unsubscribe$ = new Subject<void>();

  form: FormGroup = new FormGroup({
    additionalInputFields: this.fb.array([]),
  });

  apiError?: {
    notification: string,
    errors?: { key: string, error: string }[]
  };
  uploadFiles: {
    uploadStatus: number,
    done: boolean,
    statusTxt: string,
    uploadData: File
  } [] = [];
  uploadImages: {
    originalFilename: string,
    filePath: string,
    thumbnails: {
      small: string;
      medium: string;
      big: string;
    }
  }[] = [];
  message: string = '';

  fileGroups: { files: UploadFilePath[] }[] = [];
  s3Url: string = environment.s3Url;

  simultaneousRequestLimit: number = 5;

  constructor(private uploadService: UploadService, private fb: FormBuilder) {

  }

  ngOnInit(): void {
    this.setGroups();
  }

  ngOnChanges() {
    this.setGroups();
  }

  setGroups() {
    this.fileGroups = [];
    this.filePaths.map(file => {
      this.fileGroups.push({files: [file]});
    });
  }

  changeAdditionalInputOnFilePath(filePathKey: number, name: string, value: string) {
    this.filePaths[filePathKey].additionalInputFields = {
      ...this.filePaths[filePathKey].additionalInputFields,
      [name]: value
    };
    this.fileGroups[filePathKey].files[0].additionalInputFields = {
      ...this.filePaths[filePathKey].additionalInputFields,
      [name]: value
    };
    this.changedFiles.emit(this.filePaths);
  }

  getAdditionalInputValue(filePathKey: number, name: string): string{
    let inputValue: string = '';

    for (const [key, value] of Object.entries(this.filePaths[filePathKey].additionalInputFields ?? {})) {
      if(name == key){
        inputValue = value;
      }
    }
    return inputValue;
  }

  refreshFilePaths() {
    this.filePaths = [];
    this.fileGroups.map(group => {
      group.files.map(file => {
        this.filePaths.push(file);
      })
    });
    this.changedFiles.emit(this.filePaths);
  }

  drop(event: CdkDragDrop<UploadFilePath[], any>, index: number) {

    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex);
    this.refreshFilePaths();
    this.setGroups();
  }

  setFiles(files: FileList | null) {
    if (files) {
      for (let i = 0; i < files.length; i++) {
        this.uploadFiles.push(
          {
            uploadStatus: 0,
            done: false,
            statusTxt: 'Übertrage ' + files[i].name + '...',
            uploadData: files[i]
          });
      }
    }
    if (this.fileType == 'image') {
      this.handleImageUpload();
    } else {
      this.handleFileUpload();
    }
    // reset upload
    this.uploadedFile.nativeElement.value = '';
  }

  isUploadDone(): boolean {
    if (this.uploadFiles.length == 0) {
      return false;
    }
    if (this.uploadFiles.filter(f => !f.done)[0]) {
      return false;
    } else {
      if (this.fileType == 'image') {
        this.onUploadImagesSuccess.emit(this.uploadImages);
        this.uploadImages = [];
      }
      return true;
    }
  }

  handleFileUpload() {
    const apiCalls: Observable<{ r: HttpEvent<ApiUploadsImageResponse>, file: any }>[] = [];
    this.uploadFiles.map((file: any) => {
      if (file.uploadStatus !== 0) {
        return;
      }
      const fileRead$ = new Observable(observer => {
        const reader = new FileReader();
        reader.readAsDataURL(file.uploadData);
        reader.onload = event => {
          observer.next(reader.result);
          observer.complete();
        }
      });

      const apiCall$ = fileRead$.pipe(
        mergeMap((fileBase64: any) => {
            let visibility: 'private' | 'public' = 'private';
            if (this.fileType == 'publicFile') {
              visibility = 'public';
            }
            return this.uploadService.file({
              data: {
                fileName: file.uploadData.name,
                fileBase64,
                visibility
              }
            }).pipe(
              map(r => ({r, file})),
              delay(1000)
            )
          }
        )
      );
      apiCalls.push(apiCall$);
    });
    of(...apiCalls)
      .pipe(
        mergeMap(apiCall => apiCall, this.simultaneousRequestLimit),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(({r, file}) => {
          if (r.type === HttpEventType.UploadProgress) {
            // This is an upload progress event. Compute and show the % done:
            const percentDone = Math.round(100 * r.loaded / (r.total || 0));
            file.uploadStatus = percentDone;

            if (percentDone === 100) {
              file.statusTxt = 'Einen Augenblick bitte, die Daten werden auf dem Server verarbeitet...';
            }
            //console.log(`File is ${percentDone}% uploaded.`);/
          } else if (r instanceof HttpResponse) {
            // Keine Fehler
            if (r.body?.error) {
              this.apiError = {notification: ' ' + r.body?.error?.message + ' [' + file.uploadData.name + ']'};
              if (r.body?.error?.input) {
                this.apiError.errors = r.body.error.input;
              }
            } else if (r.body?.result?.uploads.data.filePath) {
              this.filePaths.push({
                filePath: r.body?.result?.uploads.data.filePath
              });
              this.setGroups();
              this.changedFiles.emit(this.filePaths);
            }

            file.done = true;
          } else {
            file.done = true;
          }
        }
      );
  }

  handleImageUpload() {
    const apiCalls: Observable<{ r: HttpEvent<ApiUploadsImageResponse>, file: any }>[] = [];
    this.uploadFiles.map((file: any) => {
      if (file.uploadStatus !== 0) {
        return;
      }
      const fileRead$ = new Observable(observer => {
        const reader = new FileReader();
        reader.readAsDataURL(file.uploadData);
        reader.onload = event => {
          observer.next(reader.result);
          observer.complete();
        }
      });

      const apiCall$ = fileRead$.pipe(
        mergeMap((fileBase64: any) =>
          this.uploadService.image({
            data: {
              fileName: file.uploadData.name,
              fileBase64,
              thumbnails: [
                {name: 'small', width: 300, height: 300},
                {name: 'medium', width: 800, height: 800},
                {name: 'big', width: 1500, height: 1500}
              ]
            }
          }).pipe(
            map(r => ({r, file})),
            delay(1000)
          )
        )
      );
      apiCalls.push(apiCall$);
    });
    of(...apiCalls)
      .pipe(
        mergeMap(apiCall => apiCall, this.simultaneousRequestLimit),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(({r, file}) => {
        if (r.type === HttpEventType.UploadProgress) {
          // This is an upload progress event. Compute and show the % done:
          const percentDone = Math.round(100 * r.loaded / (r.total || 0));
          file.uploadStatus = percentDone;

          if (percentDone === 100) {
            file.statusTxt = file.uploadData.name + ' wird auf dem Server verarbeitet...';
          }
          //console.log(`File is ${percentDone}% uploaded.`)/
        } else if (r instanceof HttpResponse) {
          if (r.body?.error) {
            this.apiError = {notification: ' ' + r.body?.error?.message + ' [' + file.uploadData.name + ']'};
            if (r.body?.error?.input) {
              this.apiError.errors = r.body.error.input;
            }
            // KEine Fehler
          } else if (
            r.body?.result?.uploads.data.filePath
          ) {
            this.filePaths.push({
              filePath: r.body?.result?.uploads.data.filePath,
              thumbnails: r.body?.result?.uploads.data.thumbnails
            });
            if (r.body?.result?.uploads.data.thumbnails) {
              this.uploadImages.push({
                originalFilename: file.uploadData.name,
                filePath: r.body?.result?.uploads.data.filePath,
                thumbnails: r.body?.result?.uploads.data.thumbnails
              })
            }
            this.setGroups();
          }
          this.changedFiles.emit(this.filePaths);
          file.done = true;
          this.isUploadDone();
        } else if (r instanceof HttpErrorResponse) {
          file.done = true;
          this.isUploadDone();
        }
      });
  }

  deleteFile(key: number) {
    this.fileGroups.splice(key, 1);
    this.refreshFilePaths();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  protected readonly faUpload = faUpload;
  protected readonly faTimes = faTimes;
}
