import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {AuthenticationService} from '@core/services/authentication.service';
import {setAccessExpire} from '@store/actions/storage.action';
import {environment} from '@environments/environment';
import {CaptchaService} from '@core/services/captcha.service';
import {faBan, faCheckCircle} from "@fortawesome/free-solid-svg-icons";


@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent implements OnInit {


  @Output() isVerified = new EventEmitter<boolean>();

  loading: boolean = true;
  verified: boolean = false;

  questionId: string = '';
  question: string = '';
  answers: { id: string, img: string, verify?: boolean }[] = [];

  urlS3: string  = environment.s3Url;

  constructor(private store: Store<AppState>, private captchaService: CaptchaService, private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    if(this.authenticationService.isAccessTokenExpired(1)){
      this.load();
      this.verified = false;
    } else {
      this.verified = true;
    }
    this.isVerified.emit(this.verified);
  }

  load() {
    this.captchaService.getRandomCaptcha().subscribe(
      r => {
        if (r.result?.captcha) {
          this.questionId = r.result.captcha.data.questionId;
          this.question = r.result.captcha.data.question;
          this.answers = r.result.captcha.data.answerOptions;
          this.loading = false;
        }
      }
    )
  }

  verify(answerId: string) {
    this.captchaService.verify(this.questionId, answerId).subscribe(
      r => {
        let verify = false;
        if (r.result?.captcha) {
          this.store.dispatch(setAccessExpire({
            expiredIn: r.result.captcha.data.expiredIn
          }));
          verify = true;
        }
        this.answers.map(answer => {
          if (answer.id == answerId) {
            answer.verify = verify;
          }
        })
        setTimeout(() => {
          this.isVerified.emit(verify);
          if(!verify){
            this.loading = true;
            setTimeout(() => {
              this.load();
            }, 500)
          } else {
            this.verified = true;
          }
        }, 200);
      }
    )
  }

  protected readonly faCheckCircle = faCheckCircle;
  protected readonly faBan = faBan;
}
