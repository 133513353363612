import {Directive, ElementRef, Output, EventEmitter, HostListener, OnInit, OnDestroy} from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  constructor(private _elementRef : ElementRef) {
  }

  @Output()
  public clickOutside = new EventEmitter();

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit(null);
    }
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }
}
