import {v4 as uuidv4} from 'uuid';
import {ValidatorFn} from '@angular/forms';

export class ListColumn<ALLOWED_FIELDS> {
  id: string;
  field: ALLOWED_FIELDS;
  readKeyInList?: string;
  dataType: 'string' | 'number' | 'dateTime' | 'array' | 'date' | 'boolean' | 'object' | 'resolver';
  label: string;
  isHtml?: boolean;
  isLink?: boolean;
  isHidden?: boolean;
  isSortable: boolean;
  isFilter: boolean;
  isId: boolean;
  filterCallback?: (item: { inputValue: string, filterType: string }) => void;
  //useFieldForFilter?: string;
  /*  filter?: {
      inputs?: { field: string }[];
      numbers?: { field: string }[];
      dates?: { field: string }[];
    };*/
  filterValidators?: ValidatorFn[];
  class?: string;
  buttons?: {

    edit?: (item: any) => void
  }

  constructor(data: Partial<ListColumn<ALLOWED_FIELDS>> & { field: ALLOWED_FIELDS, label: string }) {
    this.id = uuidv4();
    this.field = data.field;
    this.label = data.label;


    // defaults
    this.isSortable = true;
    this.isFilter = true;
    this.isId = false;
    this.dataType = 'string';
    Object.assign(this, data);
  }
}

export class ListColumnFactory {
  static create<ALLOWED_FIELDS>(data: Partial<ListColumn<ALLOWED_FIELDS>> & { field: ALLOWED_FIELDS, label: string }): ListColumn<ALLOWED_FIELDS> {
    return new ListColumn(data);
  }
}
