export interface GlobalState {
  notifications: null | [{
    notification: string,
    type: 'info' | 'error'
  }];
  visibilities: {
    menu: null | boolean,
    notifications: boolean
  }
}

export const initialGlobalState = (): GlobalState => {
  return {
    notifications: null,
    visibilities: {
      menu: null,
      notifications: false
    }
  };
};
