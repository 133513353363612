<form [formGroup]="form">
  <div class="is-flex is-flex-shrink-0">
    <div class="field mr-1">
      <label class="label">Standardsortierung Gruppe</label>
      <div class="control">
        <div class="select is-small">
          <select formControlName="default">
            <option value="" *ngIf="sortNames.length > 0">Name aufsteigend</option>
            <option value="" *ngIf="sortNames.length == 0">Name aufsteigend</option>
            <option *ngFor="let name of sortNames" value="{{name}}">{{name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">&nbsp;</label>
      <div class="control">
        <shared-form-submit-button class="is-inline-block" [formStatus]="formHandler.status"
                                submitLabel="Speichern" (submit)="submitForm()"></shared-form-submit-button>
      </div>
    </div>
  </div>
</form>
