<div class="dropdown has-text-left"[ngClass]="dropDownVisibility ? 'is-active' : ''" (clickOutside)="dropDownVisibility = false">
  <form [formGroup]="form">
    <div class="dropdown-trigger">
      <button (click)="dropDownVisibility = !dropDownVisibility" class="button is-small" aria-haspopup="true"
              aria-controls="dropdown-menu">
      <span class="icon">
        <fa-icon [icon]="faDownload"></fa-icon>
      </span>
        <span>Export</span>
        <span class="icon is-small">
        <fa-icon [icon]="faAngleUp" [ngClass]="!dropDownVisibility ? 'is-hidden' : ''"></fa-icon>
        <fa-icon [icon]="faAngleDown" [ngClass]="dropDownVisibility ? 'is-hidden' : ''"></fa-icon>
      </span>
      </button>
    </div>
    <div class="dropdown-menu pt-0" id="" role="menu">
      <div class="dropdown-content pb-0">
        <a *ngFor="let l of list.getExternalLinks('export')" (click)="list.clickExternalLink(l)" class="dropdown-item">
          {{l.name}}
        </a>
        <hr *ngIf="list.getExternalLinks('export').length > 0" class="dropdown-divider">
        <ng-container *ngIf="list.download">
          <div class="p-2">
            <div class="field">
              <label class="label is-small">Exportieren als</label>
              <div class="select is-small">
                <select formControlName="type">
                  <option value="csv">CSV</option>
                  <option value="xlsx">Excel</option>
                </select>
              </div>
            </div>
            <div class="field">
              <label class="label is-small">Startwert / Anzahl Einträge</label>
              <div class="control">
                <div class="is-flex">
                  <input class="input" type="number" formControlName="start" placeholder="0">
                  <div class="m-1"></div>
                  <input class="input" type="number" formControlName="limit" placeholder="2000">
                </div>
              </div>
            </div>
          </div>
          <div class="has-text-centered mt-2 p-1 has-background-light">
            <button  class="button is-small" (click)="download()" [ngClass]="(list.loading.download | async) ? 'is-loading' : ''">
              Herunterladen
            </button>
          </div>
        </ng-container>

      </div>
    </div>
  </form>
</div>
