<app-authentication-container>
  <div class="mt-6"></div>
  <div *ngIf="!passwordChanged">
    <h1 class="title is-5 mb-4">Passwort setzen</h1>
  </div>
  <div  *ngIf="passwordChanged">
    <p>Das Passwort wurde erfolgreich gespeichert. Sie können sich mit Ihren neuen Zugangsdaten <a routerLink="/login">hier </a> einloggen.
    </p>
  </div>
  <div #formTag>
    <div *ngIf="!passwordChanged">
      <div>
        <div class="is-block">
          <div class="field" [formGroup]="form">
            <div class="control">
              <input class="input" type="password" formControlName="password" placeholder="Neues Passwort" value="">
            </div>
          </div>
          <div class="field" [formGroup]="form">
            <div class="control">
              <input class="input" type="password" formControlName="passwordRetype" placeholder="Neues Passwort (wiederholen)" value="">
            </div>
          </div>
          <shared-form-submit-button [formStatus]="formHandler.status" submitLabel="Passwort setzen" (submit)="submit()"></shared-form-submit-button>
        </div>
      </div>
    </div>
  </div>
  <a class="goto-login" routerLink="/login">Zum Login <fa-icon [icon]="faSignIn"></fa-icon> </a>
</app-authentication-container>
