import {Action, createReducer, on} from '@ngrx/store';
import {
  ChangeDetectionItem,
  ChangeDetectionState,
  initialChangeDetectionState
} from '@store/state/change-detection.state';
import {resetChangedItems, setChangedItem} from '@store/actions/change-detection.action';

export const initialState: ChangeDetectionState = initialChangeDetectionState();

const ChangeDetectionReducer = createReducer(
  initialState,
  on(setChangedItem, (state: ChangeDetectionState, {item}): ChangeDetectionState => {
      let items: ChangeDetectionItem[] = [];
      if (state.items) {
        items = [...state.items];
      }
      items.push(item);
      return {
        ...state,
        items
      }
    }
  ),
  on(resetChangedItems, (state: ChangeDetectionState): ChangeDetectionState => {
      return {
        ...state,
        items: null
      }
    }
  )
);

export function reducer(state: ChangeDetectionState | undefined, action: Action) {
  return ChangeDetectionReducer(state, action);
}
