<div *ngIf="loading" class="content-content has-text-centered">
  <button class="button is-loading"></button>
</div>
<div>
  <div *ngIf="!loading">
    <article *ngIf="apiError" class="message is-danger">
      <div class="message-body">
        <b>{{ apiError.notification }}</b>
        <span *ngFor="let error of apiError?.errors" class="is-block">{{ error.key }}: {{ error.error }}</span>
      </div>
    </article>
    <form [formGroup]="form">
      <div class="tabs">
        <ul>
          <li [ngClass]="activeTab=='details' ? 'is-active' : ''" (click)="activeTab='details'">
            <a>Details
              <fa-icon [icon]="faExclamationTriangle"
                       *ngIf="formStatus=='submitForm' && form.invalid"
                       class="tab-error"></fa-icon>
            </a></li>
          <li *ngIf="form.get('linkType')?.value == ''" [ngClass]="activeTab=='seo' ? 'is-active' : ''"
              (click)="activeTab='seo'">
            <a>SEO
              <fa-icon [icon]="faExclamationCircle" *ngIf="isFormRequestError('SEO')"
                       class=" tab-error"></fa-icon>
            </a></li>

        </ul>
      </div>
      <div class="content-content is-tabs">
        <div class="tab-container" [ngClass]="activeTab!='details' ? 'is-hidden' : ''">
          <div class="columns is-multiline">
            <div class="column is-12-mobile is-6">
              <div class="field">
                <label class="label">Status</label>
                <div class="control">
                  <div class="select is-small">
                    <select formControlName="status">
                      <option value="online">Online</option>
                      <option value="offline">Offline</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-12-mobile is-6">
              <div class="field">
                <label class="label">Einmaliger Name</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="uniqueName" placeholder="Einmaliger Name">
                  <p *ngIf="isFormRequestError('uniqueName')" class="help is-danger">Bitte hinterlegen Sie einen
                    einmaligen Namen.</p>
                </div>
              </div>
            </div>
            <div class="column is-12-mobile is-6">
              <div class="field">
                <label class="label">Name</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="name" placeholder="Name">
                  <p *ngIf="isFormRequestError('name')" class="help is-danger">Bitte hinterlegen Sie einen Namen.</p>
                </div>
              </div>
            </div>
            <div class="column is-12-mobile is-6">
              <div class="field">
                <label class="label">Position</label>
                <div class="control">
                  <input class="input is-small" type="number"
                         formControlName="pos" placeholder="Position">
                  <p *ngIf="isFormRequestError('pos')" class="help is-danger">Bitte hinterlege Sie eine Position.</p>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <div class="field">
                <label class="label">Linktyp</label>
                <div class="select is-small">

                  <select formControlName="linkType" (change)="updateValidatorsForLinkType()">
                    <option value="">Interne Seite</option>
                    <option value="externalLinkUrl">Externer Link</option>
                    <option value="routerLink">Routerlink</option>
                  </select>
                </div>
              </div>
            </div>
            <ng-container *ngIf="form.get('linkType')?.value == 'externalLinkUrl'">
              <div class="column is-12-mobile is-6">
                <div class="field">
                  <label class="label">Externe Link-URL</label>
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="externalLinkUrl" placeholder="https://">
                    <p *ngIf="isFormRequestError('externalLinkUrl')" class="help is-danger">Bitte hinterlegen Sie einen
                      externen Link.</p>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="form.get('linkType')?.value == 'routerLink'">
              <div class="column is-12-mobile is-6">
                <div class="field">
                  <label class="label">Routerlink</label>
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="routerLink" placeholder="sortiment/list">
                    <p *ngIf="isFormRequestError('routerLink')" class="help is-danger">Bitte hinterlegen Sie einen
                      Router Link.</p>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="column is-12-mobile is-6">
              <div class="field">
                <label class="label">Link Positionen</label>
                <div class="control" formArrayName="linkPositions">
                  <button (click)="addField('linkPositions')" class="button is-small add"><span class="icon is-small"><fa-icon
                    [icon]="faPlusSquare"></fa-icon></span><span>Link Position hinzufügen</span></button>
                  <div class="is-flex my-2" *ngFor="let item of getFormArray('linkPositions').controls; let i = index;">
                    <div class="select is-small mr-2">
                      <select [formControlName]="i">
                        <option *ngFor="let lp of linkPositions" [value]="lp">
                          <ng-container [ngSwitch]="lp">
                            <span *ngSwitchCase="'top'">Kopf horizontal</span>
                            <span *ngSwitchCase="'foot_0'">Fuß [Spalte 1]</span>
                            <span *ngSwitchCase="'foot_1'">Fuß [Spalte 2]</span>
                            <span *ngSwitchCase="'foot_2'">Fuß [Spalte 3]</span>
                            <span *ngSwitchCase="'b2b_foot'">B2B Fuß</span>
                          </ng-container>
                        </option>
                      </select>
                    </div>
                    <span>
                          <fa-icon [icon]="faTrashCan" (click)="removeField('linkPositions', i)"
                                   class="delete-field is-pointer"></fa-icon>
                        </span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="form.get('linkType')?.value == ''" class="column is-12">
              <div class="field">
                <label class="label">
                  Inhalt
                  <a *ngIf="!editContentAsSource && editContentAsSource !== undefined"
                     (click)="editContentAsSource = true">Quellcode</a>
                  <a *ngIf="editContentAsSource" (click)="editContentAsSource = false">Editor</a>
                </label>
                <p *ngIf="isFormRequestError('content')" class="help is-danger">Bitte hinterlegen Sie einen Inhalt.</p>
                <div class="control">
                  <div [ngClass]="(editContentAsSource || editContentAsSource == undefined) ? 'is-hidden' : ''">
                    <p-editor formControlName="content" [style]="{'height':'640px'}"></p-editor>
                  </div>
                  <textarea *ngIf="editContentAsSource || editContentAsSource == undefined" class="textarea"
                            formControlName="content" style="height: 640px;"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-container" [ngClass]="activeTab!='seo' ? 'is-hidden' : ''" formGroupName="SEO">
          <div class="columns is-multiline">
            <div class="column is-12-mobile is-6">
              <div class="field">
                <label class="label">Seitentitel</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="title" placeholder="Seitentitel">
                </div>
              </div>
            </div>
            <div class="column is-12-mobile is-6">
              <div class="field">
                <label class="label">Keywords</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="keywords" placeholder="Keywords">
                </div>
              </div>
            </div>
            <div class="column is-12">
              <div class="field">
                <label class="label">Kurzbeschreibung (max. 200 Zeichen)</label>
                <div class="control">
                  <textarea class="textarea" formControlName="description"
                            placeholder="Kurzbeschreibung (max. 200 Zeichen)"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-foot has-text-centered">
        <shared-form-submit-button (submit)="submit()" [formStatus]="formStatus"></shared-form-submit-button>
      </div>
    </form>
  </div>
</div>

