import {createAction, props} from '@ngrx/store';

export const setAPIConfig = createAction('[Storage] set api config', props<{ config: string}>());

export const setInjectionToken = createAction('[Storage] set injection token', props<{ token:string,  expiredIn: number}>());

export const setAccessExpire = createAction('[Storage] set access expire', props<{ expiredIn: number}>());
export const setRefreshExpire = createAction('[Storage] set refresh expire', props<{ expiredIn: number}>());

export const deleteAccessToken = createAction('[Storage] delete access token');
export const deleteTokens = createAction('[Storage] delete tokens');

export const userId = createAction('[Storage] userId', props<{ userId: null | string}>());

export const setListConfig = createAction('[Global] setListConfig', props<{ id: string, visibleColumnFields: string[] }>());
