<div *ngIf="formHandler.status === 'loading'" class="content-content has-text-centered">
  <button class="button is-loading"></button>
</div>
<div #formTag>
  <div *ngIf="formHandler.status !== 'loading'">
    <form [formGroup]="form">
<!--      <div class="tabs">
        <ul>
          <li [ngClass]="activeTab=='details' ? 'is-active' : ''" (click)="formHandler.activeTab='details'"><a>Details
            &lt;!&ndash;<i
            *ngIf="errors && errors.tabs && errors.tabs.includes('details')"
            class="fa fa-exclamation-circle tab-error"></i>&ndash;&gt;
          </a></li>
          <li [ngClass]="activeTab=='images' ? 'is-active' : ''" (click)="formHandler.activeTab='images'"><a>Bilder &lt;!&ndash;<i
                *ngIf="errors && errors.tabs && errors.tabs.includes('images')"
                class="fa fa-exclamation-circle tab-error"></i>&ndash;&gt;</a></li>
        </ul>
      </div>-->
      <div class="content-content">
        <div class="tab-container">
          <div class="columns">
            <div class="column">
              <div class="field no-margin-bottom">
                <div class="control">
                  <label class="label">User-Level</label>
                  <div class="select is-small">
                    <select formControlName="role">
                      <option value="">Bitte auswählen</option>
                      <option value="admin">Administrator</option>
                      <option value="api">API</option>
                      <option value="staff">Mitarbeiter</option>
                      <option value="customer">Kunde</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">

            </div>
          </div>
          <div class="columns">
            <div class="column">

              <div class="field">
                <label class="label">Benutzernname</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="username" placeholder="Benutzername">
                </div>
              </div>
              <div class="field">
                <label class="label">Passwort</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="password" placeholder="Passwort">

                </div>
                <p class="is-size-7" *ngIf="this.window?.data?.itemId">Wenn kein Passwort hinterlegt wird, bleibt das
                  alte Passwort erhalten.</p>
              </div>
            </div>
            <div class="column is-half is-full-mobile">
              <div class="field">
                <label class="label">E-Mail</label>
                <div class="control">
                  <input class="input is-small" type="text" formControlName="email" placeholder="E-Mail">
                </div>
              </div>
              <app-customer-user-mapper [loadItems]="form.get('shopAccessForCustomers')?.value"
                                        (changeMapping)="setShopAccessForCustomers($event)"></app-customer-user-mapper>
            </div>
          </div>
        </div>
      </div>
      <div class="content-foot has-text-centered">
        <button *ngIf="formHandler?.status === 'error'" class="button is-small is-danger "><fa-icon [icon]="faExclamationTriangle"
          class=" save-error"></fa-icon></button>
        <button *ngIf="formHandler?.status === 'submitForm'" class="button is-small is-loading"></button>
        <button *ngIf="formHandler?.status === 'success'" class="button is-success is-small "><fa-icon [icon]="faCheck"
          class="save-success"></fa-icon>
        </button>
        <button *ngIf="!formHandler?.status" debounce-click (debounceClick)="submit()" class="button is-small">
          Speichern
        </button>
        <div *ngIf="formHandler?.status === 'readOnly'" class="button is-small is-warning read-only">Schreibgeschützt
        </div>
      </div>
    </form>
  </div>
</div>

