<form [formGroup]="form">
  <div class="p-2 has-background-white">
    <p class="title">Länderverwaltung</p>
    <div class="field">
      <label class="label">Länder verwalten</label>
      <div class="control">
        <div class="is-flex">
          <input class="input mr-1" type="text" placeholder="Land" formControlName="country">
          <button (click)="addCountry()" class="button is-success"><fa-icon [icon]="faPlusSquare"aria-hidden="true"></fa-icon>
          </button>
        </div>
      </div>
      <div class="container-countries" cdkDropList (cdkDropListDropped)="dropCountry($event)">
        <div *ngFor="let country of countries" class="is-flex mt-1 p-1" cdkDrag>
          <fa-icon [icon]="faBars" class="drag mr-2 mt-1 " cdkDragHandle></fa-icon>

          <p class="is-flex-grow-1">{{country}}</p>
          <fa-icon [icon]="faTrashCan" (click)="deleteCountry(country)" class=" mr-2 mt-1 is-cursor-pointer" aria-hidden="true"></fa-icon>
        </div>
      </div>
      <div class="field" [ngClass]="(countries.length == 0) ? 'is-hidden' : ''  ">
        <label class="label">Vorausgewähltes Land</label>
        <div class="control">
          <div class="select">
            <select formControlName="defaultCountry">
              <option *ngFor="let country of countries" value="{{country}}">{{country}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="countries.length > 0" class="is-block has-text-centered">
      <shared-form-submit-button [formStatus]="formStatus" (submit)="update()"
                              submitLabel="Speichern"></shared-form-submit-button>
    </div>
  </div>
</form>

