import {AbstractControl, FormArray, FormGroup, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {ElementRef, Renderer2} from '@angular/core';
import {ApiResult} from '@shared/api/api-result';
import {AppState} from '@store/state/app.state';
import {Store} from '@ngrx/store';
import {deleteWindow} from '@store/actions/windows.action';

export interface FormErrorMessage {
  formGroup?: string;
  key: string;
  error: string;
}

export type FormStatusDeprecated = null | 'loading' | 'readOnly' | 'submitForm' | 'success' | 'error';

export interface FormGroupControls {
  [key: string]: AbstractControl;
}

export class FormHelperDeprecated {
  protected id: string;
  protected formErrors: FormErrorMessage[] = [];
  public status: FormStatusDeprecated = null
  public notification: string = 'Das Formular wurde nicht korrekt ausgefüllt.';
  public htmlRef?: ElementRef
  public validateExtension: () => void = () => {
  };
  public hours: number[] = [];
  public minutes: number[] = [];



  constructor(
    public store: Store<AppState>,
    public changeDetectionGroup: string,
    public form: FormGroup,
    public defaultErrorMessages: FormErrorMessage[],
    public renderer: Renderer2,
    public activeTab: string = ''
  ) {
    for (let i = 0; i < 24; i++) {
      this.hours.push(i);
    }
    for (let i = 0; i < 60; i++) {
      if (i % 5 == 0) {
        this.minutes.push(i);
      }

    }
    this.id = 'id' + Math.floor(Math.random() * 100);
  }

  public fillForm(data: Object) {
    for (const [key, value] of Object.entries(data)) {
      if (this.form.controls[key] &&
        !(this.form.controls[key] instanceof FormArray)) {
        this.form.controls[key].setValue(value);
      }
    }
  }

  public validateForm() {
    this.formErrors = [];
    this.setFormValidationErrors(this.form.controls);

    /*    Object.keys(this.form.controls).forEach(key => {
          console.log(key);
          this.form.get('')?.controls
          const controlErrors: ValidationErrors | null | undefined = this.form.get(key)?.errors;
          if (controlErrors) {
            Object.keys(controlErrors).forEach(keyError => {
              let defaultError = this.defaultErrorMessages.filter(e => e.key == key);
              if (defaultError[0]) {
                this.formErrors.push(defaultError[0]);
              } else {
                this.formErrors.push({key, error: '#Bitte prüfen Sie Ihre Eingabe.'});
              }
            });
          }
        });*/
    this.validateExtension();
  }

  public setFormValidationErrors(controls: any, formGroup?: string) {
    Object.keys(controls).forEach(key => {
      const control = controls[key];
      /*if (control instanceof FormGroup) {
        formErrors = this.formErrors .concat(this.getFormValidationErrors(control.controls));
      }*/
      if (control.controls) {
        this.setFormValidationErrors(control.controls, key);
      }
      const controlErrors: ValidationErrors | null | undefined = controls[key].errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          let error: FormErrorMessage = {
            key,
            error: '#Bitte prüfen Sie Ihre Eingabe.'
          };
          let defaultError = this.defaultErrorMessages.filter(e => e.key == key)[0];
          if(defaultError){
            error.error = defaultError.error;
          }
          if (formGroup) {
            error.formGroup = formGroup;
          }
          this.formErrors.push(error);
        });
      }
    });
  }

  public isFormErrors() {
    if (this.formErrors.length > 0) {
      return true;
    }
    return false;
  }

  public isFormError(key: string) {
    if (this.formErrors.filter(e => e.key == key).length == 1) {
      return true;
    }
    return false;
  }

  public getFormError(key: string) {
    return this.formErrors.filter(e => e.key == key)[0].error;
  }


  public displayErrors() {
    // Vorhandene Fehler löschen
    let elements = this.htmlRef?.nativeElement.parentNode.querySelectorAll(".error" + this.id);
    if (elements) {
      for (let index = 0; index < elements.length; index++) {
        this.renderer.removeChild(elements[index].parentNode, elements[index]);
      }
    }


    // Roten Rahmen entfernen
    elements = this.htmlRef?.nativeElement.parentNode.querySelectorAll(" .is-danger");
    if (elements) {
      for (let index = 0; index < elements.length; index++) {
        this.renderer.removeClass(elements[index], 'is-danger');
      }
    }


    let txt;
    if (this.notification.length > 0 && (this.formErrors.length > 0 || this.status == 'error')) {
      let notificationElement = this.renderer.createElement("div");
      this.renderer.addClass(notificationElement, 'notification');
      this.renderer.addClass(notificationElement, 'is-danger');
      this.renderer.addClass(notificationElement, 'error-msg');
      this.renderer.addClass(notificationElement, 'error' + this.id);
      txt = this.renderer.createText(this.notification);
      this.renderer.appendChild(notificationElement, txt);
      this.renderer.insertBefore(this.htmlRef?.nativeElement.parentNode, notificationElement, this.htmlRef?.nativeElement);
    }


    let errorElement;
    this.formErrors.map(e => {

      if(e.formGroup){
        let container = this.htmlRef?.nativeElement.querySelector("[iw-error-group=\"" + e.formGroup + "\"]");
        elements = null;
        if(container){
          elements = container.querySelectorAll("[ng-reflect-name=\"" + e.key + "\"]");
        }

      } else {
        elements = this.htmlRef?.nativeElement.querySelectorAll("[formcontrolname=\"" + e.key + "\"]");
      }

      errorElement = this.renderer.createElement("p");
      this.renderer.addClass(errorElement, 'input-error');
      this.renderer.addClass(errorElement, 'error' + this.id);
      txt = this.renderer.createText(e.error);


      // Keine Inputs mit diesem Key gefunden
      if (!elements || elements.length == 0) {
        txt = this.renderer.createText(e.key + ' ' + e.error);
        this.renderer.appendChild(errorElement, txt);
        this.renderer.insertBefore(this.htmlRef?.nativeElement.parentNode, errorElement, this.htmlRef?.nativeElement);
      } else {
        this.renderer.appendChild(errorElement, txt);
        for (let index = 0; index < elements.length; index++) {
          let tabContent = elements[index].closest('.is-tabs');

          if(tabContent){
            let tabs = tabContent.parentNode;
          }
          //this.renderer.addClass(elements[index].closest('.tab'), 'is-danger');
          this.renderer.addClass(elements[index], 'is-danger');
          this.renderer.appendChild(elements[index].parentNode, errorElement);
        }
      }
    });
  }

  public setErrorMessages(errors: FormErrorMessage[]) {
    errors.map(e => {
      this.formErrors.push({
        key: e.key,
        error: e.error
      });
    });
  }

  public setErrorMessagesFromApi(errors: FormErrorMessage[]) {
    let key;
    errors.map(e => {
      key = e.key.replace('params.data.', '');
      /*if (!this.form.get(key)) {
        key = '';
      }*/
      this.formErrors.push({
        key,
        error: e.error
      });
    });
  }

  public removeNullValues(values: any, recursive: boolean = false) {
    for (const [key, value] of Object.entries(values)) {
      if (typeof value === 'object' &&
        value !== null
      ) {
        this.removeNullValues(value, true);
      }
      if (value === null || JSON.stringify(value) == '{}') {
        delete values[key];
      }

    }
    if (!recursive) {
      this.removeNullValues(values, true);
    }

  }

  public getFormValues(emptyValues: boolean = false) {
    const formData = {...this.form.value};
    if (emptyValues == false) {
      this.removeNullValues(formData);
    }
    return formData;
  }

  public handleApiResult(params: { r: ApiResult<any>, windowId?: string, callbackOnSuccess?: () => void }) {
    if (params.r.result) {
      this.status = 'success';
      if (params.windowId) {
        setTimeout(() => {
          this.store.dispatch(deleteWindow({id: params.windowId || ''}));
        }, 1000);
      }

      if (params.callbackOnSuccess) {
        params.callbackOnSuccess();
      }

    } else {
      this.status = 'error';
      if (params.r.error) {
        this.notification = params.r.error.message;
        if (params.r.error) {
          this.setErrorMessagesFromApi(params.r.error?.input || []);
        }
        this.displayErrors();
      }
    }
    setTimeout(() => {
      this.status = null;
    }, 1000);
  }
}
