<div *ngIf="loading" class="has-text-centered">
  <button  class="button content is-loading" ></button>
</div>
<div *ngIf="!loading">
  <h1 class="is-size-3 has-text-black is-inline-block">Bestandsstatus <fa-icon [icon]="faInfoCircle"  class="is-pointer" (click)="op.toggle($event)"></fa-icon></h1><br>
  <p-overlayPanel  #op>
    <ng-template pTemplate="content">
      <p class="m-0">
        Als Bestandsstatus des Artikels wird als erstes die hinterlegten Verfügbarkeit ausgelesen. Die Farbe der
        Verfügbarkeit kann angepasst werden, indem Sie die Felder der Bestände leer lassen und die identische Bezeichnung von
        der Verfügbarkeit definieren.<br><br>
        Sollte keine Verfügbarkeit hinterlegt sein, können Sie für den hinterlegten Bestand des Artikels einen Bestandsstatus
        erfassen. Z.B. von Bestand 50 bis Bestand 300 als Bestandsstatus "Artikel ist lieferbar". <br><br>Falls weder die
        Verfügbarkeit noch der Bestandsstatus definiert ist, wird der Bestand des Artikels ausgelesen.
      </p>
    </ng-template>
  </p-overlayPanel>
  <article *ngIf="apiError" class="message is-danger">
    <div class="message-body">
      <b>{{ apiError.notification }}</b>
      <span *ngFor="let error of apiError?.errors" class="is-block">{{ error.key }}: {{ error.error }}</span>
    </div>
  </article>
  <button class="button is-success is-small mt-2 mb-4" (click)="addArticleAvailability()">
    <span class="icon is-small">
      <fa-icon [icon]="faPlusSquare"></fa-icon>
    </span>
    <span>Bestandsstatus hinzufügen</span>

  </button>
  <div #formTag>
    <form [formGroup]="form">
      <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div class="" formGroupName="articleAvailabilities"
             *ngFor="let item of articleAvailabilities.controls; let i = index;">
          <div class="columns is-desktop" [formGroupName]="i" cdkDrag [attr.di-error-group]="i">
            <div class="column container-icon flex-none mr-4 pb-0">
                  <fa-icon [icon]="faTrashCan"  (click)="removeAvailability(i)" class="mr-6 is-pointer"></fa-icon>
            </div>
            <div class="column mr-4 pb-0 container-icon flex-none ">
              <i class="pi pi-bars is-grab" cdkDragHandle>

              </i>
            </div>
            <div class="column is-relative">

              <div class="field">
                <label class="label">Von Bestand</label>
                <div class="control">
                  <input class="input is-small" type="text" formControlName="fromInventory" type="number"
                         placeholder="Von Bestand">
                  <p *ngIf="isFormError('fromInventory', i)" class="help is-danger">Bitte hinterlegen Sie eine Menge.</p>
                </div>
              </div>
            </div>
            <div class="column is-relative">
              <div class="field">
                <label class="label">Bis Bestand</label>
                <div class="control">
                  <input class="input is-small" type="text" formControlName="toInventory" type="number"
                         placeholder="Bis Bestand">
                  <p *ngIf="isFormError('toInventory', i)" class="help is-danger">Bitte hinterlegen Sie eine Menge.</p>
                </div>
              </div>
            </div>
            <div class="column is-relative is-marginless-desktop">
              <div class="field">
                <label class="label">Bestandsstatus</label>
                <div class="control">
                  <input class="input is-small" type="text" formControlName="availability"
                         placeholder="Bestandsstatus">
                  <p *ngIf="isFormError('availability', i)" class="help is-danger">Bitte hinterlegen Sie einen Bestandsstatus.</p>
                </div>
              </div>
            </div>
            <div class="column is-relative is-marginless-desktop">
              <div class="field">
                <label class="label">Farbe {{item.value.color}}</label>
                <div class="control">
                  <input class="input is-small" (colorPickerSelect)="changeColor(i, $event)"  [(colorPicker)]="item.value.color" [style.background]="item.value.color"
                         [style.color]="item.value.color" formControlName="color"/>
                  <p *ngIf="isFormError('color', i)" class="help is-danger">Bitte hinterlegen Sie eine Farbe.</p>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>


      <div class="has-text-centered mt-6">
        <shared-form-submit-button (submit)="submit()" [formStatus]="formStatus"></shared-form-submit-button>

      </div>
    </form>
  </div>
</div>


