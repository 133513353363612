import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";

@Component({
  selector: 'app-list-delete-item',
  templateUrl: './list-delete-item.component.html',
  styleUrls: ['./list-delete-item.component.scss']
})
export class ListDeleteItemComponent implements OnInit {

  @Input() id?: string;
  @Input() confirmMsg: string = 'Möchten Sie den Eintrag wirklich löschen?';

  @Output() deleteItem: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  delete() {
    if (confirm(this.confirmMsg)) {
      this.deleteItem.emit(this.id);
    }
  }

  protected readonly faTrashCan = faTrashCan;
}
