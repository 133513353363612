import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Window} from '@shared/window-manager/interfaces/window.model';
import {Subject} from 'rxjs';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  Validators,
  FormGroup, FormBuilder, FormArray
} from '@angular/forms';
import {FormHelperDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {finalize} from 'rxjs/operators';
import {setChangedItem} from '@store/actions/change-detection.action';
import {NewsService} from '@shared/news/services/news.service';
import {UploadFilePath} from '@shared/uploads/components/uploads/upload.component';
import {faPlusSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {ApiArticlesSetRequest} from "@shared/api/articles";
import {ApiNewsSetRequest, ApiNewsUpdateRequest} from "@shared/api/news";
import {FormHandler} from "@shared/forms/models/form-handler";
import {FormDynamicArray} from "@shared/forms/models/form-dynamic-array";

@Component({
  selector: 'shared-news-form',
  templateUrl: './news-form.component.html',
  styleUrls: ['./news-form.component.scss']
})
export class NewsFormComponent implements OnInit {

  @ViewChild('formTag', {static: false}) formTag: ElementRef | undefined;
  @Input() window?: Window;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  changeDetectionGroup: string = 'news';

  form = this.fb.group({
    title: this.fb.control('', Validators.required),
    displayOption: this.fb.control(''),
    onlineFrom: this.fb.control('', Validators.required),
    onlineTo: this.fb.control('', Validators.required),
    priceGroups: this.fb.array([]),
    text: this.fb.control(''),
    images: this.fb.control([]),
    files: this.fb.control([])
    });

  formHandler: FormHandler;

  formDynamicFieldsPriceGroups: FormDynamicArray = new FormDynamicArray(this.form.get('priceGroups'), Validators.required);

  imagePaths: UploadFilePath[] = [];
  filePaths: UploadFilePath[] = [];

  activeTab = 'details';

  loading: boolean = false;


  constructor(
    private newsService: NewsService,
    private store: Store<AppState>,
    private fb: FormBuilder
  ) {
    this.formHandler = new FormHandler(this.store);
  }

  ngOnInit() {
    if (this.window?.data?.itemId) {
      this.loadItem(this.window.data.itemId);
    }
  }

  isFormKeyInvalid(key: string, displayIfSubmitForm: boolean = true): boolean {
    let form = this.form?.get(key);
    return !!(form?.invalid &&
      (
        this.formHandler.getFormStatus() == 'submitForm' &&
        displayIfSubmitForm
      )
    );
  }
  loadItem(id: string) {
    this.loading= true;
    this.newsService.get({news: {}, filters: [{fields: ['id'], values:[id]}]})
      .pipe(
        finalize(() => {
          this.loading= false;
        })
      )
      .subscribe(
        r => {
          if (r.result?.news.data[0]) {
            let data = r.result?.news.data[0];
            if(r.result?.news.data[0].images){
              this.imagePaths = r.result?.news.data[0].images;
            }
            if(r.result?.news.data[0].files){
              this.filePaths = r.result?.news.data[0].files;
            }
            if(r.result.news.data[0].priceGroups){
              r.result.news.data[0].priceGroups.map(
                priceGroup => {
                  this.formDynamicFieldsPriceGroups.add(priceGroup);
                }
              )
            }
            this.form.get('title')?.setValue(data?.title);
            if(data?.displayOptions?.[0]){
              this.form.get('displayOption')?.setValue(data?.displayOptions?.[0]);
            }
            this.form.get('onlineFrom')?.setValue(data?.onlineFrom?.toString() ?? '');
            this.form.get('onlineTo')?.setValue(data?.onlineTo?.toString() ?? '');
            this.form.get('text')?.setValue(data?.text ?? '');
          }
        }
      )
  }

  changedImages(images: any){
    this.form.get('images')?.setValue(images);
  }
  changedFiles(files: any){
    this.form.get('files')?.setValue(files);
  }

  submit() {

    if (this.form.invalid) {
      this.formHandler.setFormStatus({formStatus: 'submitForm'});
      return;
    }
    if (this.formHandler.getFormStatus() == 'loading') {
      return;
    }
    this.formHandler.setFormStatus({formStatus: 'loading'});
    let getData = (): ApiNewsSetRequest['params']['data'] => {
      let data: ApiNewsSetRequest['params']['data'] = {
        title: this.form.get('title')?.value ?? '',
        text: this.form.get('text')?.value ?? '',
        files: this.filePaths,
        priceGroups: (this.form.get('priceGroups')?.value as string[]) ?? []
      };

      data.displayOptions = [];
      if(this.form.get('displayOption')?.value == 'popup'){
        data.displayOptions = ['popup'];
      }if(this.form.get('displayOption')?.value == 'popupWithoutReadOption'){
        data.displayOptions = ['popupWithoutReadOption'];
      }

      if(this.form.get('onlineFrom')?.value){
        data.onlineFrom = new Date(this.form.get('onlineFrom')?.value ?? '');
      }
      if(this.form.get('onlineTo')?.value){
        data.onlineTo = new Date(this.form.get('onlineTo')?.value ?? '');
      }

      data.images = [];
      this.imagePaths.map(
        i => {
          if(i.thumbnails){
            data.images?.push({
              filePath: i.filePath,
              thumbnails: i.thumbnails
            });
          }
        }
      );
      return data;
    };

      this.formHandler.setFormStatus({formStatus: 'loading'});
      // Update
      if (this.window?.data &&
        this.window.data.itemId) {
        let data: ApiNewsUpdateRequest['params']['data'] = {...getData(), id: this.window?.data.itemId};
        this.newsService.update(data)
          .subscribe(
            {
              next: (r) => {
                if (r.error) {
                  this.formHandler.setFormError(r.error);
                  this.formHandler.setFormStatus(
                    {
                      formStatus: 'error',
                      resetStatusAfterTimeoutTo: null
                    }
                  );
                } else {
                  this.formHandler.setFormStatus(
                    {
                      formStatus: 'success',
                      resetStatusAfterTimeoutTo: null,
                      closeWindowId: this.window?.id,
                      callbackAfterTimeout: () => {
                        this.formHandler.setItemUpdate(data.id ?? '', this.changeDetectionGroup)
                      }
                    }
                  )
                }
              },
              error: (error) => {
                this.formHandler.setFormError({code: 'SUBSCRIPTION_ERROR', message: JSON.stringify(error)});
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'error',
                    resetStatusAfterTimeoutTo: null
                  }
                );
              }
            }
          )
        // Insert
      } else {
        this.newsService.set(getData())
          .subscribe(
            {
              next: (r) => {
                if (r.error) {
                  this.formHandler.setFormError(r.error);
                  this.formHandler.setFormStatus(
                    {
                      formStatus: 'error',
                      resetStatusAfterTimeoutTo: null
                    }
                  );
                } else {
                  this.formHandler.setFormStatus(
                    {
                      formStatus: 'success',
                      resetStatusAfterTimeoutTo: null,
                      closeWindowId: this.window?.id,
                      callbackAfterTimeout: () => {
                        this.formHandler.setItemUpdate(r.result?.news.data.id ?? '', this.changeDetectionGroup)
                      }
                    }
                  )
                }
              },
              error: (error) => {
                this.formHandler.setFormError({code: 'SUBSCRIPTION_ERROR', message: JSON.stringify(error)});
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'error',
                    resetStatusAfterTimeoutTo: null
                  }
                );
              }
            }
          )
      }

  }

  protected readonly faPlusSquare = faPlusSquare;
  protected readonly faTrashCan = faTrashCan;
}
