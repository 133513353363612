import {NativeDateAdapter} from '@angular/material/core';
import {inject, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
/** Adapts the native JS Date for use with cdk-based components that work with dates. */
export class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}
