<div *ngIf="loading" class="content-content has-text-centered">
  <button class="button is-loading"></button>
</div>
<div>
  <div [ngClass]="loading ? 'is-hidden' : ''">

    <div class="tabs">
      <ul>
        <li [ngClass]="activeTab=='details' ? 'is-active' : ''"
            (click)="activeTab = 'details'">

          <a>
            <span
              *ngIf="isFormKeyInvalid('customerNumber') || isFormKeyInvalid('email') || isFormKeyInvalid('dynamicFields') "
              class="is-error mr-2">
              <fa-icon [icon]="faExclamationCircle"></fa-icon>
            </span>
            Details</a></li>

        <li [ngClass]="activeTab=='addresses' ? 'is-active' : ''"
            (click)="activeTab = 'addresses'"><a>Adressdaten <span
          *ngIf="formHandler.getFormStatus() == 'submitForm' && addresses.length == 0"
          class="has-text-danger ml-2"><fa-icon [icon]="faCircleExclamation"></fa-icon></span>
        </a></li>
        <li [ngClass]="activeTab=='contactPersons' ? 'is-active' : ''"
            (click)="activeTab = 'contactPersons'"><a>Ansprechpartner <span
          *ngIf="formHandler.getFormStatus() == 'submitForm' && form.get('contactPersons')?.status == 'INVALID'"
          class="has-text-danger ml-2"><fa-icon [icon]="faCircleExclamation"></fa-icon></span>
        </a></li>
        <li [ngClass]="activeTab=='conditions' ? 'is-active' : ''"
            (click)="activeTab = 'conditions'"><a>
           <span
             *ngIf="isFormKeyInvalid('priceGroup')"
             class="is-error mr-2">
              <fa-icon [icon]="faExclamationCircle"></fa-icon>
            </span>
          Konditionen</a></li>

        <li [ngClass]="activeTab=='shop' ? 'is-active' : ''"
            (click)="activeTab = 'shop'"><a>
           <span
             *ngIf="isFormKeyInvalid('visibilityGroups')"
             class="is-error mr-2">
              <fa-icon [icon]="faExclamationCircle"></fa-icon>
            </span>
          Shop-Details</a></li>
        <li [ngClass]="activeTab=='notes' ? 'is-active' : ''"
            (click)="activeTab = 'notes'">
          <a>Notizen</a>
        </li>
      </ul>
    </div>
    <div class="mb-4">
      <shared-form-error [apiError]="formHandler.getFormError()"></shared-form-error>
    </div>
    <div class="content-content is-tabs">
      <!-- details -->
      <form [formGroup]="form">
        <div class="tab-container" [ngClass]="activeTab!='details' ? 'is-hidden' : ''">
          <div class="columns is-1 is-multiline">
            <div class="column is-6">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Kundennummer</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="customerNumber" placeholder="Kundennummer">
                    </div>
                    <p *ngIf="isFormError('customerNumber')" class="help is-danger">Bitte hinterlegen Sie eine
                      Kundennummer.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-6"></div>
            <div class="column is-6">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">E-Mail</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="email" placeholder="E-Mail">
                    </div>
                    <p *ngIf="isFormError('email')" class="help is-danger">Bitte hinterlegen Sie eine gültige
                      E-Mail.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Internet-Adresse</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="internetAddress" placeholder="Internet-Adresse">
                    </div>
                    <p *ngIf="isFormError('internetAddress')" class="help is-danger">Bitte hinterlegen Sie eine gültige Internet-Adresse (https://www.meineadresse.de/).</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Lieferzeitraum</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="defaultDeliveryPeriod" placeholder="Lieferzeitraum">
                    </div>
                    <p *ngIf="isFormError('defaultDeliveryPeriod')" class="help is-danger">Bitte hinterlegen Sie eine
                      Standardlieferzeitraum.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="columns mt-4">
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Kategorie</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="category" placeholder="Kategorie">
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Branche</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="industryCategory" placeholder="Branche">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <button (click)="formDynamicFields.add()" class="button is-small add"><span
                class="icon is-small"><fa-icon
                [icon]="faPlusSquare"></fa-icon></span><span>Feld hinzufügen</span>
              </button>
              <div formArrayName="dynamicFields">
                <div *ngFor="let group of formDynamicFields.getFormArray().controls; let i=index">
                  <div [formGroupName]="i" class="mt-1">
                    <div class="is-flex">
                      <div class="control">
                        <input class="input is-small" formControlName="label" type="text"
                               placeholder="Name">
                        <p class="help is-danger" *ngIf="isFormKeyInvalid('dynamicFields.'+i+'.label')">Bitte
                          hinterlegen Sie einen Namen.</p>
                      </div>
                      <div class="px-1">
                        :
                      </div>
                      <div class="control">
                        <input class="input is-small" formControlName="value" type="text"
                               placeholder="">
                        <p class="help is-danger" *ngIf="isFormKeyInvalid('dynamicFields.'+i+'.value')">Bitte
                          hinterlegen Sie einen Wert.</p>
                      </div>
                      <div class="has-text-right pt-2 pl-2">
                        <button class="delete is-small is-right"
                                (click)="formDynamicFields.remove(i)"></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </form>

      <!-- addresses -->
      <div class="tab-container" [ngClass]="activeTab!='addresses' ? 'is-hidden' : ''">
        <button (click)="setAddress();" class="button is-small add"><span
          class="icon is-small"><fa-icon
          [icon]="faPlusSquare"></fa-icon></span><span>Adresse hinzufügen</span>
        </button>
        <p *ngIf="addresses.length == 0 && this.formHandler.getFormStatus()== 'submitForm'" class="help is-danger">Bitte
          hinterlegen Sie mindestens eine Adresse.</p>
        <div class="mt-4">
          <p-table class="mt-4" #dt1 [value]="addresses" [tableStyle]="{'min-width': '50rem'}"
                   [rows]="50"
                   scrollHeight="600px"
                   styleClass="p-datatable-sm"
                   [globalFilterFields]="['company', 'firstName', 'name', 'street', 'town']"
          >
            <ng-template pTemplate="header">
              <tr>
                <th></th>
                <th class="is-no-wrap" pSortableColumn="company">Firma
                  <p-sortIcon field="company"></p-sortIcon>
                </th>
                <th class="is-no-wrap" pSortableColumn="firstName">Vorname
                  <p-sortIcon field="firstName"></p-sortIcon>
                </th>
                <th class="is-no-wrap" pSortableColumn="name">Name
                  <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th class="is-no-wrap" pSortableColumn="street">Straße
                  <p-sortIcon field="street"></p-sortIcon>
                </th>
                <th class="is-no-wrap" pSortableColumn="postcode">Postleitzahl
                  <p-sortIcon field="postcode"></p-sortIcon>
                </th>
                <th class="is-no-wrap" pSortableColumn="town">Stadt
                  <p-sortIcon field="town"></p-sortIcon>
                </th>

              </tr>
              <tr>
                <th></th>
                <th>
                  <input pInputText type="text"
                         (input)="dt1.filter($any($event.target).value ?? '', 'company', 'contains')"
                         [value]="$any(dt1.filters['company'])?.value" placeholder="Filter Firma"
                         class="input is-small">
                </th>
                <th>
                  <input pInputText type="text"
                         (input)="dt1.filter($any($event.target).value, 'firstName', 'contains')"
                         [value]="$any(dt1.filters['firstName'])?.value" placeholder="Filter Vorname"
                         class="input is-small">
                </th>
                <th>
                  <input pInputText type="text"
                         (input)="dt1.filter($any($event.target).value, 'name', 'contains')"
                         [value]="$any(dt1.filters['name'])?.value" placeholder="Filter Name" class="input is-small">
                </th>
                <th>
                  <input pInputText type="text" (input)="dt1.filter($any($event.target).value, 'street', 'contains')"
                         [value]="$any(dt1.filters['street'])?.value" placeholder="Filter Straße"
                         class="input is-small">
                </th>
                <th>
                  <input pInputText type="text"
                         (input)="dt1.filter($any($event.target).value, 'postcode', 'contains')"
                         [value]="$any(dt1.filters['postcode'])?.value" placeholder="Filter Postleitzahl"
                         class="input is-small">
                </th>
                <th>
                  <input pInputText type="text"
                         (input)="dt1.filter($any($event.target).value, 'town', 'contains')"
                         [value]="$any(dt1.filters['town'])?.value" placeholder="Filter Stadt" class="input is-small">
                </th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-address>
              <tr (dblclick)="showAddress(address.addressId);">
                <td>
                  <div class="is-flex">
                  <span *ngIf="addresses.length > 1" class="is-pointer mr-6" (click)="deleteAddress(address.addressId)">
                    <fa-icon [icon]="faTrashCan"></fa-icon>
                  </span>
                    <span (click)="showAddress(address.addressId);" class="is-pointer">
                     <fa-icon [icon]="faEdit"></fa-icon>
                  </span>
                  </div>
                </td>
                <td>{{ address.company }}</td>
                <td>{{ address.firstName }}</td>
                <td>{{ address.name }}</td>
                <td><span>{{ address.street }}</span></td>
                <td>{{ address.postcode }}</td>
                <td>{{ address.town }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8">Keine Adressen erfasst.</td>
              </tr>
            </ng-template>
          </p-table>
          <p-dialog
            [header]="existAddress(showAddressId ?? '') ? 'Adresse bearbeiten' : 'Adresse anlegen'"
            [(visible)]="showAddressDialog"
            position="center"
            [modal]="true"
            [style]="{ width: '100vw', 'max-width': '1000px' }">


            <div [formGroup]="formAddress" class="columns is-1 is-multiline">
              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Firma</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="company" placeholder="Firma">
                      </div>
                      <p *ngIf="isFormAddressError('company')" class="help is-danger">Bitte hinterlegen Sie
                        eine
                        Firma.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6">

              </div>
              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Anrede</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="salutation" placeholder="Anrede">
                      </div>
                      <p *ngIf="isFormAddressError('salutation')" class="help is-danger">Bitte hinterlegen
                        Sie
                        eine Anrede.</p>
                    </div>
                  </div>
                </div>

              </div>
              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Titel</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="title" placeholder="Titel">
                      </div>
                      <p *ngIf="isFormAddressError('title')" class="help is-danger">Bitte hinterlegen Sie
                        einen
                        Titel.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Vorname</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="firstName" placeholder="Vorname">
                      </div>
                      <p *ngIf="isFormAddressError('firstName')" class="help is-danger">Bitte hinterlegen Sie
                        einen Voranmen.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Name</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="name" placeholder="Name">
                      </div>
                      <p *ngIf="isFormAddressError('name')" class="help is-danger">Bitte hinterlegen Sie
                        einen
                        Namen.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">GesoftName1</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="gesoftName1" placeholder="gesoftName1">
                      </div>
                      <p *ngIf="isFormAddressError('gesoftName1')" class="help is-danger">Bitte hinterlegen
                        Sie
                        einen Namen.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column pb-5 is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">GesoftName2</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="gesoftName2" placeholder="gesoftName2">
                      </div>
                      <p *ngIf="isFormAddressError('gesoftName2')" class="help is-danger">Bitte hinterlegen
                        Sie
                        einen Namen.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Straße</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="street" placeholder="Straße">
                      </div>
                      <p *ngIf="isFormAddressError('street')" class="help is-danger">Bitte hinterlegen Sie
                        eine
                        Straße.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6"></div>
              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Postleitzahl</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="postcode" placeholder="Postleitzahl">
                      </div>
                      <p *ngIf="isFormAddressError('postcode')" class="help is-danger">Bitte hinterlegen Sie
                        eine
                        Postleitzahl.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Stadt:</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="town" placeholder="Stadt">
                      </div>
                      <p *ngIf="isFormAddressError('town')" class="help is-danger">Bitte hinterlegen Sie eine
                        Stadt.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Telefon</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="phone" placeholder="Telefon">
                      </div>
                      <p *ngIf="isFormAddressError('phone')" class="help is-danger">Bitte hinterlegen Sie
                        eine
                        Telefonnummer.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column pb-5 is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Mobil</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="mobile" placeholder="Mobil">
                      </div>
                      <p *ngIf="isFormAddressError('mobile')" class="help is-danger">Bitte hinterlegen Sie
                        eine
                        Mobilnummer.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Etage</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="number"
                               formControlName="floor" placeholder="Etage">
                      </div>
                      <p *ngIf="isFormAddressError('floor')" class="help is-danger">Bitte hinterlegen Sie
                        eine
                        Etage.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field is-horizontal mt-2">
                  <div class="field-label is-normal">
                    <label class="label">Aufzug</label>
                  </div>
                  <div class="field-body">
                    <div class="field mt-1 mb-1">
                      <div class="control">
                        <input class="checkbox is-small" type="checkbox"
                               formControlName="elevator" placeholder="Aufzug">
                      </div>
                      <p *ngIf="isFormAddressError('elevator')" class="help is-danger">Bitte geben Sie an, ob
                        ein
                        Aufzug vorhanden ist.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Land</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="country" placeholder="Land">
                      </div>
                      <p *ngIf="isFormAddressError('country')" class="help is-danger">Bitte hinterlegen Sie
                        ein
                        Land.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Zusätzliche Informationen</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                            <textarea class="textarea is-small"
                                      formControlName="additionalAddressInformation"
                                      placeholder="Zusätzliche Informationen"></textarea>
                      </div>
                      <p *ngIf="isFormAddressError('additionalAddressInformation' )" class="help is-danger">
                        Bitte
                        hinterlegen Sie zusätzliche Adressinformationen.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Typ</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="select is-small">
                        <select formControlName="type">
                          <option value="delivery">Lieferadresse</option>
                          <option value="billing">Rechnungsadresse</option>
                          <option value="">Rechnungs- oder Lieferadresse</option>
                        </select>
                      </div>
                      <p *ngIf="isFormAddressError('type')" class="help is-danger">Bitte hinterlegen Sie
                        einen
                        Adresstyp.</p>
                    </div>
                  </div>

                </div>
              </div>

              <div class="column is-6">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">ID</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="addressId" placeholder="ID">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="has-text-centered">
              <shared-form-submit-button [formStatus]="formAddressHandler.getFormStatus()"
                                         (submit)="updateAddress(this.showAddressId ?? '')"></shared-form-submit-button>
            </div>
          </p-dialog>
        </div>


      </div>

      <!-- contactPersons -->
      <div class="tab-container" [ngClass]="activeTab!='contactPersons' ? 'is-hidden' : ''">
        <button (click)="setContactPerson();" class="button is-small add mb-4"><span
          class="icon is-small"><fa-icon
          [icon]="faPlusSquare"></fa-icon></span><span>Ansprechpartner hinzufügen</span>
        </button>
        <p-table #dt1 [value]="contactPersons" [tableStyle]="{'min-width': '50rem'}"
                 [rows]="50"
                 scrollHeight="600px"
                 styleClass="p-datatable-sm"
                 [globalFilterFields]="['position', 'firstName', 'name', 'phone', 'mobile', 'email']"
        >
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th class="is-no-wrap" pSortableColumn="street">Position
                <p-sortIcon field="position"></p-sortIcon>
              </th>
              <th class="is-no-wrap" pSortableColumn="firstName">Vorname
                <p-sortIcon field="firstName"></p-sortIcon>
              </th>
              <th class="is-no-wrap" pSortableColumn="name">Name
                <p-sortIcon field="name"></p-sortIcon>
              </th>

              <th class="is-no-wrap" pSortableColumn="postcode">Telefon
                <p-sortIcon field="phone"></p-sortIcon>
              </th>
              <th class="is-no-wrap" pSortableColumn="town">Mobil
                <p-sortIcon field="mobile"></p-sortIcon>
              </th>
              <th class="is-no-wrap" pSortableColumn="town">E-Mail
                <p-sortIcon field="email"></p-sortIcon>
              </th>
            </tr>
            <tr>
              <th></th>
              <th>
                <input pInputText type="text"
                       (input)="dt1.filter($any($event.target).value ?? '', 'position', 'contains')"
                       [value]="$any(dt1.filters['position'])?.value" placeholder="Filter Position"
                       class="input is-small">
              </th>
              <th>
                <input pInputText type="text"
                       (input)="dt1.filter($any($event.target).value, 'firstName', 'contains')"
                       [value]="$any(dt1.filters['firstName'])?.value" placeholder="Filter Vorname"
                       class="input is-small">
              </th>
              <th>
                <input pInputText type="text"
                       (input)="dt1.filter($any($event.target).value, 'name', 'contains')"
                       [value]="$any(dt1.filters['name'])?.value" placeholder="Filter Name" class="input is-small">
              </th>
              <th>

              </th>
              <th>

              </th>
              <th>

              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-contactPerson>
            <tr (dblclick)="showContactPerson(contactPerson.contactPersonId);">
              <td>
                <div class="is-flex">
                  <span class="is-pointer mr-6" (click)="deleteContactPerson(contactPerson.contactPersonId)">
                    <fa-icon [icon]="faTrashCan"></fa-icon>
                  </span>
                  <span (click)="showContactPerson(contactPerson.contactPersonId);" class="is-pointer">
                     <fa-icon [icon]="faEdit"></fa-icon>
                  </span>
                </div>
              </td>
              <td>{{ contactPerson.position }}</td>
              <td>{{ contactPerson.firstName }}</td>
              <td>{{ contactPerson.name }}</td>
              <td><a href="tel:{{ contactPerson.phone }}">{{ contactPerson.phone }}</a></td>
              <td><a href="tel:{{ contactPerson.mobile }}">{{ contactPerson.mobile }}</a></td>
              <td><a href="mailto:{{ contactPerson.email }}">{{ contactPerson.email }}</a></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8">Keine Ansprechpartner erfasst.</td>
            </tr>
          </ng-template>
        </p-table>
        <p-dialog
          [header]="existAddress(showContactPersonId ?? '') ? 'Ansprechpartner bearbeiten' : 'Ansprechpartner anlegen'"
          [(visible)]="showContactPersonDialog"
          position="center"
          [modal]="true"
          [style]="{ width: '100vw', 'max-width': '1000px' }">


          <div [formGroup]="formContactPerson">
            <div class="columns is-multiline is-1">
              <div class="column is-6-desktop">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Anrede</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="salutation" placeholder="Anrede">
                      </div>
                      <p *ngIf="isFormContactPeronsError('salutation')" class="help is-danger">Bitte hinterlegen Sie
                        eine Anrede.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6-desktop">

              </div>
              <div class="column is-6-desktop">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Vorname</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="firstName" placeholder="Vorname">
                      </div>
                      <p *ngIf="isFormContactPeronsError('firstName')" class="help is-danger">Bitte hinterlegen Sie
                        einen Voranmen.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6-desktop">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Name</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="name" placeholder="Name">
                      </div>
                      <p *ngIf="isFormContactPeronsError('name')" class="help is-danger">Bitte hinterlegen Sie einen
                        Namen.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6-desktop">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Telefon</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input [tabIndex]="5" class="input is-small" type="text"
                               formControlName="phone" placeholder="Telefon">
                      </div>
                      <p *ngIf="isFormContactPeronsError('phone')" class="help is-danger">Bitte hinterlegen Sie eine
                        gültige Telefonnummer.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6-desktop">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Mobil</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input tabindex="4" class="input is-small" type="text"
                               formControlName="mobile" placeholder="Mobil">
                      </div>
                      <p *ngIf="isFormContactPeronsError('mobile')" class="help is-danger">Bitte hinterlegen Sie eine
                        gültige Mobilnummer.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6-desktop">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">E-Mail</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input [tabIndex]="3" class="input is-small" type="text"
                               formControlName="email" placeholder="E-Mail">
                      </div>
                      <p *ngIf="isFormContactPeronsError('email')" class="help is-danger">Bitte hinterlegen Sie eine
                        gültige E-Mail-Adresse.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6-desktop">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Position</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input tabindex="6" class="input is-small" type="text"
                               formControlName="position" placeholder="Position">
                      </div>
                      <p *ngIf="isFormContactPeronsError('position')" class="help is-danger">Bitte hinterlegen Sie eine
                        Position.</p>
                    </div>
                  </div>
                </div>

              </div>
              <div class="column is-6-desktop">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">ID</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input class="input is-small" type="text"
                               formControlName="contactPersonId" placeholder="ID">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6-desktop">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Zusätzliche Informationen</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                            <textarea tabindex="7" class="textarea is-small"
                                      formControlName="additionalInformation"
                                      placeholder="Zusätzliche Informationen"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="has-text-centered">
            <shared-form-submit-button [formStatus]="formContactPersonHandler.getFormStatus()"
                                       (submit)="updateContactPerson(this.showContactPersonId ?? '')"></shared-form-submit-button>
          </div>
        </p-dialog>


      </div>

      <!-- conditions -->
      <div class="tab-container" [ngClass]="activeTab!='conditions' ? 'is-hidden' : ''">
        <div [formGroup]="form" class="columns">
          <div class="column">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Preisgruppe</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="priceGroup" placeholder="Preisgruppe">
                  </div>
                  <p *ngIf="isFormError('priceGroup')" class="help is-danger">Bitte hinterlegen Sie eine
                    Preisgruppe.</p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Kreditlimit</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-small" type="number"
                           formControlName="creditLimit" placeholder="Kreditlimit">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Preismodus</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="select is-small">
                    <select formControlName="priceMode">
                      <option value="gross">Brutto</option>
                      <option value="net">Netto</option>
                    </select>
                  </div>
                  <p *ngIf="isFormError('priceMode')" class="help is-danger">Bitte hinterlegen Sie einen
                    Preismodus.</p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Kreditlimit verfügbar</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-small" type="number"
                           formControlName="creditLimitAvailable" placeholder="Kreditlimit verfügbar">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button (click)="addPrice(); dt1.reset()" class="button is-small add mb-4"><span
          class="icon is-small"><fa-icon
          [icon]="faPlusSquare"></fa-icon></span><span>Kundenpreis hinzufügen</span>
        </button>
        <p-table #dt1 [value]="customerArticlePrices"
                 [tableStyle]="{'min-width': '50rem'}"
                 [rows]="50" [paginator]="true"
                 scrollHeight="400px"
                 [showCurrentPageReport]="true"
                 styleClass="p-datatable-sm"
                 currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
                 [rowsPerPageOptions]="[50, 100, 250, 500]"
        >
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th pSortableColumn="articleNumber">Artikelnummer
                <p-sortIcon field="articleNumber"></p-sortIcon>
              </th>
              <th pSortableColumn="netPrice">Netto-Preis
                <p-sortIcon field="netPrice"></p-sortIcon>
              </th>
              <th pSortableColumn="netDeposit">Netto-Pfand
                <p-sortIcon field="netDeposit"></p-sortIcon>
              </th>
              <th pSortableColumn="netFee">Netto-Gebühr
                <p-sortIcon field="netFee"></p-sortIcon>
              </th>
              <th pSortableColumn="fromArticleQuantity">Ab Artikelmenge
                <p-sortIcon field="fromArticleQuantity"></p-sortIcon>
              </th>
              <th pSortableColumn="fromQuantity">Ab Menge (Warenkorb)
                <p-sortIcon field="fromQuantity"></p-sortIcon>
              </th>
              <th pSortableColumn="validFrom">Gültig von
                <p-sortIcon field="validFrom"></p-sortIcon>
              </th>
              <th pSortableColumn="validTo">Gültig bis
                <p-sortIcon field="validTo"></p-sortIcon>
              </th>
            </tr>
            <tr>
              <th></th>
              <th>
                <input pInputText type="text "
                       (input)="dt1.filter($any($event.target).value ?? '', 'articleNumber', 'contains')"
                       [value]="$any(dt1.filters['articleNumber'])?.value" placeholder="" class="input is-small">
              </th>
              <th>
                <input pInputText type="text" use
                       (input)="dt1.filter($any($event.target).value, 'netPrice', 'contains')"
                       [value]="$any(dt1.filters['netPrice'])?.value" placeholder="" class="input is-small">
              </th>
              <th>
                <input pInputText type="text"
                       (input)="dt1.filter($any($event.target).value, 'netDeposit', 'contains')"
                       [value]="$any(dt1.filters['netDeposit'])?.value" placeholder="" class="input is-small">
              </th>
              <th>
                <input pInputText type="text" (input)="dt1.filter($any($event.target).value, 'netFee', 'contains')"
                       [value]="$any(dt1.filters['netFee'])?.value" placeholder="" class="input is-small">
              </th>
              <th>
                <input pInputText type="text"
                       (input)="dt1.filter($any($event.target).value, 'fromArticleQuantity', 'contains')"
                       [value]="$any(dt1.filters['fromArticleQuantity'])?.value" placeholder=""
                       class="input is-small">
              </th>
              <th>
                <input pInputText type="text"
                       (input)="dt1.filter($any($event.target).value, 'fromQuantity', 'contains')"
                       [value]="$any(dt1.filters['fromQuantity'])?.value" placeholder="" class="input is-small">
              </th>
              <th>
                <input pInputText type="text"
                       (input)="dt1.filter($any($event.target).value, 'validFrom', 'contains')"
                       [value]="$any(dt1.filters['validFrom'])?.value" placeholder="" class="input is-small">
              </th>
              <th>
                <input pInputText type="text" (input)="dt1.filter($any($event.target).value, 'validTo', 'contains')"
                       [value]="$any(dt1.filters['validTo'])?.value" placeholder="" class="input is-small">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-articlePrices let-rowIndex="rowIndex">
            <tr [pSelectableRow]="articlePrices">
              <td>
                <fa-icon (click)="deletePrice(rowIndex)" [icon]="faTrashCan"></fa-icon>
              </td>
              <td>
                <span class="p-column-title">Artikelnummer</span>
                <input
                  pInputText
                  type="text"
                  class="input is-small"
                  placeholder="Artikelnummer"
                  [(ngModel)]="articlePrices.articleNumber"/>
              </td>
              <td>
                <span class="p-column-title">Netto-Preis</span>
                <input
                  pInputText
                  type="number"
                  class="input is-small"
                  placeholder="Netto-Preis"
                  [(ngModel)]="articlePrices.netPrice"/>
              </td>
              <td>
                <span class="p-column-title">Netto-Pfand</span>
                <input
                  pInputText
                  type="number"
                  class="input is-small"
                  placeholder="Netto-Pfand"
                  [(ngModel)]="articlePrices.netDeposit"/>
              </td>
              <td>
                <span class="p-column-title">Netto-Gebühr</span>
                <input
                  pInputText
                  type="number"
                  class="input is-small"
                  [(ngModel)]="articlePrices.netFee"/>
              </td>
              <td>
                <span class="p-column-title">Ab Artikelmenge</span>
                <input
                  pInputText
                  type="number"
                  placeholder="Ab Menge"
                  class="input is-small"
                  [(ngModel)]="articlePrices.fromArticleQuantity"/>
              </td>
              <td>
                <span class="p-column-title">Ab Artikelmenge (Warenkorb)</span>
                <input
                  pInputText
                  type="number"
                  class="input is-small"
                  placeholder="Ab Menge (Warenkorb)"
                  [(ngModel)]="articlePrices.fromCartQuantity"/>
              </td>
              <td>
                <span class="p-column-title">Gültig ab</span>
                <p class="control has-icons-left" (click)="fromPrice.open()">
                  <input [matDatepicker]="fromPrice" [(ngModel)]="articlePrices.validFrom" class="input is-small"
                         placeholder="Gültig ab">
                  <span class="icon is-small is-left">
                                      <mat-datepicker-toggle matSuffix [for]="fromPrice"></mat-datepicker-toggle>
                                      <mat-datepicker #fromPrice></mat-datepicker>
                                    </span>
                </p>
              </td>
              <td>
                <span class="p-column-title">Gültig bis</span>
                <p class="control has-icons-left" (click)="toPrice.open()">
                  <input [matDatepicker]="toPrice" [(ngModel)]="articlePrices.validTo" class="input is-small"
                         placeholder="Gültig bis">
                  <span class="icon is-small is-left">
                                      <mat-datepicker-toggle matSuffix [for]="toPrice"></mat-datepicker-toggle>
                                      <mat-datepicker #toPrice></mat-datepicker>
                                    </span>
                </p>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8">Keine Preise gefunden.</td>
            </tr>
          </ng-template>
        </p-table>

      </div>

      <!-- shop-details -->
      <div class="tab-container" [ngClass]="activeTab!='shop' ? 'is-hidden' : ''">
        <div [formGroup]="form" class="columns">
          <div class="column">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Sichtbarkeitsgruppen: </label>
              </div>
              <div class="field-body">
                <div class="is-flex-grow-1">
                  <div>
                    <button (click)="formDynamicFieldsVisibilityGroups.add()"
                            class="button is-small add"><span
                      class="icon is-small"><fa-icon
                      [icon]="faPlusSquare"></fa-icon></span><span>Gruppe hinzufügen</span>
                    </button>
                  </div>
                  <div formArrayName="visibilityGroups">
                    <div *ngFor="let group of formDynamicFieldsVisibilityGroups.getFormArray().controls; let i=index">
                      <div class="mt-1">
                        <div class="field">
                          <div class="is-flex">
                            <div class="control is-flex-grow-1">
                              <input class="input is-small" type="text"
                                     [formControlName]="i" placeholder="Gruppe">
                              <p class="help is-danger" *ngIf="isFormKeyInvalid('visibilityGroups.'+i)">Bitte
                                hinterlegen
                                Sie eine Gruppe.</p>
                            </div>
                            <div class="ml-2 has-text-right">
                              <button class="delete mt-2 is-small is-right"
                                      (click)="formDynamicFieldsVisibilityGroups.remove(i)"></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <shared-form-autocomplete [autocompleteModel]="autocompleteDynamicArticles"

                                      (editItem)="editDynamicArticle($event)"
                                      (onChangeItems)="setDynamicArticles($event)"></shared-form-autocomplete>
          </div>
        </div>
      </div>

      <!-- notes -->
      <div class="tab-container" [ngClass]="activeTab!='notes' ? 'is-hidden' : ''">
        <button (click)="showCustomerNotesDialog();" class="button is-small add mb-4"><span
          class="icon is-small"><fa-icon
          [icon]="faPlusSquare"></fa-icon></span><span>Notiz hinzufügen</span>
        </button>
        <p-table #dt1 [value]="customerNotes" [tableStyle]="{'min-width': '50rem'}"
                 [rows]="50"
                 [sortOrder]="-1"
                 [sortField]="'dateCreated'"
                 scrollHeight="600px"
                 styleClass="p-datatable-sm"
                 [globalFilterFields]="['title', 'note', 'dateCreated']"
        >
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th class="is-no-wrap" pSortableColumn="title">Titel
                <p-sortIcon field="title"></p-sortIcon>
              </th>
              <th class="is-no-wrap" pSortableColumn="note">Notiz
                <p-sortIcon field="note"></p-sortIcon>
              </th>
              <th class="is-no-wrap" pSortableColumn="dateCreated">Erstellungsdatum
                <p-sortIcon field="dateCreated"></p-sortIcon>
              </th>
            </tr>
            <tr>
              <th></th>
              <th>
                <input pInputText type="text"
                       (input)="dt1.filter($any($event.target).value ?? '', 'title', 'contains')"
                       [value]="$any(dt1.filters['title'])?.value" placeholder="Filter Titel"
                       class="input is-small">
              </th>
              <th>
                <input pInputText type="text"
                       (input)="dt1.filter($any($event.target).value, 'note', 'contains')"
                       [value]="$any(dt1.filters['note'])?.value" placeholder="Filter Notiz"
                       class="input is-small">
              </th>
              <th>
                <input pInputText type="date"
                       (input)="dt1.filter($any($event.target).value, 'dateCreated', 'contains')"
                       [value]="$any(dt1.filters['dateCreated'])?.value" placeholder="Filter Datum"
                       class="input is-small">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-note let-rowIndex="rowIndex">
            <tr (dblclick)="showCustomerNotesDialog(note.id);">
              <td>
                <div class="is-flex">
                  <span class="is-pointer mr-4" (click)="deleteCustomerNote(note.id)">
                    <fa-icon [icon]="faTrashCan" class="is-pointer mr-4"></fa-icon>
                  </span>
                  <span (click)="showCustomerNotesDialog(note.id);" class="is-pointer">
                     <fa-icon [icon]="faEdit"></fa-icon>
                  </span>
                </div>
              </td>
              <td>{{ note.title }}</td>
              <td [innerHTML]="note.note "></td>
              <td>{{ note.dateCreated | date:'dd.MM.yyyy H:mm' }} Uhr</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8">Keine Notizen erfasst.</td>
            </tr>
          </ng-template>
        </p-table>
        <p-dialog
          [header]="!showCustomerNoteId ? 'Notiz anlegen' : 'Notiz bearbeiten'"
          [(visible)]="_showCustomerNotesDialog"
          position="center"
          [modal]="true"
          [style]="{ width: '100vw', 'max-width': '1000px' }">
          <shared-customer-note-form [itemId]="showCustomerNoteId" [disableDbStore]="true"
                                     [customerNumber]="customerNumber" (onSetSuccess)="setCustomerNote($event)"
                                     (onUpdateSuccess)="updateCustomerNote($event)"></shared-customer-note-form>
        </p-dialog>


      </div>

    </div>

    <div class="content-foot has-text-centered">
      <shared-form-submit-button [formStatus]="formHandler.getFormStatus()"
                                 (submit)="submit()"></shared-form-submit-button>
    </div>
  </div>
</div>
