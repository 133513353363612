import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {
  ApiDeliveryAreasDeleteRequest,
  ApiDeliveryAreasDeleteResponse,
  ApiDeliveryAreasGetRequest,
  ApiDeliveryAreasGetRequestParams,
  ApiDeliveryAreasGetResponse,
  ApiDeliveryAreasSetParamsData,
  ApiDeliveryAreasSetRequest,
  ApiDeliveryAreasSetResponse,
  ApiDeliveryAreasUpdateParamsData,
  ApiDeliveryAreasUpdateRequest,
  ApiDeliveryAreasUpdateResponse
} from '@shared/api/delivery-areas';

@Injectable({
  providedIn: 'root'
})
export class DeliveryAreasService {

  constructor(private http: HttpClient) {
  }

  set(data: ApiDeliveryAreasSetParamsData): Observable<ApiDeliveryAreasSetResponse> {
    const body: ApiDeliveryAreasSetRequest = {
      id: 'setDeliveryTour#INVALID_PARAMS',
      command: 'deliveryAreas/set',
      params: {
        data
      }
    };
    return this.http.post<ApiDeliveryAreasSetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  setBatch(items: Omit<ApiDeliveryAreasSetRequest, 'command'>[]): Observable<ApiDeliveryAreasSetResponse[]> {

    let body: ApiDeliveryAreasSetRequest[] = [];

    items.map( r => {
      body.push({...r, command: 'deliveryAreas/set'})
    });

    return this.http.post<ApiDeliveryAreasSetResponse[]>(environment.apiUrl, JSON.stringify(body));
  }

  get(params: ApiDeliveryAreasGetRequestParams): Observable<ApiDeliveryAreasGetResponse> {
    const body: ApiDeliveryAreasGetRequest = {
      id: 'getDeliveryAreas',
      command: 'deliveryAreas/get',
      params: params
    };
    return this.http.post<ApiDeliveryAreasGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  update(data: ApiDeliveryAreasUpdateParamsData): Observable<ApiDeliveryAreasUpdateResponse> {
    const body: ApiDeliveryAreasUpdateRequest = {
      id: 'updateDeliveryAreas#INVALID_PARAMS',
      command: 'deliveryAreas/update',
      params: {
        data
      }
    };
    return this.http.post<ApiDeliveryAreasUpdateResponse>(environment.apiUrl, JSON.stringify(body));
  }

  updateBatch(items: Omit<ApiDeliveryAreasUpdateRequest, 'command'>[]): Observable<ApiDeliveryAreasUpdateResponse[]> {

    let body: ApiDeliveryAreasUpdateRequest[] = [];

    items.map( r => {
      body.push({...r, command: 'deliveryAreas/update'})
    });

    return this.http.post<ApiDeliveryAreasUpdateResponse[]>(environment.apiUrl, JSON.stringify(body));
  }

  delete(id: string): Observable<ApiDeliveryAreasDeleteResponse> {
    const body: ApiDeliveryAreasDeleteRequest = {
      id: 'deleteDeliveryAreas',
      command: 'deliveryAreas/delete',
      params: {
        data: {
          id
        }
      }
    };
    return this.http.post<ApiDeliveryAreasDeleteResponse>(environment.apiUrl, JSON.stringify(body));
  }


}
