<button *ngIf="formStatus === 'error'" class="button is-danger  is-cursor">
  <fa-icon [icon]="faExclamationTriangle"></fa-icon>
</button>
<button *ngIf="formStatus === 'success'" class="button is-success is-cursor">
  <fa-icon [icon]="faCheck"></fa-icon>
</button>

<button *ngIf="formStatus == 'loading'" class="button is-loading is-cursor">
  &nbsp;{{ loadingMessage }}
</button>


<button *ngIf="!formStatus || formStatus == 'submitForm'" debounce-click (debounceClick)="_submit()" class="button"  [ngClass]="classes">
  {{submitLabel}}
</button>

<div *ngIf="formStatus === 'readOnly'" class="button is-warning read-only is-cursor">
  {{ readOnlyText }}
</div>
