import {Component, Input, OnInit} from '@angular/core';
import {List} from '@shared/list/models/list';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {faAngleDown, faAngleUp, faDownload, faUpload} from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: 'app-list-button-import',
  templateUrl: './list-button-import.component.html',
  styleUrls: ['./list-button-import.component.scss']
})

export class ListButtonImportComponent implements OnInit {

  @Input() list!: List<any, any, any>;

  form = new FormGroup({
    type: new FormControl('xlsx'),
    start: new FormControl(0),
    limit: new FormControl(2000)
  });

  dropDownVisibility: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }


  download(){
    let expectedData: any = {};
    if(this.list.download){
      this.list.getColumns('all').map(
        c => {
          expectedData[c.field] = 1;
        }
      )
      //expectedData['address.name'] = 1;
      this.list.download({type: this.form.get('type')?.value as 'csv' | 'xlsx', start: this.form.get('start')?.value || 0, limit: this.form.get('limit')?.value || 2000, expectedData});
      const subscription: Subscription = this.list.loading.download
        .subscribe(
          r => {
            if(!r){
             this.dropDownVisibility = false;
             subscription.unsubscribe();
            }
          }
        )
    }
  }



  /*
    getItemValue(field: string, item: any): any{
    let deepKey = field.split('.');

    if(item[deepKey[0]] == undefined){
      return '';
    }

    if(deepKey.length > 1){
      return this.getItemValue(deepKey[1], item[deepKey[0]]);
    } else {
      return item[field];
    }
  }

  prepareItemsForDownload(items: Array<any>): Array<any> {

    let preparedItems: Array<any> = [];
    items.forEach((value, i) => {
      this.listConfig.columns.map(
        column => {
          if (!preparedItems[i]) {
            preparedItems[i] = {};
          }
          if (typeof value[column.field] == 'object') {
            preparedItems[i][column.label] = JSON.stringify(value[column.field]);
          } else {
            preparedItems[i][column.label] = this.getItemValue(column.field, value);
          }
        }
      )
    });
    return preparedItems;

  }
  preDownload(): void {
    this.list.limit = 50000;
    this.list.setDownload(true);
    this.list.loadItems();
  }
    exportCSV() {
      if(this.list.download){
        this.list.download();
      }
      return;

      this.preDownload();
      this.list.itemsForDownload.pipe(
        filter(r => r.length > 0),
        take(1)
      ).subscribe(
        r => {

          let data = this.prepareItemsForDownload(r);

          const replacer = (key: any, value: null) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(data[0]);
          let csv = data.map(row => header.map(fieldName => (row[fieldName])).join(';'));
          csv.unshift(header.join(';'));
          let csvArray = csv.join('\r\n');

          const blob = new Blob(["\ufeff" + csvArray], {type: 'text/csv'});
          FileSaver.saveAs(blob, 'export_' + new Date().getTime());
        }
      )
  }

  exportXLSX() {
    this.preDownload();
    this.list.itemsForDownload.pipe(
      filter(r => r.length > 0),
      take(1)
    ).subscribe(
      r => {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.prepareItemsForDownload(r));
        const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const data: Blob = new Blob([excelBuffer], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, 'export_' + new Date().getTime() + '.xlsx');
        this.list.setDownload(false);
      }
    )
  }
*/

  protected readonly faDownload = faDownload;
  protected readonly faAngleUp = faAngleUp;
  protected readonly faAngleDown = faAngleDown;
  protected readonly faUpload = faUpload;
}
