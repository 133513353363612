<div class="dropdown has-text-left"[ngClass]="dropDownVisibility ? 'is-active' : ''" (clickOutside)="dropDownVisibility = false">
  <form [formGroup]="form">
    <div class="dropdown-trigger">
      <button (click)="dropDownVisibility = !dropDownVisibility" class="button is-small" aria-haspopup="true"
              aria-controls="dropdown-menu">
      <span class="icon">
        <fa-icon [icon]="faUpload"></fa-icon>
      </span>
        <span>Import</span>
        <span class="icon is-small">
        <fa-icon [icon]="faAngleUp" [ngClass]="!dropDownVisibility ? 'is-hidden' : ''"></fa-icon>
        <fa-icon [icon]="faAngleDown" [ngClass]="dropDownVisibility ? 'is-hidden' : ''"></fa-icon>
      </span>
      </button>
    </div>
    <div class="dropdown-menu pt-0" id="" role="menu">
      <div class="dropdown-content pb-0">
        <a *ngFor="let l of list.getExternalLinks('upload')" (click)="list.clickExternalLink(l)" class="dropdown-item">
          {{l.name}}
        </a>
      </div>
    </div>
  </form>
</div>
