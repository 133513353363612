<div *ngIf="formHelper.status === 'loading'" class="content-content has-text-centered">
  <button class="button is-loading"></button>
</div>
<div *ngIf="formHelper.status !== 'loading'">
  <form [formGroup]="form" #formTag>
    <div class="tabs">
      <ul>
        <li [ngClass]="formHelper.activeTab =='overview' ? 'is-active' : ''"
            (click)="formHelper.activeTab = 'overview'">
          <a>Übersicht
          </a></li>
        <li
          [ngClass]="[formHelper.activeTab =='modalities' ? 'is-active' : '', customerSelected == false ? 'is-inactive' : '']"
          (click)="formHelper.activeTab = 'modalities'">
          <a>Modalitäten

          </a></li>
        <li
          [ngClass]="[formHelper.activeTab =='positions' ? 'is-active' : '', customerSelected == false ? 'is-inactive' : '']"
          (click)="formHelper.activeTab = 'positions'">
          <a>Positionen

          </a></li>
        <li
          [ngClass]="[formHelper.activeTab =='text' ? 'is-active' : '', customerSelected == false ? 'is-inactive' : '']"
          (click)="formHelper.activeTab = 'text'">
          <a>Vor- & Schlusstexte

          </a></li>
      </ul>
    </div>
    <div class="content-content is-tabs">
      <div *ngIf="formHelper.activeTab == 'overview'" class="tab-container">
        <div class="columns">
          <div class="column">
            <div class="columns">
              <div class="column is-half">
                <div class="field">
                  <label class="label">Dokument-Typ</label>
                  <div class="control">
                    <div class="select is-small">
                      <select>
                        <option>Bitte auswählen</option>
                        <option>Angebot</option>
                        <option>Auftragsannahme</option>
                        <option>Auftragsbestätigung</option>
                        <option>Lieferschein</option>
                        <option>Rechnung</option>
                        <option>Extern</option>
                        <option>Gutschrift</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-half">
                    <div class="field">
                      <div class="control">
                        <label class="label">Vorgangsnummer</label>
                        <div class="control">
                          <input class="input is-small" type="text"
                                 placeholder="Wird automatisch ausgefüllt" disabled>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-half">
                    <div class="field">
                      <div class="control">
                        <label class="label">Sachbearbeiter</label>
                        <div class="control">
                          <input class="input is-small" type="text"
                                 placeholder="demo" disabled>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              <div class="column is-half">
                <div class="field date">
                  <label class="label">Erstellungsdatum</label>
                  <div class="columns">
                    <div class="column">
                      <div class="control has-icons-left">
                        <input [matDatepicker]="dateCreated" class="input is-small" formControlName="dateCreated"
                               (click)="dateCreated.open()"
                               placeholder="Lieferdatum">
                        <mat-datepicker-toggle matSuffix [for]="dateCreated"></mat-datepicker-toggle>
                        <mat-datepicker #dateCreated></mat-datepicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field date">
                  <label class="label">Lieferdatum</label>
                  <div class="columns">
                    <div class="column">
                      <div class="control has-icons-left">
                        <input [matDatepicker]="deliveryDate" class="input is-small" formControlName="deliveryDate"
                               (click)="deliveryDate.open()"
                               placeholder="Erstellungsdatum">
                        <mat-datepicker-toggle matSuffix [for]="deliveryDate"></mat-datepicker-toggle>
                        <mat-datepicker #deliveryDate></mat-datepicker>
                      </div>
                    </div>
                    <div class="column no-padding-bottom">
                      <mat-form-field>
                        <mat-label>Stunde</mat-label>
                        <mat-select formControlName="hour">
                          <mat-option *ngFor="let hour of formHelper.hours" [value]="hour">
                            {{hour}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="column no-padding-bottom">
                      <mat-form-field>
                        <mat-label>Minute</mat-label>
                        <mat-select formControlName="minute">
                          <mat-option *ngFor="let minute of formHelper.minutes" [value]="minute">
                            {{minute}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="field date">
                  <label class="label">Rückgabedatum</label>
                  <div class="columns">
                    <div class="column">
                      <div class="control has-icons-left">
                        <input [matDatepicker]="returnDate" class="input is-small" formControlName="returnDate"
                               (click)="returnDate.open()"
                               placeholder="Erstellungsdatum">
                        <mat-datepicker-toggle matSuffix [for]="returnDate"></mat-datepicker-toggle>
                        <mat-datepicker #returnDate></mat-datepicker>
                      </div>
                    </div>
                    <div class="column no-padding-bottom">
                      <mat-form-field>
                        <mat-label>Stunde</mat-label>
                        <mat-select>
                          <mat-option *ngFor="let hour of formHelper.hours" [value]="hour">
                            {{hour}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="column no-padding-bottom">
                      <mat-form-field>
                        <mat-label>Minute</mat-label>
                        <mat-select>
                          <mat-option *ngFor="let minute of formHelper.minutes" [value]="minute">
                            {{minute}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">

          <div class="column">

          </div>
        </div>

        <h1 class="subtitle mb-0">Kunde</h1>
        <a (click)="customerSelected = !customerSelected">SIMULATION - KUNDENAUSWAHL-TOGGLE</a>
        <div class="columns">
          <div class="column">
            <div class="columns is-gapless">
              <div class="column mr-2 is-warning">
                <app-customer-user-mapper label="Daten von bestehendem Kunden übernehmen"></app-customer-user-mapper>
              </div>
              <div class="column mb-0">
                <button class="button is-success is-small new-customer"
                        (click)="openWindow()">
    <span class="icon is-small">
      <fa-icon [icon]="faPlusSquare"></fa-icon>
    </span>
                  <span>Neuen Kunden erfassen</span>
                </button>
              </div>
            </div>

            <div class="address-data" [ngClass]="[customerSelected == false ? 'is-hidden' : '']">
              <button class="button is-info is-small mb-4 mt-0"
                      (click)="formHelper.activeTab = 'positions'">
    <span class="icon is-small">
      <fa-icon [icon]="faArrowRight"></fa-icon>
    </span>
                <span>Direkt zur Positionserfassung</span>
              </button>
              <div class="field">
                <label class="label">Firma</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="password" placeholder="Vorname">

                </div>
              </div>
              <div class="field">
                <label class="label">Titel</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         formControlName="username" placeholder="Titel">
                </div>
              </div>
              <div class="columns mt-0 mb-2 pt-0 is-gapless">
                <div class="column is-half">
                  <div class="field">
                    <label class="label">Vorname</label>
                    <div class="control">
                      <input class="input is-small" type="text"
                             formControlName="password" placeholder="Vorname">

                    </div>
                  </div>
                </div>
                <div class="column ml-2">
                  <div class="field">
                    <label class="label">Name</label>
                    <div class="control">
                      <input class="input is-small" type="text"
                             placeholder="Name">

                    </div>
                  </div>

                </div>

              </div>

              <div class="field">
                <label class="label">Adresszusatz</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         placeholder="Adresszusatz">

                </div>
              </div>
              <div class="columns is-gapless mb-2">
                <div class="column mr-2">
                  <div class="field">
                    <label class="label">Postleitzahl</label>
                    <div class="control">
                      <input class="input is-small" type="text"
                             placeholder="Postleitzahl">

                    </div>
                  </div>
                </div>
                <div class="column is-three-quarters">
                  <div class="field">
                    <label class="label">Ort</label>
                    <div class="control">
                      <input class="input is-small" type="text"
                             placeholder="Ort">

                    </div>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Straße, Haus-Nr.</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         placeholder="Straße, Haus-Nr.">

                </div>
              </div>
              <p>PREVIEW-HINWEIS: Lieferadresse erscheint automatisch auf der rechten Seite, sofern bei der
                Kundenauswahl eine ausgewählt wurde.</p>
            </div>


          </div>

        </div>


      </div>
      <div *ngIf="formHelper.activeTab == 'modalities'" class="tab-container">
        <h1 class="subtitle mb-0">Konditionen</h1>
        <div class="column">

          <div class="columns mb-0">
            <div class="column">
              <div class="field">
                <label class="label">Preis Kennzeichnung</label>
                <div class="control">
                  <div class="select is-small">
                    <select>
                      <option>Bitte wählen</option>
                      <option>Abhol-Brutto</option>
                      <option>Gastro-Netto</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Zahlungsart</label>
                <div class="control">
                  <div class="select is-small">
                    <select>
                      <option>Bitte wählen</option>
                      <option>Rechnung</option>
                      <option>Lastschrift</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns mt-0 mb-0">
            <div class="column">
              <div class="field">
                <label class="label">Skonto in %</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         placeholder="0.00">

                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Skonto Tage</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         placeholder="0">

                </div>
              </div>
            </div>
          </div>
        </div>
        <h1 class="subtitle mb-0 mt-2">Skonto</h1>
        <div class="column">

          <div class="columns mt-0 mb-0">

            <div class="column">

              <div class="field">
                <label class="label">Kondition</label>
                <div class="control">
                  <div class="select is-small">
                    <select>
                      <option>Diverses Angebot</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Lieferart</label>
                <div class="control">
                  <div class="select is-small">
                    <select>
                      <option>Abholung</option>
                      <option>Versand</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Abrechnungsart</label>
                <div class="control">
                  <div class="select is-small">
                    <select>
                      <option>Sammelrechnung</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Leergutart</label>
                <div class="control">
                  <div class="select is-small">
                    <select>
                      <option>3. Leergut komplett</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Kreditlimit</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         placeholder="0">
                </div>
              </div>
            </div>
            <div class="column">


              <div class="field">
                <label class="label">Lager</label>
                <div class="control">
                  <div class="select is-small">
                    <select>
                      <option></option>
                      <option>3. Leergut komplett</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Tour</label>
                <div class="control">
                  <div class="select is-small">
                    <select>
                      <option></option>
                      <option>3. Leergut komplett</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Tourinfo</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         placeholder="Tourinfo">
                </div>
              </div>
              <div class="field">
                <label class="label">Rabatt in %</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         placeholder="0.00">

                </div>

              </div>
              <div class="field">
                <label class="label">Netto-Tage</label>
                <div class="control">
                  <input class="input is-small" type="text"
                         placeholder="0">

                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div *ngIf="formHelper.activeTab == 'positions'" class="tab-container">

        <div class="container-tabledf">
          <button class="button is-success is-small">
    <span class="icon is-small">
      <fa-icon [icon]="faPlusSquare"></fa-icon>
    </span>
            <span>Position hinzufügen</span>
          </button>

          <table
            class="table is-fullwidth is-hoverable is-striped is-bordered"
            style="min-width:1000px; margin-top:10px;">
            <thead>
            <tr>
              <th>
                <app-list-header-column label="Art"></app-list-header-column>

              </th>
              <th>
                Art.-Nr.
              </th>
              <th>
                Bezeichnung
              </th>
              <th>
                Preis ab 01.02.2021
              </th>
              <th>
                Preis
              </th>

              <th>
                Menge
              </th>
              <th>
                Warenwert
              </th>
              <th>
                Pfand
              </th>
              <th>
                Gesamtpreis
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>A</td>
              <td>14531</td>
              <td>Coca-Cola</td>
              <td class="has-text-right">10,35 €</td>
              <td class="has-text-right">9,35 €</td>
              <td class="has-text-right">10</td>
              <td class="has-text-right">93,50 €</td>
              <td class="has-text-right">33,00 €</td>
              <td class="has-text-right">126,50 €</td>
            </tr>
            <tr>
              <td>A</td>
              <td>14531</td>
              <td>Coca-Cola</td>
              <td class="has-text-right">10,35 €</td>
              <td class="has-text-right">9,35 €</td>
              <td class="has-text-right">10</td>
              <td class="has-text-right">93,50 €</td>
              <td class="has-text-right">33,00 €</td>
              <td class="has-text-right">126,50 €</td>
            </tr>
            <tr>
              <td>A</td>
              <td>14531</td>
              <td>Coca-Cola</td>
              <td class="has-text-right">10,35 €</td>
              <td class="has-text-right">9,35 €</td>
              <td class="has-text-right">10</td>
              <td class="has-text-right">93,50 €</td>
              <td class="has-text-right">33,00 €</td>
              <td class="has-text-right">126,50 €</td>
            </tr>
            <tr>
              <td></td>
              <td><input class="input is-small"></td>
              <td></td>
              <td class="has-text-right"></td>
              <td class="has-text-right"></td>
              <td class="has-text-right"></td>
              <td class="has-text-right"></td>
              <td class="has-text-right"></td>
              <td class="has-text-right"></td>
            </tr>
            </tbody>

          </table>
        </div>
        <div class="notification has-text-centered">
          <table
            class="table "
            style="margin:10px auto auto auto; ">
            <thead>
            <th colspan="2">Summen</th>
            </thead>

            <tbody>
            <tr>
              <td class="has-text-right">Warenwert: </td>
              <td>155,00 €</td>
            </tr>
            <tr>
              <td class="has-text-right">Pfand: </td>
              <td>155,00 €</td>
            </tr>
            <tr>
              <td class="has-text-right">Gesamtbetrag: </td>
              <td>155,00 €</td>
            </tr>
            </tbody>
          </table>
        </div>


      </div>
      <div *ngIf="formHelper.activeTab == 'text'" class="tab-container">
        <div class="columns">
          <div class="column">
            <label>Vortext</label>
            <textarea class="textarea" placeholder="Vortext"></textarea>
          </div>
          <div class="column">
            <label>Schlusstext</label>
            <textarea class="textarea" placeholder="Schlusstext"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="content-foot has-text-centered">
      <shared-form-submit-button></shared-form-submit-button>
    </div>

  </form>
</div>


