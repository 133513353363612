import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Window} from '@shared/window-manager/interfaces/window.model';
import {Subject} from 'rxjs';
import {
  FormBuilder,
  Validators
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {finalize} from 'rxjs/operators';
import {VouchersService} from '@shared/vouchers/services/vouchers.service';
import {FormHandler} from "@shared/forms/models/form-handler";
import {ApiVouchersSetRequest, ApiVouchersUpdateRequest} from "@shared/api/vouchers";

@Component({
  selector: 'shared-voucher-form',
  templateUrl: './voucher-form.component.html',
  styleUrls: ['./voucher-form.component.scss']
})
export class VoucherFormComponent implements OnInit {

  @ViewChild('formTag', {static: false}) formTag: ElementRef | undefined;
  @Input() window?: Window;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  changeDetectionGroup: string = 'vouchers';

  form = this.fb.group({
    status: this.fb.control('', Validators.required),
    code: this.fb.control('', Validators.required),
    value: this.fb.control(0, Validators.required),
    balance: this.fb.control(0, Validators.required),
    discountInPercent: this.fb.control(0, Validators.required),
    validTo: this.fb.control('')
  });

  formHandler: FormHandler;

  activeTab = 'details';

  loading: boolean = false;

  constructor(
    private vouchersService: VouchersService,
    private store: Store<AppState>,
    private fb: FormBuilder
  ) {
    this.formHandler = new FormHandler(this.store);
  }

  ngOnInit() {
    if (this.window?.data?.itemId) {
      this.loadItem(this.window.data.itemId);
    }
  }

  loadItem(id: string) {
    this.loading = true;
    this.vouchersService.get({vouchers: {}, filters: [{fields: ['id'], values: [id]}]})
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        r => {
          if (r.result?.vouchers.data[0]) {
            let data = r.result?.vouchers.data[0];
            this.form.get('status')?.setValue(data.status);
            this.form.get('code')?.setValue(data.code);
            this.form.get('value')?.setValue(data.value);
            this.form.get('balance')?.setValue(data?.balance ?? 0);
            this.form.get('discountInPercent')?.setValue(data?.discountInPercent ?? 0);
            this.form.get('validTo')?.setValue(data.validTo?.toString() ?? '');

          }
        }
      )
  }

  isFormKeyInvalid(key: string, displayIfSubmitForm: boolean = true): boolean {
    let form = this.form?.get(key);
    return !!(form?.invalid &&
      (
        this.formHandler.getFormStatus() == 'submitForm' &&
        displayIfSubmitForm
      )
    );
  }

  submit() {
    if (this.form.invalid) {
      this.formHandler.setFormStatus({formStatus: 'submitForm'});
      return;
    }
    if (this.formHandler.getFormStatus() == 'loading') {
      return;
    }
    this.formHandler.setFormStatus({formStatus: 'loading'});
    let getData = (): ApiVouchersSetRequest['params']['data'] => {
      let data: ApiVouchersSetRequest['params']['data'] = {
        status: (this.form.get('status')?.value as any) ?? '',
        code: this.form.get('code')?.value ?? '',
        value: this.form.get('value')?.value ?? 0,
        balance: this.form.get('balance')?.value ?? 0,
        discountInPercent: this.form.get('discountInPercent')?.value ?? 0
      };

      if (this.form.get('validTo')?.value) {
        data.validTo = new Date(this.form.get('validTo')?.value ?? '');
      }


      return data;
    };

    this.formHandler.setFormStatus({formStatus: 'loading'});
    // Update
    if (this.window?.data &&
      this.window.data.itemId) {
      let data: ApiVouchersUpdateRequest['params']['data'] = {...getData()};
      let unset: ApiVouchersUpdateRequest['params']['unset'] = undefined;
      if (this.form.get('validTo')?.value == '') {
        unset = ['validTo'];
      }

      this.vouchersService.update(data, unset)
        .subscribe(
          {
            next: (r) => {
              if (r.error) {
                this.formHandler.setFormError(r.error);
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'error',
                    resetStatusAfterTimeoutTo: null
                  }
                );
              } else {
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'success',
                    resetStatusAfterTimeoutTo: null,
                    closeWindowId: this.window?.id,
                    callbackAfterTimeout: () => {
                      this.formHandler.setItemUpdate(data.code ?? '', this.changeDetectionGroup)
                    }
                  }
                )
              }
            },
            error: (error) => {
              this.formHandler.setFormError({code: 'SUBSCRIPTION_ERROR', message: JSON.stringify(error)});
              this.formHandler.setFormStatus(
                {
                  formStatus: 'error',
                  resetStatusAfterTimeoutTo: null
                }
              );
            }
          }
        )
      // Insert
    } else {
      this.vouchersService.set(getData())
        .subscribe(
          {
            next: (r) => {
              if (r.error) {
                this.formHandler.setFormError(r.error);
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'error',
                    resetStatusAfterTimeoutTo: null
                  }
                );
              } else {
                this.formHandler.setFormStatus(
                  {
                    formStatus: 'success',
                    resetStatusAfterTimeoutTo: null,
                    closeWindowId: this.window?.id,
                    callbackAfterTimeout: () => {
                      this.formHandler.setItemUpdate(r.result?.vouchers.data.id ?? '', this.changeDetectionGroup)
                    }
                  }
                )
              }
            },
            error: (error) => {
              this.formHandler.setFormError({code: 'SUBSCRIPTION_ERROR', message: JSON.stringify(error)});
              this.formHandler.setFormStatus(
                {
                  formStatus: 'error',
                  resetStatusAfterTimeoutTo: null
                }
              );
            }
          }
        )
    }
  }

}
