export interface AutocompleteItem{
  name: string;
  value: string;
}

export class Autocomplete {

  public loading: boolean = false;

  public label: string;
  public placeholder: string;
  public loadItems: (searchString: string) => void;
  public sortable: boolean;
  public editable: boolean;
  public excludeItemName: string = '';
  public maxSelectedItems: number;
  public startSearchAfterTypedCharacters: number;
  public limit: number = 50;

  protected items: AutocompleteItem[] = [];
  protected selectedItems: AutocompleteItem[] = [];

  constructor(
    params: {
      label: string,
      placeholder: string,
      loadItems: (searchString: string) => void,
      sortable?: boolean,
      editable?: boolean,
      maxSelectedItems?: number,
      startSearchAfterTypedCharacters?: number,
      limit?: number
    }
  ) {
    this.label = params.label;
    this.placeholder = params.placeholder;
    this.loadItems = params.loadItems;
    this.sortable = params.sortable ?? true;
    this.editable = params.editable ?? true;
    this.maxSelectedItems = params.maxSelectedItems ?? 0;
    this.startSearchAfterTypedCharacters = params.startSearchAfterTypedCharacters ?? 2;
    this.limit = params.limit ?? 50;

  }

  setItems(items: AutocompleteItem[]){
    this.items = [];
    items.map(
      i => {
        if(i.name !== this.excludeItemName){
            this.items.push(i);
        }
      }
    )
  }

  getItems(): AutocompleteItem[]{
    return this.items;
  }

  selectItem(item: AutocompleteItem){
    this.selectedItems = this.selectedItems.filter(r => r.value !== item.value);
    this.selectedItems.push(item);
    this.items = this.items.filter(r => r.value !== item.value);
  }

  /*setSelectedItems(items: AutocompleteItem[]){
    this.selectedItems = items;
  }*/

  getSelectedItems(): AutocompleteItem[]{
    return this.selectedItems;
  }

  deleteSelectedItem(item: AutocompleteItem){
    this.selectedItems = this.selectedItems.filter(r => r.value !== item.value);
  }

  resetSelectedItems(){
    this.selectedItems = [];
  }

}
