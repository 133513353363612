<!--<div *ngIf="(notifications$ | async)" class="notification" >
  <button (click)="toggleNotifications(false)" class="delete"></button>
  <div class="notification" [ngClass]="{'is-danger': item.type == 'error'}" role="alert" *ngFor="let item of notifications$ | async; index as i " [innerHTML]="item.notification"></div>
</div>-->
<!--<div class="notification-container">
  <div class="notification is-danger" [ngClass]="{'is-danger': item.type == 'error'}" role="alert" *ngFor="let item of notifications$ | async; index as i">
    <button class="delete" (click)="destroyLogs()"></button>
    <span [innerHTML]="item.notification"></span>
  </div>
</div>-->

<div class="modal is-active" *ngIf="(notifications$ | async)">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Fehler</p>
      <button class="delete" aria-label="close" (click)="destroyLogs()"></button>
    </header>
    <section class="modal-card-body">
      <div *ngFor="let item of notifications$ | async; index as i">
        <span [innerHTML]="item.notification"></span>
      </div>

    </section>
  </div>
</div>
