<button *ngIf="formHandler.status === 'loading'" class="button is-loading content"></button>
<div #formTag>
  <div *ngIf="formHandler.status !== 'loading'">
    <form [formGroup]="form">
      <div formGroupName="emailServer" iw-error-group="emailServer">

        <div class="columns">
          <div class="column">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">E-Mail-Adresse</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="email" placeholder="E-Mail-Adresse">
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Absender</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="from" placeholder="Absender">
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Postausgangsserver</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="host" placeholder="Postausgangsserver">
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Benutzername</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="username" placeholder="Benutzername">
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Passwort</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-small" type="text"
                           formControlName="password" placeholder="Passwort">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Verschlüsselung</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <div class="select is-small">
                      <select formControlName="encryption">
                        <option value="tls">TLS</option>
                        <option value="ssl">SSL</option>
                        <option value="none">Unverschlüsselt (nicht empfohlen)</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Port</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-small" type="number"
                           formControlName="port" placeholder="Port">
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">SMTP-Authentifizierung</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <label class="checkbox">
                      <input type="checkbox" formControlName="smtpAuth">
                      SMTP-Authentifizierung
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div class="has-text-centered mt-6">
        <shared-form-submit-button (submit)="submit()" [formStatus]="formHandler.status"></shared-form-submit-button>

      </div>
    </form>
  </div>
</div>
