<app-authentication-container>
  <div class="mt-6"></div>
  <div *ngIf="sentTo.length == 0 && captcha">
    <h1 class="title is-5 mb-0">Passwort vergessen</h1>
    <p class="mt-2 mb-4 is-size-7">Bitte hinterlegen Sie Ihre E-Mail-Adresse / Benutzernamen, um einen Link für die Zurücksetzung des Passwortes per E-Mail zu erhalten.</p>
  </div>
  <div #formTag>

    <div *ngIf="sentTo.length == 0">
      <app-captcha (isVerified)="setCaptcha($event)"></app-captcha>
        <div>
          <div *ngIf="captcha" class="is-block">


              <div class="field" [formGroup]="form">
                <div class="control has-icons-left has-icons-right">
                  <input class="input" type="text" formControlName="username" placeholder="E-Mail-Adresse / Benutzername" value="">
                  <span class="icon is-small is-left"><fa-icon [icon]="faUser"></fa-icon></span>
                </div>
              </div>
              <shared-form-submit-button [formStatus]="formHandler.status" submitLabel="Link zusenden" (submit)="submit()"></shared-form-submit-button>
            </div>


          </div>
        </div>
    </div>
    <div  *ngIf="sentTo">
      <p>Eine E-Mail zur Zurücksetzung des Passwortes wurde erfolgreich an {{sentTo}} gesendet.
      </p>
    </div>
    <a class="goto-login" routerLink="/login">Zum Login <fa-icon [icon]="faSignIn"></fa-icon> </a>
</app-authentication-container>
