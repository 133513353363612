import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {
  ApiArticlesDeleteRequest, ApiArticlesDeleteResponse,
  ApiArticlesGetRequest,
  ApiArticlesGetRequestParams,
  ApiArticlesGetResponse,
  ApiArticlesSetRequest,
  ApiArticlesSetResponse,
  ApiArticlesUpdateRequest,
  ApiArticlesUpdateResponse
} from '@shared/api/articles';
import {ConfigAvailability} from '@shared/config/interfaces/config';
import {
  ApiArticleIndividualSortDeleteRequest, ApiArticleIndividualSortDeleteResponse,
  ApiArticleIndividualSortGetRequest,
  ApiArticleIndividualSortGetResponse,
  ApiArticleIndividualSortSetRequest,
  ApiArticleIndividualSortSetResponse,
  ArticleIndividualSortSetParamsData
} from '@shared/api/article-individual-sort';
import {ApiArticlePricesGetRequest, ApiArticlePricesGetResponse} from '@shared/api/article-prices';


@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  availabilities: ConfigAvailability[] = [];

  currency: string = 'EUR';

  constructor(private http: HttpClient) {
  }

  setBatch(items: Omit<ApiArticlesSetRequest, 'command'>[]): Observable<ApiArticlesSetResponse[]> {

    let body: ApiArticlesSetRequest[] = [];

    items.map( r => {
      body.push({...r, command: 'articles/set'})
    });

    return this.http.post<ApiArticlesSetResponse[]>(environment.apiUrl, JSON.stringify(body));
  }

  updateBatch(items: Omit<ApiArticlesUpdateRequest, 'command'>[]): Observable<ApiArticlesUpdateResponse[]> {

    let body: ApiArticlesUpdateRequest[] = [];

    items.map( r => {
      body.push({...r, command: 'articles/update'})
    });

    return this.http.post<ApiArticlesUpdateResponse[]>(environment.apiUrl, JSON.stringify(body));
  }

  getArticles(params: ApiArticlesGetRequestParams): Observable<ApiArticlesGetResponse> {
    const body: ApiArticlesGetRequest = {id: 'getArticles', command: 'articles/get', params};
    return this.http.post<ApiArticlesGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  getArticlePrices(articleNumber: string): Observable<ApiArticlePricesGetResponse> {
    const body: ApiArticlePricesGetRequest = {
      id: 'getArticlePrices',
      command: 'articlePrices/get',
      params: {
        filters: [
          {fields: ['articleNumber'], values: [articleNumber]},
        ],
        articlePrices: {

        }
      }
    };
    //this.environmentService.environment.URL_
    return this.http.post<ApiArticlePricesGetResponse>(environment.apiUrl, JSON.stringify(body));
  }


  setAvailabilities(availabilities: ConfigAvailability[]) {
    this.availabilities = availabilities;
  }

  getAvailability(inventory: number, availability?: string): string {

    if (availability &&
      availability.length > 0) {
      return availability;
    }

    let availabilities: ConfigAvailability[] = this.availabilities.filter(
      availability =>
        (
          (
            availability.fromInventory === null ||
            (
              availability.fromInventory !== undefined &&
              inventory >= availability.fromInventory
            )
          )
          &&
          (
            availability.toInventory === null ||
            (
              availability.toInventory !== undefined &&
              inventory <= availability.toInventory
            )
          )
        )
    );


    if (availabilities[0]) {
      return availabilities[0].name;
    }
    return '';
  }

  getAvailabilityColor(inventory: number, availability?: string): string {
    let availabilities: ConfigAvailability[];
    if (availability &&
      availability.length > 0) {
      availabilities = this.availabilities.filter(
        availabilityC => availabilityC.name == availability
      );
      if (availabilities[0]) {
        return availabilities[0].color;
      }
    }

    availabilities = this.availabilities.filter(
      availability =>
        (
          (
            availability.fromInventory === null ||
            (
              availability.fromInventory !== undefined &&
              inventory >= availability.fromInventory
            )
          )
          &&
          (
            availability.toInventory === null ||
            (
              availability.toInventory !== undefined &&
              inventory <= availability.toInventory
            )
          )
        )
    );
    if (availabilities[0]) {
      return availabilities[0].color;
    }
    return 'red';
  }

  getUnitPrice(quantity: number, netPrice: number, taxRate: number, priceMode: 'gross' | 'net') {
    let unitPrice: any = (netPrice / quantity).toFixed(3);
    unitPrice = parseFloat(unitPrice);
    if (priceMode == 'net') {
      return new Intl.NumberFormat('de-CH', {
        style: 'currency',
        currency: 'CHF',
        maximumFractionDigits: 2
      }).format(unitPrice);
    } else {
      return new Intl.NumberFormat('de-CH', {
        style: 'currency',
        currency: 'CHF',
        maximumFractionDigits: 2
      }).format(((unitPrice) / 100 * (100 + taxRate)));
    }
  }

  getFormattedPrice(price: number) {
    return new Intl.NumberFormat('de-CH', {style: 'currency', currency: 'CHF', maximumFractionDigits: 2}).format(price);
  }

  getPrice(netPrice: number, taxRate: number, priceMode: 'gross' | 'net') {
    if (priceMode == 'net') {
      return new Intl.NumberFormat('de-CH',
        {
          style: 'currency', currency: 'CHF',
          maximumFractionDigits: 2
        }).format(netPrice);
    } else {
      return new Intl.NumberFormat('de-CH', {
        style: 'currency',
        currency: 'CHF',
        maximumFractionDigits: 2
      }).format((netPrice / 100 * (100 + taxRate)));
    }
  }

  setArticleIndividualSorts(params: { data: ArticleIndividualSortSetParamsData[] }): Observable<ApiArticleIndividualSortSetResponse[]> {

    const body: ApiArticleIndividualSortSetRequest[] = [];
    let i = 0;
    params.data.map(data => {
      body.push({
        id: 'setArticleIndividualSorts' + i,
        command: 'articleIndividualSort/set',
        params: {
          data
        }
      })
      i++;
    })
    return this.http.post<ApiArticleIndividualSortSetResponse[]>(environment.apiUrl, JSON.stringify(body));
  }

  getArticleIndividualSort(params: { type: string, sortName: string }): Observable<ApiArticleIndividualSortGetResponse> {
    const body: ApiArticleIndividualSortGetRequest = {
      id: 'getArticleIndividualSort',
      command: 'articleIndividualSort/get',
      params: {
        filters: [
          {
            fields: ['type'],
            values: [params.type]
          },
          {
            fields: ['sortName'],
            values: [params.sortName]
          }
        ],
        articleIndividualSort: {
          expectedData: {
            id: 1,
            value: 1,
            sortName: 1
          }
        }
      }
    };
    return this.http.post<ApiArticleIndividualSortGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  getArticleIndividualSortNames(params: { type: string }): Observable<ApiArticleIndividualSortGetResponse> {
    const body: ApiArticleIndividualSortGetRequest = {
      id: 'getArticleIndividualSortTypes',
      command: 'articleIndividualSort/get',
      params: {
        filters: [{
          fields: ['type'],
          values: [params.type]
        }],
        articleIndividualSort: {
          groupBy: 'sortName',
          expectedData: {
            sortName: 1
          }

        }
      }
    };
    return this.http.post<ApiArticleIndividualSortGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  deleteArticleIndividualSorts(params: { ids: string[] }): Observable<ApiArticleIndividualSortDeleteResponse[]> {
    const body: ApiArticleIndividualSortDeleteRequest[] = [];
    let i = 0;
    params.ids.map(id => {
      body.push({
        id: 'setArticleIndividualSort_' + i,
        command: 'articleIndividualSort/delete',
        params: {
          data: {
            id
          }
        }
      })
      i++;
    })
    return this.http.post<ApiArticleIndividualSortDeleteResponse[]>(environment.apiUrl, JSON.stringify(body));
  }

  set(data: ApiArticlesSetRequest['params']['data']): Observable<ApiArticlesSetResponse> {
    const body: ApiArticlesSetRequest = {
      id: 'updateArticle#INVALID_PARAMS',
      command: 'articles/set',
      params: {
        data
      }
    };
    return this.http.post<ApiArticlesSetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  update(data: ApiArticlesUpdateRequest['params']['data'], unset?: ['invisible', 'disableOrder']): Observable<ApiArticlesUpdateResponse> {
    const body: ApiArticlesUpdateRequest = {
      id: 'updateArticle#INVALID_PARAMS',
      command: 'articles/update',
      params: {
        data,
        unset
      }
    };
    return this.http.post<ApiArticlesUpdateResponse>(environment.apiUrl, JSON.stringify(body));
  }



  async resolveArticleNumbersToNames(articleNumbers: string[]): Promise<{articleNumber: string, name: string}[]> {
    let data: {articleNumber: string, name: string}[] = [];
    const body: ApiArticlesGetRequest[] = [];
    articleNumbers.filter((value, index, self) => self.indexOf(value) === index).map(id => {
      body.push({
        id,
        command: 'articles/get',
        params: {
          filters: [{fields: ['articleNumber'], values: [id]}],
          articles: {
            expectedData: {
              articleNumber: 1,
              name: 1
            }
          }
        }
      })
    });
    if(articleNumbers.length > 0){
      await this.http.post<ApiArticlesGetResponse[]>(environment.apiUrl, JSON.stringify(body)).toPromise().then(
        r => {
          if(r){
            articleNumbers.map(articleNumber => {
              let filter = r.filter(data => data.id == articleNumber);
              if (filter[0]?.result) {
                data.push({articleNumber, name:filter[0].result?.articles?.data[0].name || ''})
              }

            })
          }

        }
      )
    }
    return data;
  }

  download(params: ApiArticlesGetRequestParams, type: 'csv' | 'xlsx'): Observable<any> {
    const body: ApiArticlesGetRequest = {
      id: 'downloadArticles',
      command: 'articles/get',
      params
    };
    return this.http.post<any>(environment.apiUrl + 'download.php?type=' + type, JSON.stringify(body), {responseType: 'blob' as 'json'});
  }

  delete(articleNumber: string): Observable<ApiArticlesDeleteResponse>{
    const body: ApiArticlesDeleteRequest = {
      id: 'deleteArticle',
      command: 'articles/delete',
      params: {
        data: {
          articleNumber
        }
      }
    };
    return this.http.post<ApiArticlesDeleteResponse>(environment.apiUrl, JSON.stringify(body));
  }
}
