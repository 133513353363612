import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@environments/environment";
import {
  ApiDynamicPagesDeleteRequest,
  ApiDynamicPagesDeleteResponse,
  ApiDynamicPagesGetRequest, ApiDynamicPagesGetResponse,
  ApiDynamicPagesSetRequest,
  ApiDynamicPagesSetResponse, ApiDynamicPagesUpdateRequest, ApiDynamicPagesUpdateResponse
} from "@shared/api/dynamic-articles";

@Injectable({
  providedIn: 'root'
})
export class DynamicPagesService {
  constructor(private http: HttpClient) {
  }

  set(data: ApiDynamicPagesSetRequest['params']['data']): Observable<ApiDynamicPagesSetResponse> {
    const body: ApiDynamicPagesSetRequest = {
      id: 'setDynamicPages#INVALID_PARAMS',
      command: 'dynamicPages/set',
      params: {
        data
      }
    };
    return this.http.post<ApiDynamicPagesSetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  get(params: ApiDynamicPagesGetRequest['params']): Observable<ApiDynamicPagesGetResponse> {
    const body: ApiDynamicPagesGetRequest = {
      id: 'getDynamicPages',
      command: 'dynamicPages/get',
      params: params
    };
    return this.http.post<ApiDynamicPagesGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  update(data: ApiDynamicPagesUpdateRequest['params']['data']): Observable<ApiDynamicPagesUpdateResponse> {
    const body: ApiDynamicPagesUpdateRequest = {
      id: 'updateDynamicPages#INVALID_PARAMS',
      command: 'dynamicPages/update',
      params: {
        data
      }
    };
    return this.http.post<ApiDynamicPagesUpdateResponse>(environment.apiUrl, JSON.stringify(body));
  }

  delete(id: string): Observable<ApiDynamicPagesDeleteResponse> {
    const body: ApiDynamicPagesDeleteRequest = {
      id: 'deleteDynamicPage',
      command: 'dynamicPages/delete',
      params: {
        data: {
          id
        }
      }
    };
    return this.http.post<ApiDynamicPagesDeleteResponse>(environment.apiUrl, JSON.stringify(body));
  }

}
