<div class="field">
  <label class="label">{{label}}</label>
  <div class="control has-icons-left" [ngClass]="loading ? 'is-loading' : '' ">
    <input class="input is-small " type="text" placeholder="Suchen..." #s (input)="loadData(s.value)">
    <span class="icon is-small is-left"><fa-icon [icon]="faSearch"></fa-icon></span>
  </div>
  <div class="panel" *ngIf="items.length > 0">
    <a *ngFor="let item of items" class="panel-block" (click)="setItem(item)">
      <span class="name">KdNr.: {{item.customerNumber}}, E-Mail: {{item.email}}</span>
      <span class="name"><b>Adressen:</b></span>
      <span class="name" *ngFor="let address of item.addresses ; let i = index">{{i + 1}}
        .) {{address.gesoftName1}} {{address.gesoftName2}}, {{address.street}}
        , {{address.postcode}} {{address.town}}</span>
    </a>
  </div>
  <div class="panel-list panel is-primary" [ngClass]="draggable ? 'is-draggable' : ''" cdkDropList
       (cdkDropListDropped)="drop($event)">
    <div class="panel-block" cdkDrag *ngFor="let item of selectedItems">
      <fa-icon [icon]="faBars" class=" drag-handle" cdkDragHandle></fa-icon>
      <span class="name">KdNr.: {{item.customerNumber}}, E-Mail: {{item.email}}</span>
      <span class="name"><b>Adressen:</b></span>
      <span class="name" *ngFor="let address of item.addresses ; let i = index">
        <label class="checkbox">
  <input type="checkbox" class="mr-0" (click)="toggleAddress(item.id, address.addressId || '')"
         pTooltip="Der User kann für eine ausgewählte Adresse Bestellungen auslösen" tooltipPosition="right"
         [checked]="(!item.shopAccessForCustomer?.permissions?.addressIds || item.shopAccessForCustomer?.permissions?.addressIds?.includes(address.addressId || ''))">
          {{address.gesoftName1}} {{address.gesoftName2}}, {{address.street}}, {{address.postcode}} {{address.town}}
</label>
      </span>
      <span class="name"><b>Rechte:</b><br>
      <label class="checkbox is-block">
  <input type="checkbox" class="mr-0" [checked]="(item.shopAccessForCustomer?.permissions?.grant?.includes('users'))"
         (click)="toggleGrant(item.id, 'users')">
        User anlegen / bearbeiten (Administrator)
</label>
        <label class="checkbox is-block">
  <input type="checkbox" class="mr-0" [checked]="(item.shopAccessForCustomer?.permissions?.grant?.includes('invoices'))"
         (click)="toggleGrant(item.id, 'invoices')">
        Rechnungen einsehen
</label>
        <label class="checkbox is-block">
  <input type="checkbox" class="mr-0" [checked]="(item.shopAccessForCustomer?.permissions?.grant?.includes('prices'))"
         (click)="toggleGrant(item.id, 'prices')">
        Preise einsehen
</label>
        <label class="checkbox is-block">
  <input type="checkbox" class="mr-0" [checked]="(item.shopAccessForCustomer?.permissions?.grant?.includes('favorites'))"
         (click)="toggleGrant(item.id, 'favorites')">
        Favoriten bearbeiten
</label>
                <label class="checkbox is-block">
  <input type="checkbox" class="mr-0" [checked]="(item.shopAccessForCustomer?.permissions?.grant?.includes('customerAddresses'))"
         (click)="toggleGrant(item.id, 'customerAddresses')">
        Kundenadressen anlegen / bearbeiten
</label>
      </span>
      <button class="delete is-small is-right" (click)="deleteItem(item.id)"></button>
    </div>
  </div>
</div>


