import {Component, Input, OnInit} from '@angular/core';
import {lastValueFrom, Subject, Subscription} from 'rxjs';
import {List} from '@shared/list/models/list';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {ListColumnFactory} from '@shared/list/models/list-column';
import {Validators} from '@angular/forms';
import {ApiCustomerNote, ApiCustomerNotesGetRequest} from "@shared/api/customer-notes";
import {CustomerNoteService} from "@shared/customer/services/customer-note.service";
import {ApiFilter} from "@shared/api/filter";

interface RequestParams extends Required<ApiCustomerNotesGetRequest['params']['customerNotes']> {
}

@Component({
  selector: 'shared-customer-note-list',
  templateUrl: './customer-note-list.component.html',
  styleUrls: ['./customer-note-list.component.scss']
})
export class CustomerNoteListComponent implements OnInit {

  @Input() customerNumber?: string;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  loadItems$: Subscription | undefined;

  list: List<keyof RequestParams['expectedData'], ApiCustomerNote, 'id'>;


  showItemId?: string;
  showNotesDialog: boolean = false;

  constructor(private store: Store<AppState>,
              private customerNoteService: CustomerNoteService) {
    this.list = new List({
      id: 'customerNotes',
      headline: '',
      store: this.store,
      itemIdKey: 'id'
    });

    this.list.newItem = {
      label: 'Notiz erstellen',
      action: () => this.setNote()
    };

    this.list.subscribeChangeDetection({groups: ['customerNotes'], sort: {field: 'dateCreated', direction: -1}});

    this.list.row = {
      doubleClick: (item) => this.editNote(item.id),
      details: {
        history: true,
        edit: (item) => this.editNote(item.id),
        delete: {
          action: (item: any) => this.deleteItem(item)
        }

      }
    };

    this.list.columns = [
      ListColumnFactory.create({
        field: 'id',
        label: 'Id',
        class: 'id',
        isHidden: true,
        isId: true,
        filterValidators: [Validators.minLength(24), Validators.maxLength(24)]
      }),
      ListColumnFactory.create({
        field: 'customerNumber',
        label: 'Kundennummer',
        isHidden: true
      }),
      ListColumnFactory.create({
        field: 'title',
        label: 'Titel'
      }),
      ListColumnFactory.create({
        field: 'note',
        label: 'Notiz',
        isHtml: true
      }),
      ListColumnFactory.create({
        field: 'dateCreated',
        label: 'Erstellungsdatum',
        dataType: 'dateTime'
      }),
    ];

    this.list.loadItems = () => this.loadItems({
      filters: this.list.getFilters(),
      customerNotes: {
        sort: this.list.getSort(),
        start: this.list.start
      }
    });
  }

  ngOnInit(): void {
    this.list.setSort([{field: 'dateCreated', direction: -1}])
    this.loadItems({customerNotes: {sort: this.list.getSort() } });
  }

  setNote(){
    this.showItemId = undefined;
    this.showNotesDialog = true;
  }

  editNote(itemId: string){
    this.showItemId = itemId;
    this.showNotesDialog = true;
  }


  async prepareItems(items: ApiCustomerNote[]): Promise<ApiCustomerNote[]> {
    return items;
  }

  loadItemAndUpdateList(id: string) {
    this.customerNoteService.get({customerNotes: {}, filters: [{fields: ['id'], values: [id]}]}).subscribe(
      r => {
        if (r.result &&
          r.result.customerNotes?.data &&
          r.result.customerNotes?.data.length > 0) {
          this.list.updateItem({id, item: r.result.customerNotes.data[0]})
        }
      }
    )
  }

  async getTotalItems(){
    let r = await lastValueFrom(this.customerNoteService.get({customerNotes: {start: 0, limit: 1}, filters: [{fields: ['customerNumber'], values: [this.customerNumber ?? '']}] }));
    return r.result?.customerNotes.count.filtered ?? 0;
  }

  loadItems(params?: ApiCustomerNotesGetRequest['params']): void {
    params = {
      ...params,
      customerNotes: {
        ...params?.customerNotes,
        expectedData: this.list.getColumnFields('userStorage')
      }
    };

    let filter: ApiFilter<any> = {fields: ['customerNumber'], values: [this.customerNumber ?? '']};
    if(params.filters){
      params.filters.push(filter);
    } else {
      params.filters = [filter];
    }

    if (this.list.resetItems) {
      if (this.loadItems$) {
        this.loadItems$.unsubscribe();
      }
      this.list.loading.list.next(true);
    } else {
      this.list.loading.notification.next(true);
    }

    this.loadItems$ = this.customerNoteService.get(
      params
    )
      .subscribe(
        {
          next: async (r) => {
            let totalItems = await this.getTotalItems();
            if (r.result) {
              this.list.count = {total: totalItems, filtered: r.result.customerNotes?.count.filtered ?? 0};
              let data: any[] = await this.prepareItems(r.result.customerNotes?.data);

              this.list.setItems(data || [], this.list.resetItems);
            } else {
              this.list.count = {total: totalItems, filtered: 0};
              this.list.setItems([], true);
            }
            this.list.loading.list.next(false);
            this.list.loading.notification.next(false);
          },
          error: () => {
            this.list.loading.list.next(false);
            this.list.loading.notification.next(false);
          }
        }
      );
  }


  deleteItem(item: any) {
    if (confirm('Möchten Sie die Notiz ' + (item.title ?? '') + ' wirklich löschen?')) {
      this.customerNoteService.delete(item.id)
        .subscribe(
          r => {
            if (r.result?.customerNotes.deleted) {
              this.list.deleteItemSuccess(item.id);
            }
          }
        )
    }
  }


  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
