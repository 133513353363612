import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {
  ApiDynamicArticlesDeleteRequest, ApiDynamicArticlesDeleteResponse,
  ApiDynamicArticlesGetRequest,
  ApiDynamicArticlesGetResponse,
  ApiDynamicArticlesSetRequest,
  ApiDynamicArticlesSetResponse, ApiDynamicArticlesUpdateRequest, ApiDynamicArticlesUpdateResponse
} from '@shared/api/api-dynamic-article';

@Injectable({
  providedIn: 'root'
})
export class DynamicArticlesService {
  constructor(private http: HttpClient) {
  }

  set(data: ApiDynamicArticlesSetRequest['params']['data']): Observable<ApiDynamicArticlesSetResponse> {
    const body: ApiDynamicArticlesSetRequest = {
      id: 'setDynamicArticle#INVALID_PARAMS',
      command: 'dynamicArticles/set',
      params: {
        data
      }
    };
    return this.http.post<ApiDynamicArticlesSetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  get(params: ApiDynamicArticlesGetRequest['params']): Observable<ApiDynamicArticlesGetResponse> {
    const body: ApiDynamicArticlesGetRequest = {
      id: 'getDynamicArticles',
      command: 'dynamicArticles/get',
      params: params
    };
    return this.http.post<ApiDynamicArticlesGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  update(data: ApiDynamicArticlesUpdateRequest['params']['data'], unset: ApiDynamicArticlesUpdateRequest['params']['unset'] ): Observable<ApiDynamicArticlesUpdateResponse> {
    const body: ApiDynamicArticlesUpdateRequest = {
      id: 'updateDynamicArticle#INVALID_PARAMS',
      command: 'dynamicArticles/update',
      params: {
        data,
        unset
      }
    };
    return this.http.post<ApiDynamicArticlesUpdateResponse>(environment.apiUrl, JSON.stringify(body));
  }

  delete(id: string): Observable<ApiDynamicArticlesDeleteResponse> {
    const body: ApiDynamicArticlesDeleteRequest = {
      id: 'deleteDynamicArticle',
      command: 'dynamicArticles/delete',
      params: {
        data: {
          id
        }
      }
    };
    return this.http.post<ApiDynamicArticlesDeleteResponse>(environment.apiUrl, JSON.stringify(body));
  }

  async resolveIdsToNames(ids: string[]): Promise<{id: string, name: string}[]> {
    let data: {id: string, name: string}[] = [];
    const body: ApiDynamicArticlesGetRequest[] = [];
    ids.filter((value, index, self) => self.indexOf(value) === index).map(id => {
      body.push({
        id,
        command: 'dynamicArticles/get',
        params: {
          filters: [{fields: ['id'], values: [id]}],
          dynamicArticles: {
            expectedData: {
              id: 1,
              name: 1
            }
          }
        }
      })
    });
    if(ids.length > 0){
      await this.http.post<ApiDynamicArticlesGetResponse[]>(environment.apiUrl, JSON.stringify(body)).toPromise().then(
        r => {
          if(r){
            ids.map(id => {
              let filter = r.filter(data => data.id == id);
              if (filter[0]?.result &&
                filter[0]?.result.dynamicArticles.data[0]) {
                data.push({id, name:filter[0].result.dynamicArticles.data[0].name ?? '-'})
              } else {
                data.push({id, name: 'ARTIKEL EXISTIERT NICHT. Bitte Eintrag überarbeiten.'})
              }

            })
          }

        }
      )
    }
    return data;
  }
}
