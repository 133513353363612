<form [formGroup]="form">
  <div class="columns is-multiline">
    <div class="column is-4">
      <div class="field mr-1" style="width:100%;">
        <label class="label">Standardsortierung Kategorie</label>
        <div class="control">
          <div class="select is-small">
            <select formControlName="default">
              <ng-container *ngFor="let x of getSortArticleListAsFormArray().controls; let i = index;">
                <option *ngIf="x.get('available')?.value == true" value="{{x.get('nameInternal')?.value}}">{{x.get('name')?.value}}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="field mr-1" style="width:100%;">
        <label class="label">Standardsortierung Angebote</label>
        <div class="control">
          <div class="select is-small">
            <select formControlName="offers">
              <ng-container *ngFor="let x of getSortArticleListAsFormArray().controls; let i = index;">
                <option *ngIf="x.get('available')?.value == true" value="{{x.get('nameInternal')?.value}}">{{x.get('name')?.value}}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="field mr-1" style="width:100%;">
        <label class="label">Standardsortierung Neuheiten</label>
        <div class="control">
          <div class="select is-small">
            <select formControlName="novelties">
              <ng-container *ngFor="let x of getSortArticleListAsFormArray().controls; let i = index;">
                <option *ngIf="x.get('available')?.value == true" value="{{x.get('nameInternal')?.value}}">{{x.get('name')?.value}}</option>
              </ng-container>

            </select>
          </div>

        </div>
      </div>

    </div>
    <div class="column is-4">
      <div class="field mr-1" style="width:100%;">
        <div class="control">
          <div class="dropdown-sort-available dropdown" [ngClass]="visibilitySortArticleList ? 'is-active' : ''" (clickOutside)="visibilitySortArticleList = false">
            <div class="dropdown-trigger">
              <button (click)="visibilitySortArticleList = !visibilitySortArticleList" class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span>Verfügbare Sortierungen im Shop</span>
                     <span class="icon is-small">
        <fa-icon [icon]="faAngleDown" *ngIf="!visibilitySortArticleList" aria-hidden="true"></fa-icon>
        <fa-icon [icon]="faAngleUp" *ngIf="visibilitySortArticleList" aria-hidden="true"></fa-icon>
      </span>

              </button>
            </div>
            <div class=" dropdown-menu" role="menu">
              <div class="dropdown-content" formGroupName="sortArticleList" cdkDropList (cdkDropListDropped)="dropSortArticleListe($event)">

                <div  *ngFor="let x of getSortArticleListAsFormArray().controls; let i = index;" class="dropdown-item" cdkDrag>
                  <div class="is-flex is-align-items-center" [formGroupName]='i' title="{{x.get('nameInternal')?.value}}" >
                    <div>
                      <fa-icon [icon]="faBars" class=" is-cursor-grab" cdkDragHandle></fa-icon>
                    </div>
                    <div>
                      <input type="checkbox" class="ml-2 mr-2 mt-2" formControlName="available">
                    </div>
                    <div class="is-flex-grow-1">
                      <input type="text" class="input" formControlName="name">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div class="column is-8">
      <div class="field">
        <div class="control">
          <shared-form-submit-button class="is-inline-block" [formStatus]="formHandler.status"
                                  submitLabel="Speichern" (submit)="submitForm()"></shared-form-submit-button>
        </div>
      </div>
    </div>
  </div>
</form>
