import {Store} from "@ngrx/store";
import {AppState} from "@store/state/app.state";
import {deleteWindow} from "@store/actions/windows.action";
import {setChangedItem} from "@store/actions/change-detection.action";

export type FormStatus = null | 'loading' | 'readOnly' | 'submitForm' | 'success' | 'error';

export interface ApiError {
  code: string;
  message: string;
  input?: {
    key: string;
    error: string;
  }[];
}

export class FormHandler {
  protected formStatus: FormStatus = null;
  protected error?: ApiError;

  constructor(
    public store: Store<AppState>
  ) {
  }

  public setFormStatus(
    data: {
      formStatus: FormStatus,
      closeWindowId?: string,
      resetStatusAfterTimeoutTo?: FormStatus,
      callbackAfterTimeout?: () => void
    }
  ): void {
    this.formStatus = data.formStatus;
    setTimeout(() => {
      if (data.closeWindowId) {
        this.store.dispatch(deleteWindow({id: data.closeWindowId}));
      }
      if (data.resetStatusAfterTimeoutTo !== undefined) {
        this.formStatus = data.resetStatusAfterTimeoutTo;
      }
      if(data.callbackAfterTimeout) {
        data.callbackAfterTimeout();
      }
    }, 1000);
  }

  public getFormStatus(): FormStatus {
    return this.formStatus;
  }

  public setFormError(error?: ApiError): void {
    this.error = error;
  }

  public getFormError(): ApiError | undefined{
    return this.error;
  }

  public setItemSet(itemId: string, group: string, userId: string = '') {
    this.store.dispatch(setChangedItem({
      item: {
        itemId,
        date: new Date().getTime(),
        userId,
        action: 'set',
        group
      }
    }));

  }

  public setItemUpdate(itemId: string, group: string, userId: string = '') {
    this.store.dispatch(setChangedItem({
      item: {
        itemId,
        date: new Date().getTime(),
        userId,
        action: 'update',
        group
      }
    }));
  }
}
