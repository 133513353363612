import {Injectable} from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService} from '@core/services/authentication.service';

@Injectable({providedIn: 'root'})
export class AuthGuard  {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Gültiger access token vorhanden
    if (this.authenticationService.isAccessToken() ||
      !this.authenticationService.isRefreshTokenExpired()) {
      return true;

    }
    // Kein gültiger Token
    let extras = {};
    if (state.url.length > 1) {
      extras = {queryParams: {redirectTo: state.url}};
    }
    this.router.navigate(['/login'], extras).then();
    return false;
  }
}
