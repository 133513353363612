<div *ngIf="loading" class="content-content has-text-centered">
  <button class="button is-loading"></button>
</div>
<div #formTag>
  <div *ngIf="!loading">
    <form [formGroup]="form">
      <div class="tabs">
        <ul>
          <li [ngClass]="formHandler.activeTab=='details' ? 'is-active' : ''" (click)="formHandler.activeTab='details'">
            <a>Details
            </a></li>
        </ul>
      </div>
      <div class="content-content is-tabs">
        <div *ngIf="formHandler.activeTab=='details'" class="tab-container">
          <div class="columns">
            <div class="column is-half-desktop">
              <div class="field">
                <label class="label">Name</label>
                <input class="input is-small" placeholder="Name" formControlName="name" >
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Ab Datum</label>
                <p class="control has-icons-left" (click)="from.open()">
                  <input [matDatepicker]="from" class="input is-small" placeholder="Ab Datum" formControlName="from" >
                  <span class="icon is-small is-left">
                          <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
                          <mat-datepicker #from></mat-datepicker>
                        </span>
                </p>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Bis Datum</label>
                <p class="control has-icons-left" (click)="to.open()">
                  <input [matDatepicker]="to" class="input is-small" placeholder="Bis Datum" formControlName="to" >
                  <span class="icon is-small is-left">
                          <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
                          <mat-datepicker #to></mat-datepicker>
                        </span>
                </p>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Begründung</label>
                <div class="control">
                  <textarea class="textarea" placeholder="Begründung" formControlName="txt"></textarea>
                </div>
              </div>

            </div>
          </div>
          <div class="columns">
            <div class="column is-two-fifths is-full-mobile">
              <shared-form-autocomplete [autocompleteModel]="autocompleteDeliveryTour" (editItem)="editDeliveryTours($event)" (onChangeItems)="setDeliveryTours($event)"></shared-form-autocomplete>
              <div class="field ">
                <label class="label"></label>
                <div class="control">
                  <button (click)="addField('deliveryPeriods')" class="button is-small add"><span class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Ausschließlich folgende Lieferzeiträume</span></button>
                </div>
                <div [attr.di-error-group]="'deliveryPeriods'">
                  <div class="" formArrayName="deliveryPeriods"
                       *ngFor="let item of getFormArray('deliveryPeriods').controls; let i = index;">
                    <p class="control has-icon-left  mt-2">
                      <input class="input is-small" placeholder="Lieferzeitraum" [formControlName]="i">
                      <span>
                          <fa-icon [icon]="faTrashCan" (click)="removeField('deliveryPeriods', i)" class="delete-field"></fa-icon>
                        </span>
                    </p>
                  </div>
                </div>

              </div>
              <div class="field ">
                <label class="label"></label>
                <div class="control">
                  <button (click)="addField('customerPriceGroups')" class="button is-small add"><span class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Kundenpreisgruppe sperren</span></button>
                </div>
                <div [attr.di-error-group]="'customerPriceGroups'">
                  <div class="" formArrayName="customerPriceGroups"
                       *ngFor="let item of getFormArray('customerPriceGroups').controls; let i = index;">
                    <p class="control has-icon-left  mt-2">
                      <input class="input is-small" placeholder="Preisgruppe" [formControlName]="i">
                      <span>
                          <fa-icon [icon]="faTrashCan" (click)="removeField('customerPriceGroups', i)" class="delete-field"></fa-icon>
                        </span>
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-foot has-text-centered">
        <shared-form-submit-button (submit)="submit()" [formStatus]="formHandler.status"></shared-form-submit-button>
      </div>
    </form>
  </div>
</div>

