import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {List} from '@shared/list/models/list';
import {ListColumn} from '@shared/list/models/list-column';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {faCalendar} from "@fortawesome/free-regular-svg-icons";

@Component({
  selector: 'app-list-filter-date',
  templateUrl: './list-filter-date.component.html',
  styleUrls: ['./list-filter-date.component.scss']
})
export class ListFilterDateComponent implements OnInit, OnDestroy {
  @Input() listColumn!: ListColumn<any>;
  @Input() list!: List<any, any, any>;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  filterFromValue: string = '';
  filterToValue: string = '';

  form = new FormGroup({
    from: new FormControl(''),
    to: new FormControl('')
  });

  constructor() {

  }

  ngOnInit(): void {
    this.list.loading.list
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        l => {
          if (this.list.getFilterDates()?.length == 0) {
            this.form.get('from')?.setValue('');
            this.form.get('to')?.setValue('');
          }
        }
      )
  }

  resetFormWithCurrentFilterValue() {
    if (this.form.get('from')?.value) {
      this.form.get('from')?.setValue(this.filterFromValue);
    } else {
      this.form.get('from')?.setValue('');
    }
    if (this.form.get('to')?.value) {
      this.form.get('to')?.setValue(this.filterToValue);
    } else {
      this.form.get('to')?.setValue('');
    }
  }

  setTimeIfEmpty(inputKey: string, hours: number, minutes: number) {
    if (this.form.get(inputKey)?.value == '') {
      const startTime = new Date();
      startTime.setHours(hours, minutes, 0); // set hours, minutes and seconds
      this.form.get(inputKey)?.setValue(startTime);
    }
  }

  setFilter() {
    if (this.filterFromValue != this.form.get('from')?.value ||
      this.filterToValue != this.form.get('to')?.value) {
      this.filterFromValue = this.form.get('from')?.value ?? '';
      this.filterToValue = this.form.get('to')?.value ?? '';
      this.list.setFilterDate({
        id: this.listColumn.id,
        fields: [this.listColumn.field],
        greaterThanEqual: this.form.get('from')?.value || undefined,
        lessThanEqual: this.form.get('to')?.value || undefined
      })
    }

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected readonly faCalendar = faCalendar;
}
