import {Component, Input} from '@angular/core';

@Component({
  selector: 'shared-loading-content',
  templateUrl: './loading-content.component.html',
  styleUrls: ['./loading-content.component.scss']
})
export class LoadingContentComponent {

  @Input() loading!: boolean;


  constructor() {


  }

}
