import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authentication-container',
  templateUrl: './authentication-container.component.html',
  styleUrls: ['./authentication-container.component.scss']
})
export class AuthenticationContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  isDicom(): boolean{
    if(window.location.host == 'login.gesoft.de'){
      return true;
    }
    return false;
  }

}
