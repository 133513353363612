import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormHelperDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {setWindow} from '@store/actions/windows.action';
import {faPlusSquare} from "@fortawesome/free-regular-svg-icons";
import {faArrowRight, faPlus} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-sales-form',
  templateUrl: './sales-form.component.html',
  styleUrls: ['./sales-form.component.scss']
})
export class SalesFormComponent implements OnInit {

  @ViewChild('formTag', {static: false}) formTag: ElementRef | undefined;
  @Input() window?: Window;

  form = new UntypedFormGroup({
    id: new UntypedFormControl(null, Validators.required),
    dateCreated: new UntypedFormControl({value: new Date(), disabled: true}),
    deliveryDate: new UntypedFormControl(new Date()),
    returnDate: new UntypedFormControl(),
    minute: new UntypedFormControl(0),
    hour: new UntypedFormControl(8),
    role: new UntypedFormControl('', Validators.required),
    username: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    password: new UntypedFormControl(null)
  });

  formHelper: FormHelperDeprecated;

  customerSelected: boolean = false;

  errors = null;
  msg = '';


  constructor(
    private store: Store<AppState>,
    private renderer: Renderer2
  ) {
    this.formHelper = new FormHelperDeprecated(
      this.store,
      'sales',
      this.form,
      [
        {key: 'role', error: 'Bitte wählen Sie einen User-Level aus.'},
      ],
      this.renderer,
      'overview'
    );
    this.formHelper.activeTab = 'overview';
  }


  openWindow() {
    this.store.dispatch(setWindow({id: 'newUser', component: 'shared-user-form', title: 'User anlegen'}))
  }

  ngOnInit() {

  }


  protected readonly faPlus = faPlus;
  protected readonly faPlusSquare = faPlusSquare;
  protected readonly faArrowRight = faArrowRight;
}
