<div *ngIf="!loading">
  <form [formGroup]="form">
    <shared-form-error [apiError]="formHandler.getFormError()"></shared-form-error>
    <div class="p-2 has-background-white">
      <div class="columns is-multiline">
        <div class="column is-12">
          <div class="control">
            <button (click)="addRecipient()" class="button is-small add"><span
              class="icon is-small">
            <fa-icon [icon]="faPlusSquare"></fa-icon>
          </span><span>Empfänger hinzufügen</span></button>
          </div>
          <div>
            <div class="" formArrayName="recipients"
                 *ngFor="let item of recipients.controls; let i = index;">
              <p class="control has-icon-left  mt-2 is-flex">
                <input class="input is-small mr-2" type="email" placeholder="Empfänger" [formControlName]="i">
                <span>
                <fa-icon [icon]="faTrashCan" (click)="removeRecipient(i)" class="is-cursor-pointer"></fa-icon>
                        </span>
              </p>
              <p *ngIf="isFormRequestError('recipients.' +i)" class="help is-danger">Bitte hinterlegen Sie eine gültige
                E-Mail-Adresse.</p>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="control">
            <div class="is-flex">
              <button (click)="addSubject()" class="button is-small add"><span
                class="icon is-small">
            <fa-icon [icon]="faPlusSquare"></fa-icon>
          </span><span  #positionInfo>Individuellen Betreff hinzufügen</span> </button>
              <div  (click)="opPositionInfo.toggle($event, positionInfo)" class="mt-1 ml-2 is-pointer">
                <fa-icon [icon]="faInfoCircle"></fa-icon>
              </div>
            </div>


          </div>
          <p-overlayPanel #opPositionInfo>
            <ng-template pTemplate="content">
              <p class="m-0">
                Der Standardetreff ist Ihre Bestellung :orderId. Die :orderId ist ein Platzhalter und wird mit der BestellId aus der Datenbank ersetzt. Sofern unterschiedliche Betreffe hinterlegt sind,
                wird das Betreff verwendet, welches als erstes zutrifft.
              </p>
            </ng-template>
          </p-overlayPanel>
          <div *ngIf="subjects.controls.length > 0" cdkDropList (cdkDropListDropped)="dropRequiredDynamicArticle($event)" class="mt-3">
            <div class="panel p-3 mb-0" formArrayName="subjects" cdkDrag
                 *ngFor="let item of subjects.controls; let i = index;"
            >
              <p>Betreff #{{i+1}}</p>
              <p class="control has-icon-left  is-flex" [formGroupName]="i">

                <span>
                  <fa-icon [icon]="faBars" class="mt-2 mr-2 is-grab" cdkDragHandle></fa-icon>
                </span>
                <input class="input is-small mr-2" type="text" placeholder="Betreff" formControlName="subject">
                <span>
                  <fa-icon [icon]="faCog" (click)="toggleSubjectDetails(i)"
                           class="mr-2 is-pointer"
                           [ngClass]="isSubjectDetails(i) ? 'has-text-danger-dark' : ''"></fa-icon>
                </span>
                <span>
                <fa-icon [icon]="faTrashCan" (click)="removeSubject(i)" class="ml-2 is-cursor-pointer"></fa-icon>
                        </span>
              </p>
              <p *ngIf="isFormRequestError('subjects.' +i+'.subject')" class="help is-danger">Bitte hinterlegen Sie
                einen Betreff.</p>
              <div [ngClass]="isSubjectDetailsVisibility(i) ? 'mt-1 pb-4': 'is-hidden'">
                <shared-form-autocomplete

                  [autocompleteModel]="autocompleteDynamicArticles"
                  (editItem)="editRequiredDynamicArticle($event)"
                  (onChangeItems)="setRequiredDynamicArticleIds(i, $event)"></shared-form-autocomplete>
              </div>


            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Anfangstext E-Mail</label>
            <div class="control">
              <textarea class="textarea" placeholder="Anfangstext" formControlName="textFieldTop"></textarea>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Schlusstext E-Mail</label>
            <div class="control">
              <textarea class="textarea" placeholder="Schlusstext" formControlName="textFieldBottom"></textarea>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Einzelpreis anzeigen</label>
            <div class="control">
              <div class="select is-small">
                <select formControlName="visibilitySinglePrice">
                  <option [value]="false">Nein</option>
                  <option [value]="true">Ja</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Flaschenpreis anzeigen</label>
            <div class="control">
              <div class="select is-small">
                <select formControlName="visibilityUnitPrice">
                  <option [value]="false">Nein</option>
                  <option [value]="true">Ja</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <shared-upload (changedFiles)="changedAttachments($event)" [filePaths]="attachments"
                         fileType="privateFile" labelButton="Anhang hochladen..."></shared-upload>
        </div>
      </div>
      <div class="has-text-centered">
        <shared-form-submit-button [formStatus]="formHandler.getFormStatus()" (submit)="submit()"
                                submitLabel="Speichern"></shared-form-submit-button>
      </div>

    </div>
  </form>
</div>


