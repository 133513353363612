import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {HttpClient} from '@angular/common/http';
import {
  ApiConfigsGetRequest,
  ApiConfigsGetResponse,
  ApiConfigsUpdateRequest,
  ApiConfigsUpdateResponse,
  ApiConfig
} from '@shared/api/configs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http: HttpClient) {

  }

  getConfig(type: string = 'live'): Observable<ApiConfigsGetResponse> {
    const body: ApiConfigsGetRequest = {
      id: 'getConfig',
      command: 'configs/get',
      params: {
        filters: [{
          fields: ['type'],
          values: [type]
        }],
        configs: {}
      }
    };
    return this.http.post<ApiConfigsGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  updateConfig(params: { data: Partial<ApiConfig> & { id: string } }): Observable<ApiConfigsUpdateResponse> {
    const body: ApiConfigsUpdateRequest = {
      id: 'updateConfig#INVALID_PARAMS',
      command: 'configs/update',
      params
    };
    return this.http.post<ApiConfigsUpdateResponse>(environment.apiUrl, JSON.stringify(body));
  }
}
