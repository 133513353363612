export interface StorageState {
  APIConfig?: string,
  tokens: {
    access: null | {
      injectionToken?: string,
      createdTimestampInSeconds: number,
      expiredIn: number
    }
    refresh: null | {
      createdTimestampInSeconds: number,
      expiredIn: number
    }
  }
  userId: null | string;
  listConfigs: { id: string, visibleColumnFields: string[] }[]
}

export const initialStorageState = (): StorageState => {
  return {
    tokens: {
      access: null,
      refresh: null
    },
    userId: null,
    listConfigs: []
  };
};
