<div *ngIf="loading" class="has-text-centered">
  <button class="button content is-loading"></button>
</div>
<div *ngIf="!loading">

  <h1 class="is-size-3 has-text-black is-block">Zahlungsmethoden</h1>
  <article *ngIf="apiError" class="message is-danger">
    <div class="message-body">
      <b>{{apiError.notification}}</b>
      <span *ngFor="let error of apiError?.errors" class="is-block"><span *ngIf="error.key !== 'email'">{{error.key}}
        :</span> {{error.error}}</span>
    </div>
  </article>
  <div #formTag>
    <form [formGroup]="form">

      <div formGroupName="paymentMethods">
        <h1 class="is-size-5 has-text-black is-block mt-3">Paypal</h1>
        <button *ngIf="disablePaypal" (click)="setDisablePaypal(false)" class="button is-success is-small mt-2 mb-2">
          <span>Aktivieren</span>
        </button>
        <div *ngIf="!disablePaypal" formGroupName="paypal" [attr.di-error-group]="'paypal'" class="mb-5">
          <div class="columns is-desktop">
            <div class="column">
              <div class="columns is-multiline">
                <div class="column is-2">
                  <fa-icon [icon]="faTrashCan" (click)="setDisablePaypal(true)" class=" trash m-2 ml-0 is-cursor-pointer"></fa-icon>
                </div>
                <div class="column is-5">
                  <div class="field">
                    <label class="label">Name</label>
                    <div class="control">
                      <input class="input is-small" type="text" formControlName="name" type="text"
                             placeholder="Name">
                      <p *ngIf="isFormRequestError('paymentMethods.paypal.name')" class="help is-danger">Bitte hinterlegen Sie einen Namen.</p>
                    </div>
                  </div>
                </div>
                <div class="column is-5">
                  <div class="field">
                    <label class="label">Interner Name</label>
                    <div class="control">
                      <input class="input is-small" type="text" formControlName="nameInternal" type="text"
                             placeholder="Interner Name">
                      <p *ngIf="isFormRequestError('paymentMethods.paypal.nameInternal')" class="help is-danger">Bitte hinterlegen Sie einen internen Namen.</p>
                    </div>
                  </div>
                </div>
                <div class="column is-2 is-hidden-mobile"></div>
                <div class="column is-10 is-12-mobile">
                  <div class="field">
                    <label class="label">clientId (Rest-API)</label>
                    <div class="control">
                      <input class="input is-small" type="text" formControlName="clientId" type="text"
                             placeholder="clientId">
                      <p *ngIf="isFormRequestError('paymentMethods.paypal.clientId')" class="help is-danger">Bitte hinterlegen Sie eine ClientId.</p>
                    </div>
                  </div>
                </div>
                <div class="column is-2 is-hidden-mobile"></div>
                <div class="column is-10 is-12-mobile">
                  <div class="field">
                    <label class="label">Secret (Rest-API)</label>
                    <div class="control">
                      <input class="input is-small" type="text" formControlName="secret" type="text"
                             placeholder="Secret">
                      <p *ngIf="isFormRequestError('paymentMethods.paypal.secret')" class="help is-danger">Bitte hinterlegen Sie einen secret key.</p>
                    </div>
                  </div>
                </div>
                <div class="column is-2 is-hidden-mobile"></div>
                <div class="column is-10 is-12-mobile">
                  <div class="field">
                    <label class="checkbox">
                      <input type="checkbox" formControlName="sandbox">
                      Sandbox
                    </label>
                  </div>
                </div>
                <div class="column is-2 is-hidden-mobile"></div>
                <div class="column is-10 is-12-mobile">
                  <div class="field pr-3 ">
                    <label class="label"></label>
                    <div class="control">
                      <button (click)="addField('paypal','requiredPriceGroups')" class="button is-small add"><span
                        class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Erforderliche Preisgruppe/n</span></button>
                    </div>
                    <div [attr.di-error-group]="'requiredPriceGroups'">
                      <div class="" formArrayName="requiredPriceGroups"
                           *ngFor="let item of getFormArray('paypal', 'requiredPriceGroups').controls; let i = index;">
                        <p class="control has-icon-left  mt-2 is-flex">
                          <input class="input is-small mr-2" placeholder="Preisgruppe" [formControlName]="i">
                          <span>
                              <fa-icon [icon]="faTrashCan" (click)="removeField('paypal', 'requiredPriceGroups', i)"
                                 class=" is-cursor-pointer"></fa-icon>
                            </span>
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="column is-2 is-hidden-mobile"></div>
                <div class="column is-10 is-12-mobile">
                  <div class="field pr-3 ">
                    <label class="label"></label>
                    <div class="control">
                      <button (click)="addField('paypal','requiredPaymentMethods')" class="button is-small add"><span
                        class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Erforderliche Zahlungsmethode/n des Kunden</span>
                      </button>
                    </div>
                    <div [attr.di-error-group]="'requiredPaymentMethods'">
                      <div class="" formArrayName="requiredPaymentMethods"
                           *ngFor="let item of getFormArray('paypal', 'requiredPaymentMethods').controls; let i = index;">
                        <p class="control has-icon-left  mt-2 is-flex">
                          <input class="input is-small mr-2" placeholder="Zahlungsmethode" [formControlName]="i">
                          <span>
                              <fa-icon [icon]="faTrashCan" (click)="removeField('paypal', 'requiredPaymentMethods', i)"
                                 class="is-cursor-pointer"></fa-icon>
                            </span>
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="column is-2 is-hidden-mobile"></div>
                <div class="column is-10 is-12-mobile">
                  <shared-upload (changedFiles)="setExternalPaymentImage('paypal', $event)" [filePaths]="getExternalPaymentImage('paypal')" fileType="publicFile"

                                 label="Logo" [maxFiles]="1" labelButton="Logo hochladen..."></shared-upload>
                </div>
              </div>
            </div>
            <div class="column is-relative is-marginless-desktop">
              <div class="field">
                <label class="label">Beschreibung</label>
                <div class="control">
                  <textarea formControlName="description" class="textarea"></textarea>
                </div>
              </div>
            </div>

          </div>
        </div>
          <h1 class="is-size-5 has-text-black is-block mt-3">Klarna</h1>
          <button *ngIf="disableKlarna" (click)="setDisableKlarna(false)" class="button is-success is-small mt-2 mb-2">
              <span>Aktivieren</span>
          </button>
          <div *ngIf="!disableKlarna" formGroupName="klarna" [attr.di-error-group]="'klarna'" class="mb-5">
              <div class="columns is-desktop">
                  <div class="column">
                      <div class="columns is-multiline">
                          <div class="column is-2">
                              <fa-icon [icon]="faTrashCan" (click)="setDisableKlarna(true)" class=" trash m-2 ml-0 is-cursor-pointer"></fa-icon>
                          </div>
                          <div class="column is-5">
                              <div class="field">
                                  <label class="label">Name</label>
                                  <div class="control">
                                      <input class="input is-small" type="text" formControlName="name" type="text"
                                             placeholder="Name">
                                      <p *ngIf="isFormRequestError('paymentMethods.klarna.name')" class="help is-danger">Bitte hinterlegen Sie einen Namen.</p>
                                  </div>
                              </div>
                          </div>
                          <div class="column is-5">
                              <div class="field">
                                  <label class="label">Interner Name</label>
                                  <div class="control">
                                      <input class="input is-small" type="text" formControlName="nameInternal" type="text"
                                             placeholder="Interner Name">
                                      <p *ngIf="isFormRequestError('paymentMethods.klarna.nameInternal')" class="help is-danger">Bitte hinterlegen Sie einen internen Namen.</p>
                                  </div>
                              </div>
                          </div>
                          <div class="column is-2 is-hidden-mobile"></div>
                          <div class="column is-10 is-12-mobile">
                              <div class="field">
                                  <label class="label">Benutzername (UID)</label>
                                  <div class="control">
                                      <input class="input is-small" formControlName="username" type="text"
                                             placeholder="Benutzername (UID)">
                                      <p *ngIf="isFormRequestError('paymentMethods.klarna.username')" class="help is-danger">Bitte hinterlegen Sie einen Benutzername (UID).</p>
                                  </div>
                              </div>
                          </div>
                          <div class="column is-2 is-hidden-mobile"></div>
                          <div class="column is-10 is-12-mobile">
                              <div class="field">
                                  <label class="label">Passwort</label>
                                  <div class="control">
                                      <input class="input is-small"formControlName="password" type="text"
                                             placeholder="Passwort">
                                      <p *ngIf="isFormRequestError('paymentMethods.klarna.password')" class="help is-danger">Bitte hinterlegen Sie ein Passwort.</p>
                                  </div>
                              </div>
                          </div>
                          <div class="column is-2 is-hidden-mobile"></div>
                          <div class="column is-10 is-12-mobile">
                              <div class="field">
                                  <label class="checkbox">
                                      <input type="checkbox" formControlName="sandbox">
                                      Sandbox
                                  </label>
                              </div>
                          </div>
                          <div class="column is-2 is-hidden-mobile"></div>
                          <div class="column is-10 is-12-mobile">
                              <div class="field pr-3 ">
                                  <label class="label"></label>
                                  <div class="control">
                                      <button (click)="addField('klarna','requiredPriceGroups')" class="button is-small add"><span
                                              class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Erforderliche Preisgruppe/n</span></button>
                                  </div>
                                  <div [attr.di-error-group]="'requiredPriceGroups'">
                                      <div class="" formArrayName="requiredPriceGroups"
                                           *ngFor="let item of getFormArray('klarna', 'requiredPriceGroups').controls; let i = index;">
                                          <p class="control has-icon-left  mt-2 is-flex">
                                              <input class="input is-small mr-2" placeholder="Preisgruppe" [formControlName]="i">
                                              <span>
                              <fa-icon [icon]="faTrashCan" (click)="removeField('klarna', 'requiredPriceGroups', i)"
                                       class=" is-cursor-pointer"></fa-icon>
                            </span>
                                          </p>
                                      </div>
                                  </div>

                              </div>
                          </div>
                          <div class="column is-2 is-hidden-mobile"></div>
                          <div class="column is-10 is-12-mobile">
                              <div class="field pr-3 ">
                                  <label class="label"></label>
                                  <div class="control">
                                      <button (click)="addField('klarna','requiredPaymentMethods')" class="button is-small add"><span
                                              class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Erforderliche Zahlungsmethode/n des Kunden</span>
                                      </button>
                                  </div>
                                  <div [attr.di-error-group]="'requiredPaymentMethods'">
                                      <div class="" formArrayName="requiredPaymentMethods"
                                           *ngFor="let item of getFormArray('klarna', 'requiredPaymentMethods').controls; let i = index;">
                                          <p class="control has-icon-left  mt-2 is-flex">
                                              <input class="input is-small mr-2" placeholder="Zahlungsmethode" [formControlName]="i">
                                              <span>
                              <fa-icon [icon]="faTrashCan" (click)="removeField('klarna', 'requiredPaymentMethods', i)"
                                       class="is-cursor-pointer"></fa-icon>
                            </span>
                                          </p>
                                      </div>
                                  </div>

                              </div>
                          </div>
                          <div class="column is-2 is-hidden-mobile"></div>
                          <div class="column is-10 is-12-mobile">
                              <shared-upload (changedFiles)="setExternalPaymentImage('klarna', $event)" [filePaths]="getExternalPaymentImage('klarna')" fileType="publicFile"

                                             label="Logo" [maxFiles]="1" labelButton="Logo hochladen..."></shared-upload>
                          </div>
                      </div>
                  </div>
                  <div class="column is-relative is-marginless-desktop">
                      <div class="field">
                          <label class="label">Beschreibung</label>
                          <div class="control">
                              <textarea formControlName="description" class="textarea"></textarea>
                          </div>
                      </div>
                  </div>

              </div>
          </div>

        <h1 class="is-size-5 has-text-black is-block mt-3">Stripe</h1>
        <button *ngIf="disableStripe" (click)="setDisableStripe(false)" class="button is-success is-small mt-2 mb-2">
          <span>Aktivieren</span>
        </button>
        <div *ngIf="!disableStripe" formGroupName="stripe" class="mb-5">
          <div class="columns is-desktop">
            <div class="column">
              <div class="columns is-multiline">
                <div class="column is-2">
                  <fa-icon [icon]="faTrashCan" (click)="setDisableStripe(true)" class="trash m-2 ml-0 is-cursor-pointer"></fa-icon>
                </div>

                <div class="column is-5">

                  <div class="field">
                    <label class="label">Name</label>
                    <div class="control">
                      <input class="input is-small" type="text" formControlName="name" type="text"
                             placeholder="Name">
                    </div>
                    <p *ngIf="isFormRequestError('paymentMethods.stripe.name')" class="help is-danger">Bitte hinterlegen Sie einen Namen.</p>
                  </div>
                </div>
                <div class="column is-5">
                  <div class="field">
                    <label class="label">Interner Name</label>
                    <div class="control">
                      <input class="input is-small" type="text" formControlName="nameInternal" type="text"
                             placeholder="Interner Name">
                      <p *ngIf="isFormRequestError('paymentMethods.stripe.nameInternal')" class="help is-danger">Bitte hinterlegen Sie einen internen Namen.</p>
                    </div>
                  </div>
                </div>
                <div class="column is-2 is-hidden-mobile"></div>
                <div class="column is-10 is-12-mobile">
                  <div class="field">
                    <label class="label">Öffentlicher Schlüssel</label>
                    <div class="control">
                      <input class="input is-small" type="text" formControlName="publicKey" type="text"
                             placeholder="Public Key">
                      <p *ngIf="isFormRequestError('paymentMethods.stripe.publicKey')" class="help is-danger">Bitte hinterlegen Sie einen öffentlichen Schlüssel.</p>
                    </div>
                  </div>
                </div>
                <div class="column is-2 is-hidden-mobile"></div>
                <div class="column is-10 is-12-mobile">
                  <div class="field">
                    <label class="label">Geheimschlüssel</label>
                    <div class="control">
                      <input class="input is-small" type="text" formControlName="secretKey" type="text"
                             placeholder="Secret Key">
                      <p *ngIf="isFormRequestError('paymentMethods.stripe.secretKey')" class="help is-danger">Bitte hinterlegen Sie einen Geheimschlüssel.</p>
                    </div>
                  </div>
                </div>
                <div class="column is-2 is-hidden-mobile"></div>
                <div class="column is-10 is-12-mobile">
                  <div class="field pr-3 ">
                    <label class="label"></label>
                    <div class="control">
                      <button (click)="addField('stripe','requiredPriceGroups')" class="button is-small add"><span
                        class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Erforderliche Preisgruppe/n</span></button>
                    </div>
                    <div [attr.di-error-group]="'requiredPriceGroups'">
                      <div class="" formArrayName="requiredPriceGroups"
                           *ngFor="let item of getFormArray('stripe', 'requiredPriceGroups').controls; let i = index;">
                        <p class="control has-icon-left  mt-2 is-flex">
                          <input class="input is-small mr-2" placeholder="Preisgruppe" [formControlName]="i">
                          <span>
                              <fa-icon [icon]="faTrashCan" (click)="removeField('stripe', 'requiredPriceGroups', i)"
                                 class="is-cursor-pointer"></fa-icon>
                            </span>
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="column is-2 is-hidden-mobile"></div>
                <div class="column is-10 is-12-mobile">
                  <div class="field pr-3 ">
                    <label class="label"></label>
                    <div class="control">
                      <button (click)="addField('stripe','requiredPaymentMethods')" class="button is-small add"><span
                        class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Erforderliche Zahlungsmethode/n des Kunden</span>
                      </button>
                    </div>
                    <div [attr.di-error-group]="'requiredPaymentMethods'">
                      <div class="" formArrayName="requiredPaymentMethods"
                           *ngFor="let item of getFormArray('stripe', 'requiredPaymentMethods').controls; let i = index;">
                        <p class="control has-icon-left  mt-2 is-flex">
                          <input class="input is-small mr-2" placeholder="Zahlungsmethode" [formControlName]="i">
                          <span>
                              <fa-icon [icon]="faTrashCan" (click)="removeField('stripe', 'requiredPaymentMethods', i)"
                                 class="is-cursor-pointer"></fa-icon>
                            </span>
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="column is-2 is-hidden-mobile"></div>
                <div class="column is-10 is-12-mobile">
                  <shared-upload (changedFiles)="setExternalPaymentImage('stripe', $event)" [filePaths]="getExternalPaymentImage('stripe')" fileType="publicFile"

                                 label="Logo" [maxFiles]="1" labelButton="Logo hochladen..."></shared-upload>
                </div>
              </div>
            </div>
            <div class="column is-relative is-marginless-desktop">
              <div class="field">
                <label class="label">Beschreibung</label>
                <div class="control">
                  <textarea formControlName="description" class="textarea"></textarea>
                </div>
              </div>
            </div>

          </div>
        </div>


          <h1 class="is-size-5 has-text-black is-block mt-4">Dynamische Zahlungsmethoden</h1>
          <button class="button is-success is-small mt-2 mb-2" (click)="addDynamicPaymentMethod()">
    <span class="icon is-small">
      <fa-icon [icon]="faPlusSquare"></fa-icon>
    </span>
            <span>Dynamische Zahlungsmethode hinzufügen</span>
          </button>


        <p *ngIf="paymentMethods.controls.length == 0">Keine dynamischen Zahlungsmethoden hinterlegt.</p>
        <div cdkDropList (cdkDropListDropped)="drop($event)">
          <div class="" formGroupName="dynamic"
               *ngFor="let item of paymentMethods.controls; let pmI = index;">
            <div class="columns is-desktop" [formGroupName]="pmI" cdkDrag [attr.di-error-group]="pmI">
              <div class="column">
                <div class="columns is-multiline">
                  <div class="column is-2">
                    <fa-icon [icon]="faTrashCan" class=" trash m-2 ml-0 is-cursor-pointer"
                          (click)="removeDynamicPaymentMethod(pmI)"></fa-icon>
                    <fa-icon [icon]="faBars" class=" drag m-2 is-cursor-grab" cdkDragHandle></fa-icon>
                  </div>
                  <div class="column is-5">

                    <div class="field">
                      <label class="label">Name</label>
                      <div class="control">
                        <input class="input is-small" type="text" formControlName="name" type="text"
                               placeholder="Name">
                        <p *ngIf="isFormRequestError('paymentMethods.dynamic.'+pmI+'.name')" class="help is-danger">Bitte hinterlegen Sie einen Namen.</p>
                      </div>
                    </div>
                  </div>
                  <div class="column is-5">
                    <div class="field">
                      <label class="label">Interner Name</label>
                      <div class="control">
                        <input class="input is-small" type="text" formControlName="nameInternal" type="text"
                               placeholder="Interner Name">
                      </div>
                      <p *ngIf="isFormRequestError('paymentMethods.dynamic.'+pmI+'.nameInternal')" class="help is-danger">Bitte hinterlegen Sie einen internen Namen.</p>
                    </div>
                  </div>
                  <div class="column is-2 is-hidden-mobile"></div>
                  <div class="column is-10 is-12-mobile">
                    <div class="field pr-3 ">
                      <label class="label"></label>
                      <div class="control">
                        <button (click)="addFieldByDynamicMethod(pmI, 'requiredPriceGroups')" class="button is-small add"><span
                          class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Erforderliche Preisgruppe/n</span></button>
                      </div>
                      <div [attr.di-error-group]="'requiredPriceGroups'">
                        <div class="" formArrayName="requiredPriceGroups"
                             *ngFor="let item of getFormArrayFromDynamicMethod(pmI, 'requiredPriceGroups').controls; let i = index;">
                          <p class="control has-icon-left  mt-2 is-flex">
                            <input class="input is-small mr-2" placeholder="Preisgruppe" [formControlName]="i">
                            <span>
                          <fa-icon [icon]="faTrashCan" (click)="removeFieldByDynamicMethod(pmI, 'requiredPriceGroups', i)"
                             class="is-cursor-pointer"></fa-icon>
                        </span>
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="column is-2 is-hidden-mobile"></div>
                  <div class="column is-10 is-12-mobile">
                    <div class="field pr-3 ">
                      <label class="label"></label>
                      <div class="control">
                        <button (click)="addFieldByDynamicMethod(pmI, 'requiredPaymentMethods')" class="button is-small add"><span
                          class="icon is-small"><fa-icon [icon]="faPlusSquare"></fa-icon></span><span>Erforderliche Zahlungsmethode/n des Kunden</span>
                        </button>
                      </div>
                      <div [attr.di-error-group]="'requiredPaymentMethods'">
                        <div class="" formArrayName="requiredPaymentMethods"
                             *ngFor="let item of getFormArrayFromDynamicMethod(pmI, 'requiredPaymentMethods').controls; let i = index;">
                          <p class="control has-icon-left  mt-2 is-flex">
                            <input class="input is-small mr-2" placeholder="Zahlungsmethode" [formControlName]="i">
                            <span>
                          <fa-icon [icon]="faTrashCan" (click)="removeFieldByDynamicMethod(pmI, 'requiredPaymentMethods', i)"
                             class=" is-cursor-pointer"></fa-icon>
                        </span>
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="column is-2 is-hidden-mobile"></div>
                  <div class="column is-10 is-12-mobile">
                    <shared-upload (changedFiles)="setDynamicPaymentImage(pmI, $event)" [filePaths]="getDynamicPaymentImage(pmI)" fileType="publicFile"
                                   label="Logo" [maxFiles]="1" labelButton="Logo hochladen..."></shared-upload>
                  </div>
                </div>
              </div>
              <div class="column is-relative is-marginless-desktop">
                <div class="field">
                  <label class="label">Beschreibung</label>
                  <div class="control">
                    <textarea formControlName="description" class="textarea"></textarea>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="has-text-centered mt-6">
    <shared-form-submit-button (submit)="submit()" [formStatus]="formHandler.status"></shared-form-submit-button>
  </div>

</div>

