import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormHelperDeprecated, FormStatusDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {
  FormArray, FormBuilder,
  FormControl, FormGroup,
  Validators
} from '@angular/forms';
import {ConfigService} from '@shared/config/services/config.service';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {finalize} from 'rxjs/operators';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ApiConfig} from '@shared/api/configs';
import {faPlusSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {UploadFilePath} from "@shared/uploads/components/uploads/upload.component";

@Component({
  selector: 'shared-config-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {

  @ViewChild('formTag', {static: false}) formTag: ElementRef | undefined;

  apiError?: {
    notification: string,
    errors?: { key: string, error: string }[]
  };

  loading: boolean = true;

  disablePaypal: boolean = true;
  disableStripe: boolean = true;
  disableKlarna: boolean = true;

  formHandler: FormHelperDeprecated;

  form = this.fb.group({
    id: new FormControl(''),
    paymentMethods: this.fb.group({
      paypal: this.fb.group({
        image: this.fb.control(''),
        clientId: this.fb.control(''),
        secret: this.fb.control(''),
        sandbox: this.fb.control(false),
        name: this.fb.control(''),
        nameInternal: this.fb.control(''),
        description: this.fb.control(''),
        requiredPriceGroups: this.fb.array<string[]>([]),
        requiredPaymentMethods: this.fb.array<string[]>([])
      }),
      stripe: this.fb.group({
        image: this.fb.control(''),
        publicKey: this.fb.control(''),
        secretKey: this.fb.control(''),
        name: this.fb.control(''),
        nameInternal: this.fb.control(''),
        description: this.fb.control(''),
        requiredPriceGroups: this.fb.array<string[]>([]),
        requiredPaymentMethods: this.fb.array<string[]>([])
      }),
      klarna: this.fb.group({
        image: this.fb.control(''),
        username: this.fb.control(''),
        password: this.fb.control(''),
        sandbox: this.fb.control(false),
        name: this.fb.control(''),
        nameInternal: this.fb.control(''),
        description: this.fb.control(''),
        requiredPriceGroups: this.fb.array<string[]>([]),
        requiredPaymentMethods: this.fb.array<string[]>([])
      }),
      dynamic: this.fb.array([])
    })


  });
  formStatus: FormStatusDeprecated = null;

  constructor(private fb: FormBuilder, private configService: ConfigService, private store: Store<AppState>, private renderer: Renderer2) {
    this.formHandler = new FormHelperDeprecated(
      this.store,
      '',
      this.form,
      [
        {key: 'name', error: 'Bitte hinterlegen Sie einen Namen.'},
        {key: 'nameInternal', error: 'Bitte hinterlegen Sie einen internen Namen.'},
      ],
      this.renderer
    );
  }

  ngAfterViewInit() {
    this.formHandler.htmlRef = this.formTag;
  }

  ngOnInit(): void {
    this.configService.getConfig()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        r => {
          if (r.result) {
            this.form.get('id')?.setValue(r.result.configs.data[0].id);
            if (r.result.configs.data[0].paymentMethods?.paypal?.name) {
              this.disablePaypal = false;
              this.form.controls.paymentMethods.get('paypal')?.get('clientId')?.disable();
              this.form.controls.paymentMethods.get('paypal')?.get('clientId')?.setValue(r.result.configs.data[0].paymentMethods.paypal.clientId ?? '');
              this.form.controls.paymentMethods.get('paypal')?.get('image')?.setValue(r.result.configs.data[0].paymentMethods.paypal.image ?? '');
              this.form.controls.paymentMethods.get('paypal')?.get('secret')?.setValue('VERSCHLÜSSELT');
              this.form.controls.paymentMethods.get('paypal')?.get('secret')?.disable();
              this.form.controls.paymentMethods.get('paypal')?.get('sandbox')?.disable();
              this.form.controls.paymentMethods.get('paypal')?.get('sandbox')?.setValue(r.result.configs.data[0].paymentMethods.paypal.sandbox ?? false);
              this.form.controls.paymentMethods.get('paypal')?.get('name')?.setValue(r.result.configs.data[0].paymentMethods.paypal.name);
              this.form.controls.paymentMethods.get('paypal')?.get('nameInternal')?.setValue(r.result.configs.data[0].paymentMethods.paypal.nameInternal);
              this.form.controls.paymentMethods.get('paypal')?.get('description')?.setValue(r.result.configs.data[0].paymentMethods.paypal.description);

              r.result.configs.data[0].paymentMethods.paypal.requiredPriceGroups?.map(
                r => {
                  this.addField('paypal', 'requiredPriceGroups', r);
                }
              );
              r.result.configs.data[0].paymentMethods.paypal.requiredPaymentMethods?.map(
                r => {
                  this.addField('paypal', 'requiredPaymentMethods', r);
                }
              );
            }
            if (r.result.configs.data[0].paymentMethods?.klarna?.name) {
              this.disableKlarna = false;
              this.form.controls.paymentMethods.get('klarna')?.get('username')?.disable();
              this.form.controls.paymentMethods.get('klarna')?.get('username')?.setValue(r.result.configs.data[0].paymentMethods.klarna.username ?? '');
              this.form.controls.paymentMethods.get('klarna')?.get('image')?.setValue(r.result.configs.data[0].paymentMethods.klarna.image ?? '');
              this.form.controls.paymentMethods.get('klarna')?.get('password')?.setValue('VERSCHLÜSSELT');
              this.form.controls.paymentMethods.get('klarna')?.get('password')?.disable();
              this.form.controls.paymentMethods.get('klarna')?.get('sandbox')?.disable();
              this.form.controls.paymentMethods.get('klarna')?.get('sandbox')?.setValue(r.result.configs.data[0].paymentMethods.klarna.sandbox ?? false);
              this.form.controls.paymentMethods.get('klarna')?.get('name')?.setValue(r.result.configs.data[0].paymentMethods.klarna.name);
              this.form.controls.paymentMethods.get('klarna')?.get('nameInternal')?.setValue(r.result.configs.data[0].paymentMethods.klarna.nameInternal);
              this.form.controls.paymentMethods.get('klarna')?.get('description')?.setValue(r.result.configs.data[0].paymentMethods.klarna.description);

              r.result.configs.data[0].paymentMethods.klarna.requiredPriceGroups?.map(
                r => {
                  this.addField('klarna', 'requiredPriceGroups', r);
                }
              );
              r.result.configs.data[0].paymentMethods.klarna.requiredPaymentMethods?.map(
                r => {
                  this.addField('klarna', 'requiredPaymentMethods', r);
                }
              );
            }
            if (r.result.configs.data[0].paymentMethods?.stripe?.name) {
              this.disableStripe = false;
              this.form.controls.paymentMethods.get('stripe')?.get('image')?.setValue(r.result.configs.data[0].paymentMethods.stripe.image ?? '');
              this.form.controls.paymentMethods.get('stripe')?.get('publicKey')?.setValue(r.result.configs.data[0].paymentMethods.stripe.publicKey ?? '');
              this.form.controls.paymentMethods.get('stripe')?.get('publicKey')?.disable();
              this.form.controls.paymentMethods.get('stripe')?.get('secretKey')?.setValue('VERSCHLÜSSELT');
              this.form.controls.paymentMethods.get('stripe')?.get('secretKey')?.disable();
              this.form.controls.paymentMethods.get('stripe')?.get('name')?.setValue(r.result.configs.data[0].paymentMethods.stripe.name);
              this.form.controls.paymentMethods.get('stripe')?.get('nameInternal')?.setValue(r.result.configs.data[0].paymentMethods.stripe.nameInternal);
              this.form.controls.paymentMethods.get('stripe')?.get('description')?.setValue(r.result.configs.data[0].paymentMethods.stripe.description);
              r.result.configs.data[0].paymentMethods.stripe.requiredPriceGroups?.map(
                r => {
                  this.addField('stripe', 'requiredPriceGroups', r);
                }
              );
              r.result.configs.data[0].paymentMethods.stripe.requiredPaymentMethods?.map(
                r => {
                  this.addField('stripe', 'requiredPaymentMethods', r);
                }
              );
            }

            //this.form.get('id')?.setValue(r.result.configs.data[0].id)
            r.result.configs.data[0].paymentMethods?.dynamic?.map(
              r => {
                this.addDynamicPaymentMethod({
                  image: r.image ?? '',
                  name: r.name,
                  nameInternal: r.nameInternal,
                  description: r.description,
                  requiredPriceGroups: r.requiredPriceGroups,
                  requiredPaymentMethods: r.requiredPaymentMethods
                })

              }
            )
          }
        }
      )
  }

  setDisableKlarna(value: boolean) {
    this.disableKlarna = value;
    if (!this.disableKlarna) {
      this.form.get('paymentMethods.klarna.username')?.setValidators([Validators.required, Validators.minLength(2)]);
      this.form.get('paymentMethods.klarna.username')?.updateValueAndValidity();
      this.form.get('paymentMethods.klarna.password')?.setValidators([Validators.required, Validators.minLength(2)]);
      this.form.get('paymentMethods.klarna.password')?.updateValueAndValidity();
      this.form.get('paymentMethods.klarna.name')?.setValidators([Validators.required, Validators.minLength(2)]);
      this.form.get('paymentMethods.klarna.name')?.updateValueAndValidity();
      this.form.get('paymentMethods.klarna.nameInternal')?.setValidators([Validators.required, Validators.minLength(1)]);
      this.form.get('paymentMethods.klarna.nameInternal')?.updateValueAndValidity();
    } else {
      this.form.get('paymentMethods.klarna.username')?.setValidators(null);
      this.form.get('paymentMethods.klarna.username')?.updateValueAndValidity();
      this.form.get('paymentMethods.klarna.username')?.reset();
      this.form.get('paymentMethods.klarna.username')?.enable();
      this.form.get('paymentMethods.klarna.password')?.setValidators(null);
      this.form.get('paymentMethods.klarna.password')?.updateValueAndValidity();
      this.form.get('paymentMethods.klarna.password')?.reset();
      this.form.get('paymentMethods.klarna.password')?.enable();
      this.form.get('paymentMethods.klarna.sandbox')?.reset();
      this.form.get('paymentMethods.klarna.sandbox')?.enable();
      this.form.get('paymentMethods.klarna.name')?.setValidators(null);
      this.form.get('paymentMethods.klarna.name')?.updateValueAndValidity();
      this.form.get('paymentMethods.klarna.name')?.reset();
      this.form.get('paymentMethods.klarna.nameInternal')?.setValidators(null);
      this.form.get('paymentMethods.klarna.nameInternal')?.updateValueAndValidity();
      this.form.get('paymentMethods.klarna.nameInternal')?.reset();
      this.form.get('paymentMethods.klarna.description')?.reset();
      this.getFormArray('klarna', 'requiredPriceGroups').clear();
      this.getFormArray('klarna', 'requiredPaymentMethods').clear();
    }
  }

  setDisablePaypal(value: boolean) {
    this.disablePaypal = value;
    if (!this.disablePaypal) {
      this.form.get('paymentMethods.paypal.clientId')?.setValidators([Validators.required, Validators.minLength(2)]);
      this.form.get('paymentMethods.paypal.clientId')?.updateValueAndValidity();

      this.form.get('paymentMethods.paypal.secret')?.updateValueAndValidity();
      this.form.get('paymentMethods.paypal.name')?.setValidators([Validators.required, Validators.minLength(2)]);
      this.form.get('paymentMethods.paypal.name')?.updateValueAndValidity();
      this.form.get('paymentMethods.paypal.nameInternal')?.setValidators([Validators.required, Validators.minLength(1)]);
      this.form.get('paymentMethods.paypal.nameInternal')?.updateValueAndValidity();
    } else {
      this.form.get('paymentMethods.paypal.clientId')?.setValidators(null);
      this.form.get('paymentMethods.paypal.clientId')?.updateValueAndValidity();
      this.form.get('paymentMethods.paypal.clientId')?.reset();
      this.form.get('paymentMethods.paypal.clientId')?.enable();
      this.form.get('paymentMethods.paypal.secret')?.setValidators(null);
      this.form.get('paymentMethods.paypal.secret')?.updateValueAndValidity();
      this.form.get('paymentMethods.paypal.secret')?.reset();
      this.form.get('paymentMethods.paypal.secret')?.enable();
      this.form.get('paymentMethods.paypal.sandbox')?.reset();
      this.form.get('paymentMethods.paypal.sandbox')?.enable();
      this.form.get('paymentMethods.paypal.name')?.setValidators(null);
      this.form.get('paymentMethods.paypal.name')?.updateValueAndValidity();
      this.form.get('paymentMethods.paypal.name')?.reset();
      this.form.get('paymentMethods.paypal.nameInternal')?.setValidators(null);
      this.form.get('paymentMethods.paypal.nameInternal')?.updateValueAndValidity();
      this.form.get('paymentMethods.paypal.nameInternal')?.reset();
      this.form.get('paymentMethods.paypal.description')?.reset();
      this.getFormArray('paypal', 'requiredPriceGroups').clear();
      this.getFormArray('paypal', 'requiredPaymentMethods').clear();
    }
  }

  setDisableStripe(value: boolean) {
    this.disableStripe = value;
    if (!this.disableStripe) {
      this.form.get('paymentMethods.stripe.publicKey')?.setValidators([Validators.required, Validators.minLength(2)]);
      this.form.get('paymentMethods.stripe.publicKey')?.updateValueAndValidity();
      this.form.get('paymentMethods.stripe.secretKey')?.setValidators([Validators.required, Validators.minLength(2)]);
      this.form.get('paymentMethods.stripe.secretKey')?.updateValueAndValidity();
      this.form.get('paymentMethods.stripe.name')?.setValidators([Validators.required, Validators.minLength(2)]);
      this.form.get('paymentMethods.stripe.name')?.updateValueAndValidity();
      this.form.get('paymentMethods.stripe.nameInternal')?.setValidators([Validators.required, Validators.minLength(1)]);
      this.form.get('paymentMethods.stripe.nameInternal')?.updateValueAndValidity();
    } else {
      this.form.get('paymentMethods.stripe.publicKey')?.setValidators(null);
      this.form.get('paymentMethods.stripe.publicKey')?.updateValueAndValidity();
      this.form.get('paymentMethods.stripe.publicKey')?.reset();
      this.form.get('paymentMethods.stripe.publicKey')?.enable();
      this.form.get('paymentMethods.stripe.secretKey')?.setValidators(null);
      this.form.get('paymentMethods.stripe.secretKey')?.updateValueAndValidity();
      this.form.get('paymentMethods.stripe.secretKey')?.reset();
      this.form.get('paymentMethods.stripe.secretKey')?.enable();
      this.form.get('paymentMethods.stripe.name')?.setValidators(null);
      this.form.get('paymentMethods.stripe.name')?.updateValueAndValidity();
      this.form.get('paymentMethods.stripe.name')?.reset();
      this.form.get('paymentMethods.stripe.nameInternal')?.setValidators(null);
      this.form.get('paymentMethods.stripe.nameInternal')?.updateValueAndValidity();
      this.form.get('paymentMethods.stripe.nameInternal')?.reset();
      this.form.get('paymentMethods.stripe.description')?.reset();
      this.getFormArray('stripe', 'requiredPriceGroups').clear();
      this.getFormArray('stripe', 'requiredPaymentMethods').clear();
    }
  }

  isFormRequestError(value: string): boolean {
    let form = this.form?.get(value);
    return !!(form?.errors &&
      this.formHandler.status == 'submitForm');
  }

  drop(event: CdkDragDrop<string[]>) {
    const paymentMethods = this.paymentMethods;

    let newIndex: number = event.currentIndex;
    if (newIndex === -1) {
      newIndex = paymentMethods.length - 1;
    } else if (newIndex == paymentMethods.length) {
    }

    const currentGroup = paymentMethods.at(event.previousIndex);
    paymentMethods.removeAt(event.previousIndex);
    paymentMethods.insert(newIndex, currentGroup)
  }

  get paymentMethods() {
    return this.form.get('paymentMethods')?.get('dynamic') as FormArray;
  }


  addDynamicPaymentMethod(params?: {
    image: string;
    name: string;
    nameInternal: string;
    description: string;
    requiredPriceGroups?: string[];
    requiredPaymentMethods?: string[];
  }) {
    let requiredPriceGroupsControls: FormControl[] = [];
    if (params?.requiredPriceGroups) {
      params.requiredPriceGroups.map(
        r => {
          requiredPriceGroupsControls.push(this.fb.control(r, Validators.required));
        }
      )
    }
    let requiredPaymentMethodsControls: FormControl[] = [];
    if (params?.requiredPaymentMethods) {
      params.requiredPaymentMethods.map(
        r => {
          requiredPaymentMethodsControls.push(this.fb.control(r, Validators.required));
        }
      )
    }
    this.paymentMethods.push(new FormGroup({

      image: new FormControl(params?.image ?? ''),
      name: new FormControl(params?.name ?? null, [Validators.required, Validators.minLength(1)]),
      nameInternal: new FormControl(params?.nameInternal ?? '', [Validators.required, Validators.minLength(1)]),
      description: new FormControl(params?.description ?? ''),
      requiredPriceGroups: this.fb.array(requiredPriceGroupsControls),
      requiredPaymentMethods: this.fb.array(requiredPaymentMethodsControls)
    }));
  }

  setExternalPaymentImage(paymentMethod: 'stripe' | 'paypal' | 'klarna', image: UploadFilePath[]) {
    this.form.get('paymentMethods')?.get(paymentMethod)?.get('image')?.setValue(image[0]?.filePath ?? '');
  }

  setDynamicPaymentImage(index: number, image: UploadFilePath[]) {
    this.paymentMethods.at(index).get('image')?.setValue(image[0]?.filePath ?? '');
  }

  getExternalPaymentImage(paymentMethod: 'stripe' | 'paypal' | 'klarna'): UploadFilePath[] {
    if (this.form.get('paymentMethods')?.get(paymentMethod)?.get('image')?.value !== '') {
      let filePath = this.form.get('paymentMethods')?.get(paymentMethod)?.get('image')?.value ?? '';
      return [{
        filePath,
        thumbnails: {
          small: filePath,
          medium: filePath,
          big: filePath

        }
      }];
    }
    return [];
  }

  getDynamicPaymentImage(index: number): UploadFilePath[] {
    if (this.paymentMethods.at(index).get('image')?.value != '') {
      let filePath = this.paymentMethods.at(index).get('image')?.value ?? '';
      return [{
        filePath,
        thumbnails: {
          small: filePath,
          medium: filePath,
          big: filePath

        }
      }];
    }
    return [];
  }

  removeDynamicPaymentMethod(index: any) {
    const paymentMethods = this.paymentMethods;
    paymentMethods.removeAt(index);
  }

  addFieldByDynamicMethod(pmIndex: any, type: 'requiredPriceGroups' | 'requiredPaymentMethods') {
    this.getFormArrayFromDynamicMethod(pmIndex, type).push(this.fb.control('', Validators.required));
    this.formHandler.htmlRef = this.formTag;
  }

  removeFieldByDynamicMethod(pmIndex: any, type: 'requiredPriceGroups' | 'requiredPaymentMethods', index: number) {
    const formArray = this.getFormArrayFromDynamicMethod(pmIndex, type);
    formArray.removeAt(index);
  }

  getFormArrayFromDynamicMethod(pmIndex: any, type: 'requiredPriceGroups' | 'requiredPaymentMethods'): FormArray {
    return this.paymentMethods.at(pmIndex).get(type) as FormArray;
  }

  getFormArray(method: 'paypal' | 'stripe' | 'klarna', type: 'requiredPriceGroups' | 'requiredPaymentMethods') {
    return this.form.get('paymentMethods')?.get(method)?.get(type) as FormArray;
  }

  addField(method: "paypal" | "stripe" | "klarna", type: "requiredPriceGroups" | "requiredPaymentMethods", value?: string) {
    this.getFormArray(method, type).push(this.fb.control(value, Validators.required));
  }

  removeField(method: 'paypal' | 'stripe' | 'klarna', type: 'requiredPriceGroups' | 'requiredPaymentMethods', index: number) {
    this.getFormArray(method, type).removeAt(index);
  }

  submit() {
    if (this.formStatus != null &&
      this.formStatus != 'submitForm') {
      return;
    }
    this.formHandler.status = 'submitForm';
    if (!this.form.valid) {
      return;
    }
    this.formHandler.status = 'loading';

    this.apiError = undefined;
    let data: Partial<ApiConfig> & { id: string } = {
      id: this.form.get('id')?.value ?? '',
      paymentMethods: {}
    };
    if (data.paymentMethods) {
      if (!this.disablePaypal) {
        data.paymentMethods.paypal = {
          description: this.form.get('paymentMethods.paypal.description')?.value ?? '',
          name: this.form.get('paymentMethods.paypal.name')?.value ?? '',
          nameInternal: this.form.get('paymentMethods.paypal.nameInternal')?.value ?? '',
          requiredPaymentMethods: this.form.get('paymentMethods.paypal.requiredPaymentMethods')?.value as string[] ?? [],
          requiredPriceGroups: this.form.get('paymentMethods.paypal.requiredPriceGroups')?.value as string[] ?? []
        }
        if (this.getExternalPaymentImage('paypal')[0]?.filePath) {
          data.paymentMethods.paypal.image = this.getExternalPaymentImage('paypal')[0]?.filePath;
        }
        if (!this.form.get('paymentMethods.paypal.clientId')?.disabled) {
          data.paymentMethods.paypal.clientId = this.form.get('paymentMethods.paypal.clientId')?.value ?? '';
          data.paymentMethods.paypal.secret = this.form.get('paymentMethods.paypal.secret')?.value ?? '';
          data.paymentMethods.paypal.sandbox = (this.form.get('paymentMethods.paypal.sandbox')?.value == true);
        }
      }
      if (!this.disableKlarna) {
        data.paymentMethods.klarna = {
          description: this.form.get('paymentMethods.klarna.description')?.value ?? '',
          name: this.form.get('paymentMethods.klarna.name')?.value ?? '',
          nameInternal: this.form.get('paymentMethods.klarna.nameInternal')?.value ?? '',
          requiredPaymentMethods: this.form.get('paymentMethods.klarna.requiredPaymentMethods')?.value as string[] ?? [],
          requiredPriceGroups: this.form.get('paymentMethods.klarna.requiredPriceGroups')?.value as string[] ?? []
        }
        if (this.getExternalPaymentImage('klarna')[0]?.filePath) {
          data.paymentMethods.klarna.image = this.getExternalPaymentImage('klarna')[0]?.filePath;
        }
        if (!this.form.get('paymentMethods.klarna.username')?.disabled) {
          data.paymentMethods.klarna.username = this.form.get('paymentMethods.klarna.username')?.value ?? '';
          data.paymentMethods.klarna.password = this.form.get('paymentMethods.klarna.password')?.value ?? '';
          data.paymentMethods.klarna.sandbox = (this.form.get('paymentMethods.klarna.sandbox')?.value == true);
        }
      }
      if (!this.disableStripe) {
        data.paymentMethods.stripe = {
          description: this.form.get('paymentMethods.stripe.description')?.value ?? '',
          name: this.form.get('paymentMethods.stripe.name')?.value ?? '',
          nameInternal: this.form.get('paymentMethods.stripe.nameInternal')?.value ?? '',
          requiredPaymentMethods: this.form.get('paymentMethods.stripe.requiredPaymentMethods')?.value as string[] ?? [],
          requiredPriceGroups: this.form.get('paymentMethods.stripe.requiredPriceGroups')?.value as string[] ?? []
        }
        if (this.getExternalPaymentImage('stripe')[0]?.filePath) {
          data.paymentMethods.stripe.image = this.getExternalPaymentImage('stripe')[0]?.filePath;
        }
        if (!this.form.get('paymentMethods.stripe.publicKey')?.disabled) {
          data.paymentMethods.stripe.publicKey = this.form.get('paymentMethods.stripe.publicKey')?.value ?? '';
          data.paymentMethods.stripe.secretKey = this.form.get('paymentMethods.stripe.secretKey')?.value ?? '';
        }
      }
      data.paymentMethods.dynamic = this.form.get('paymentMethods.dynamic')?.value as any[] ?? [];
    }


    this.configService.updateConfig({data})
      .subscribe(
        r => {
          if (r.error) {

            this.apiError = {notification: r.error.message};
            if (r.error.input) {
              this.apiError.errors = r.error.input;
            }
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
            this.formHandler.status = null;
          } else {
            this.formHandler?.handleApiResult({r});
          }
        },
        error => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          this.formHandler.notification = error;
          this.formHandler.status = null;
          this.formHandler.displayErrors();
        }
      )

  }


  protected readonly faTrashCan = faTrashCan;
  protected readonly faPlusSquare = faPlusSquare;
  protected readonly faBars = faBars;
}
