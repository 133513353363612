import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {
  ApiCustomerNotesDeleteRequest, ApiCustomerNotesDeleteResponse,
  ApiCustomerNotesGetRequest, ApiCustomerNotesGetResponse,
  ApiCustomerNotesSetRequest,
  ApiCustomerNotesSetResponse,
  ApiCustomerNotesUpdateRequest, ApiCustomerNotesUpdateResponse
} from "@shared/api/customer-notes";


@Injectable({
  providedIn: 'root'
})
export class CustomerNoteService {


  constructor(private http: HttpClient) {
  }

  set(data: ApiCustomerNotesSetRequest['params']['data']): Observable<ApiCustomerNotesSetResponse> {
    const body: ApiCustomerNotesSetRequest = {
      id: 'setCustomerNotes#INVALID_PARAMS',
      command: 'customerNotes/set',
      params: {
        data
      }
    };
    return this.http.post<ApiCustomerNotesSetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  update(data: ApiCustomerNotesUpdateRequest['params']['data']): Observable<ApiCustomerNotesUpdateResponse> {
    const body: ApiCustomerNotesUpdateRequest = {
      id: 'updateCustomer#INVALID_PARAMS',
      command: 'customerNotes/update',
      params: {
        data
      }
    };
    return this.http.post<ApiCustomerNotesUpdateResponse>(environment.apiUrl, JSON.stringify(body));
  }


  get(params: ApiCustomerNotesGetRequest['params']): Observable<ApiCustomerNotesGetResponse> {
    const body: ApiCustomerNotesGetRequest = {
      id: 'getCustomerNotes',
      command: 'customerNotes/get',
      params
    };
    return this.http.post<ApiCustomerNotesGetResponse>(environment.apiUrl, JSON.stringify(body));
  }

  getBatch(commands: ApiCustomerNotesGetRequest[]): Observable<ApiCustomerNotesGetResponse[]>{
    return this.http.post<ApiCustomerNotesGetResponse[]>(environment.apiUrl, JSON.stringify(commands));
  }

  delete(id: string): Observable<ApiCustomerNotesDeleteResponse> {
    const body: ApiCustomerNotesDeleteRequest = {
      id: 'deleteCustomerNote',
      command: 'customerNotes/delete',
      params: {
        data: {
          id
        }
      }
    };
    return this.http.post<ApiCustomerNotesDeleteResponse>(environment.apiUrl, JSON.stringify(body));
  }
}
