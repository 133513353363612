import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Window} from '@shared/window-manager/interfaces/window.model';
import {Subject} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormHelperDeprecated} from '@shared/forms/models/form-helper-deprecated';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {finalize} from 'rxjs/operators';
import {ConfigService} from '@shared/config/services/config.service';

@Component({
  selector: 'shared-settings-email-server',
  templateUrl: './email-server.component.html',
  styleUrls: ['./email-server.component.scss']
})
export class EmailServerComponent implements OnInit {
  @ViewChild('formTag', {static: false}) formTag: ElementRef | undefined;
  @Input() window?: Window;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  changeDetectionGroup: string = 'users';

  form = new FormGroup({
    id: new FormControl(''),
    emailServer:new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email]),
      from: new FormControl('', Validators.required),
      host: new FormControl('', Validators.required),
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      encryption: new FormControl('ssl', Validators.required),
      port: new FormControl('465', Validators.required),
      smtpAuth: new FormControl(false)
    })
  });

  formHandler: FormHelperDeprecated;

  errors = null;
  activeTab = '';
  msg = '';
  data: any;


  constructor(
    private configService: ConfigService,
    private store: Store<AppState>,
    private renderer: Renderer2
  ) {
    this.formHandler = new FormHelperDeprecated(
      this.store,
      this.changeDetectionGroup,
      this.form,
      [
        {key: 'email', error: 'Bitte hinterlegen Sie eine E-Mail.'},
        {key: 'from', error: 'Bitte hinterlegen Sie einen Absender.'},
        {key: 'host', error: 'Bitte hinterlegen Sie einen Postausgangsserver.'},
        {key: 'username', error: 'Bitte hinterlegen Sie einen Benutzernamen.'},
        {key: 'password', error: 'Bitte hinterlegen Sie ein Passwort.'},
        {key: 'encryption', error: 'Bitte hinterlegen Sie eine Verschlüsselung.'},
        {key: 'port', error: 'Bitte hinterlegen Sie einen Port.'}
      ],
      this.renderer
    );
  }

  ngAfterViewInit() {
    this.formHandler.htmlRef = this.formTag;
  }

  ngOnInit() {
      this.loadItem();
  }

  loadItem() {
    this.formHandler.status = 'loading';
    this.configService.getConfig()
      .pipe(
        finalize(() => {
          this.formHandler.status = null;
        })
      )
      .subscribe(
        r => {
          this.form.controls['id'].setValue(r.result?.configs.data[0].id ?? '');
          if (r.result?.configs.data[0].emailServer) {
            for (const [key, value] of Object.entries(r.result?.configs.data[0].emailServer)) {
              this.form.controls.emailServer.get(key)?.setValue(value);
            }
          }
          this.form.controls.emailServer.get('password')?.setValue('');
        }
      )
  }

  submit() {
    this.formHandler.validateForm();
    this.formHandler.displayErrors();
    if (!this.formHandler.status &&
      !this.formHandler.isFormErrors()) {
      this.formHandler.status = 'submitForm';
        let params = { data:  this.formHandler?.getFormValues() };
        params.data.emailServer.port = parseInt(params.data.emailServer.port);
        this.configService.updateConfig(params)
          .subscribe(
            r => {
              this.formHandler?.handleApiResult({
                r
              });
            },
            error => {
              this.formHandler.notification = error;
              this.formHandler.status = null;
              this.formHandler.displayErrors();
            }
          )

    }
  }

}
