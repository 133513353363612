<div>
  <div>
    <form>
<!--           <div class="tabs">
              <ul>
                <li [ngClass]="activeTab=='upload' ? 'is-active' : ''" (click)="activeTab='upload'">
                  <a>
                    Upload
                  </a>
                </li>
                <li [ngClass]="activeTab=='uploadedFiles' ? 'is-active' : ''" (click)="activeTab='uploadedFiles'">
                  <a>Hochgeladene Dateien</a></li>
              </ul>
            </div>-->
      <div class="content-content">
        <article *ngIf="apiError" class="message is-danger">
          <div class="message-body">
            <b>{{apiError.notification}}</b>
            <span *ngFor="let error of apiError?.errors" class="is-block">{{error.key}}: {{error.error}}</span>
          </div>
        </article>
        <div class="tab-container" [ngClass]="activeTab!='upload' ? 'is-hidden' : ''">

          <div class="columns">
            <div class="column">
              <shared-upload [filePaths]="uploadFilePaths" (changedFiles)="changedFiles($event)" fileType="publicFile"></shared-upload>
            </div>
          </div>

            <p  class="is-size-5 mb-2">
              Hochgeladene Dateien
            </p>
            <div *ngIf="loading" class="has-text-centered">
              <button class="button is-loading"></button>
            </div>
            <p *ngIf="!loading && uploadsStorage.length == 0">Keine Daten vorhanden.</p>
            <table
              class="table is-fullwidth is-hoverable is-striped is-bordered mt-4">
              <tr *ngFor="let item of uploadsStorage">
                <td>
                  <fa-icon [icon]="faTrashCan" class="is-pointer" (click)="delete(item.id)"></fa-icon>
                </td>
                <td>
                  <div class="is-word-break"><a href="{{s3Url+item.path}}" target="_blank">{{s3Url+item.path}}</a> </div>
                </td>
                <td>
                  <fa-icon *ngIf="isCopyToClipboard(s3Url+item.path)" [icon]="faCircleCheck" class="has-text-success"></fa-icon>
                  <fa-icon *ngIf="!isCopyToClipboard(s3Url+item.path)" [icon]="faCopy" class="is-pointer" (click)="copyToClipboard(s3Url+item.path)"></fa-icon>
                </td>
              </tr>
            </table>

        </div>
      </div>
      <div class="content-foot has-text-centered">
        <shared-form-submit-button></shared-form-submit-button>
      </div>
    </form>
  </div>
</div>


