import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {List} from '@shared/list/models/list';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ListColumn} from '@shared/list/models/list-column';

@Component({
  selector: 'app-list-filter-input',
  templateUrl: './list-filter-input.component.html',
  styleUrls: ['./list-filter-input.component.scss']
})
export class ListFilterInputComponent implements OnInit, OnDestroy {
  @Input() listColumn!: ListColumn<any>;
  @Input() list!: List<any, any, any>;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  form = new FormGroup({
    input: new FormControl(''),
    filterType: new FormControl('*')
  });

  constructor() {
  }

  ngOnInit(): void {
    this.list.loading.list
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        l => {
          if (this.list.getFilters()?.length == 0 &&
            this.list.getFilterArrayObjects()?.length == 0) {
            this.form.get('input')?.setValue(null);
          }
        }
      );

    if (this.listColumn.filterValidators) {
      this.form.get('input')?.setValidators(this.listColumn.filterValidators);
      this.form.updateValueAndValidity();
    }
  }

  setFilter() {
    if (this.listColumn.filterCallback &&
      this.form.valid) {
      this.listColumn.filterCallback({
        inputValue: this.form.get('input')?.value ?? '',
        filterType: this.form.get('filterType')?.value ?? '*'
      });
    } else {
      if (this.form.valid) {
        let value: string = this.form.get('input')?.value ?? '', useRegex: 0 | 1 = 1;

        if (this.form.get('filterType')?.value == '=') {
          useRegex = 0;
        } else {
          if (value.length > 0) {
            value = '.*' + value + '.*';
          }
        }
        this.list.setFilter({
          id: this.listColumn.id,
          fields: [this.listColumn.field],
          useRegex,
          values: [value]
        });
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
