import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  filter,
  switchMap,
  take
} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '@store/state/app.state';
import {setNotification} from '@store/actions/global.actions';
import {
  logout, refreshToken
} from '@store/actions/authentication.action';
import {AuthenticationService} from '@core/services/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService, private store: Store<AppState>) {

  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const isAccessToken = this.authenticationService.isAccessToken();
    const isInjectToken = this.authenticationService.isInjectionToken();
    const isAccessTokenExpired = this.authenticationService.isAccessTokenExpired(5);
    const isRefreshTokenExpired = this.authenticationService.isRefreshTokenExpired();
    const isRefreshToken = this.authenticationService.isRefreshToken();
    const isLoading = this.authenticationService.isLoading();

    // access token vorhanden
    if (isAccessToken) {
      if (isAccessTokenExpired) {
        // refresh token ist gültig > refresh
        if (isRefreshToken &&
          !isRefreshTokenExpired) {
          // Es läuft bereits ein refresh
          if (!isLoading) {
            this.store.dispatch(refreshToken());
          }
          return this.store.select(state => state.authentication.isLoading).pipe(
            filter(isLoading => isLoading === false || isLoading == undefined),
            take(1),
            switchMap(
              () => {
                return next.handle(request);
              }
            )
          );
          // kein gültiger Token > User ausloggen
        } else {
          this.store.dispatch(logout());
          this.store.dispatch(setNotification({
            notification: 'Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein.',
            notificationType: 'error'
          }));
          return this.store.select(state => state.authentication.isLoading).pipe(
            filter(isLoading => isLoading === false || isLoading == undefined),
            take(1),
            switchMap(
              () => {
                return next.handle(request);
              }
            )
          );
        }
      }

      // Es wurde ein Token per URL übergeben, diese muss per injection in den Header geladen werden
      if (isInjectToken) {
        request = this.injectToken(request);
      }
      return next.handle(request);

      // Gibt kein Token
    } else {
      return next.handle(request);
    }
  }


  injectToken(request: HttpRequest<any>) {
    const accessToken = this.authenticationService.getInjectionToken();
    if (accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    } else {
      return request;
    }
    return request;
  }

}
