<section class="modal" [ngClass]="active ? 'is-active' : ''">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ uploadSchema.type }} hochladen</p>
      <button (click)="uploadClose.emit()" class="delete" aria-label="close"></button>

    </header>
    <div class="has-background-white">
      <ul class="steps is-narrow is-medium is-centered has-content-centered my-4">
        <li class="steps-segment" [ngClass]="(getStep()==1)? 'is-active' : ''">
          <a (click)="reset()" class="has-text-dark">
          <span class="steps-marker" [ngClass]="(getStep()==1)? 'is-black' : ''">
            <span class="icon">
               <fa-icon [icon]="faFileCsv"></fa-icon>
            </span>
          </span>
            <div class="steps-content">
              <p class="heading">{{ uploadSchema.type }} hochladen</p>
            </div>
          </a>
        </li>
        <li class="steps-segment" [ngClass]="(getStep()==2)? 'is-active' : ''">
          <span class="steps-marker" [ngClass]="getStep()==2 ? 'is-black' : ''">
            <span class="icon">
              <fa-icon [icon]="faLeftRight"></fa-icon>

            </span>
          </span>
          <div class="steps-content">
            <p class="heading">Spalten zuordnen</p>
          </div>
        </li>
        <li class="steps-segment" [ngClass]="(getStep()==2 && uploadFormStatus == 'success') ? 'is-active' : ''">
        <span class="steps-marker" [ngClass]="isConfirmationError() ? 'is-warning' : 'is-hollow'">
          <span class="icon is-danger">
            <fa-icon *ngIf="!isConfirmationError()" [icon]="faCheck"></fa-icon>
            <fa-icon *ngIf="isConfirmationError()" [icon]="faExclamation"></fa-icon>
          </span>
        </span>
          <div class="steps-content">
            <p class="heading">Bestätigung</p>
          </div>
        </li>
      </ul>
    </div>
    <section  #body class="modal-card-body">
      <article *ngIf="errors && errors.length > 0" class="message is-danger mx-4">
        <div class="message-body">
          <span *ngFor="let e of errors" class="is-block p-1">{{ e.message }}</span>
        </div>
      </article>
      <div *ngIf="getStep()==1" class="file is-boxed pb-4 has-text-centered">
        <label class="file-label m-auto">
          <input class="file-input" type="file" name="resume" type="file" accept='.csv'
                 (change)="fileChangeListener($event)">
          <span class="file-cta">
            <span class="file-icon"><fa-icon [icon]="faUpload"></fa-icon></span>
            <span class="file-label">
              CSV-Datei auswählen
            </span>
          </span>
        </label>
      </div>
      <div *ngIf="getStep()==2">
        <div *ngIf="uploadSchema.info" class="notification is-warning is-light" [innerHTML]="uploadSchema.info"></div>
        <table class="table m-auto" style="max-height: 60vh;">
          <thead>
          <tr>
            <th>Name</th>
            <th>Beispiel aus CSV <span class="is-size-7">[Zeile 2]</span></th>
            <th>Zuordnung <span class="is-size-7">[Zeile 1]</span></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let field of uploadSchema.fields">
            <td>{{ field.label }}</td>
            <td>
              <ng-container *ngFor="let value of data[0]; index as i">
            <span *ngIf="getSelectedCellNumber(field.field) == i">
              {{ getSecondRow(i) }}
            </span>
              </ng-container>
            </td>
            <td>
              <div class="select is-small"
                   [ngClass]="(field.required && startUpload && !isCellMapping(field.field))? 'is-danger' : ''">
                <select (change)="setCellMapping(field.field, $any($event.target).value)">
                  <option [value]="null">Keine Zuordnung</option>
                  <option *ngFor="let value of data[0]; index as i"
                          [selected]="getSelectedCellNumber(field.field) == i" [value]="i">{{ value }}
                  </option>
                </select>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="getStep()==3 && uploadFormStatus=='success'" class="p-4 has-text-centered content">
        <table *ngIf="uploadConfirmations && uploadConfirmations.length > 0" class="table m-auto has-text-left"
               style="width: 80%;">
          <tr *ngFor="let c of uploadConfirmations">
            <td>
              <span *ngIf="c.status=='new'" class="tag is-pulled-left is-success">Neu</span>
              <span *ngIf="c.status=='update'" class="tag is-pulled-left is-info">Aktualisiert</span>
              <span *ngIf="c.status=='error'" class="tag is-pulled-left is-danger">Fehler</span>
            </td>
            <td *ngFor="let f of c.fields" [innerHTML]="f"></td>
          </tr>
        </table>
      </div>
    </section>
    <footer class="modal-card-foot">
      <div *ngIf="getStep() == 2" class="has-text-centered m-auto">
        <div clasS="is-block mb-2">
          <label class="checkbox" (click)="dataSkipFirstRow= !dataSkipFirstRow">
            <input type="checkbox" [checked]="dataSkipFirstRow ? true: false">
            Erste Zeile in CSV überspringen
          </label>
        </div>
        <div class="m-auto has-background-white is-inline-block">
          <shared-form-submit-button  (submit)="submit()"  submitLabel="Daten übermitteln" [loadingMessage]="uploadLoadingMessage" [formStatus]="uploadFormStatus"></shared-form-submit-button>
        </div>
      </div>
      <button *ngIf="getStep() == 3" (click)="uploadClose.emit()" class="button m-auto">
        <span>Fenster schließen</span>
      </button>
    </footer>
  </div>
