import {Action, createReducer, on} from '@ngrx/store';
import {
  visibilities,
  setNotification,
  resetNotifications
} from '../actions/global.actions';
import {GlobalState, initialGlobalState} from '../state/global.state';

export const initialState: GlobalState = initialGlobalState();

const GlobalReducer = createReducer(
  initialState,
  on(setNotification, (state: GlobalState, {notification, notificationType}): GlobalState => {
      let notifications: null | [{
        notification: string,
        type: 'info' | 'error'
      }] = null;
      if (state.notifications) {
        notifications = [...state.notifications];
        notifications.unshift({notification: notification, type: notificationType});
      } else {
        notifications = [{notification: notification, type: notificationType}];
      }
      return {
        ...state,
        notifications: notifications,
        visibilities: {
          ...state.visibilities,
          notifications: true
        },

      }
    }
  ),
  on(resetNotifications, (state: GlobalState): GlobalState => {
      return {
        ...state,
        notifications: null
      }
    }
  ),

  on(visibilities, (state: GlobalState, {property, value}): GlobalState => {
    return {
      ...state,
      visibilities: {
        ...state.visibilities,
        [property]: value
      }
    }
  })


);

export function reducer(state: GlobalState | undefined, action: Action) {
  return GlobalReducer(state, action);
}
