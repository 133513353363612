import {Component, Input} from '@angular/core';
import {ApiError} from "@shared/forms/models/form-handler";

@Component({
  selector: 'shared-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent {

  @Input() apiError!: ApiError | undefined;


  constructor() {


  }

}
