<form [formGroup]="form">
<div class="field">
  <div class="control has-icons-left ">
    <input class="input is-small" type="text" placeholder="Suche" formControlName="input" (input)="_set()">
    <span class="icon is-small is-left">
            <fa-icon [icon]="faSearch"></fa-icon>
          </span>
  </div>
</div>
</form>
