import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Window} from "@shared/window-manager/interfaces/window.model";
import {
  FormArray,
  FormBuilder, FormControl, FormGroup, UntypedFormArray,
  Validators
} from "@angular/forms";
import {FormStatusDeprecated} from "@shared/forms/models/form-helper-deprecated";
import {Store} from "@ngrx/store";
import {AppState} from "@store/state/app.state";
import {finalize} from "rxjs/operators";
import {setChangedItem} from "@store/actions/change-detection.action";
import {DynamicPagesService} from "@shared/dynamic-pages/services/dynamic-pages.service";
import {ApiDynamicPagesSetRequest, ApiDynamicPagesUpdateRequest} from "@shared/api/dynamic-articles";
import {deleteWindow} from "@store/actions/windows.action";
import {faExclamationCircle, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {faPlusSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";

@Component({
  selector: 'shared-form-dynamic-page',
  templateUrl: './dynamic-page-form.component.html',
  styleUrls: ['./dynamic-page-form.component.scss']
})
export class DynamicPageFormComponent implements OnInit {

  @ViewChild('quill', {static: false}) quill: any;

  @Input() window?: Window;


  editContentAsSource?: boolean = false;

  changeDetectionGroup: string = 'dynamicPages';

  apiError?: {
    notification: string,
    errors?: { key: string, error: string }[]
  };
  form = this.fb.group({
    status: this.fb.control('online', Validators.required),
    linkPositions: this.fb.array([this.fb.control('top')]),
    uniqueName: this.fb.control(''),
    pos: this.fb.control(0, Validators.required),
    name: this.fb.control('', Validators.required),
    linkType: this.fb.control(''),
    externalLinkUrl: this.fb.control(''),
    routerLink: this.fb.control(''),
    content: this.fb.control(''),
    SEO: this.fb.group({
      title: this.fb.control(''),
      keywords: this.fb.control(''),
      description: this.fb.control('')
    })
  });

  linkPositions: ('top' | 'b2b_foot' | 'foot_0' | 'foot_1' | 'foot_2')[] = ['top', 'b2b_foot', 'foot_0', 'foot_1', 'foot_2'];

  formStatus: FormStatusDeprecated = null;
  loading?: boolean;


  activeTab = '';


  constructor(
    private dynamicPageService: DynamicPagesService,
    private store: Store<AppState>,
    private fb: FormBuilder
  ) {
    this.activeTab = 'details';


  }


  ngOnInit() {
    if (this.window?.data?.itemId) {
      this.loadItem(this.window.data.itemId);
    }
  }

  ngAfterViewInit() {

    /* this.quill.on('text-change', (delta: any, oldDelta: any, source: any) => {
       console.log(delta, oldDelta, source);
     });*/
  }

  loadItem(id: string) {
    this.loading = true;
    this.dynamicPageService.get({dynamicPages: {}, filters: [{fields: ['id'], values: [id]}]})
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        r => {
          if (r.result?.dynamicPages.data[0]) {
            let data = r.result?.dynamicPages.data[0];
            this.form.get('status')?.setValue(data.status);
            this.form.get('name')?.setValue(data?.name);
            this.form.get('uniqueName')?.setValue(data?.uniqueName);
            this.form.get('pos')?.setValue(data?.pos ?? 0);
            this.form.get('content')?.setValue(data?.content);

            this.form.get('SEO')?.get('title')?.setValue(data?.SEO?.title ?? '');
            this.form.get('SEO')?.get('description')?.setValue(data?.SEO?.description ?? '');
            this.form.get('SEO')?.get('keywords')?.setValue(data?.SEO?.keywords ?? '');


            const matches = data?.content.matchAll(/<([a-z][a-z0-9]*)[^>]*?(\/?)>/gm);
            for (const match of matches) {
              if (match[1] == 'div' ||
                match[1] == 'shared-article-slider'
              ) {
                console.log('disabled edit because ' + match);
                this.editContentAsSource = undefined;
                break;
              }
            }
            this.form.get('routerLink')?.setValue(data?.routerLink ?? '');
            this.form.get('externalLinkUrl')?.setValue(data?.externalLinkUrl ?? '');


            if((data?.externalLinkUrl?.length ?? 0) > 0){
              this.form.get('linkType')?.setValue('externalLinkUrl');
            } else if( (data?.routerLink?.length ?? 0) > 0){
                this.form.get('linkType')?.setValue('routerLink')
            }

            this.getFormArray('linkPositions').clear();
            data?.linkPositions?.map(
              p => {
                this.getFormArray('linkPositions').push(this.fb.control(p, Validators.required));
              }
            )
          }
        }
      )
  }

  updateValidatorsForLinkType() {
    switch(this.form.get('linkType')?.value){
      case 'externalLink':
        this.form.get('externalLinkUrl')?.setValidators([Validators.required, Validators.pattern(/http(s)?:\/\/[\w.-]+(\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+/)]);
        this.form.get('externalLinkUrl')?.updateValueAndValidity();
        this.form.get('routerLink')?.setValue('');
        break;
      case 'routerLink':
        this.form.get('externalLinkUrl')?.setValue('');
        break;
      default:
        this.form.get('routerLink')?.setValue('');
        this.form.get('externalLinkUrl')?.setValue('');
        break;
    }


    if (this.form.get('linkType')?.value == 'externalLink') {
      this.form.get('externalLinkUrl')?.setValidators([]);
      this.form.get('externalLinkUrl')?.updateValueAndValidity();
      this.form.get('externalLinkUrl')?.setValue('');
    }
  }

  isFormRequestError(value: string): boolean {
    let form = this.form?.get(value);
    return !!(form?.errors &&
      this.formStatus == 'submitForm');
  }

  addField(type: 'linkPositions') {
    this.getFormArray(type).push(this.fb.control('top', Validators.required));
  }

  removeField(type: 'linkPositions', index: number) {
    const formArray = this.getFormArray(type);
    formArray.removeAt(index);
  }


  getFormArray(type: 'linkPositions'): FormArray {
    return this.form.get(type) as FormArray;
  }

  submit() {
    if (this.formStatus != null &&
      this.formStatus != 'submitForm') {
      return;
    }
    this.formStatus = 'submitForm';
    if (!this.form.valid) {
      return;
    }
    this.formStatus = 'loading';
    let linkPositions: ('top' | 'b2b_foot' | 'foot_0' | 'foot_1' | 'foot_2')[] = [];
    this.getFormArray('linkPositions').controls.map(
      x => {
        linkPositions.push(x.value)
      }
    )
    if (this.window?.data &&
      this.window.data.itemId) {
      let data: ApiDynamicPagesUpdateRequest['params']['data'] = {
        id: this.window.data.itemId,
        SEO: {
          description: this.form.get('SEO')?.get('description')?.value ?? '',
          keywords: this.form.get('SEO')?.get('keywords')?.value ?? '',
          title: this.form.get('SEO')?.get('title')?.value ?? ''
        },
        content: this.form.get('content')?.value ?? '',
        name: this.form.get('name')?.value ?? '',
        status: this.form.get('status')?.value as any ?? 'online',
        routerLink: this.form.get('routerLink')?.value as any ?? '',
        externalLinkUrl: this.form.get('externalLinkUrl')?.value as any ?? '',
        linkPositions,
        uniqueName: this.form.get('uniqueName')?.value ?? '',
        pos: parseInt(this.form.get('pos')?.value as any)
      };
      this.dynamicPageService.update(data).subscribe(
        {
          next: (r) => {
            if (r.error) {
              this.apiError = {notification: r.error.message};
              if (r.error.input) {
                this.apiError.errors = r.error.input;
              }
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              this.formStatus = null;
            }
            if (r.result?.dynamicPages.updated) {

              this.formStatus = 'success';
              setTimeout(() => {
                this.store.dispatch(deleteWindow({id: this.window?.id || ''}));
                this.store.dispatch(setChangedItem({
                  item: {
                    itemId: this.window?.data.itemId,
                    date: new Date().getTime(),
                    userId: '',
                    action: 'update',
                    group: this.changeDetectionGroup
                  }
                }));
                this.formStatus = null;
              }, 1000);
            } else {
              this.formStatus = 'error';
              setTimeout(() => {
                this.formStatus = null;
              }, 1000);
            }
          },
          error: () => {
            this.formStatus = 'error';
            setTimeout(() => {
              this.formStatus = null;
            }, 1000);
          }
        }
      )
    } else {
      let data: ApiDynamicPagesSetRequest["params"]["data"] = {
        SEO: {
          description: this.form.get('SEO')?.get('description')?.value ?? '',
          keywords: this.form.get('SEO')?.get('keywords')?.value ?? '',
          title: this.form.get('SEO')?.get('title')?.value ?? ''
        },
        content: this.form.get('content')?.value ?? '',
        name: this.form.get('name')?.value ?? '',
        status: this.form.get('status')?.value as any ?? 'online',
        linkPositions,
        pos: parseInt(this.form.get('pos')?.value as any),
        uniqueName: this.form.get('uniqueName')?.value ?? ''
      };
      this.dynamicPageService.set(data).subscribe(
        {
          next: (r) => {
            if (r.error) {
              this.apiError = {notification: r.error.message};
              if (r.error.input) {
                this.apiError.errors = r.error.input;
              }
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              this.formStatus = null;
            }
            if (r.result?.dynamicPages.data.id) {
              this.formStatus = 'success';

              setTimeout(() => {
                this.store.dispatch(deleteWindow({id: this.window?.id || ''}));
                this.store.dispatch(setChangedItem({
                  item: {
                    itemId: r.result?.dynamicPages.data.id || '',
                    date: new Date().getTime(),
                    userId: '',
                    action: 'set',
                    group: this.changeDetectionGroup
                  }
                }));
                this.formStatus = null;
              }, 1000);
            } else {
              this.formStatus = 'error';
              setTimeout(() => {
                this.formStatus = null;
              }, 1000);
            }
          },
          error: () => {
            this.formStatus = 'error';
            setTimeout(() => {
              this.formStatus = null;
            }, 1000);
          }
        }
      )
    }

  }

  ngOnDestroy() {

  }

  protected readonly faExclamationTriangle = faExclamationTriangle;
  protected readonly faExclamationCircle = faExclamationCircle;
  protected readonly faPlusSquare = faPlusSquare;
  protected readonly faTrashCan = faTrashCan;
}
